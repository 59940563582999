import React, { useState } from 'react';
import styles from './ReviewRating.module.css';
import star from '../../../assets/images/ic_star.png';

interface ReviewRatingprops {
  score: (score: number) => void;
}

function ReviewRating({ score }: ReviewRatingprops) {
  const [selectedScore, setSelectedScore] = useState<number | null>(null); // 선택한 Ratings 상태 관리
  const [reviewText, setReviewText] = useState<string>(''); // 리뷰 메시지 상태 관리

  const handleStarClick = (scoreValue: number) => {
    setSelectedScore(scoreValue); // 선택된 Ratings 상태 업데이트
    setReviewText(getReviewMessage(scoreValue));

    // 부모 컴포넌트에 score 전달
    score(scoreValue); // 부모의 score 상태 업데이트
  };

  const getReviewMessage = (score: number) => {
    if (score === 1) {
      return 'It was not good.';
    } else if (score === 2) {
      return 'It was a bit bad.';
    } else if (score === 3) {
      return 'It was average.';
    } else if (score === 4) {
      return 'It was quite useful!';
    } else if (score === 5) {
      return 'It was really useful!';
    }
    return '';
  };

  return (
    <div className={styles['rating-container']}>
      <div>
        {[1, 2, 3, 4, 5].map((score) => (
          <img
            key={score} // React에서 각 요소에 고유한 키 부여
            src={star}
            alt='Ratings'
            className={`${styles.star} ${
              selectedScore !== null && score <= selectedScore ? styles.selected : ''
            }`} // 선택된 Ratings에 따라 클래스 추가
            onClick={() => handleStarClick(score)} // 클릭 이벤트 핸들러
          />
        ))}
        <p>{reviewText}</p>
      </div>
    </div>
  );
}

export default ReviewRating;
