import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { User } from '../models/user';
import { Mate } from '../models/mate';
import { Product } from '../models/product';
import { UserWithdrawalMethod } from '../models/userWithdrawalMethod';
import { MeetingRequestData } from '../models/meetingRequestData';

interface GlobalStore {
  isAuthenticated: boolean;
  setAuthenticated: (isAuthenticated: boolean) => void;

  user: User | null;
  setUser: (user: User | null) => void;

  language: string;
  setLanguage: (language: string) => void;

  mate: Mate | null; //내 메이트 프로필 보관용.
  setMate: (mate: Mate | null) => void;

  selectedBottomMenu: string; //바텀 네비게이션바 선택된 메뉴.
  setSelectedBottomMenu: (name: string) => void;

  tempProduct: Product | null; //선택된 상품. (다른 페이지에서 일시적으로 사용될)
  setTempProduct: (product: Product | null) => void;

  tempUserWithdrawalMethod: UserWithdrawalMethod | null;
  setTempUserWithdrawalMethod: (userWithdrawalMethod: UserWithdrawalMethod | null) => void;

  tempMeetingRequestData: MeetingRequestData | null;
  setTempMeetingRequestData: (meetingRequestData: MeetingRequestData | null) => void;

  resetStore: () => void;
}

const useGlobalStore = create(
  persist<GlobalStore>(
    (set) => ({
      isAuthenticated: false, //로그인 여부.
      setAuthenticated: (isAuthenticated: boolean) => set({ isAuthenticated: isAuthenticated }),

      user: null, //로그인된 유저 객체.
      setUser: (user: User | null) => set({ user: user }),

      language: 'en', //언어 설정 값. (기본값 영어)
      setLanguage: (language: string) => set({ language: language }),

      mate: null,
      setMate: (mate: Mate | null) => set({ mate: mate }),

      selectedBottomMenu: 'home',
      setSelectedBottomMenu: (name: string) => set({ selectedBottomMenu: name }),

      tempProduct: null,
      setTempProduct: (product: Product | null) => set({ tempProduct: product }),

      tempUserWithdrawalMethod: null,
      setTempUserWithdrawalMethod: (userWithdrawalMethod: UserWithdrawalMethod | null) =>
        set({ tempUserWithdrawalMethod: userWithdrawalMethod }),

      tempMeetingRequestData: null,
      setTempMeetingRequestData: (meetingRequestData: MeetingRequestData | null) => set({ tempMeetingRequestData: meetingRequestData }),

      resetStore: () => set({ 
        isAuthenticated: false, 
        user: null, 
        mate: null, 
        tempProduct: null, 
        tempUserWithdrawalMethod: null,
        tempMeetingRequestData: null,
      })
    }),
    {
      name: 'global-storage',
    }
  )
);

export default useGlobalStore;
