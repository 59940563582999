import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './MyMeeting.module.css';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import MeetingSuggestCard from './components/MeetingSuggestCard';
import UpcomingMeetingCard, { MeetingType } from './components/UpcomingMeetingCard';
import searchIcon from '../../assets/images/ic_search.png';
import { Sheet } from 'react-modal-sheet';
import closeIcon from '../../assets/images/ic_close.png';
import bellIcon from '../../assets/images/ic_bell.png';
import { User } from '../../models/user'; // User 인터페이스 import
import MeetingStatusCard from './components/MeetingStatusCard';
import { Meeting } from '../../models/meeting';
import meetingService from '../../services/meetingService';
import { MeetingStatus } from '../../constants/constants';
import Popup from '../../components/popup/Popup';
import mateService from '../../services/mateService';
import authService from '../../services/authService';
import userService from '../../services/userService';
import { Mate } from '../../models/mate';
import { Product } from '../../models/product';
import { format } from 'date-fns';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import timeIcon from '../../assets/images/ic_time.png';
import { MateVerifyStatus } from '../../constants/constants';

function MyMeeting() {
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);
  const [isOpenMeetingSheet, setOpenMeetingSheet] = useState(false);
  const [openMeetingData, setOpenMeetingData] = useState<Meeting | undefined>(undefined);
  const [writtenReview, setWrittenReview] = useState(false); // 리뷰 작성 여부
  const [alarmCount, setAlarmCount] = useState(0); // 알림 갯수 상태 추가
  const [meetingVisible, setMeetingVisible] = useState(true); // 미팅 요청 탭 표시 상태
  const [selectedMeetingTabIndex, setSelectedMeetingTabIndex] = useState(0);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [meetingSuggest, setMeetingSuggest] = useState<Meeting[]>([]);
  const [userData, setUserDate] = useState<User | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedMeetingId, setSelectedMeetingId] = useState<number | null>(null);
  // 링크복사 함수
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  // 알림 누를 시 Requests 이동 되는 함수
  useEffect(() => {
    if (location.state?.meetingVisible !== undefined) {
      setMeetingVisible(location.state.meetingVisible);
    }
  }, [location.state]);

  const productReview = location.state; // 전달된 state 데이터를 받음
  const today = new Date();
  // 오늘 날짜 값 설정
  today.setHours(0, 0, 0, 0); // 오늘 자정 설정
  const date = new Date(today);
  date.setDate(today.getDate() + 3);

  useEffect(() => {
    loadNotificationCount();
    userList(); // 컴포넌트 마운트 시 데이터 가져오기
    suggestData();
    meetingData(); // 컴포넌트 마운트 시 데이터 가져오기
  }, []);

  async function loadNotificationCount() {
    const count: number = await userService.getNotificationCount();
    setAlarmCount(count);
  }

  const userList = async () => {
    try {
      const userListData = await authService.myprofile();
      if (userListData && userListData.user) {
        // user 객체 확인
        setUserDate(userListData.user); // user를 상태로 설정
      }
    } catch (err) {
      console.log(err);
      toast('error', 'Something went wrong');
    }
  };

  // console.log('제안 온 미팅', meetingSuggest);
  console.log(userData);
  console.log(meetings);

  const meetingData = async () => {
    try {
      const meetingList = await meetingService.getMeeting();
      if (meetingList && meetingList.length > 0) {
        setMeetings(meetingList);
      }
    } catch (err) {
      console.log(err);
      toast('error', 'Something went wrong');
    }
  };
  const suggestData = async () => {
    try {
      const suggestList = await meetingService.getSuggest();
      if (suggestList && suggestList.length > 0) {
        setMeetingSuggest(suggestList);
      }
    } catch (err) {
      console.log(err);
      toast('error', 'Something went wrong');
    }
  };

  useEffect(() => {
    // 상태가 전달되면 바텀 시트 열기
    if (location.state?.reviewSubmitted) {
      setOpenBottomSheet(true);
    }
  }, [location.state]);

  const meetingTabList = [
    { status: 'ALL', label: 'ALL' },
    { status: 'WAITING', label: 'WAITING' },
    { status: 'DETERMINE', label: 'DETERMINE' },
    { status: 'COMPLETE', label: 'COMPLETE' },
    { status: 'CANCELLED', label: 'CANCELLED' },
  ];

  // 추후 수정
  const sortMeetings = (a: Meeting, b: Meeting) => {
    const dateA = a.meeting_date ? new Date(a.meeting_date).getTime() : -Infinity;
    const dateB = b.meeting_date ? new Date(b.meeting_date).getTime() : -Infinity;
    return dateB - dateA; // 최신순으로 정렬
  };

  const upcomingMeetings = meetings
    .filter((meeting) => {
      if (!meeting.meeting_date) return false;
      if (meeting.status !== MeetingStatus.DETERMINE) return false; //확정된 미팅 아닐 경우 표시하지 않음.

      const meetingDate = new Date(meeting.meeting_date);
      return meetingDate >= today && meetingDate <= date; // 오늘부터 3일 뒤까지
    })
    .sort((a, b) => {
      const dateA = a.meeting_date ? new Date(a.meeting_date).getTime() : -Infinity;
      const dateB = b.meeting_date ? new Date(b.meeting_date).getTime() : -Infinity;
      return dateA - dateB; // 오름차순 정렬 (오늘이 먼저 나오도록)
    });

  const filteredMeetings =
    selectedMeetingTabIndex === 0
      ? meetings.sort(sortMeetings)
      : meetings
          .filter((meeting) => meeting.status === meetingTabList[selectedMeetingTabIndex].status)
          .sort(sortMeetings);

  function selectMeetingTab(index: number) {
    setSelectedMeetingTabIndex(index);
  }

  function done() {
    toast('success', 'Sign in!');
  }

  function getTime() {
    const date = new Date();
    const hour = date.getHours();
    const minute = date.getMinutes();

    const amPm = hour >= 12 ? 'PM' : 'AM';
    const resultHour = hour > 12 ? hour - 12 : hour;

    return `${amPm} ${resultHour}:${minute < 10 ? '0' + minute : minute}`;
  }

  useEffect(() => {
    if (location.state?.reviewSubmitted) {
      setOpenBottomSheet(true);
      setWrittenReview(false); // 작성 가능한 리뷰가 없는 경우
    }
  }, [location.state]);

  // 미팅 요청을 클릭하여 목록을 표시하는 함수
  const toMeetingRequest = () => {
    setMeetingVisible(false);
  };
  const toMeetingAll = () => {
    setMeetingVisible(true);
  };

  // 네비게이션 함수들
  const toPayment = () => {
    navigate('/meeting/myMeeting/payment');
  };

  // id로 연동되게 수정
  const toSuggested = (selectedSuggest: Meeting) => {
    navigate('/meeting/suggested', {
      state: selectedSuggest,
    });
  };

  // id로 연동되게 수정
  const toConfirmDetail = (meeting: Meeting) => {
    navigate('/meeting/confirmDetail', { state: meeting });
  };

  const toReview = (meeting: Meeting) => {
    if (userData?.id === meeting.mate_user_id) {
      // 본인의 미팅에 접근을 차단
      toast('error', 'You cannot leave a review for your own meeting.');
      return;
    }

    navigate('/meeting/review', { state: meeting });
  };
  const toAlarm = () => {
    navigate('/my/notification');
  };
  const goProfile = (user: Meeting) => {
    navigate(`/mate/profile`, {
      state: user.user,
    });
  };
  const showMeetingSheet = (meeting: Meeting) => {
    setOpenMeetingData(meeting);
    setOpenMeetingSheet(true);
  };

  const toMeet = () => {
    if (
      openMeetingData!.testMeeting !== null &&
      openMeetingData!.testMeeting.meet_url !== null &&
      openMeetingData!.testMeeting.meet_url !== ''
    ) {
      //링크 팝업으로 띄우기.
      window.open(openMeetingData!.testMeeting.meet_url!, '_blank', 'noopener, noreferrer');

      //미팅 완료 처리 요청
      requestCompleteMeeting(openMeetingData?.id!);
    }
    setOpenMeetingSheet(false);
  };

  // 미팅 링크 복사 함수
  const successCopy = async () => {
    try {
      if (meetings[0]?.meet_url) {
        await navigator.clipboard.writeText(meetings[0].meet_url);
        toast('success', 'Meeting link copied!', false);
      } else {
        toast('error', 'No meeting link available', false);
      }
    } catch (err) {
      toast('error', 'Failed to copy link', false);
      console.log(err);
    }
  };

  // 상품 변경
  const cancelBtn = async (meetingId: number) => {
    const meetingStatus: MeetingStatus = MeetingStatus.CANCELLED;
    try {
      const response = await meetingService.cancelMeeting(meetingId);
      if (response) {
        // 미팅 취소 성공 시, 미팅 목록 상태 업데이트
        setMeetings((prevMeetings) =>
          prevMeetings.map((meeting) =>
            meeting.id === meetingId
              ? { ...meeting, status: meetingStatus } // 상태 변경
              : meeting
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const requestCompleteMeeting = async (meetingId: number) => {
    try {
      const response = await meetingService.completeMeeting(meetingId);
      if (response === 'success') {
        setMeetings((prevMeetings) =>
          prevMeetings.map((meeting) =>
            meeting.id === meetingId
              ? { ...meeting, status: MeetingStatus.COMPLETE } // 상태 변경
              : meeting
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 구매 확정
  const confirmBtn = async (meetingId: number) => {
    const meetingStatus: MeetingStatus = MeetingStatus.COMPLETE;
    try {
      const response = await meetingService.confirmPurchase(meetingId);
      if (response === 'success') {
        setMeetings((prevMeetings) =>
          prevMeetings.map((meeting) =>
            meeting.id === meetingId
              ? { ...meeting, status: meetingStatus } // 상태 변경
              : meeting
          )
        );
      }
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={false}
        actionType='icon'
        // 나중엔 메이트, 회원 구분하여 조인
        actionTitle={
          <div className={styles['alarm']}>
            <img
              src={bellIcon}
              alt='Notification'
              style={{ margin: 0 }}
            />
            {alarmCount > 0 && <div className={styles['alarm-count']}>{alarmCount}</div>}
          </div>
        }
        secondaryTitle={
          <img
            src={searchIcon}
            alt=''
            style={{ margin: 0 }}
          />
        }
        onAction={toAlarm}
        onSecondaryAction={() => navigate('/search')}
      />
      <div className={styles['list-container']}>
        <div className={styles['title-container']}>
          {/* {userData?.mate && userData?.mate.verify_status === MateVerifyStatus.APPROVE ? (
            <div className={styles['mate-title-container']}>
              <div
                className={`${styles['title-text']} ${
                  meetingVisible ? '' : styles['title-text-no-active']
                }`}
                onClick={toMeetingAll}
              >
                View All
              </div>
              <div
                className={`${styles['title-text']} ${
                  meetingVisible ? styles['title-text-no-active'] : ''
                }`}
                style={{ marginLeft: '1.4rem' }}
                onClick={toMeetingRequest}
              >
                {meetingSuggest.length >= 1 && <span></span>}
                Requests
              </div>
            </div>
          ) : (
            <div className={styles['mate-title-container']}>
              <div className={styles['title-text']}>My Meetings</div>
            </div>
          )} */}
          {userData?.mate === null || userData?.mate!.verify_status === MateVerifyStatus.WAIT ? (
            <div className={styles['mate-title-container']}>
              <div className={styles['title-text']}>My Meetings</div>
            </div>
          ) : (
            <div className={styles['mate-title-container']}>
              <div
                className={`${styles['title-text']} ${
                  meetingVisible ? '' : styles['title-text-no-active']
                }`}
                onClick={toMeetingAll}
              >
                View All
              </div>
              <div
                className={`${styles['title-text']} ${
                  meetingVisible ? styles['title-text-no-active'] : ''
                }`}
                style={{ marginLeft: '1.4rem' }}
                onClick={toMeetingRequest}
              >
                {meetingSuggest.length >= 1 && <span></span>}
                Requests
              </div>
            </div>
          )}

          <div className={styles['title-trailer']}>How to Join</div>
        </div>
        {/* 미팅 요청 버튼을 클릭하여 isMeetingRequestVisible이 true일 때만 MeetingSuggestCard 목록을 렌더링 */}
        {meetingVisible == true ? (
          <div>
            <div className={styles['timezone-container']}>
              <div>
                <img
                  src={timeIcon}
                  alt=''
                />
              </div>
              <div>
                {' '}
                {upcomingMeetings.length === 0 && meetingSuggest.length === 0
                  ? `How about connecting with someone new?`
                  : upcomingMeetings.length > 0 && meetingSuggest.length > 0
                  ? `${meetingSuggest.length} meetings awaiting Mate's respons\r\n ${upcomingMeetings.length} coming up soon!`
                  : upcomingMeetings.length > 0
                  ? `${upcomingMeetings.length} coming up soon!`
                  : meetingSuggest.length > 0
                  ? `${meetingSuggest.length} meetings awaiting Mate's respons`
                  : ''}
              </div>
            </div>

            {upcomingMeetings.length > 0 && (
              <>
                <div className={styles['sub-text']}>
                  Upcoming Meetings {upcomingMeetings.length}
                </div>
                {upcomingMeetings.slice(0, 1).map((meeting) => (
                  <UpcomingMeetingCard
                    key={meeting.id}
                    productTime={meeting.product.time}
                    type={meeting.product.type}
                    time={meeting.product.time}
                    link={
                      meeting.testMeeting !== null &&
                      meeting.testMeeting.meet_url !== null &&
                      meeting.testMeeting.meet_url !== ''
                        ? meeting.testMeeting.meet_url!
                        : ''
                    }
                    name={meeting.product.type}
                    nickName={meeting!.mate!.user!.nickname}
                    company={meeting!.mate!.user!.company}
                    meetingTime={meeting.meeting_date!}
                    onAction={() => toConfirmDetail(meeting)}
                    onSecondaryAction={() => showMeetingSheet(meeting)}
                    onthirdAction={successCopy}
                  />
                ))}
              </>
            )}

            <div className={styles['sub-text']}>My Meeting History</div>
            <div className={styles['my-meeting-history-button-container']}>
              {meetingTabList.map((tab, idx) => (
                <Button
                  key={idx}
                  width='auto'
                  height='3.3rem'
                  paddingHorizontalRem={1.6}
                  radius={6}
                  active={selectedMeetingTabIndex === idx}
                  activeColor='#E9EBEE'
                  activeTextColor='#1D1D1E'
                  activeFontWeight={600}
                  activeFontSize={'1.4rem'}
                  inactiveColor='#F7F8F9'
                  inactiveTextColor='#AAACB6'
                  inactiveFontWeight={600}
                  inactiveFontSize={'1.4rem'}
                  onClick={() => selectMeetingTab(idx)}
                >
                  {tab.label}
                </Button>
              ))}
            </div>
            <div>
              {filteredMeetings.map((meeting) => (
                <MeetingStatusCard
                  key={meeting.id}
                  profileImage={
                    meeting.mate?.user?.profile_image
                      ? `${process.env.REACT_APP_STORE_ADDRESS}/${meeting.mate?.user.profile_image}`
                      : ''
                  }
                  productName={meeting.product.type}
                  statusText={meeting.status}
                  nickName={meeting.mate?.user?.nickname ?? 'Unknown'}
                  companyName={meeting.mate?.user?.company ?? 'Unknown'}
                  meetingTime={meeting.meeting_date}
                  productTime={meeting.product.time ?? 0} // 기본값 설정
                  actionTitle1={
                    meeting.status === 'WAITING' || meeting.status === 'DETERMINE'
                      ? 'Cancel'
                      : meeting.status === 'COMPLETE'
                      ? 'View Details'
                      : 'Status unknown'
                  }
                  actionTitle2={
                    meeting.status === 'WAITING'
                      ? 'View Payment'
                      : meeting.status === 'DETERMINE'
                      ? 'View Details'
                      : meeting.status === 'COMPLETE'
                      ? 'Leave a Review'
                      : 'Status unknown'
                  }
                  actionTitle3={
                    meeting.status === 'COMPLETE' ? 'Confirm Purchase' : 'Status unknown'
                  }
                  useButtonCount={
                    meeting.status === 'CANCELLED'
                      ? 0
                      : meeting.status === 'WAITING' || meeting.status === 'DETERMINE'
                      ? 2
                      : meeting.status === 'COMPLETE' && userData?.id === meeting.mate_user_id
                      ? 1
                      : 2
                  }
                  onAction1={() => {
                    if (
                      (meeting.status === 'WAITING' || meeting.status === 'DETERMINE') &&
                      meeting.id
                    ) {
                      setSelectedMeetingId(meeting.id); // 선택한 meetingId 저장
                      setShowPopup(true); // 팝업 열기
                    } else if (meeting.status === 'COMPLETE') {
                      toConfirmDetail(meeting);
                    }
                  }}
                  onAction2={() =>
                    meeting.status === 'WAITING'
                      ? toPayment()
                      : meeting.status === 'DETERMINE'
                      ? toConfirmDetail(meeting)
                      : meeting.status === 'COMPLETE'
                      ? toReview(meeting)
                      : ''
                  }
                  onAction3={() =>
                    meeting.status === 'COMPLETE' ? confirmBtn(meeting?.id || 0) : ''
                  }
                />
              ))}
            </div>
          </div>
        ) : (
          <div>
            <div className={styles['meeting-request-container']}>
              {meetingSuggest.map((meeting: Meeting, idx: number) => (
                <MeetingSuggestCard
                  key={idx}
                  type={meeting.product.type}
                  price={meeting.product.price}
                  profileImage={
                    meeting.user.mate !== null && meeting.user?.mate?.profile_image !== '' // 메이트가 있으면 유저 프로필로
                      ? `${process.env.REACT_APP_STORE_ADDRESS}/${meeting.user.mate!.profile_image}` // 메이트가 없으면 유저 프로필로
                      : meeting.user.profile_image !== ''
                      ? `${process.env.REACT_APP_STORE_ADDRESS}/${meeting.user.profile_image}`
                      : profileDefaultIcon
                  }
                  mateTag={meeting.user.mate === null ? '' : 'Mate'}
                  suggestNickName={meeting.user.nickname}
                  mateStatus={meeting.mate_status}
                  onAction={() => toSuggested(meeting)} // 클릭된 meeting를 전달
                  goProfile={() => goProfile(meeting)}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <Footer />
      <Sheet
        isOpen={isOpenMeetingSheet}
        detent='content-height'
        onClose={() => setOpenMeetingSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px' }}>
          <Sheet.Content style={{ marginBottom: '2.0rem' }}>
            <div className={styles['sheet-container']}>
              <div className={styles['sheet-title']}>Start Your Meeting</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenMeetingSheet(false)}
              />
            </div>
            <div className={styles['sheet-sub-title']}>
              Copy the link below or join directly.
              <br />
              You can enter 10 minutes before start time.
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-content-description-text']}>
                {'Meetings are recorded for quality control\r\nand stored for 7 days.'}
              </div>
              <div className={styles['button-container']}>
                <>
                  <Button
                    width='100%'
                    height={'4.8rem'}
                    radius={10}
                    activeFontSize='1.5rem'
                    activeFontWeight={600}
                    activeTextColor='#FFFFFF'
                    active={true}
                    onClick={toMeet}
                  >
                    Join Now
                  </Button>
                </>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheet(false)} />
      </Sheet>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setOpenBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px' }}>
          <Sheet.Content style={{ marginBottom: '2.0rem' }}>
            <div className={styles['sheet-container']}>
              <div className={styles['sheet-title']}>Review Submitted!</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-content-description-text']}>
                {'Thanks for your helpful feedback'}
              </div>
              <div className={styles['button-container']}>
                {writtenReview ? (
                  <>
                    <Button
                      height={'4.8rem'}
                      width='100%'
                      radius={10}
                      activeFontSize='1.5rem'
                      activeFontWeight={600}
                      active={true}
                      onClick={() => navigate('/meeting/myReview')}
                    >
                      View my review
                    </Button>
                    <Button
                      width='100%'
                      height={'4.8rem'}
                      radius={10}
                      activeFontSize='1.5rem'
                      activeFontWeight={600}
                      active={true}
                      onClick={() => setOpenBottomSheet(false)}
                    >
                      Write new review
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      height={'4.8rem'}
                      width='100%'
                      radius={10}
                      activeFontSize='1.5rem'
                      activeFontWeight={600}
                      active={true}
                      onClick={() => setOpenBottomSheet(false)}
                      bgColor='var(--color-white200)'
                      activeTextColor='black'
                    >
                      Close
                    </Button>
                    <Button
                      width='100%'
                      height={'4.8rem'}
                      radius={10}
                      activeFontSize='1.5rem'
                      activeFontWeight={600}
                      active={true}
                      onClick={() => navigate('/meeting/myReview', { state: productReview })}
                    >
                      View my review
                    </Button>
                  </>
                )}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheet(false)} />
      </Sheet>
      <Popup
        show={showPopup}
        width={'27.4rem'}
        height={'18rem'} /*onClose={() => setShowPopup(false)}*/
        onClose={() => setShowPopup(false)}
      >
        <div className={styles['popup-container']}>
          <div className={styles['popup-title']}>Do you want to cancel?</div>
          <div className={styles['popup-content']}>{'A canceled meeting cannot be restored!'}</div>
          <div className={styles['popup-button-container']}>
            <div
              className={styles['popup-button']}
              // onClick={() => goInputProfile()}
              onClick={() => {
                if (selectedMeetingId) {
                  cancelBtn(selectedMeetingId); // meetingId 전달
                  setShowPopup(false); // 팝업 닫기
                }
              }}
            >
              Yes
            </div>
            <div
              className={styles['popup-button']}
              // onClick={() => goInputProfile()}
              onClick={() => {
                setShowPopup(false); // 팝업 닫기
              }}
            >
              No
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default MyMeeting;
