import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import styles from './Denied.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import selectedIcon from '../../assets/images/ic_radio_selected.png';
import unselectedIcon from '../../assets/images/ic_radio_unselected.png';
import arrowDownIcon from '../../assets/images/ic_combo_arrow_down.png';
import backIcon from '../../assets/images/ic_back.png';
import checkIcon from '../../assets/images/ic_check_default.png';
// react-calendar 라이브러리 사용
import Calendar from 'react-calendar';
// 달력 스타일 css
import './DeniedCalender.css';
import ReviewSelect from './components/ReviewSelect';
import { Meeting } from '../../models/meeting';
import meetingService from '../../services/meetingService';

function MeetingDenied() {
  const navigate = useNavigate();
  const location = useLocation();
  const suggestData = location.state;
  const [selectedReason, setSelectedReason] = useState<string | null>(null); // 선택된 이유를 관리

  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);
  const [selectedDates, setSelectedDates] = useState<(Date | null)[]>([null, null, null]); // 3개의 날짜 관리
  const [value, onChange] = useState<Date | null>(null); // 모달 내부의 임시 선택된 날짜
  const [selectedTimes, setSelectedTimes] = useState<string[]>(['', '', '']); // 3개의 시간 관리
  const [currentSelectIndex, setCurrentSelectIndex] = useState<number | null>(null); // 현재 선택 중인 슬롯
  const [filteredTimes, setFilteredTimes] = useState<string[]>([]); // 필터링된 시간 목록

  // 월요일이 0으로 일요일이 6으로
  const getAdjustedDay = (day: number): number => {
    return (day + 6) % 7; // 월요일을 0으로, 일요일을 6으로 조정
  };

  const toResuggested = () => {
    // 선택된 이유가 "상품과 적합하지 않은 신청자에요" (selectedReason === 1)라면 거절 화면으로 이동
    if (selectedReason === 'notMatch' || selectedReason === 'etc') {
      navigate('/meeting/deniedComplete'); // 'denied' 경로로 이동
    } else if (selectedReason === 'changeSchedule') {
      navigate('/meeting/resuggested'); // 일정 변경을 원할 때 'resuggested' 경로로 이동
    }
  };

  const handleReasonSelect = (index: string) => {
    setSelectedReason(index); // 선택된 이유를 업데이트합니다.
  };
  // console.log(suggestData.mate.available_time_list);
  // console.log(suggestData);

  const filterTimesByDay = (day: number) => {
    const adjustedDay = getAdjustedDay(day); // 요일 값 조정
    return suggestData.mate.available_time_list.filter((time: string) => {
      const dayOfWeek = parseInt(time.split(',')[0], 10);
      return dayOfWeek === adjustedDay;
    });
  };

  const handleDateChange = (date: Date) => {
    onChange(date);
    const dayOfWeek = date.getDay();
    const timesForDay = filterTimesByDay(dayOfWeek);
    const splitInto30MinIntervals = (timeRange: string): string[] => {
      const [startTime, endTime] = timeRange.split('-').map((time) => time.trim());

      const toMinutes = (time: string) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };

      const toTimeStringAMPM = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        const period = hours < 12 ? 'AM' : 'PM';
        const displayHours = hours % 12 || 12; // 0을 12로 변환
        return `${String(displayHours)}:${String(mins).padStart(2, '0')} ${period} (30m)`;
      };

      const startMinutes = toMinutes(startTime);
      const endMinutes = toMinutes(endTime);
      const intervals: string[] = [];

      for (let current = startMinutes; current < endMinutes; current += 30) {
        intervals.push(toTimeStringAMPM(current));
      }

      return intervals;
    };

    // 모든 시간을 30분 간격으로 나눔
    const intervalsForDay = timesForDay.flatMap((time: string) =>
      splitInto30MinIntervals(time.split(',').slice(1).join(' - '))
    );

    setFilteredTimes(intervalsForDay);
  };

  const handleTimeSelect = (time: string) => {
    if (currentSelectIndex !== null) {
      const updatedDates = [...selectedDates];
      const updatedTimes = [...selectedTimes];

      updatedDates[currentSelectIndex] = value;
      updatedTimes[currentSelectIndex] = time;

      setSelectedDates(updatedDates);
      setSelectedTimes(updatedTimes);

      setOpenBottomSheet(false);
      onChange(null); // 초기화
    }
  };

  const isDateSelected = (index: number) => selectedDates[index] !== null;

  const deniedMeeting = async () => {
    try {
      const meetingId = suggestData.id;
      const deniedType = selectedReason ?? 'etc';
      // 날짜와 시간을 ISO 형식으로 변환하는 함수
      const toISOFormat = (date: Date | null, time: string): string => {
        if (!date || !time) return '';

        const [hour, minute, period] = time.match(/(\d+):(\d+)\s(AM|PM)/i)!.slice(1);

        // 시간 계산
        let hour24 = parseInt(hour, 10);
        if (period === 'PM' && hour24 !== 12) hour24 += 12;
        if (period === 'AM' && hour24 === 12) hour24 = 0;

        // Date 객체를 새로운 시간으로 설정
        const updatedDate = new Date(date);
        updatedDate.setHours(hour24, parseInt(minute, 10));

        return updatedDate.toISOString(); // ISO 8601 포맷
      };

      const schedule1 = toISOFormat(selectedDates[0], selectedTimes[0] || '');
      const schedule2 = toISOFormat(selectedDates[1], selectedTimes[1] || '');
      const schedule3 = toISOFormat(selectedDates[2], selectedTimes[2] || '');

      const response = await meetingService.deniedMeeting(
        meetingId,
        deniedType,
        schedule1,
        schedule2,
        schedule3
      );
      if (response) {
        console.log('거절 완료', response);
        console.log(meetingId);
        console.log(deniedType);
        console.log(schedule1);
        console.log(schedule2);
        console.log(schedule3);
        // 네비게이션
        if (selectedReason === 'notMatch' || selectedReason === 'etc') {
          navigate('/meeting/deniedComplete'); // 'denied' 경로로 이동
        } else if (selectedReason === 'changeSchedule') {
          navigate('/meeting/resuggested'); // 일정 변경을 원할 때 'resuggested' 경로로 이동
        }
      } else {
        console.log('실패');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['container']}>
        <div className={styles['title-text']}>Could you let us know why?</div>
        <div className={styles['title-hint-text']}>
          The reason will not be shared with the requester.
        </div>
        <div className={styles['item-container']}>
          {/*
          <div
            className={styles['title-item-row']}
            onClick={() => handleReasonSelect('changeSchedule')}
          >
            <img
              src={selectedReason === 'changeSchedule' ? selectedIcon : unselectedIcon}
              alt=''
            />
            I want to reschedule
          </div>
          */}
          <div
            className={styles['title-item-row']}
            onClick={() => handleReasonSelect('notMatch')}
          >
            <img
              src={selectedReason === 'notMatch' ? selectedIcon : unselectedIcon}
              alt=''
            />
            The meeting topic doesn't align with them
          </div>
          <div
            className={styles['title-item-row']}
            onClick={() => handleReasonSelect('etc')}
          >
            <img
              src={selectedReason === 'etc' ? selectedIcon : unselectedIcon}
              alt=''
            />
            Other
          </div>
        </div>

        {/* 선택된 이유가 0인 경우에만 divider가 보이게 설정 */}
        <div
          className={styles['divider']}
          style={{ display: selectedReason === 'changeSchedule' ? 'block' : 'none' }}
        />

        {selectedReason === 'changeSchedule' && (
          <>
            <div className={styles['sub-text']}>Would you like to propose a different date?</div>
            <div className={styles['sub-hint-text']}>
              You can suggest a new meeting time to the requester
            </div>
            {[0, 1, 2].map((index) => {
              const label =
                index === 0
                  ? 'Propose First Schedule'
                  : index === 1
                  ? 'Propose Second Schedule'
                  : 'Propose Third Schedule';

              return !isDateSelected(index) ? (
                <div
                  key={index}
                  className={styles['sub-item-row']}
                  onClick={() => {
                    setCurrentSelectIndex(index);
                    setOpenBottomSheet(true);
                  }}
                >
                  {label}
                  <img
                    src={arrowDownIcon}
                    alt=''
                  />
                </div>
              ) : (
                <div
                  key={index}
                  className={`${styles['sub-item-row']} ${styles['sub-item-row-selected']}`}
                  onClick={() => {
                    setCurrentSelectIndex(index);
                    setOpenBottomSheet(true);
                  }}
                >
                  <div>
                    <div className={styles['sub-item-datetime1']}>
                      {selectedDates[index]?.toLocaleDateString('en-US', {
                        month: 'numeric',
                        day: 'numeric',
                      })}{' '}
                      (
                      {selectedDates[index]?.toLocaleDateString('en-US', {
                        weekday: 'short',
                      })}
                      )
                    </div>
                    <div className={styles['sub-item-datetime2']}>{selectedTimes[index]}</div>
                  </div>
                  <img
                    src={checkIcon}
                    alt=''
                  />
                </div>
              );
            })}
          </>
        )}

        <div style={{ marginBottom: '0.6rem' }} />
        <div className={styles['button-container']}>
          <Button
            height={'5.6rem'}
            radius={12}
            activeFontSize='1.6rem'
            activeFontWeight={700}
            active={true}
            // onClick={toResuggested}
            onClick={() => deniedMeeting()}
          >
            Done
          </Button>
        </div>
      </div>

      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setOpenBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '64rem' }}>
          <Sheet.Content style={{ marginBottom: '1.4rem' }}>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-flex']}>
                <img
                  src={backIcon}
                  alt=''
                  onClick={() => setOpenBottomSheet(false)}
                />
                <div className={styles['sheet-terms-title']}>Select Schedule</div>
              </div>
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content']}>
                <Calendar
                  value={value}
                  onClickDay={handleDateChange} // 날짜 클릭 시 동작
                  className={styles['sheet-calendar']}
                  formatDay={(locale, date) => date.toLocaleString('en', { day: 'numeric' })}
                  calendarType='hebrew'
                  locale='en-US'
                />
              </div>
              <div className={styles['sheet-terms-sub-title']}>{'Select Your Preferred Time'}</div>
              {filteredTimes.map((time: string, idx: number) => (
                <div
                  key={idx}
                  onClick={() => handleTimeSelect(time)}
                  className={styles['sheet-time-option']}
                >
                  <ReviewSelect text={time} />
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheet(false)} />
      </Sheet>
    </div>
  );
}

export default MeetingDenied;
