import React, { useState } from 'react';
import styles from './MeetingReviewLast.module.css';

import { useLocation, useNavigate } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import TextArea from '../../components/element/TextArea';
import productService from '../../services/productService';

import { ProductReview } from '../../models/productReview';
import useToast from '../../hook/useToast'; // 토스트 훅 추가
function MeetingReviewLast() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast(); // 토스트 훅 초기화
  const [mateMsg, setMateMsg] = useState<string>('');
  const productReview = location.state; // 전달된 state 데이터를 받음

  const toNext = (updatedAnswer: ProductReview) => {
    navigate('/meeting/review/service', { state: updatedAnswer });
  };

  // 리뷰 작성 API 호출 없이 선택한 답변과 이유만 업데이트
  const submitReview = async () => {
    if (!mateMsg) {
      toast('error', 'Please write at least 5 words', false);
      return;
    } // 선택되지 않으면 아무것도 안 함

    try {
      // productReview에서 필요한 데이터만 추출하고, 유용한 답변 및 이유 추가
      const updatedAnswer: ProductReview = {
        ...productReview, // 기존 리뷰 데이터 유지
        to_mate_message: mateMsg, // 선택된 답변 업데이트
      };

      // 다음 페이지로 업데이트된 데이터 전달
      toNext(updatedAnswer); // 상태 업데이트 후, 다음 페이지로 이동
      const response = await productService.addReview(updatedAnswer);
      if (response) {
        // console.log('리뷰 전송 성공:', response);
        toNext(updatedAnswer); // 성공 시 다음 페이지로 이동
      }
    } catch (err) {
      toast('error', 'Something went wrong', false); // 실패 메시지 출력
    }
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-container']}>{'Share Your Thanks'}</div>
      <div className={styles['text']}>
        {'Your feedback will motivate and encourage the mate'}
      </div>

      <TextArea
        placeholder='Share your thoughts...'
        useBgColor={true}
        width='unset'
        marginLeft='1.6rem'
        marginRight='1.6rem'
        height='auto'
        autoResize={true}
        radius={16}
        useCount={true}
        maxLength={500}
        value={mateMsg}
        onChange={(e) => setMateMsg(e.target.value)}
      />
      <div className={styles['button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={1.6}
          active={mateMsg.length > 5}
          onClick={submitReview}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default MeetingReviewLast;
