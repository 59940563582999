import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './CalculationRequestAddPaypal.module.css';
import TextField from '../../components/element/TextField';
import { UserWithdrawalMethod } from '../../models/userWithdrawalMethod';
import useGlobalStore from '../../stores/globalStore';
import useToast from '../../hook/useToast';

function CalculationRequestAddPaypal() {
  const toast = useToast();
  const [email, setEmail] = useState('');
  const location = useLocation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { methodData, selectedSettlement } = location.state || {};
  const { id } = useParams<{ id?: string }>();
  const { tempUserWithdrawalMethod, setTempUserWithdrawalMethod } = useGlobalStore();
  const { user, setUser } = useGlobalStore();
  console.log(tempUserWithdrawalMethod);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Selected ID:', id);
    // ID를 기반으로 데이터 로드
  }, [id]);

  const toRequest = () => {
    if (email.length < 7 && firstName.length < 5) {
      toast('error', 'Please enter your email and name.', false);
      return;
    }

    if (id) {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        country_code: methodData.country_code,
        nationality: methodData.nationality,
        withdrawal_method: methodData.withdrawal_method,
        created_date: new Date(),
        email: email,
        recipient_name: firstName || '',
        currency: 'USD',
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });

      navigate(`/calculation/request/method/${id}`, {
        state: { methodData: tempUserWithdrawalMethod, selectedSettlement },
      });
    } else {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        country_code: methodData.country_code,
        nationality: methodData.nationality,
        withdrawal_method: methodData.withdrawal_method,
        created_date: new Date(),
        email: email,
        recipient_name: firstName || '',
        currency: 'USD',
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });

      navigate('/calculation/request/method/add', {
        state: { methodData: tempUserWithdrawalMethod, selectedSettlement },
      });
    }
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'Add a payout method'}</div>
      <div className={styles['text']}>{'Enter your PayPal email address'}</div>

      <div className={styles['text-container']}>
        <div className={styles['text-item']}>
          <div className={styles['text-label']}>{'PayPal email'}</div>
          <TextField
            width='unset'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onClear={() => setEmail('')} // 화살표 함수로 변경
          />
        </div>

        <div className={styles['text-item']}>
          <div className={styles['text-label']}>{'Recipient Name'}</div>
          <TextField
            width='unset'
            placeholder='Please enter your last name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onClear={() => setFirstName('')} // 화살표 함수로 변경
          />
        </div>

        <div className={styles['button-container']}>
          <Button
            width='unset'
            marginHorizontalRem={1.6}
            active={email.length > 7 && firstName.length > 5}
            onClick={toRequest}
          >
            Add payout
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CalculationRequestAddPaypal;
