import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';

import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import { NotificationModel } from '../../models/notification';
import UserService from '../../services/userService';
import Alarm from '../meeting/components/Alarm';
import styles from './Notification.module.css';
import closeIcon from '../../assets/images/ic_close.png';

function Notification() {
  const navigate = useNavigate();
  const [notificationList, setNotificationList] = useState<NotificationModel[]>([]);
  const [openFailed, setOpenFailed] = useState(false);

  useEffect(() => {
    loadNotificationList();
  }, []);

  async function loadNotificationList() {
    const notificationList = await UserService.getNotificationList();
    setNotificationList(notificationList);
  }

  async function readAllNotification() {
    const result = await UserService.readAllNotification();
    if (result === 'success') {
      loadNotificationList();
    }
  }

  async function readNotification(id: number) {
    const result = await UserService.readNotification(id);
    if (result === 'success') {
      const newData = notificationList.find((value) => value.id === id)!;
      newData.checked = true;
      setNotificationList([...notificationList.filter((value) => value.id !== id), newData]);
    }
  }

  async function goBtn(id: number, redirect_url: string) {
    await readNotification(id);

    /*
    if (review) {
      navigate('/mate/detail');
    } else if (success) {
      navigate('/mate/register');
    } else if (error) {
      navigate('/product/reject');
    } else if (suspended) {
      navigate('/product/suspend');
    } else if (product && !error && !suspended) {
      navigate('/product/success'); // product가 true일 때 error와 suspended가 false일 경우 실행
    } else {
      setOpenFailed(true); // 실패 상태 창을 열기
    }
    */
  }

  return (
    <div className={styles['container']}>
      <Header
        onBack={() => navigate(-1)}
        title='Notifications'
        useSettingButton={true}
        onSettingAction={() => navigate('/setting/notification')}
      />
      <div className={styles['item']}>
        <div
          className={styles['title']}
          onClick={() => readAllNotification()}
        >
          {'Mark all as read'}
        </div>
      </div>
      <div className={styles['alarm-container']}>
        {notificationList.map((notification, idx) => (
          <Alarm
            key={idx}
            category={notification.category}
            message={notification.message}
            checked={notification.checked}
            created_date={notification.created_date}
            redirect_url={notification.redirect_url}
            onClick={() => goBtn(notification.id!, notification.redirect_url)} // 모든 인자 전달
          />
        ))}
      </div>
    </div>
  );
}

export default Notification;
