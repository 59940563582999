import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import Header from '../../components/layout/Header';
import styles from './SearchResultProduct.module.css';
import discountIcon from '../../assets/images/ic_discount.png';
import arrowDownIcon from '../../assets/images/ic_combo_arrow_down.png';
import backIcon from '../../assets/images/ic_back.png';
import ReviewSelect from '../meeting/components/ReviewSelect';
import checkIcon from '../../assets/images/ic_check_default.png';
import closeIcon from '../../assets/images/ic_close.png';
import Button from '../../components/element/Button';
import Calendar from 'react-calendar';
import '../meeting/DeniedCalender.css';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import TextArea from '../../components/element/TextArea';
import TextField from '../../components/element/TextField';
import useToast from '../../hook/useToast';
import mateService from '../../services/mateService';
import uploadService from '../../services/uploadService';
import authService from '../../services/authService';
import { MeetingRequestInfo } from '../../models/meeting';
import { User } from '../../models/user'; // User 인터페이스 import
import { addHours, addMinutes, format, isBefore, parse, startOfDay } from 'date-fns';

function SearchResultProduct() {
  const toast = useToast();
  const location = useLocation();
  const mateData = location.state.mateData;
  const selectedProductData = location.state.selectedProduct;
  // const mateData = location.state.mateData;
  //const couponData = location.state.couponData;
  const navigate = useNavigate();
  const [uploadedFileName, setUploadedFileName] = useState<string | null>('Upload your file'); // 파일 이름 상태 추가
  const [uploadedFilePath, setUploadedFilePath] = useState<string | null>(''); // 파일 이름 상태 추가
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);
  const [isOpenBackBottomSheet, setOpenBackBottomSheet] = useState(false);
  const [selectedDates, setSelectedDates] = useState<(Date | null)[]>([null, null, null]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>(['', '', '']);
  const [currentSelectIndex, setCurrentSelectIndex] = useState<number | null>(null);
  const [selectedDateTimes, setSelectedDateTimes] = useState<{ date: Date | null; time: string }[]>(
    []
  );
  // 달력 value
  const [value, onChange] = useState<Date | null>(null); // 모달 내부의 임시 선택된 날짜

  const [selectedAnswer, setSelectedAnswer] = useState<string[]>([]);
  const [myData, setMyDate] = useState<User | null>(null);
  const [filteredTimes, setFilteredTimes] = useState<string[]>([]); // 필터링된 시간 목록

  const [QnAValue, setQnAValue] = useState<string>('');
  const [linkValue, setLinkValue] = useState<string>('');

  useEffect(() => {
    if (selectedAnswer.length === 3) {
      setQnAValue(''); // selectedAnswer의 길이가 3이면 값 초기화
    }
  }, [selectedAnswer.length]);
  useEffect(() => {
    myProfileData(); // 컴포넌트 마운트 시 데이터 가져오기
  }, []);

  const myProfileData = async () => {
    try {
      const userListData = await authService.myprofile();
      if (userListData && userListData.user) {
        // user 객체 확인
        setMyDate(userListData.user); // user를 상태로 설정
      }
    } catch (err) {
      console.log(err);
      toast('error', 'Something went wrong');
    }
  };
  console.log('Selected product:', selectedProductData);

  const requestMeeting = async () => {
    try {
      const toISOFormat = (date: Date | null, time: string): string => {
        if (!date || !time) return '';

        const [hour, minute, period] = time.match(/(\d+):(\d+)\s(AM|PM)/i)!.slice(1);

        // 시간 계산
        let hour24 = parseInt(hour, 10);
        if (period === 'PM' && hour24 !== 12) hour24 += 12;
        if (period === 'AM' && hour24 === 12) hour24 = 0;

        // Date 객체를 새로운 시간으로 설정
        const updatedDate = new Date(date);
        updatedDate.setHours(hour24, parseInt(minute, 10));

        return updatedDate.toISOString(); // ISO 8601 포맷
      };
      // 선택 여부 확인
      if (!selectedDates[0] || !selectedTimes[0]) {
        toast('error', 'Schedule1 not Selected.', false);
        return;
      }
      if (!selectedDates[1] || !selectedTimes[1]) {
        toast('error', 'Schedule2 not Selected.', false);
        return;
      }
      if (!selectedDates[2] || !selectedTimes[2]) {
        toast('error', 'Schedule3 not Selected.', false);
        return;
      }
      if (selectedProductData?.type === 'COFFEECHAT') {
        if (selectedAnswer.length < 3 && !QnAValue) {
          toast('error', 'Please choose the 3 Topic.', false);
          return;
        }
        if (selectedAnswer.length < 2 && QnAValue) {
          toast('error', 'Please choose the 3 Topic.', false);
          return;
        }
        if (selectedAnswer.length === 0 && QnAValue === '') {
          toast('error', 'Please choose the Topic.', false);
          return;
        }
      } else {
        if (QnAValue === '') {
          toast('error', 'Please write the Topic.', false);
          return;
        }
      }

      const meetingRequestInfo: MeetingRequestInfo = {
        schedule1: toISOFormat(selectedDates[0], selectedTimes[0] || ''),
        schedule2: toISOFormat(selectedDates[1], selectedTimes[1] || ''),
        schedule3: toISOFormat(selectedDates[2], selectedTimes[2] || ''),
        selected_subjects: selectedAnswer,
        user_written_subject: QnAValue,
        file_url: uploadedFilePath ?? '',
        link_url: linkValue,
      };

      navigate(`/searchResult/product/${mateData.user_id}/payment`, {
        state: {
          meetingRequestInfo,
          mateData,
          selectedProductData,
          myData,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const salePrice = (price: number, couponPrice: number) => {
    return price - couponPrice;
  };

  const getAdjustedDay = (day: number): number => {
    return (day + 6) % 7; // 월요일을 0으로, 일요일을 6으로 조정
  };

  const filterTimesByDay = (day: number) => {
    const adjustedDay = getAdjustedDay(day); // 요일 값 조정
    return mateData.available_time_list.filter((time: string) => {
      const dayOfWeek = parseInt(time.split(',')[0], 10);
      return dayOfWeek === adjustedDay;
    });
  };
  const handleDateChange = (date: Date) => {
    const today = startOfDay(new Date()); // 오늘 날짜의 시작 시간(00:00:00)
    const todaySelectTime = addHours(new Date(), 4); // 현재 시각 기준 +4시간
    const selectedTimeSlots = new Set(selectedTimes); // 이미 선택된 시간대 (Set 사용)

    if (isBefore(date, today)) {
      toast('error', 'You cannot select a past date.', false);
      return;
    }
    onChange(date);
    const dayOfWeek = date.getDay();
    const timesForDay = filterTimesByDay(dayOfWeek);
    const splitInto30MinIntervals = (timeRange: string): string[] => {
      const [startTime, endTime] = timeRange.split('-').map((time) => time.trim());

      const toMinutes = (time: string) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };

      const toTimeStringAMPM = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        const period = hours < 12 ? 'AM' : 'PM';
        const displayHours = hours % 12 || 12; // 0을 12로 변환
        return `${String(displayHours)}:${String(mins).padStart(2, '0')} ${period} (30m)`;
      };

      const startMinutes = toMinutes(startTime);
      const endMinutes = toMinutes(endTime);
      const intervals: string[] = [];

      const addInterval = (current: number) => {
        const intervalDate = addMinutes(startOfDay(date), current);
        // 오늘 기준 +4시간을 초과하지 않는 시간만 필터링
        if (isBefore(intervalDate, addHours(new Date(), 4))) return null;
        return toTimeStringAMPM(current);
      };

      // Handle intervals that cross midnight
      if (endMinutes < startMinutes) {
        for (let current = startMinutes; current < 24 * 60; current += 30) {
          const interval = addInterval(current);
          if (interval) intervals.push(interval);
        }
        for (let current = 0; current < endMinutes; current += 30) {
          const interval = addInterval(current);
          if (interval) intervals.push(interval);
        }
      } else {
        for (let current = startMinutes; current < endMinutes; current += 30) {
          const interval = addInterval(current);
          if (interval) intervals.push(interval);
        }
      }
      if (startMinutes < 1440 && endMinutes > 0) {
        intervals.push(toTimeStringAMPM(1440 - 30));
        intervals.push(toTimeStringAMPM(0));
      }
      return intervals;
    };

    // 모든 시간을 30분 간격으로 나눔
    const intervalsForDay = timesForDay.flatMap((time: string) =>
      splitInto30MinIntervals(time.split(',').slice(1).join(' - '))
    );

    setFilteredTimes(intervalsForDay);
  };

  const handleTimeSelect = (time: string) => {
    if (!value) return;

    // 현재 선택된 날짜와 시간의 조합 확인
    const selectedDateTime = { date: value, time };

    // 같은 날짜와 시간 조합이 이미 선택되었는지 확인
    const isAlreadySelected = selectedDateTimes.some(
      (dt) =>
        dt.date?.toISOString().split('T')[0] ===
          selectedDateTime.date?.toISOString().split('T')[0] && dt.time === time
    );

    if (isAlreadySelected) {
      toast('error', 'This time slot has already been selected for this date.', false);
      return;
    }

    if (currentSelectIndex !== null) {
      // 업데이트된 날짜와 시간 조합 리스트
      const updatedDateTimes = [...selectedDateTimes];
      updatedDateTimes[currentSelectIndex] = selectedDateTime;

      setSelectedDateTimes(updatedDateTimes);

      const updatedDates = [...selectedDates];
      const updatedTimes = [...selectedTimes];

      updatedDates[currentSelectIndex] = value;
      updatedTimes[currentSelectIndex] = time;

      setSelectedDates(updatedDates);
      setSelectedTimes(updatedTimes);

      setOpenBottomSheet(false);
      onChange(null); // 선택 초기화
    }
  };

  const isDateSelected = (index: number) => selectedDates[index] !== null;

  const getCharacterLimit = (type: string) => {
    if (type === 'COFFEECHAT') return 500;
    if (type === 'INTERVIEW' || type === 'REVIEW') return 1000;
    return 0; // 기본값
  };

  const characterLimit = getCharacterLimit(selectedProductData.type);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        // 'temp' 폴더에 파일 업로드
        const uploadResult = await uploadService.addFile('file', formData);

        if (uploadResult) {
          const fileUrl = `https://nailedit-store.s3.us-east-1.amazonaws.com/${uploadResult}`;
          setUploadedFileName(file.name); // 파일 URL 저장
          toast('success', 'File uploaded successfully!', false);
          setUploadedFilePath(fileUrl); // 파일 URL 저장
        }
      } catch (error) {
        console.error(error);
        toast('error', 'Upload failed, please try again', false);
      }
    } else {
      setUploadedFileName(null); // 파일이 선택되지 않았을 경우 초기화
      toast('error', 'No file selected', false);
    }
  };

  const isButtonActive = () => {
    if (selectedProductData.type === 'COFFEECHAT') {
      // 커피챗: 첫 번째 일정이 3개 선택되거나 QnA가 채워지면 활성화
      return selectedAnswer.length >= 3 || (selectedAnswer.length >= 2 && QnAValue.length > 0);
    } else {
      return selectedDates.some((date) => date !== null) && QnAValue.length > 0; // 인터뷰 및 리뷰: 하나 이상의 일정과 QnA가 모두 선택되어야 활성화
    }
  };

  const openBackSheet = () => {
    setOpenBackBottomSheet(true);
  };
  const toBack = () => {
    navigate(-1);
  };

  const handleSelect = (text: string) => {
    // 현재 선택된 리뷰가 클릭된 경우 선택 해제
    if (selectedAnswer.includes(text)) {
      setSelectedAnswer((prev) => prev.filter((item) => item !== text)); // 선택된 항목 제거
    } else {
      setSelectedAnswer((prev) => [...prev, text]); // 새로운 항목 추가
    }
  };

  return (
    <div className={styles['container']}>
      <Header onBack={openBackSheet} />
      <div>
        {mateData ? (
          <>
            <div className={styles['product-top']}>
              <span>
                Propose{' '}
                {selectedProductData.type === 'COFFEECHAT'
                  ? 'Coffee Chat'
                  : selectedProductData.type === 'INTERVIEW'
                  ? 'Interview Practice'
                  : selectedProductData?.type === 'REVIEW'
                  ? 'Resume/Portfolio Review'
                  : 'Unknown Product'}{' '}
                to
                <br />
                {mateData.user ? mateData.user.nickname : 'User not found'}
              </span>
            </div>
            <div className={styles['product-title']}>{'Payment Due'}</div>
            <div className={styles['product-price-container']}>
              <div className={styles['product-coupon-price']}>
                <span className={styles['product-price']}>${selectedProductData.price}</span>
                {/* <br />${45}{' '}
                <span className={styles['product-coupon']}>with coupon</span> */}
              </div>
            </div>
            <div className={styles['product-meeting-container']}>
              <div className={styles['product-title']}>{'Select Available Meeting Times'}</div>
              <div className={styles['product-text']}>
                {'The mate will select an available schedule.'}
              </div>
              {/* <div className={styles['product-meeting-time']}>
                {'Showing in {City} time'}
                <div
                  className={styles['product-meeting-mate-time']}
                >{`Convert to Mate's`}</div>
              </div> */}
              {[0, 1, 2].map((index) => {
                const label =
                  index === 0
                    ? 'Propose First Time Slot'
                    : index === 1
                    ? 'Propose Second Time Slot'
                    : 'Propose Third Time Slot';

                return !isDateSelected(index) ? (
                  <div
                    key={index}
                    className={styles['sub-item-row']}
                    onClick={() => {
                      setCurrentSelectIndex(index);
                      setOpenBottomSheet(true);
                    }}
                  >
                    {label}
                    <img
                      src={arrowDownIcon}
                      alt=''
                    />
                  </div>
                ) : (
                  <div
                    key={index}
                    className={`${styles['sub-item-row']} ${styles['sub-item-row-selected']}`}
                    onClick={() => setOpenBottomSheet(true)}
                  >
                    <div>
                      <div className={styles['sub-item-datetime1']}>
                        {selectedDates[index]?.toLocaleDateString('en-US', {
                          month: 'numeric',
                          day: 'numeric',
                        })}{' '}
                        (
                        {selectedDates[index]?.toLocaleDateString('en-US', {
                          weekday: 'short',
                        })}
                        )
                      </div>
                      <div className={styles['sub-item-datetime2']}>{selectedTimes[index]}</div>
                    </div>
                    <img
                      src={checkIcon}
                      alt=''
                    />
                  </div>
                );
              })}
              <div className={styles['product-select-container']}>
                <div className={styles['product-title']}>
                  {selectedProductData?.type === 'COFFEECHAT'
                    ? 'Suggested Topics'
                    : 'Your Focus Areas'}
                </div>

                {selectedProductData?.type === 'COFFEECHAT' ? (
                  <>
                    <div className={styles['product-text']}>
                      {'Select up to 3 topics, including custom ones'}
                    </div>
                    {mateData.chat_subject.map((text: string, idx: number) => (
                      <div key={idx}>
                        <ReviewSelect
                          text={text}
                          useBgColor={true}
                          onSelect={(active, text) => handleSelect(text)}
                        />
                      </div>
                    ))}

                    <div className={styles['product-text-container']}>
                      <>
                        <div className={styles['product-text']}>
                          {'Custom Topic'}
                          <div className={styles['product-text-count']}>
                            {`${QnAValue.length} / ${characterLimit}`}{' '}
                          </div>
                          {/* 현재 문자 수와 제한 표시 */}
                        </div>
                        <div className={styles['product-text-area']}>
                          <TextArea
                            width='unset'
                            marginLeft='1.6rem'
                            marginRight='1.6rem'
                            height='auto'
                            radius={12}
                            autoResize={true}
                            value={selectedAnswer.length === 3 ? '' : QnAValue}
                            disable={selectedAnswer.length === 3 && true}
                            onChange={(e) => {
                              if (e.target.value.length <= characterLimit) {
                                setQnAValue(e.target.value); // 제한 내에서만 상태 업데이트
                              }
                            }}
                            placeholder='(Examples)
Q. What is the most important thing to focus on when starting data analysis?
Q. What are some commonly used analysis techniques in the industry?
Q. What are effective methods for data storytelling?
Q. What are the essential requirements and tips for passing interviews when applying for jobs in Japan?'
                          />
                        </div>
                      </>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles['product-text']}>
                      {`List specific topics for your review/practice`}
                    </div>
                    <div className={styles['product-text-container']}>
                      <div className={styles['product-text']}>
                        {'Write your own'}
                        <div className={styles['product-text-count']}>
                          {`${QnAValue.length} / ${characterLimit}`}{' '}
                        </div>
                        {/* 현재 문자 수와 제한 표시 */}
                      </div>
                      <div className={styles['product-text-area']}>
                        <TextArea
                          width='unset'
                          marginLeft='1.6rem'
                          marginRight='1.6rem'
                          height='auto'
                          radius={12}
                          autoResize={true}
                          value={QnAValue}
                          onChange={(e) => {
                            if (e.target.value.length <= characterLimit) {
                              setQnAValue(e.target.value); // 제한 내에서만 상태 업데이트
                            }
                          }}
                          placeholder='(Examples)
Q. What is the most important thing to focus on when starting data analysis?
Q. What are some commonly used analysis techniques in the industry?
Q. What are effective methods for data storytelling?
'
                        />
                      </div>
                    </div>
                    <div className={styles['product-file-container']}>
                      <div className={styles['product-title']}>
                        {'Add your resume and portfolio!'}
                      </div>
                      <div className={styles['product-text']}>
                        {'Add your resume or portfolio for advance review'}
                      </div>
                      <div className={styles['product-file-item']}>
                        <div className={styles['product-file-text']}>{'File'}</div>
                        <div className={styles['product-file']}>
                          <input
                            type='file'
                            id='input-file'
                            style={{ display: 'none' }}
                            onChange={handleFileChange} // 파일 선택 핸들러 추가
                          />
                          {/* 파일 이름 표시 */}
                          {uploadedFileName && (
                            <span className={styles['file-name']}>
                              {uploadedFileName ? uploadedFileName : `Didn't Uploaded File`}
                            </span>
                          )}
                          <label
                            htmlFor='input-file'
                            className={styles['input-file-button']}
                          >
                            Attach
                          </label>
                        </div>
                        <div className={styles['product-file-text']}>{'Link'}</div>
                        <TextField
                          width='unset'
                          placeholder='Write your Link'
                          useCount={false}
                          value={linkValue}
                          onChange={(e) => setLinkValue(e.target.value)}
                          onClear={() => setLinkValue('')}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <p>error!</p>
        )}
      </div>
      <div className={styles['button-container']}>
        <Button
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem'
          activeFontWeight={700}
          active={isButtonActive()}
          onClick={() => requestMeeting()}
        >
          Proceed to Payment
        </Button>
      </div>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setOpenBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '64rem' }}>
          <Sheet.Content style={{ marginBottom: '1.4rem' }}>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-flex']}>
                <img
                  src={backIcon}
                  alt=''
                  onClick={() => setOpenBottomSheet(false)}
                />
                <div className={styles['sheet-terms-title']}>Select Schedule</div>
              </div>
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content']}>
                <Calendar
                  value={value}
                  onClickDay={handleDateChange}
                  className={styles['sheet-calendar']}
                  formatDay={(locale, date) => date.toLocaleString('en', { day: 'numeric' })}
                  calendarType='hebrew'
                  locale='en-US'
                  tileDisabled={({ date }) => isBefore(date, startOfDay(new Date()))} // 오늘 이전 날짜 비활성화
                />
              </div>
              <div className={styles['sheet-terms-sub-title']}>
                {'Choose Your Preferred Time Slots'}
              </div>

              {filteredTimes.map((time: string, idx: number) => (
                <div
                  key={idx}
                  onClick={() => handleTimeSelect(time)}
                  className={styles['sheet-time-option']}
                >
                  <ReviewSelect text={time} />
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheet(false)} />
      </Sheet>
      <Sheet
        isOpen={isOpenBackBottomSheet}
        detent='content-height'
        onClose={() => setOpenBackBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px' }}>
          <Sheet.Content style={{ marginBottom: '2.0rem' }}>
            <div className={styles['back-sheet-container']}>
              <div className={styles['back-sheet-title']}>{'Want to leave this page?'}</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBackBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['back-sheet-sub-title']}>
                {'All entered information will be discarded'}
              </div>
              <div className={styles['back-button-container']}>
                <Button
                  height={'4.8rem'}
                  width='100%'
                  radius={10}
                  activeFontSize='1.5rem'
                  activeFontWeight={600}
                  active={true}
                  bgColor='var(--color-white200)'
                  activeTextColor='black'
                  onClick={toBack}
                >
                  Close
                </Button>
                <Button
                  width='100%'
                  height={'4.8rem'}
                  radius={10}
                  activeFontSize='1.5rem'
                  activeFontWeight={600}
                  active={true}
                  onClick={() => setOpenBackBottomSheet(false)}
                >
                  Continue Proposing
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBackBottomSheet(false)} />
      </Sheet>
    </div>
  );
}

export default SearchResultProduct;
