import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';

import { User } from '../../models/user';
import authService from '../../services/authService';
import { VerifyStatus } from '../../constants/constants';

import styles from './SignUp.module.css';
import Header from '../../components/layout/Header';
import TextField from '../../components/element/TextField';
import PatternTextField from '../../components/element/PatternTextField';
import SelectBox from '../../components/element/SelectBox';
import Button from '../../components/element/Button';
import Popup from '../../components/popup/Popup';
import useToast from '../../hook/useToast';
import useGlobalStore from '../../stores/globalStore';

import nationData from '../../assets/data/nation.json';

import uncheckedIcon from '../../assets/images/ic_unchecked.png';
import checkedIcon from '../../assets/images/ic_checked.png';
import closeIcon from '../../assets/images/ic_close.png';
import celebrateIcon from '../../assets/images/ic_celebrate.png';

function SignUp() {
  const navigate = useNavigate();
  const toast = useToast();
  const { language, setUser } = useGlobalStore();
  const [emailVerifyStatus, setEmailVerifyStatus] = useState<VerifyStatus>(
    VerifyStatus.NONE
  );
  const [cellPhoneVerifyStatus, setCellPhoneVerifyStatus] =
    useState<VerifyStatus>(VerifyStatus.NONE);
  const [nickNameVerifyStatus, setNickNameVerifyStatus] =
    useState<VerifyStatus>(VerifyStatus.NONE);
  const [isRegistorActive, setRegisterActive] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [openBottomSheetType, setOpenBottomSheetType] = useState(''); //열린 약관 종류.
  const [isOpenGenderBottomSheet, setOpenGenderBottomSheet] = useState(false); //성별 바텀시트.
  const [isOpenInternationalNumberBottomSheet, setOpenInternationalNumberBottomSheet] = useState(false); //국제번호 바텀시트.
  const [timer, setTimer] = useState<number>(180);
  const [isActiveTimer, setActiveTimer] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    email: '',
    emailVerifyCode: '',
    password: '',
    nickName: '',
    birth: '',
    gender: '',
    internationalNumber: '+1', //국가번호.
    cellPhone: '',
    cellPhoneVerifyCode: '',
    agreeAge14: false,
    agreeService: false,
    agreePrivacy: false,
    agreePolicy: false,
    agreeMarketing: false,
  });

  const inputRules: any = {
    email: {
      maxLength: 30,
      pattern:
        /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
    },
    emailVerifyCode: {
      minLength: 6,
      maxLength: 6,
    },
    password: {
      minLength: 8,
      maxLength: 20,
      pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/,
    },
    nickName: {
      maxLength: 11,
    },
    birth: {
      minLength: 8,
    },
    cellPhone: {
      minLength: 6,
      maxLength: 11,
    },
    cellPhoneVerifyCode: {
      minLength: 6,
      maxLength: 6,
    },
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const rule = inputRules[name];
    let formattedValue = value;

    if (rule.maxLength && value.length > rule.maxLength) {
      formattedValue = value.slice(0, rule.maxLength); //글자 수 초과하지 않게.
    }

    if(name === 'birth') {
      formattedValue = formattedValue.replaceAll(' ', '');
      formattedValue = formattedValue.replaceAll('/', '');
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  // 각 필드의 유효성 검사 규칙 정의
  const validateField: any = {
    email: (value: string) => inputRules['email'].pattern.test(value),
    emailVerifyCode: (value: string) =>
      value.length >= inputRules['emailVerifyCode'].minLength &&
      value.length <= inputRules['emailVerifyCode'].maxLength &&
      emailVerifyStatus === VerifyStatus.COMPLETE,
    password: (value: string) =>
      inputRules['password'].pattern.test(value) &&
      value.length >= inputRules['password'].minLength &&
      value.length <= inputRules['password'].maxLength,
    nickName: (value: string) =>
      value.length <= inputRules['nickName'].maxLength &&
      nickNameVerifyStatus === VerifyStatus.COMPLETE,
    birth: (value: string) =>
      value.length >= inputRules['birth'].minLength,
    gender: (value: string) =>
      value !== '',
    internationalNumber: (value: string) => value.length > 0,
    cellPhone: (value: string) =>
      value.length >= inputRules['cellPhone'].minLength &&
      value.length <= inputRules['cellPhone'].maxLength,
    cellPhoneVerifyCode: (value: string) =>
      value.length >= inputRules['cellPhoneVerifyCode'].minLength &&
      value.length <= inputRules['cellPhoneVerifyCode'].maxLength &&
      cellPhoneVerifyStatus === VerifyStatus.COMPLETE,
    agreeAge14: (value: boolean) => value === true,
    agreeService: (value: boolean) => value === true,
    agreePrivacy: (value: boolean) => value === true,
    agreePolicy: (value: boolean) => value === true,
    agreeMarketing: (value: boolean) => true,
  };

  useEffect(() => {
    //모든 필드가 유효한 값인지 체크.
    const allFieldsValid = Object.keys(formData).every(
      (key) => formData[key] !== undefined && validateField[key](formData[key])
    );
    if (allFieldsValid) {
      setRegisterActive(true);
    } else {
      setRegisterActive(false);
    }
  }, [formData]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
		if (isActiveTimer) {
			interval = setInterval(() => {
				setTimer((prevTimer: number) => (prevTimer > 0 ? prevTimer - 1 : 0));

        if(timer <= 1) {
          toast('error', 'The verification time has expired', false);
          stopTimer();
          setCellPhoneVerifyStatus(VerifyStatus.NONE);
        }
			}, 1000);
		}
		return () => clearInterval(interval);
  }, [isActiveTimer, timer]);

  const stopTimer = () => {
		setActiveTimer(false);
	};

  const formatTime = () => {
		const minutes = Math.floor(timer / 60);
		const seconds = timer % 60;
		return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
	};

  function agreeTerms(type: string) {
    if(type === 'service') {
      setFormData({ ...formData, agreeService: true });
    }else if(type === 'privacy') {
      setFormData({ ...formData, agreePrivacy: true });
    }else if(type === 'policy') {
      setFormData({ ...formData, agreePolicy: true });
    }else if(type === 'marketing') {
      setFormData({ ...formData, agreeMarketing: true });
    }
    setOpenBottomSheetType('');
  }

  function goInputProfile() {
    navigate('/auth/profileInput');
  }

  async function register() {
    let error: boolean = false;
    
    if(emailVerifyStatus !== VerifyStatus.COMPLETE) {
      toast('error', 'Please authenticate your email.');
      return;
    }

    if(!validateField['password'](formData['password'])) {
      toast('error', 'Invalid password.');
      return;
    }

    if(nickNameVerifyStatus !== VerifyStatus.COMPLETE) {
      toast('error', 'Please check the nickname duplicate.');
      return;
    }

    if(!validateField['birth'](formData['birth'])) {
      toast('error', 'Invalid birth.');
      return;
    }

    if(formData.gender === '') {
      toast('error', 'Please select the gender.');
      return;
    }

    if(cellPhoneVerifyStatus !== VerifyStatus.COMPLETE) {
      toast('error', 'Please verify your cell phone.');
      return;
    }

    if(formData.agreeAge14 === false || formData.agreeService === false || formData.agreePrivacy === false || formData.agreePolicy === false) {
      toast('error', 'Please agree to the required terms and conditions.');
      return;
    }

    if(error !== false || isRegistorActive === false) {
      toast('error', 'Please enter all the information.');
      return;
    }

    //가입 요청.
    const data: any = await authService.register(
      formData.email,
      formData.password,
      formData.nickName,
      formData.birth,
      formData.gender,
      formData.internationalNumber,
      formData.cellPhone,
      formData.agreeMarketing
    );

    if (!data || data['type'] !== 'success') {
      toast(
        'error',
        'An issue occurred while signing up\r\nPlease try again.',
        false
      );
      return;
    }

    setUser(data['user']);
    localStorage.setItem('authToken', data['token']);
    setShowPopup(true);
  }

  async function requestEmailVerify() {
    if (formData.email === '' || emailVerifyStatus !== VerifyStatus.NONE)
      return;

    const isDuplicate: boolean = await authService.checkDuplicateEmail(
      formData.email
    );
    if (isDuplicate) {
      toast('error', 'The same email address already exists', false);
      return;
    }

    //이메일 인증 번호 전송.
    setEmailVerifyStatus(VerifyStatus.WAIT);
    toast('success', 'Check email for the verification code', false);
    await authService.requestEmailVerify(formData.email);
  }

  async function checkEmailVerifyCode() {
    if (formData.email === '' || emailVerifyStatus === VerifyStatus.COMPLETE)
      return;

    const result: boolean = await authService.checkVerifyCode(
      formData.email,
      formData.emailVerifyCode
    );
    if (!result) {
      toast('error', 'The code doesn’t match. Try again', false);
      return;
    }

    //이메일 인증 완료.
    setEmailVerifyStatus(VerifyStatus.COMPLETE);
    toast('success', 'Email verification successful!', false);
  }

  async function requestCellPhoneVerify() {
    if (
      formData.cellPhone === '' ||
      cellPhoneVerifyStatus !== VerifyStatus.NONE
    )
      return;

    const isDuplicate: boolean = await authService.checkDuplicateCellPhone(
      formData.internationalNumber,
      formData.cellPhone
    );
    if (isDuplicate) {
      toast('error', 'The same number is already registered', false);
      return;
    }

    //휴대폰 인증 번호 전송.
    setCellPhoneVerifyStatus(VerifyStatus.WAIT);
    toast('success', 'Check your messages for code', false);
    setTimer(180); //만료시간 3분.
    setActiveTimer(true);
    
    await authService.requestCellPhoneVerify(
      formData.internationalNumber,
      formData.cellPhone
    );
  }

  async function checkCellPhoneVerifyCode() {
    if (
      formData.cellPhone === '' ||
      cellPhoneVerifyStatus === VerifyStatus.COMPLETE
    )
      return;

    const result: boolean = await authService.checkVerifyCode(
      formData.internationalNumber + formData.cellPhone,
      formData.cellPhoneVerifyCode
    );
    if (!result) {
      toast('error', "The code doesn't match. Try again", false);
      return;
    }

    //휴대폰 인증 완료.
    setCellPhoneVerifyStatus(VerifyStatus.COMPLETE);
    toast('success', 'Phone verified!', false);
    stopTimer();
  }

  async function checkDuplicateNickName() {
    if (formData.nickName === '' || nickNameVerifyStatus !== VerifyStatus.NONE)
      return;

    const isDuplicate: boolean = await authService.checkDuplicateNickName(
      formData.nickName
    );
    if (isDuplicate) {
      toast('error', 'This name is already in use', false);
      return;
    }

    //닉네임 중복 체크 완료.
    setNickNameVerifyStatus(VerifyStatus.COMPLETE);
    toast('success', 'Username available!', false);
  }

  function selectGender(gender: string) {
    setFormData({
      ...formData,
      gender: gender,
    });
    setOpenGenderBottomSheet(false);
  }

  function selectInternationalNumber(code: string) {
    setFormData({
      ...formData,
      internationalNumber: '+' + code,
    });
    setOpenInternationalNumberBottomSheet(false);
  }

  function toggleAllTerms() {
    if (
      formData.agreeAge14 === false ||
      formData.agreeService === false ||
      formData.agreePrivacy === false ||
      formData.agreePolicy === false ||
      formData.agreeMarketing === false
    ) {
      setFormData({
        ...formData,
        agreeAge14: true,
        agreeService: true,
        agreePrivacy: true,
        agreePolicy: true,
        agreeMarketing: true,
      });
    } else {
      setFormData({
        ...formData,
        agreeAge14: false,
        agreeService: false,
        agreePrivacy: false,
        agreePolicy: false,
        agreeMarketing: false,
      });
    }
  }

  function toggleAge14() {
    setFormData({ ...formData, agreeAge14: !formData.agreeAge14 });
  }

  function toggleService() {
    setFormData({ ...formData, agreeService: !formData.agreeService });
  }

  function togglePrivacy() {
    setFormData({ ...formData, agreePrivacy: !formData.agreePrivacy });
  }

  function togglePolicy() {
    setFormData({ ...formData, agreePolicy: !formData.agreePolicy });
  }

  function toggleMarketing() {
    setFormData({ ...formData, agreeMarketing: !formData.agreeMarketing });
  }

  function viewServiceTerms() {
    setOpenBottomSheetType('service');
  }

  function viewPrivacyTerms() {
    setOpenBottomSheetType('privacy');
  }

  function viewPolicyTerms() {
    setOpenBottomSheetType('policy');
  }

  function viewMarketingTerms() {
    setOpenBottomSheetType('marketing');
  }

  return (
    <div className={styles['container']}>
      <Header title='Sign Up' onBack={() => navigate(-1)} />
      <div className={styles['form-container']}>
        <div className={styles['label-container']}>
          <label className={styles['label']}>Email</label>
        </div>
        <div className={styles['input-field-container']}>
          <TextField
            name='email'
            type='email'
            value={formData.email}
            enable={emailVerifyStatus === VerifyStatus.NONE}
            actionButtonTitle='Check'
            onAction={() => requestEmailVerify()}
            actionButtonEnable={
              formData.email !== '' &&
              validateField['email'](formData['email']) &&
              emailVerifyStatus === VerifyStatus.NONE
            } //이메일 입력되었으면서 인증을 아직 안한 경우.
            placeholder='Enter email to use as ID'
            onChange={(event) => handleChange(event)}
          />
        </div>
        {emailVerifyStatus !== VerifyStatus.NONE && (
          <div className={styles['label-container']}>
            <label className={styles['label']}>Email verification code</label>
            <div className={styles['label-description']}>
              The verification code is valid for 3 minutes.
            </div>
          </div>
        )}
        {emailVerifyStatus !== VerifyStatus.NONE && (
          <div className={styles['input-field-container']}>
            <TextField
              name='emailVerifyCode'
              type='number'
              value={formData.emailVerifyCode}
              enable={emailVerifyStatus !== VerifyStatus.COMPLETE}
              actionButtonTitle={
                formData.emailVerifyCode === '' ? ' Pending' : 'Verified'
              }
              onAction={() => checkEmailVerifyCode()}
              actionButtonEnable={
                formData.emailVerifyCode !== '' &&
                emailVerifyStatus !== VerifyStatus.COMPLETE
              } //이메일이 입력되었으면서 인증 완료를 아직 안한 경우.
              placeholder='Enter the verification code'
              onChange={(event) => handleChange(event)}
            />
          </div>
        )}
        <div className={styles['label-container']}>
          <label className={styles['label']}>Password</label>
          <div className={styles['label-description']}>
            8-20 characters: letters, numbers, symbols
          </div>
        </div>
        <div className={styles['input-field-container']}>
          <TextField
            name='password'
            type='password'
            value={formData.password}
            placeholder='Enter your password'
            onChange={(event) => handleChange(event)}
            onClear={() => setFormData({ ...formData, password: '' })}
          />
        </div>
        <div className={styles['label-container']}>
          <label className={styles['label']}>Username</label>
          <div className={styles['label-description']}>Up to 11 characters</div>
        </div>
        <div className={styles['input-field-container']}>
          <TextField
            name='nickName'
            type='text'
            value={formData.nickName}
            enable={nickNameVerifyStatus === VerifyStatus.NONE}
            actionButtonTitle={
              nickNameVerifyStatus === VerifyStatus.NONE
                ? 'Check'
                : 'Verified'
            }
            onAction={() => checkDuplicateNickName()}
            actionButtonEnable={
              formData.nickName !== '' &&
              nickNameVerifyStatus !== VerifyStatus.COMPLETE
            }
            placeholder='Choose your username'
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div className={styles['label-container']}>
          <label className={styles['label']}>Date of birth</label>
        </div>
        <div className={styles['input-field-container']}>
        <PatternTextField 
          name='birth' 
          type='text' 
          value={formData.birth} 
          format="## / ## / ####" 
          placeholder="MM / DD / YYYY" 
          //mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
          onChange={(event) => handleChange(event)}
        />
        </div>
        <div className={styles['label-container']}>
          <label className={styles['label']}>Gender</label>
        </div>
        <div className={styles['input-field-container']}>
          <SelectBox
            width={'100%'}
            name='gender'
            type='text'
            value={formData.gender}
            placeholder={'Select gender'}
            onAction={() => setOpenGenderBottomSheet(true)}
          />
        </div>
        <div className={styles['label-container']}>
          <label className={styles['label']}>Phone number</label>
        </div>
        <div className={styles['input-field-container']}>
          <SelectBox
            width={'11.6rem'}
            name='internationalNumber'
            type='text'
            value={formData.internationalNumber}
            placeholder='+1'
            onAction={() => setOpenInternationalNumberBottomSheet(true)}
          />
          <div className={styles['cellphone-padding']} />
          <TextField
            name='cellPhone'
            type='text'
            value={formData.cellPhone}
            enable={cellPhoneVerifyStatus !== VerifyStatus.COMPLETE}
            actionButtonTitle='Verify'
            actionButtonWidth={'5.8rem'}
            onAction={() => requestCellPhoneVerify()}
            actionButtonEnable={
              formData.cellPhone !== '' &&
              cellPhoneVerifyStatus === VerifyStatus.NONE
            }
            placeholder='Phone number'
            onChange={(event) => handleChange(event)}
          />
        </div>
        {cellPhoneVerifyStatus !== VerifyStatus.NONE && (
          <div className={styles['label-container']}>
            <label className={styles['label']}>Verification Code</label>
            {isActiveTimer && <div className={styles['label-error']}>{formatTime()}</div>}
          </div>
        )}
        {cellPhoneVerifyStatus !== VerifyStatus.NONE && (
          <div className={styles['input-field-container']}>
            <TextField
              name='cellPhoneVerifyCode'
              type='number'
              value={formData.cellPhoneVerifyCode}
              enable={cellPhoneVerifyStatus !== VerifyStatus.COMPLETE}
              actionButtonTitle='Verify'
              onAction={() => checkCellPhoneVerifyCode()}
              actionButtonEnable={
                formData.cellPhoneVerifyCode !== '' &&
                cellPhoneVerifyStatus !== VerifyStatus.COMPLETE
              }
              placeholder='Enter the verification code'
              onChange={(event) => handleChange(event)}
            />
          </div>
        )}
        <div className={styles['terms-container']}>
          <div className={styles['terms-title']}>User Terms of Service</div>
          <div
            className={styles['terms-all-checked']}
            onClick={() => toggleAllTerms()}
          >
            <img
              src={
                formData.agreeAge14 === true &&
                formData.agreeService === true &&
                formData.agreePrivacy === true &&
                formData.agreePolicy === true &&
                formData.agreeMarketing === true
                  ? checkedIcon
                  : uncheckedIcon
              }
              alt=''
            />
            I agree to all
          </div>
          <div className={styles['terms-separate']} />
          <div className={styles['terms-item']} onClick={() => toggleAge14()}>
            <img
              src={formData.agreeAge14 === true ? checkedIcon : uncheckedIcon}
              alt=''
            />
            I am 14 or older (Required)
          </div>
          <div className={styles['terms-item']}>
            <div
              className={styles['terms-button']}
              onClick={() => toggleService()}
            >
              <img
                src={
                  formData.agreeService === true ? checkedIcon : uncheckedIcon
                }
                alt=''
              />
              General terms of use
            </div>
            <div
              className={styles['terms-view-content']}
              onClick={(event) => viewServiceTerms()}
            >
              View
            </div>
          </div>
          <div className={styles['terms-item']}>
            <div
              className={styles['terms-button']}
              onClick={() => togglePrivacy()}
            >
              <img
                src={
                  formData.agreePrivacy === true ? checkedIcon : uncheckedIcon
                }
                alt=''
              />
              Privacy Policy
            </div>
            <div
              className={styles['terms-view-content']}
              onClick={(event) => viewPrivacyTerms()}
            >
              View
            </div>
          </div>
          <div className={styles['terms-item']}>
            <div
              className={styles['terms-button']}
              onClick={() => togglePolicy()}
            >
              <img
                src={
                  formData.agreePolicy === true ? checkedIcon : uncheckedIcon
                }
                alt=''
              />
              Operational Policy
            </div>
            <div
              className={styles['terms-view-content']}
              onClick={(event) => viewPolicyTerms()}
            >
              View
            </div>
          </div>
          <div className={styles['terms-item']}>
            <div
              className={styles['terms-button']}
              onClick={() => toggleMarketing()}
            >
              <img
                src={
                  formData.agreeMarketing === true ? checkedIcon : uncheckedIcon
                }
                alt=''
              />
              Consent to Marketing Use of Data
            </div>
            <div
              className={styles['terms-view-content']}
              onClick={(event) => viewMarketingTerms()}
            >
              View
            </div>
          </div>
          <div className={styles['terms-bottom-padding']} />
        </div>
      </div>
      <div className={styles['register-button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={2.0}
          active={isRegistorActive}
          onClick={() => register()}
        >
          Sign Up
        </Button>
      </div>
      <Sheet
        isOpen={openBottomSheetType !== ''}
        detent='content-height'
        onClose={() => setOpenBottomSheetType('')}
      >
        <Sheet.Container
          style={{ borderRadius: '12px 12px 0px 0px' }}
        >
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-terms-title']}>{openBottomSheetType === 'service' ? 'General terms of use' : 
                                                            openBottomSheetType === 'privacy' ? 'Privacy Policy' : 
                                                            openBottomSheetType === 'policy' ? 'Operational Policy' :
                                                            'Consent to Marketing Use of Data'}</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBottomSheetType('')}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content']} style={{height: '41.4rem'}}>
                <iframe src={
                  openBottomSheetType === 'service' ? `/terms/terms_service_${language}.html` : 
                  openBottomSheetType === 'privacy' ? `/terms/terms_privacy_${language}.html` : 
                  openBottomSheetType === 'policy' ? `/terms/terms_policy_${language}.html` : 
                  `/terms/terms_marketing_${language}.html` 
                } style={{width: '100%', border: 'none'}}></iframe>
              </div>
            </Sheet.Scroller>
            <div className={styles['sheet-terms-button-container']}>
              <div className={styles['sheet-terms-button']} onClick={() => agreeTerms(openBottomSheetType)}>동의하기</div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheetType('')} />
      </Sheet>
      <Sheet
        isOpen={isOpenGenderBottomSheet}
        detent='content-height'
        onClose={() => setOpenGenderBottomSheet(false)}
      >
        <Sheet.Container
          style={{ borderRadius: '12px 12px 0px 0px', height: '21.3rem' }}
        >
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenGenderBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content']} style={{height: '4.14rem'}}onClick={() => selectGender('Female')}>
                <div>Female</div>
              </div>
              <div className={styles['sheet-terms-content']} style={{height: '4.14rem'}}onClick={() => selectGender('Male')}>
                <div>Male</div>
              </div>
              <div className={styles['sheet-terms-content']} style={{height: '4.14rem'}}onClick={() => selectGender('Non-binary')}>
                <div>Non-binary</div>
              </div>
              <div className={styles['sheet-terms-content']} style={{height: '4.14rem'}}onClick={() => selectGender('Prefer not to say')}>
                <div>Prefer not to say</div>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenInternationalNumberBottomSheet(false)}
        />
      </Sheet>
      <Sheet
        isOpen={isOpenInternationalNumberBottomSheet}
        detent='content-height'
        onClose={() => setOpenInternationalNumberBottomSheet(false)}
      >
        <Sheet.Container
          style={{ borderRadius: '12px 12px 0px 0px', height: '17.3rem' }}
        >
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenInternationalNumberBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {nationData.filter((e) => e.use === true).map((e) => (
                <div
                  className={styles['sheet-terms-content']} style={{height: '4.14rem'}}
                  onClick={() => selectInternationalNumber(e.code)}
                >
                  <div>{e.text}</div>
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenInternationalNumberBottomSheet(false)}
        />
      </Sheet>
      <Popup
        show={showPopup}
        width={'27.4rem'}
        height={'34rem'} /*onClose={() => setShowPopup(false)}*/
      >
        <div className={styles['popup-container']}>
          <img className={styles['popup-image']} src={celebrateIcon} alt='' />
          <div className={styles['popup-title']}>Welcome to Nailedit!!</div>
          <div className={styles['popup-content']}>
            {'connect with people\r\nwho share your passion!'}
          </div>
          <div
            className={styles['popup-button']}
            onClick={() => goInputProfile()}
          >
            Complete Your Profile
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default SignUp;
