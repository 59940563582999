import styles from './UpcomingMeetingCard.module.css';
import Button from '../../../components/element/Button';

import pinIcon from '../../../assets/images/ic_pin.png';
import dateIcon from '../../../assets/images/ic_date.png';
import { addMinutes, differenceInDays, format, startOfDay } from 'date-fns';

export enum MeetingType {
  COFFEECHAT,
  INTERVIEW,
  REVIEW,
}

interface UpcomingMeetingCardProps {
  time: number;
  type: string;
  name: string;
  productTime: number;
  company: string;
  nickName: string;
  meetingTime: Date;
  link: string;
  onAction?: () => void;
  onSecondaryAction?: () => void;
  onthirdAction?: () => void;
}

const UpcomingMeetingCard: React.FC<UpcomingMeetingCardProps> = ({
  type,
  name,
  time,
  company,
  nickName,
  productTime,
  meetingTime,
  link,
  onthirdAction,
  onAction,
  onSecondaryAction,
}) => {
  function formatDateToCustomFormat(meetingDate: Date): string {
    const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const month = meetingDate.getMonth() + 1; // 월 (0부터 시작)
    const day = meetingDate.getDate(); // 일
    const dayOfWeekString = dayOfWeek[meetingDate.getDay()]; // 요일
    const hours = meetingDate.getHours().toString().padStart(2, '0');
    const minutes = meetingDate.getMinutes().toString().padStart(2, '0');
    const getHours = meetingDate.getHours();
    const period = getHours >= 12 ? 'PM' : 'AM';
    return `${month}/${day}(${dayOfWeekString})  ${hours}:${minutes} ${period}`;
  }
  // meetingTime을 "MM/DD(요일)" 형식에서 Date 객체로 변환하여 날짜 차이를 계산
  const getMeetingDayDifference = (meetingTime: Date): string => {
    const today = startOfDay(new Date()); // 오늘 날짜의 00:00:00
    const meetingDate = startOfDay(meetingTime); // 미팅 날짜의 00:00:00

    // 날짜 차이를 계산
    const diffDays = differenceInDays(meetingDate, today);

    if (diffDays === 0) {
      return 'Today!';
    } else if (diffDays === 1) {
      return 'Tomorrow!';
    } else if (diffDays === 2) {
      return '2 days!';
    } else {
      return `${diffDays} days left`;
    }
  };

  const formatMeetingTime = (meetingTime?: string, productTime?: number) => {
    const baseTime = new Date(meetingTime || new Date()); // meetingTime이 없으면 현재 시간
    const duration = productTime ?? 30; // productTime이 없으면 기본 30분

    const endTime = addMinutes(baseTime, duration); // baseTime + productTime (기본 30분)

    // 시작 시간과 끝 시간 포맷팅
    const formattedStartTime = format(baseTime, 'MMM dd (EEE) HH:mm');
    const formattedEndTime = format(endTime, 'HH:mm a');

    return `${formattedStartTime} ~ ${formattedEndTime}`;
  };

  return (
    <div>
      <div className={styles['card-container']}>
        <div className={styles['card-row']}>
          <div className={styles['card-title']}>
            {time}min {type}
          </div>
          <div className={styles['card-tag']}>
            {/* meeting_date의 시간이 오늘이면 오늘이에요! 출력 */}
            <span>{getMeetingDayDifference(meetingTime)}</span>
          </div>
        </div>
        <div className={styles['card-row']}>
          <div className={styles['card-profile-name']}>{nickName}</div>
          <div className={styles['card-company']}>{company}</div>
        </div>
        <div className={styles['card-row']}>
          <img
            src={dateIcon}
            alt=''
          />
          <div className={styles['card-description']}>
            {meetingTime ? (
              formatMeetingTime(meetingTime.toLocaleString(), productTime)
            ) : (
              <span style={{ color: 'gray' }}>No Meeting Scheduled</span>
            )}
          </div>
        </div>
        <div className={styles['card-row']}>
          <img
            src={pinIcon}
            alt=''
          />
          <div className={styles['card-description']}>Meet Link</div>
          {/*
          <div
            className={styles['card-copy']}
            onClick={onthirdAction}
          >
            Copy Link
          </div>
          */}
          <div className={styles['card-copy']}>{link === '' ? 'Waiting' : 'Ready'}</div>
        </div>
        <div className={styles['card-row']}>
          <Button
            width={'100vw'}
            height={'4rem'}
            activeFontSize='1.4rem'
            activeFontWeight={600}
            active={true}
            onClick={onAction}
          >
            Check Details
          </Button>
          <div style={{ marginLeft: '0.7rem' }} />
          <Button
            width={'100vw'}
            height={'4rem'}
            activeFontSize='1.4rem'
            activeFontWeight={600}
            active={link !== '' ? true : false}
            onClick={() => (link !== '' ? onSecondaryAction!() : null)}
          >
            Join
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpcomingMeetingCard;
