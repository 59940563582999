import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import useGlobalStore from '../../stores/globalStore';
import useToast from '../../hook/useToast';
import UserService from '../../services/userService';
import styles from './NotificationSettingType.module.css';

function NotificationSettingType() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user, setUser } = useGlobalStore(); 
  const [notificationType, setNotificationType] = useState<string[]>([]);

  useEffect(() => {
    setNotificationType(user?.notification_type ?? []);
  }, []);

  const handleTypeSelect = (type: string) => {
    if(notificationType.includes(type)) {
      const newNotificationType = notificationType.filter((value) => value != type);
      setNotificationType(newNotificationType);
    }else{
      setNotificationType([...notificationType, type]);
    }
  };

  async function save() {
    const result = await UserService.updateNotificationType(notificationType);
    if(result === 'success') {
      setUser({...user!, notification_type: notificationType});
      navigate(-1);
    }
  }

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
        title='Change notification method'
      />
      <div className={styles['title']}>Notification method</div>
      <div className={styles['info']}>
        Choose preferred method for updates and information
      </div>
      <div className={styles['type-container']}>
        <div
          className={`${styles['type-item']} ${notificationType.some((value) => value == 'email') ? styles['active'] : ''}`}
          onClick={() => handleTypeSelect('email')}
        >
          <span>Email</span>
        </div>
        <div
          className={`${styles['type-item']} ${notificationType.some((value) => value == 'sms') ? styles['active'] : ''}`}
          onClick={() => handleTypeSelect('sms')}
        >
          <span>SMS</span>
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          height='5.6rem'
          radius={12}
          activeFontSize='1.6rem'
          activeFontWeight={700}
          marginHorizontalRem={2}
          active={true}
          onClick={() => save()}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}

export default NotificationSettingType;
