import React, { useState } from 'react';
import styles from './SelectBox.module.css';
import arrowDownIcon from '../../assets/images/ic_arrow_down.png';

interface SelectBoxProps {
  radius?: string;
  paddingLeft?: string;
  paddingRight?: string;
  gap?: string;
  width?: string;
  height?: string;
  value: string;
  actionButtonTitle?: string;
  borderColor?: string;
  backgroundColor?: string;
  textColor?: string;
  arrowSize?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onAction?: () => void;
  placeholder?: string;
  type?: string;
  error?: boolean;
  useBgColor?: boolean; // 배경 색상 사용 여부
  useBorder?: boolean; // 테두리 사용 여부
  fontSize?: string; // 글자 크기
  fontWeight?: number; // 글자 두께
  [key: string]: any; // 기타 props를 처리할 수 있도록 추가.
}

const SelectBox: React.FC<SelectBoxProps> = ({
  radius = '1.2rem',
  paddingLeft = '1.2rem',
  paddingRight = '1.2rem',
  gap = '0px',
  width = undefined,
  height = '4.8rem',
  value,
  actionButtonTitle = '',
  borderColor = '#0000001E',
  backgroundColor = '#00000000',
  textColor = '#1D1D1E',
  fontSize = '1.5rem',
  fontWeight = 500,
  arrowSize = '20px',
  onChange,
  onClear,
  onAction,
  placeholder,
  type = 'text',
  useBgColor = false, // 기본값 설정
  useBorder = false, // 기본값 설정
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div
      className={`${styles['container']} ${isFocused ? styles['container-focused'] : ''} ${
        useBgColor ? styles['bgColor'] : ''
      } ${useBorder ? styles['border'] : ''}`}
      style={{
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        width: width,
        height: height,
        borderColor: borderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: radius,
        backgroundColor: backgroundColor,
      }}
      onClick={onAction}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div
        className={`${styles['text']} ${value !== '' ? styles['text-active'] : ''}`}
        style={{ color: textColor, fontSize: fontSize, fontWeight: fontWeight }} // 글자 크기 및 두께 적용
      >
        {value === '' ? placeholder : value}
      </div>
      <img
        src={arrowDownIcon}
        alt=''
        style={{ marginLeft: gap, width: arrowSize, height: arrowSize }}
      />
    </div>
  );
};

export default SelectBox;
