import React, { useState } from 'react';
import styles from './TextEditor.module.css';

const TextEditor: React.FC = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  return (
    <div className={styles.inputContainer}>      
      <input 
        id="titleInput"
        type="text" 
        value={title} 
        onChange={(e) => setTitle(e.target.value)} 
        className={styles.titleInput} 
        placeholder="Enter title"
      />
      <textarea 
        id="contentInput"
        value={content} 
        onChange={(e) => setContent(e.target.value)} 
        className={styles.contentTextarea}
        placeholder="Enter content"
      ></textarea>
    </div>
  );
};

export default TextEditor;
