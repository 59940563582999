import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import Header from '../../components/layout/Header';
import ComboBoxButton from '../../components/element/ComboBoxButton';
import TextField from '../../components/element/TextField';
import TextArea from '../../components/element/TextArea';
import Button from '../../components/element/Button';
import CountryVerify from '../my/components/CountryVerify';
import EditTimezone from '../my/components/EditTimezone';
import JobSelect from '../my/components/JobSelect';
import LanguageSelect from '../my/components/LanguageSelect';
import CompanyEmailVerify from '../my/components/CompanyEmailVerify';
import styles from './MyInfoModify.module.css';
import timezoneData from '../../assets/data/timezone.json';
import uploadService from '../../services/uploadService';
import useGlobalStore from '../../stores/globalStore';
import { User } from '../../models/user';
import authService from '../../services/authService';
import { VerifyStatus } from '../../constants/constants';
import useToast from '../../hook/useToast';
import nationData from '../../assets/data/nation.json';
import cameraIcon from '../../assets/images/ic_camera.png';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import closeIcon from '../../assets/images/ic_close.png';
import selectedIcon from '../../assets/images/ic_radio_selected.png';
import unselectedIcon from '../../assets/images/ic_radio_unselected.png';
import SelectBox from '../../components/element/SelectBox';

function MyInfoModify() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user, setUser } = useGlobalStore();
  const [nickNameVerifyStatus, setNickNameVerifyStatus] =
    useState<VerifyStatus>(VerifyStatus.NONE);
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);
  const [showCountryVerify, setShowCountryVerify] = useState(false);
  const [showTimeZone, setShowTimeZone] = useState(false);
  const [showJobSetting, setShowJobSetting] = useState(false);
  const [showLanguageSetting, setShowLanguageSetting] = useState(false);
  const [showCompanyVerify, setShowCompanyVerify] = useState(false);
  const [selectedTimezoneIndex, setSelectedTimezoneIndex] = useState(0);
  const thumbnailRef = useRef<HTMLInputElement | null>(null);
  const [tempUser, setTempUser] = useState<User | null>(null);
  const [file, setFile] = useState<any>(null);
  const [jobDirectly, setJobDirectly] = useState<boolean>(false); //직무 직접 입력
  const [jobName, setJobName] = useState<string>(''); //직접 입력한 직무
  const [selectedNation, setSelectedNation] = useState<{
    nation: string;
    abb: string;
  } | null>(null);
  const [isOpenNationBottomSheet, setOpenNationBottomSheet] = useState(false);

  useEffect(() => {
    setTempUser(JSON.parse(JSON.stringify(user)));
  }, []);

  function selectNation(nation: string, abb: string) {
    // 선택된 국가 정보를 업데이트
    setSelectedNation({ nation, abb });

    // 사용자 데이터에도 국가 정보 저장
    setTempUser({
      ...tempUser!,
      country_code: abb,
      country_name: nation,
    });

    // 국가 선택 바텀 시트를 닫음
    setOpenNationBottomSheet(false);
  }

  async function done() {
    if (jobDirectly === true) {
      //직접 입력인 경우.
      tempUser!.category = jobName;
      tempUser!.job = jobName;
    }

    if (tempUser?.language.length <= 0) {
      toast('error', 'Select language');
      //return;
    }
    if (tempUser?.job === '') {
      toast('error', 'Select job category');
      //return;
    }

    //프로필 이미지가 있으면 업로드 처리.
    let uploadFileName = '';
    if (file != null) {
      uploadFileName = await uploadFile();
    }

    //프로필 저장 처리.
    const data: any = await authService.modifyProfile(
      tempUser!.email,
      tempUser!.country_code,
      tempUser!.country_name,
      tempUser!.timezone,
      tempUser!.language,
      tempUser!.category,
      tempUser!.job,
      tempUser!.company,
      tempUser!.annual.toString(),
      uploadFileName, //tempUser!.profileImage,
      tempUser!.notification_type
    );

    if (!data || data['type'] !== 'success') {
      toast('error', 'Something went wrong while siging up', false);
      return;
    }

    setUser(data['user']);
    navigate(-2);
  }

  // function goCountryVerifyPage() {
  //   setShowCountryVerify(true);
  // }

  function goJobPage() {
    setShowJobSetting(true);
  }

  function goLanguagePage() {
    setShowLanguageSetting(true);
  }

  function goTimeZonePage() {
    setShowTimeZone(true);
  }

  function goCompanyPage() {
    setShowCompanyVerify(true);
  }

  async function checkDuplicateNickName() {
    if (tempUser?.nickname === '' || nickNameVerifyStatus !== VerifyStatus.NONE)
      return;

    const isDuplicate: boolean = await authService.checkDuplicateNickName(
      tempUser!.nickname
    );
    if (isDuplicate) {
      toast('error', 'This username is already taken', false);
      return;
    }

    //닉네임 중복 체크 완료.
    setNickNameVerifyStatus(VerifyStatus.COMPLETE);
    toast('success', 'This username is available!', false);
  }

  function selectJob(category: string, job: string) {
    if (job === '-') {
      //직접 입력.
      setJobDirectly(true);
      setJobName('');
    } else {
      setJobDirectly(false);
      setJobName('');
      setTempUser({
        ...tempUser!,
        category: category,
        job: job,
      });
    }
    setShowJobSetting(false);
  }

  function selectLanguages(languages: string[]) {
    //언어 설정 창에서 설정한 언어를 가지고 넘어오는 곳.
    setTempUser({
      ...tempUser!,
      language: languages,
    });
    setShowLanguageSetting(false);
  }

  // function selectCountry(countryCode: string, countryName: string) {
  //   setTempUser({
  //     ...tempUser!,
  //     country_code: countryCode,
  //     country_name: countryName,
  //   });
  //   setShowCountryVerify(false);
  // }

  function selectCompany(companyName: string, email: string) {
    setTempUser({
      ...tempUser!,
      email: email,
      company: companyName,
    });
    setShowCompanyVerify(false);
    toast('success', 'Your company info has been updated', false);
  }

  function selectTimezone(timezoneIndex: number) {
    setTempUser({
      ...tempUser!,
      timezone: timezoneData[timezoneIndex]['Timezone'],
    });
    setSelectedTimezoneIndex(timezoneIndex);
    setShowTimeZone(false);
  }

  async function uploadFile() {
    let formData = new FormData();
    formData.append('file', file);
    const uploadFileName: string = await uploadService.addImage(
      'user',
      formData
    );
    return uploadFileName;
  }

  const onChangeImageUpload = (e: any) => {
    setFile(e.target.files[0]);

    if (thumbnailRef.current?.files != null) {
      setTempUser({
        ...tempUser!,
        profile_image: URL.createObjectURL(thumbnailRef.current?.files[0]),
      });
    }
  };

  function selectThumbnail() {
    thumbnailRef.current?.click();
    setOpenBottomSheet(false);
  }

  function deleteProfileThumbnail() {
    setTempUser({
      ...tempUser!,
      profile_image: '',
    });
    setOpenBottomSheet(false);
  }

  function updateAnnual(annualText: string) {
    let value: number = 0;
    if (annualText !== '') {
      const temp = Number(annualText);
      if (!isNaN(temp)) {
        value = temp;
      }
    }
    setTempUser({ ...tempUser!, annual: value });
  }

  return (
    <div className={styles['container']}>
      <Header
        onBack={() => navigate(-1)}
        title='Edit My Profile'
        actionType='none'
      />
      <div className={styles['form-container']}>
        <div className={styles['profile-image-container']}>
          <div
            className={styles['profile-image-thumbnail']}
            onClick={() => setOpenBottomSheet(true)}
          >
            <img
              src={
                tempUser?.profile_image !== ''
                  ? `${
                      !tempUser?.profile_image.includes('http')
                        ? process.env.REACT_APP_STORE_ADDRESS + '/'
                        : ''
                    }${tempUser?.profile_image}`
                  : profileDefaultIcon
              }
              alt=''
            />
            <form encType='multipart/form-data' onSubmit={uploadFile}>
              <input
                type='file'
                style={{ display: 'none' }}
                ref={thumbnailRef}
                accept='image/*'
                onChange={onChangeImageUpload}
              />
            </form>
            {tempUser?.profile_image === '' && (
              <div className={styles['profile-image-camera-icon']}>
                <img src={cameraIcon} alt='' />
              </div>
            )}
          </div>
        </div>
        <div className={styles['label-container']}>
          <label className={styles['label']}>About Me (1-2 lines)</label>
        </div>
        <div className={styles['input-field-container']}>
          <TextArea
            name='introduce_message'
            width='100%'
            height='9.0rem'
            value={tempUser?.introduce_message}
            onChange={(event: any) =>
              setTempUser({
                ...tempUser!,
                introduce_message: event.target.value,
              })
            }
          />
        </div>
        <div style={{ marginTop: '1.2rem' }} />
        <div className={styles['label-container']}>
          <label className={styles['label']}>Username</label>
        </div>
        <div className={styles['input-field-container']}>
          <TextField
            name='nickname'
            type='text'
            width='100%'
            value={tempUser?.nickname}
            onChange={(event) =>
              setTempUser({ ...tempUser!, nickname: event.target.value })
            }
            actionButtonEnable={true}
            actionButtonTitle={'Check'}
            onAction={() => checkDuplicateNickName()}
          />
        </div>
        <div style={{ marginTop: '1.2rem' }} />
        <div className={styles['label-container']}>
          <label className={styles['label']}>Country</label>
        </div>
        <div className={styles['select-container']}>
          <SelectBox
            // 유저 정보를 받아와서 기본 디폴트 값은 유저국가로?
            value={
              tempUser?.country_name
                ? tempUser?.country_name
                : 'Choose Your Country'
            } // 선택된 나라 표시
            onAction={() => setOpenNationBottomSheet(true)}
          />
        </div>
        {/* <ComboBoxButton
          marginLeft='2.0rem'
          marginRight='2.0rem'
          paddingLeft='1.6rem'
          paddingRight='1.4rem'
          type='combobox'
          text={tempUser?.country_name}
          onAction={() => goCountryVerifyPage()}
        /> */}
        <div style={{ marginTop: '1.2rem' }} />
        <div className={styles['label-container']}>
          <label className={styles['label']}>Time Zone</label>
        </div>
        <ComboBoxButton
          marginLeft='2.0rem'
          marginRight='2.0rem'
          paddingLeft='1.6rem'
          paddingRight='0.6rem'
          type='button'
          text={tempUser?.timezone}
          actionButtonTitle='Change'
          onAction={() => goTimeZonePage()}
        />
        <div style={{ marginTop: '1.2rem' }} />
        <div className={styles['label-container']}>
          <label className={styles['label']}>Job Category</label>
        </div>
        <ComboBoxButton
          marginLeft='2.0rem'
          marginRight='2.0rem'
          paddingLeft='1.6rem'
          paddingRight='1.4rem'
          type='combobox'
          text={
            jobDirectly === true
              ? 'Add Custom'
              : tempUser?.job === ''
              ? 'Select your job category'
              : tempUser?.job
          }
          onAction={() => goJobPage()}
        />
        {jobDirectly === true && <div style={{ marginTop: '1.2rem' }} />}
        {jobDirectly === true && (
          <div className={styles['input-field-container']}>
            <TextField
              name='job'
              type='text'
              value={jobName}
              onChange={(event) => setJobName(event.target.value)}
              onClear={() => setJobName('')}
            />
          </div>
        )}
        <div style={{ marginTop: '1.2rem' }} />
        <div className={styles['label-container']}>
          <label className={styles['label']}>Language</label>
        </div>
        <ComboBoxButton
          marginLeft='2.0rem'
          marginRight='2.0rem'
          paddingLeft='1.6rem'
          paddingRight='0.6rem'
          type='button'
          text={tempUser?.language.join(', ')}
          actionButtonTitle='Change'
          onAction={() => goLanguagePage()}
        />
        <div style={{ marginTop: '1.2rem' }} />
        <div className={styles['label-container']}>
          <label className={styles['label']}>Company</label>
        </div>
        <ComboBoxButton
          marginLeft='2.0rem'
          marginRight='2.0rem'
          paddingLeft='1.6rem'
          paddingRight='0.6rem'
          type='button'
          text={tempUser?.company}
          actionButtonTitle='Change'
          onAction={() => goCompanyPage()}
        />
        <div style={{ marginTop: '1.2rem' }} />
        <div className={styles['label-container']}>
          <label className={styles['label']}>Years of experience</label>
          <div className={styles['label-prefix']}>
            {tempUser?.annual !== undefined && `${tempUser?.annual} years`}
          </div>
        </div>
        <div className={styles['input-field-container']}>
          <TextField
            width='100%'
            name='annual'
            type='text'
            value={tempUser?.annual.toString()}
            onChange={(event) => updateAnnual(event.target.value)}
          />
        </div>
        <div style={{ marginTop: '4.0rem' }} />
      </div>
      <div className={styles['modify-button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={2.0}
          active={true}
          onClick={() => done()}
        >
          Update
        </Button>
      </div>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setOpenBottomSheet(false)}
      >
        <Sheet.Container
          style={{ borderRadius: '12px 12px 0px 0px', height: '17.3rem' }}
        >
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-terms-title']}>
                Edit Profile Photo
              </div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBottomSheet(false)}
              />
            </div>
            <div
              className={styles['sheet-terms-content']}
              onClick={() => selectThumbnail()}
            >
              Choose from Library
            </div>
            <div
              className={styles['sheet-terms-content']}
              onClick={() => deleteProfileThumbnail()}
            >
              Delete photo
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheet(false)} />
      </Sheet>
      <Sheet
        isOpen={isOpenNationBottomSheet}
        detent='content-height'
        onClose={() => setOpenNationBottomSheet(false)}
      >
        <Sheet.Container
          style={{ borderRadius: '12px 12px 0px 0px', height: '54.7rem' }}
        >
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Choose country</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenNationBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {nationData
                .filter(
                  (e) => e.use === true && e.disableWhenDuplicate === false
                )
                .map((e) => (
                  <div
                    key={e.nation}
                    className={styles['sheet-terms-content']}
                    onClick={() => selectNation(e.nation, e.abb)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={
                          selectedNation?.nation === e.nation
                            ? selectedIcon
                            : unselectedIcon
                        }
                        alt=''
                        style={{ marginRight: '0.5rem' }}
                      />
                      <div>{e.nation}</div>
                    </div>
                  </div>
                ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenNationBottomSheet(false)} />
      </Sheet>
      {/* {showCountryVerify == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100vw',
          }}
        >
          <CountryVerify
            onVerify={(countryCode, countryName) =>
              selectCountry(countryCode, countryName)
            }
            onCancel={() => setShowCountryVerify(false)}
          />
        </div>
      )} */}
      {showTimeZone == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
          }}
        >
          <EditTimezone
            onSelect={(timeZoneIndex) => selectTimezone(timeZoneIndex)}
            onCancel={() => setShowTimeZone(false)}
          />
        </div>
      )}
      {showJobSetting == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
          }}
        >
          <JobSelect
            onSelect={(category, job) => selectJob(category, job)}
            onCancel={() => setShowJobSetting(false)}
          />
        </div>
      )}
      {showLanguageSetting == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
          }}
        >
          <LanguageSelect
            languages={tempUser?.language}
            onSelect={(languages) => selectLanguages(languages)}
            onCancel={() => setShowLanguageSetting(false)}
          />
        </div>
      )}
      {showCompanyVerify == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100vw',
          }}
        >
          <CompanyEmailVerify
            currentEmail={tempUser!.email}
            onVerify={(companyName, email) => selectCompany(companyName, email)}
            onCancel={() => setShowCompanyVerify(false)}
          />
        </div>
      )}
    </div>
  );
}

export default MyInfoModify;
