import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './FindIdLogin.module.css';
import Header from '../../components/layout/Header';
import TextField from '../../components/element/TextField';
import Button from '../../components/element/Button';

function FindIdLogin() {
  const navigate = useNavigate();
  const location = useLocation(); // location을 사용하여 state 접근
  const email = location.state?.email; // state에서 email 가져오기
  function toLogin() {
    navigate('/auth/signIn');
  }
  function toPassword() {
    navigate('/auth/passwordReset');
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-container']}>
        {'Your Registered Email'}
      </div>
      {email ? (
        <div className={styles['input-field-container']}>
          <TextField
            value={email}
            enable={false} // 읽기 전용
          />
        </div>
      ) : (
        <div className={styles['error-info']}>
          <span>No matching account found.</span>
        </div>
      )}
      <Button marginHorizontalRem={2.0} active onClick={toLogin}>
      Sign In
      </Button>
      <div className={styles['space']}>{/* 공백 */}</div>
      <div className={styles['password-reset-button']} onClick={toPassword}>Reset Password</div>
    </div>
  );
}

export default FindIdLogin;
