import React, { useEffect, useState } from 'react';
import Header from '../../components/layout/Header';
import styles from './MyMeetingPayment.module.css';
import { useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import closeIcon from '../../assets/images/ic_close.png';
import SelectBox from '../../components/element/SelectBox';
import userService from '../../services/userService';
import { PurchaseHistory } from '../../models/purchaseHistory';
import { format, formatISO, isSameMonth, subYears } from 'date-fns';

function MyMeetingPayment() {
  const navigate = useNavigate();
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false); // 바텀시트 상태
  const [selectedFilter, setSelectedFilter] = useState('All'); // 선택된 필터 상태
  const [purchaseList, setPurchaseList] = useState<PurchaseHistory[]>();
  const [page, setPage] = useState<number>(1);
  const [monthList, setMonthList] = useState<string[]>([]); // 월 리스트 상태
  const [filteredPurchaseList, setFilteredPurchaseList] = useState<PurchaseHistory[]>([]); // 필터된 목록

  useEffect(() => {
    // 오늘 날짜를 기준으로 1년 전 계산
    const endDate = new Date().toISOString();
    const startDate = subYears(new Date(), 1).toISOString();
    // 데이터를 가져오는 함수 호출
    getPurchaseHistory(page, 1000, startDate, endDate);
  }, []);

  useEffect(() => {
    if (selectedFilter === 'All') {
      setFilteredPurchaseList(purchaseList || []); // 필터가 All일 때는 전체 리스트로 설정
    } else {
      const filtered = purchaseList?.filter(
        (purchase) => format(new Date(purchase.created_date), 'MMM yyyy') === selectedFilter
      );
      setFilteredPurchaseList(filtered || []);
    }
  }, [selectedFilter, purchaseList]);

  async function getPurchaseHistory(
    page: number,
    count: number,
    startDate: Date | string,
    endDate: Date | string
  ) {
    try {
      const list = await userService.getPurchaseHistory(page, count, startDate, endDate);
      setPurchaseList(list); // 정렬된 목록을 상태에 저장
      const months = list.map((purchase) => format(new Date(purchase.created_date), 'MMM yyyy'));
      setMonthList(Array.from(new Set(months)));
    } catch (err) {
      console.error('Error fetching purchase history:', err);
    }
  }
  console.log(purchaseList);

  // 필터 선택 함수
  function selectFilter(type: string) {
    setSelectedFilter(type);
    setOpenBottomSheet(false); // 선택 후 바텀시트 닫기
  }

  function getPaymentMethod(method: string) {
    if(method === 'applepay') {
      return 'Apple Pay';
    }else if(method === 'googlepay') {
      return 'Google Pay';
    }else if(method === 'card') {
      return 'Card';
    }else{
      return 'Unknown';
    }
  }
  
  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-container']}>{'My Order History'}</div>
      <div className={styles['text']}>{'Apply the filter to view the last 5 meetings.'}</div>
      <div className={styles['input-field-container']}>
        <SelectBox
          width={'12rem'}
          height={'3.3rem'}
          name='type'
          type='text'
          value={selectedFilter}
          placeholder=''
          onAction={() => setOpenBottomSheet(true)}
          useBgColor={true}
          fontSize='1.4rem'
          fontWeight={600}
          radius='0.8rem'
        />
      </div>

      <div className={styles['payment-history']}>
        {filteredPurchaseList?.map((purchase: PurchaseHistory, index) => (
          <div key={purchase.id}>
            {index === 0 ||
            !isSameMonth(
              new Date(purchase.created_date),
              new Date(filteredPurchaseList[index - 1].created_date)
            ) ? (
              <div className={styles['payment-history-date']}>
                {format(new Date(purchase.created_date), 'MMM yyyy')}
              </div>
            ) : null}
            <div className={styles['payment-item']}>
              <div className={styles['payment-name']}>
                <span>Seller</span>
                <span>Product</span>
                <span>Total Amount</span>
                <span>Purchase Date</span>
                <span>Fulfillment Date</span>
                <span>Payment Method</span>
              </div>
              <div className={styles['payment-data']}>
                <span>{purchase.user_nickname}</span>
                <span>{purchase.product?.type}</span>
                <span>${purchase.final_price}</span>
                <span> {format(new Date(purchase.purchased_date), 'MMM dd, yyyy h:mm a')}</span>
                <span>{format(new Date(purchase.created_date), 'MMM dd, yyyy h:mm a')}</span>
                <span>{getPaymentMethod(purchase.payment_method)}</span>
              </div>
            </div>
            <div className={styles['line']}></div>
          </div>
        ))}
      </div>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setOpenBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px' }}>
          <Sheet.Content style={{ marginBottom: '2.0rem' }}>
            <div className={styles['sheet-container']}>
              <div className={styles['sheet-title']}>Filter by date</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div
                className={styles['sheet-terms-content']}
                onClick={() => selectFilter('All')}
              >
                <div>{'All'}</div>
              </div>
              {monthList.map((month) => (
                <div
                  className={styles['sheet-terms-content']}
                  onClick={() => selectFilter(month)}
                  key={month}
                >
                  <div>{month}</div>
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheet(false)} />
      </Sheet>
    </div>
  );
}

export default MyMeetingPayment;
