// src/components/CommunityPosts.tsx

import React, { useEffect, useState } from 'react';
import CommunityPost from './CommunityPost';
import styles from './CommunityPosts.module.css';
import communityService from '../../../services/communityService';
import { Community } from '../../../models/community';
import CommunityNotice from './CommunityNotice';

const CommunityPosts = () => {
  const [list, setList] = useState<Community[]>([]);
  const [notice, setNotice] = useState<Community[]>([]);

  useEffect(() => {
    getCommunityList();
  }, []);

  async function getCommunityList() {
    const response = await communityService.getList();
    if (response && response.list) {
      setList(response.list);
    }
    if (response && response.noticeList) {
      setNotice(response.noticeList);
    }
  }
  console.log(list);
  console.log(notice);

  return (
    <>
      {notice?.map((text: Community, index: number) => (
        <CommunityNotice text={text.content} />
      ))}
      <div className={styles['communityPosts']}>
        {list?.map((post: Community, index: number) => (
          <CommunityPost
            key={index}
            id={post.id!} // Ensure the `id` is being passed correctly
            title={post.content}
            date={post.created_date}
            views={post.count}
            category={post.category}
            replies={post.count}
          />
        ))}
      </div>
      <div className={styles.bottomSpacer} />
    </>
  );
};

export default CommunityPosts;
