import React, { useEffect, useState } from 'react';
import styles from './DiscountCoupon.module.css';
import unCheck from '../../../assets/images/ic_unchecked.png'; // 체크 아이콘 이미지 경로를 맞춰 주세요
import check from '../../../assets/images/ic_checked.png';
import arrow from '../../../assets/images/setting/ic_arrowRight.png';
import { Coupon } from '../../../models/coupon';
import userService from '../../../services/userService';

interface DiscountCouponProps {
  text?: string;
  discount?: number;
  day?: string | Date | null;
  start?: string | Date | null;
  end?: string | Date | null;
  padding?: number;
  active?: boolean;
  onClick?: () => void; // 클릭 시 호출할 콜백 함수
  useBgColor?: boolean;
  useCheck?: boolean; // useCheck를 추가
  checked?: boolean;
  useCoupon?: boolean;
  deadline?: boolean;
}

const DiscountCoupon: React.FC<DiscountCouponProps> = ({
  text,
  day,
  discount,
  padding,
  start,
  end,
  active = false,
  useBgColor = false,
  onClick,
  useCheck = false, // 기본값을 false로 설정
  checked = false,
  useCoupon = false,
  deadline = false,
}) => {
  // 날짜를 원하는 형식으로 변환하는 함수
  const formatDateRange = (start: string | Date | null, end: string | Date | null): string => {
    if (!start || !end) return '';
    const startDate = new Date(start);
    const endDate = new Date(end);

    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };

    const startFormatted = startDate.toLocaleDateString('en-US', options);
    const endFormatted = endDate.toLocaleDateString('en-US', options);

    return `${startFormatted} - ${endFormatted}`;
  };
  // 날짜 차이를 계산하는 함수

  const calculateDateDifference = (
    start: string | Date | null,
    end: string | Date | null
  ): number => {
    if (!start || !end) return 0;

    const toDay = new Date();
    const endDate = new Date(end);

    // 두 날짜의 차이를 밀리초 단위로 계산
    const differenceInTime = endDate.getTime() - toDay.getTime();

    // 밀리초를 일(day)로 변환 (1일 = 24시간 * 60분 * 60초 * 1000밀리초)
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return Math.floor(differenceInDays); // 차이를 절댓값으로 반환
  };

  return (
    <div
      className={styles['container']}
      style={{
        padding: `${padding}rem`,
        backgroundColor: `${useBgColor ? 'var(--color-green50)' : 'white'}`,
      }}
    >
      <div className={styles['text-container']}>
        <h1>
          {text && discount ? <>{`${discount}% off ${text} `}</> : <h1>Don't have any coupons.</h1>}
        </h1>
        {deadline ? (
          <h2>{formatDateRange(start!, end!)}</h2>
        ) : (
          calculateDateDifference(start!, end!) > 0 && (
            <h2>{`${calculateDateDifference(start!, end!)} Days left`}</h2>
          )
        )}
      </div>
      {/* useCheck가 true일 경우 checkIcon을 보여줌 */}
      {useCheck ? (
        <div
          className={styles['sub-action-container']}
          onClick={onClick}
        >
          <img
            src={checked ? check : unCheck}
            alt=''
          />
        </div>
      ) : (
        <>
          {/* active가 true일 경우 sub-action-container를 보여줍니다 */}
          {useCheck ? (
  <div
    className={styles['sub-action-container']}
    onClick={onClick}
  >
    <img
      src={checked ? check : unCheck}
      alt={checked ? 'Checked' : 'Unchecked'}
    />
  </div>
) : useCoupon ? (
  <div
    className={styles['sub-action-container']}
    onClick={onClick}
  >
    {'USE'}
  </div>
) : (
  <div className={styles['sub-text-container']} />
)}

        </>
      )}
    </div>
  );
};

export default DiscountCoupon;
