import React, { useState } from 'react';
import styles from './CommunityTabs.module.css'; // Import the CSS module

const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState('HOT'); // State for active tab

  const tabs = ['HOT', 'Job Search', 'Lifestyle', 'Field/Role', 'Studying Abroad', 'Events']; // Tabs

  return (
    <div className={styles.tabs}>
      <div className={styles.frame}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.chip} ${activeTab === tab ? styles.activeChip : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            <span className={activeTab === tab ? styles.activeText : styles.inactiveText}>
              {tab}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabsComponent;
