import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import styles from './MeetingTime.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import TextField from '../../components/element/TextField';
import SelectBox from '../../components/element/SelectBox';
import useToast from '../../hook/useToast';
import useGlobalStore from '../../stores/globalStore';
import timezoneData from '../../assets/data/timezone.json';

import timeIcon from '../../assets/images/ic_time.png';
import selectedIcon from '../../assets/images/ic_radio_selected.png';
import unselectedIcon from '../../assets/images/ic_radio_unselected.png';
import closeIcon from '../../assets/images/ic_close.png';
import mateService from '../../services/mateService';
import { isAfter, isBefore, isEqual, parse } from 'date-fns';

dayjs.extend(utc);
dayjs.extend(tz);

function MateMettingTime() {
  const toast = useToast();
  const navigator = useNavigate();
  const { user, mate, setMate } = useGlobalStore();
  const [selectedTimezoneIndex, setSelectedTimezoneIndex] = useState<number>(0);
  const [timeList, setTimeList] = useState<string[]>([
    '0,00:00,00:00', //요일 번호,사용여부(0,1),시작시간,종료시간
    '0,00:00,00:00',
    '0,00:00,00:00',
    '0,00:00,00:00',
    '0,00:00,00:00',
    '0,00:00,00:00',
    '0,00:00,00:00',
  ]);
  const [dayOfTheWeekList, setDayOfTheWeekList] = useState<string[]>([
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
    'SUN',
  ]);
  const [selectTimeList, setSelectTimeList] = useState<string[]>([
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ]);
  const [openTimeBottomSheetData, setOpenTimeBottomSheetData] = useState<string>(''); //시간 설정 바텀시트 정보.

  useEffect(() => {
    // mate 객체가 존재하는지 확인
    if (!mate) return;

    const idx = timezoneData.findIndex((element) => element['Timezone'] === mate.timezone);

    if (idx !== -1) {
      setSelectedTimezoneIndex(idx);
    }

    // mate 객체에 available_time_list가 없으면 기본값 설정
    if (!mate.available_time_list) {
      mate.available_time_list = [
        '0,00:00,00:00',
        '0,00:00,00:00',
        '0,00:00,00:00',
        '0,00:00,00:00',
        '0,00:00,00:00',
        '0,00:00,00:00',
        '0,00:00,00:00',
      ];
    }

    // available_time_list를 기반으로 timeList 업데이트
    for (let i = 0; i < mate.available_time_list.length; i++) {
      const timeListIdx = parseInt(mate.available_time_list[i].split(',')[0]);

      setTimeList((prevItems) => {
        const newItems = [...prevItems];
        const parts = mate.available_time_list[i].split(',');

        // 체크된 상태로 시작시간과 종료시간을 설정
        newItems[timeListIdx] = `1,${parts[1]},${parts[2]}`;
        return newItems;
      });
    }
  }, [mate]);

  function getTime() {
    return dayjs.utc().tz(timezoneData[selectedTimezoneIndex].tzCode).format('HH:mm');
  }

  function checkItem(idx: number) {
    setTimeList((prevItems) => {
      const newItems = [...prevItems];
      if (newItems[idx].split(',')[0] === '0') {
        //체크 해제 상태.
        newItems[idx] = '1,' + newItems[idx].split(',')[1] + ',' + newItems[idx].split(',')[2];
      } else {
        //체크 상태.
        newItems[idx] = '0,' + newItems[idx].split(',')[1] + ',' + newItems[idx].split(',')[2];
      }
      console.log(newItems);
      return newItems;
    });
  }

  function selectTime(time: string) {
    const idx = parseInt(openTimeBottomSheetData.split(',')[0]); // 선택한 요일.
    const type = openTimeBottomSheetData.split(',')[1]; // 선택한 시간 타입.(startTime, endTime)

    setTimeList((prevItems) => {
      const newItems = [...prevItems];
      const StartTime = newItems[idx].split(',')[1];
      const EndTime = newItems[idx].split(',')[2];

      const parsedTime = parse(time, 'HH:mm', new Date());
      const parsedStartTime = parse(StartTime, 'HH:mm', new Date());
      const parsedEndTime = parse(EndTime, 'HH:mm', new Date());

      if (type === 'startTime') {
        if (
          (isAfter(parsedTime, parsedEndTime) && EndTime !== '00:00') ||
          isEqual(parsedTime, parsedEndTime)
        ) {
          toast('error', 'Start time must be earlier than end time');
          return prevItems;
        }
        newItems[idx] = `${newItems[idx].split(',')[0]},${time},${EndTime}`;
      } else {
        if (
          (isAfter(parsedTime, parsedEndTime) && StartTime !== '00:00') ||
          isEqual(parsedTime, parsedStartTime)
        ) {
          toast('error', 'End time must be later than start time');
          return prevItems;
        }
        newItems[idx] = `${newItems[idx].split(',')[0]},${StartTime},${time}`;
      }
      return newItems;
    });
    setOpenTimeBottomSheetData('');
  }

  async function next() {
    const tempList = [];

    // timeList에서 체크된 항목을 tempList에 추가
    for (let i = 0; i < timeList.length; i++) {
      if (timeList[i].split(',')[0] === '1') {
        // 사용 중인 시간 아이템만 추가
        tempList.push(`${i},${timeList[i].split(',')[1]},${timeList[i].split(',')[2]}`);
      }
    }

    // 사용자가 하나도 선택하지 않은 경우
    if (tempList.length <= 0) {
      toast('error', 'Select your available times');
      return;
    }

    // mate 객체가 있으면 available_time_list 덮어쓰기
    if (user!.mate!) {
      // 기존 available_time_list 덮어쓰기
      setMate({ ...user!.mate, available_time_list: tempList });
    } else {
      // mate 객체가 없으면 새로운 mate 객체 생성
      setMate({
        ...mate!, // 기존 값이 있으면 그대로 유지
        available_time_list: tempList,
      });
    }

    // 다음 단계로 이동
    navigator('/mate/introduce');
  }

  async function saveMate({ validCheck = true }) {
    let data: any;

    // timeList에서 체크된 항목을 tempList에 추가
    const tempList = timeList
      .map((time, idx) => {
        if (time.split(',')[0] === '1') {
          return `${idx},${time.split(',')[1]},${time.split(',')[2]}`;
        }
        return ''; // 사용되지 않는 항목을 빈 문자열로 반환
      })
      .filter((item) => item !== ''); // 빈 문자열을 필터링하여 제거

    // 사용자가 하나도 선택하지 않은 경우
    if (tempList.length <= 0) {
      toast('error', 'Select your available times');
      return;
    }

    // mate 객체의 available_time_list만 덮어쓰는 방식
    if (mate) {
      mate.available_time_list = tempList; // mate 객체 직접 수정
    }

    // user.mate 객체가 있을 경우, available_time_list 덮어쓰기
    if (user?.mate) {
      user.mate.available_time_list = tempList; // user.mate 객체도 직접 수정
    }

    // 프로필 저장 처리 전에 mate 객체 상태 확인
    console.log('Saving mate profile:', mate);

    // 프로필 저장 API 호출
    data = await mateService.saveMateProfile(mate!); // mate 객체를 그대로 전달

    if (!data || data['type'] !== 'success') {
      toast('error', 'Profile creation failed. Please try again', false);
      return;
    }

    // 성공적으로 저장된 후 mate 상태 업데이트
    setMate(data['mate']); // 업데이트된 데이터로 변경

    if (validCheck) {
      navigator('/mate/profileComplete');
    }
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigator(-1)} />
      <div className={styles['content']}>
        <div className={styles['title-text']}>{'Select your days and time slots'}</div>
        <div className={styles['description-text']}>{'Meetings will match your availability.'}</div>
        <div style={{ marginTop: '2.4rem' }} />
        <div className={styles['timezone-container']}>
          <img
            src={timeIcon}
            alt=''
          />
          {`${
            timezoneData[selectedTimezoneIndex]['Timezone_en(wo_utc)']
          }, ${getTime()}\r\nSelect schedule based on your time zone`}
        </div>
        <div style={{ marginTop: '2.4rem' }} />
        <div className={styles['item-column']}>
          {timeList.map((time, idx) => (
            <div className={styles['item-row']}>
              <div
                className={styles['item-row']}
                onClick={() => checkItem(idx)}
              >
                <img
                  src={time.split(',')[0] === '1' ? selectedIcon : unselectedIcon}
                  alt=''
                />
                <div className={styles['item-text']}>{dayOfTheWeekList[idx]}</div>
              </div>
              <SelectBox
                width={'11.6rem'}
                name='startTime'
                type='text'
                value={time.split(',')[1]}
                onAction={() => setOpenTimeBottomSheetData(`${idx},startTime`)}
              />
              <div className={styles['item-divider']}>~</div>
              <SelectBox
                width={'11.6rem'}
                name='endTime'
                type='text'
                value={time.split(',')[2]}
                onAction={() => setOpenTimeBottomSheetData(`${idx},endTime`)}
              />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '4.0rem' }} />
      </div>
      <div className={styles['next-button-container']}>
        {user!.mate?.verify_status === 'APPROVE' ? (
          <Button
            width={'100%'}
            marginHorizontalRem={2.0}
            active={true}
            onClick={() => saveMate({ validCheck: true })}
          >
            Update Timeslot
          </Button>
        ) : (
          <Button
            width={'100%'}
            marginHorizontalRem={2.0}
            active={true}
            onClick={() => next()}
          >
            Next: Create a Mate Profile
          </Button>
        )}
      </div>
      <Sheet
        isOpen={openTimeBottomSheetData !== ''}
        detent='content-height'
        onClose={() => setOpenTimeBottomSheetData('')}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.5rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              Choose time slot
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenTimeBottomSheetData('')}
              />
            </div>
            <Sheet.Scroller>
              {selectTimeList.map((e) => (
                <div
                  className={styles['sheet-terms-content']}
                  onClick={() => selectTime(e)}
                >
                  <div>{e}</div>
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenTimeBottomSheetData('')} />
      </Sheet>
    </div>
  );
}

export default MateMettingTime;
