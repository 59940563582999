import { useNavigate } from 'react-router-dom';
import styles from './ResetEmail.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import resetEmail from '../../assets/images/resetEmail/resetEmail.png';
import { useState } from 'react';

function ResetEmail() {
  function toVerification() {
    navigate('/auth/emailVerification');
  }

  const navigate = useNavigate();
  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-container']}>{'Check your email for code'}</div>
      <div className={styles['text']}>
        {
          "We've sent a password reset email.\r\nIf you don't see it, check your spam folder."
        }
      </div>
      <div className={styles['resetEmail-icon']}>
        <img
          src={resetEmail}
          alt='이메일 아이콘'
        />
      </div>
      <Button
        marginHorizontalRem={2.0}
        active={true}
        onClick={() => toVerification()}
      >
        확인
      </Button>
    </div>
  );
}

export default ResetEmail;
