import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import selectedIcon from '../../assets/images/ic_check_default.png';
import styles from './CalculationRequestAddAddress.module.css';
import infoIcon from '../../assets/images/ic_info.png';
import settlementData from '../../assets/data/settlement.json';
import TextField from '../../components/element/TextField';

function CalculationRequestAddAddress() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const toRequest = () => {
    navigate('/calculation/request/add/email');
  };
  const [selectedType, setSelectedType] = useState(null);
  const { selectedNation, selectedSettlement } = location.state || {}; // 전달된 selectedNation 가져오기

  console.log(selectedNation);

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'Set up a withdrawal method'}</div>
      <div className={styles['text']}>{'Please enter your address'}</div>

      <div className={styles['text-container']}>
        <div className={styles['text-item']}>
          <div className={styles['text-label']}>{'Date of Birth'}</div>
          <TextField
            width='unset'
            placeholder='YYYY/MM/DD'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onClear={() => setEmail('')} // 화살표 함수로 변경
          />
        </div>

        <div className={styles['text-item']}>
          <div className={styles['text-label']}>{'Nationality'}</div>
          <TextField
            width='unset'
            placeholder='Country/Region' //성을 입력해달라고 되어있었는데 국가 입력 같아서 국가입력으로 멘트 변경
            value={selectedSettlement.nation}
            onChange={(e) => setFirstName(e.target.value)}
            actionButtonTitle='Change'
            onAction={() => setFirstName('')}
            actionButtonEnable={true}
          />
        </div>

        <div className={styles['text-item']}>
          <div className={styles['text-label']}>{'City/Province/State'}</div>

          <TextField
            width='unset'
            placeholder='City/District'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onClear={() => setLastName('')} // 화살표 함수로 변경
          />
        </div>
        <div className={styles['text-item']}>
          <div className={styles['text-label']}>{'Street name, Building number'}</div>

          <TextField
            width='unset'
            placeholder='e.g., Gangnam-daero, Seocho-gu'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onClear={() => setLastName('')} // 화살표 함수로 변경
          />
        </div>
        <div className={styles['text-item']}>
          <div className={styles['text-label']}>{'Additional Address Information'}</div>

          <TextField
            width='unset'
            placeholder='Please enter the remaining address'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onClear={() => setLastName('')} // 화살표 함수로 변경
          />
        </div>
      </div>

      <div className={styles['button-container']}>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={Boolean(email && firstName && lastName)}
          onClick={toRequest}
        >
          Completed
        </Button>
      </div>
    </div>
  );
}

export default CalculationRequestAddAddress;
