import { useNavigate } from 'react-router-dom';
import styles from './NotificationSetting.module.css';
import Header from '../../components/layout/Header';

import ic_arrowRight from '../../assets/images/setting/ic_arrowRight.png';
import ic_toggle_off from '../../assets/images/ic_toggle_off.png';
import ic_toggle_on from '../../assets/images/ic_toggle_on.png';
import { useState } from 'react';
import UserService from '../../services/userService';
import useGlobalStore from '../../stores/globalStore';

import translateLanguagesData from '../../assets/data/translate_languages.json';

function SettingNotification() {
  const navigate = useNavigate();
  const { user, setUser } = useGlobalStore();

  const goLanguages = () => {
    navigate('/setting/notification/languages');
  };
  
  const goType = () => {
    navigate('/setting/notification/type');
  };

  async function updateNotification(type: string, on: boolean) {
    const result = await UserService.updateNotification(type, on);
    if(result === 'success') {
      if(type === 'community_reply') {
        setUser({...user!, notification_community_reply: on});
      }else if(type === 'recommend_mate_email') {
        setUser({...user!, notification_recommend_mate_email: on});
      }else if(type === 'marketing') {
        setUser({...user!, agree_marketing: on});
      }else if(type === 'meeting') {
        setUser({...user!, notification_meeting: on});
      }else if(type === 'hide_same_company') {
        setUser({...user!, hide_same_company: on});
      }
    }
  }

  return (
    <div>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
      />
      <div className={styles['profile-container']}>
        <div className={styles['welcome-text']}>Notification Settings</div>
      </div>

      <div
        className={styles['card-menu-container']}
        style={{ marginTop: '1.6rem' }}
      >
        <div>Language</div>
        <div
          className={styles['card-menu-add-product']}
          onClick={goLanguages}
        >
          {translateLanguagesData.find((e) => e.code === user?.translate_language_code)?.language}
          <img
            src={ic_arrowRight}
            alt=''
          />
        </div>
      </div>
      <div className={styles['card-menu-container']}>
        <div>Notifications</div>
        <div
          className={styles['card-menu-add-product']}
          onClick={goType}
        >
          {user?.notification_type.join(', ').replace('sms', 'SMS').replace('email', 'Email')}
          <img
            src={ic_arrowRight}
            alt=''
          />
        </div>
      </div>
      <div className={styles['divider']} />

      <div className={styles['card-menu-container']}>
        <div>Notifications for comments</div>
        <img
          onClick={() => updateNotification('community_reply', !user?.notification_community_reply)}
          src={user?.notification_community_reply ? ic_toggle_on : ic_toggle_off}
          alt=''
        />
      </div>

      <div className={styles['card-menu-container']}>
        <div>Mate recommendation emails</div>
        <img
          onClick={() => updateNotification('recommend_mate_email', !user?.notification_recommend_mate_email)}
          src={user?.notification_recommend_mate_email ? ic_toggle_on : ic_toggle_off}
          alt=''
        />
      </div>

      <div className={styles['card-menu-container']}>
        <div>Marketing Communications</div>
        <img
          onClick={() => updateNotification('marketing', !user?.agree_marketing)}
          src={user?.agree_marketing ? ic_toggle_on : ic_toggle_off}
          alt=''
        />
      </div>

      <div className={styles['card-menu-container']}>
        <div>Meeting request notifications</div>
        <img
          onClick={() => updateNotification('meeting', !user?.notification_meeting)}
          src={user?.notification_meeting ? ic_toggle_on : ic_toggle_off}
          alt=''
        />
      </div>
      <div
        className={styles['card-menu-container']}
        style={{ marginBottom: '4.0rem' }}
      >
        <div>Hide my profile from colleagues</div>
        <img
          onClick={() => updateNotification('hide_same_company', !user?.hide_same_company)}
          src={user?.hide_same_company ? ic_toggle_on : ic_toggle_off}
          alt=''
        />
      </div>
    </div>
  );
}

export default SettingNotification;
