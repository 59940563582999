import Header from '../../components/layout/Header';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Button from '../../components/element/Button';
import styles from './NotificationSettingTime.module.css';
import TextField from '../../components/element/TextField';
import SearchBar from '../../components/element/SearchBar';

function NotificationSettingTimeSearch() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  return (
    <div>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
        title='Time Zone'
      />
      <SearchBar
        placeholder='Seoul'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        useBackButton={false}
      />
    </div>
  );
}

export default NotificationSettingTimeSearch;
