import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './ProfileComplete.module.css';

import profileCompleteIcon from '../../assets/images/ic_profile_complete.png';

function ProfileComplete() {
  const navigate = useNavigate();

  function done() {
    
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)}/>
      <div className={styles['label-container']}>
        Your profile is all set!<br/>Take a dive with new people
      </div>
      <div className={styles['complete-image']}>
        <img src={profileCompleteIcon} width={'16.0rem'} height={'16.0rem'} alt=""/>
      </div>
      <div className={styles['title']}>Is my profile set up right? 👀</div>
      <div className={styles['link-text']} onClick={() => navigate('/my/myInfo')}>View My Profile</div>
      <div className={styles['title']}>Ready to share your expertise with others?</div>
      <div className={styles['link-text']} onClick={() => navigate('/mate/agreement')}>Apply to be a Mate</div>
      <div className={styles['button-container']}>
        <Button width={'100%'} marginHorizontalRem={2.0} active={true} onClick={() => window.location.href = '/'}>
          Explore Nailedit
        </Button>
      </div>
    </div>
    );
  }
  
  export default ProfileComplete;
    