import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LinkedinInput.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import TextField from '../../components/element/TextField';
import useToast from '../../hook/useToast';
import useGlobalStore from '../../stores/globalStore';

function MateLinkedinInput() {
  const toast = useToast();
  const navigator = useNavigate();
  const { mate, setMate } = useGlobalStore();
  const [linkedinUrl, setLinkedinUrl] = useState<string>('');

  useEffect(() => {
    if(mate!.linkedin_url !== undefined) {
      setLinkedinUrl(mate!.linkedin_url);
    }
  }, []);

  function skip() {
    setMate({ ...mate!, linkedin_url: '' });
    navigator('/mate/timezone');
  }
  async function next() {
    if (linkedinUrl === '') {
      toast('error', 'Enter your LinkedIn.');
      return;
    }

    setMate({ ...mate!, linkedin_url: linkedinUrl });
    navigator('/mate/timezone');
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigator(-1)} />
      <div className={styles['title-text']}>Verify Your Experience with Linkedin</div>
      <div className={styles['description-text']}>
        {
          'Please provide your profile link for accurate career\r\nverification. This will be used internally only.'
        }
      </div>
      <div style={{ marginTop: '3.2rem' }} />
      <div className={styles['label-text']}>Linkedin link</div>
      <div className={styles['input-field-container']}>
        <TextField
          name='address'
          type='text'
          maxLength={30}
          value={linkedinUrl}
          enable={true}
          placeholder='Add url'
          onChange={(event) => setLinkedinUrl(event.target.value)}
        />
      </div>
      <div style={{ marginTop: '3.2rem' }} />
      <Button
        marginHorizontalRem={2.0}
        active={true}
        onClick={() => next()}
      >
        Next
      </Button>
      <div
        className={styles['skip-text']}
        onClick={() => skip()}
      >
        Skip
      </div>
    </div>
  );
}

export default MateLinkedinInput;
