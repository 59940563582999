import { useEffect, useState } from 'react';
import Header from '../../components/layout/Header';
import { Sheet } from 'react-modal-sheet';
import { useNavigate } from 'react-router-dom';
import styles from './PersonalInfoNumber.module.css';
import closeIcon from '../../assets/images/ic_close.png';
import Button from '../../components/element/Button';
import TextField from '../../components/element/TextField';
import SelectBox from '../../components/element/SelectBox';
import nationData from '../../assets/data/nation.json';
import selectedIcon from '../../assets/images/ic_radio_selected.png';
import unselectedIcon from '../../assets/images/ic_radio_unselected.png';
import useToast from '../../hook/useToast';
import { VerifyStatus } from '../../constants/constants';
import useGlobalStore from '../../stores/globalStore';
import authService from '../../services/authService';

function PersonalInfoNumber() {
  const toast = useToast();
  const [isOpenNationBottomSheet, setOpenNationBottomSheet] = useState(false);
  const [selectedNation, setSelectedNation] = useState<string | null>('82');
  const [receive, setReceive] = useState(false);
  const [value, setValue] = useState('');
  const [isVerifyActive, setVerifyActive] = useState<boolean>(false);
  const [isActiveTimer, setActiveTimer] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(180);
  const { user, setUser } = useGlobalStore();
  const navigate = useNavigate();
  const [number, setNumber] = useState('');
  const [cellPhoneVerifyStatus, setCellPhoneVerifyStatus] = useState<VerifyStatus>(
    VerifyStatus.NONE
  );
  const [formData, setFormData] = useState<any>({
    internationalNumber: '+1', //국가번호.
    cellPhone: '',
    cellPhoneVerifyCode: '',
    password: '',
  });
  const inputRules: any = {
    password: {
      minLength: 8,
      maxLength: 20,
      pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/,
    },
    cellPhone: {
      minLength: 6,
      maxLength: 11,
    },
    cellPhoneVerifyCode: {
      minLength: 6,
      maxLength: 6,
    },
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const rule = inputRules[name];
    let formattedValue = value;

    if (rule.maxLength && value.length > rule.maxLength) {
      formattedValue = value.slice(0, rule.maxLength); //글자 수 초과하지 않게.
    }

    if (name === 'birth') {
      formattedValue = formattedValue.replaceAll(' ', '');
      formattedValue = formattedValue.replaceAll('/', '');
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  // 각 필드의 유효성 검사 규칙 정의
  const validateField: any = {
    password: (value: string) =>
      inputRules['password'].pattern.test(value) &&
      value.length >= inputRules['password'].minLength &&
      value.length <= inputRules['password'].maxLength,
    internationalNumber: (value: string) => value.length > 0,
    cellPhone: (value: string) =>
      value.length >= inputRules['cellPhone'].minLength &&
      value.length <= inputRules['cellPhone'].maxLength,
    cellPhoneVerifyCode: (value: string) =>
      value.length >= inputRules['cellPhoneVerifyCode'].minLength &&
      value.length <= inputRules['cellPhoneVerifyCode'].maxLength &&
      cellPhoneVerifyStatus === VerifyStatus.COMPLETE,
  };

  useEffect(() => {
    //모든 필드가 유효한 값인지 체크.
    const allFieldsValid = Object.keys(formData).every(
      (key) => formData[key] !== undefined && validateField[key](formData[key])
    );
    if (allFieldsValid) {
      setVerifyActive(true);
    } else {
      setVerifyActive(false);
    }
  }, [formData]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isActiveTimer) {
      interval = setInterval(() => {
        setTimer((prevTimer: number) => (prevTimer > 0 ? prevTimer - 1 : 0));

        if (timer <= 1) {
          toast('error', 'The verification time has expired', false);
          stopTimer();
          setCellPhoneVerifyStatus(VerifyStatus.NONE);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isActiveTimer, timer]);

  const stopTimer = () => {
    setActiveTimer(false);
  };

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  async function requestCellPhoneVerify() {
    if (formData.cellPhone === '' || cellPhoneVerifyStatus !== VerifyStatus.NONE) return;

    const isDuplicate: boolean = await authService.checkDuplicateCellPhone(
      formData.internationalNumber,
      formData.cellPhone
    );
    if (isDuplicate) {
      toast('error', 'The same number is already registered', false);
      return;
    }

    //휴대폰 인증 번호 전송.
    setCellPhoneVerifyStatus(VerifyStatus.WAIT);
    toast('success', 'Check your messages for code', false);
    setTimer(180); //만료시간 3분.
    setActiveTimer(true);
    setReceive(true);

    await authService.requestCellPhoneVerify(formData.internationalNumber, formData.cellPhone);
  }

  async function checkCellPhoneVerifyCode() {
    // 초기 조건 확인
    if (formData.cellPhone === '' || cellPhoneVerifyStatus === VerifyStatus.COMPLETE) return;

    try {
      // 인증 코드 확인
      const result = await authService.checkVerifyCode(
        formData.internationalNumber + formData.cellPhone,
        formData.cellPhoneVerifyCode
      );

      if (!result) {
        toast('error', "The code doesn't match. Try again", false);
        return;
      }

      // 휴대폰 인증 완료 처리
      setCellPhoneVerifyStatus(VerifyStatus.COMPLETE);
      toast('success', 'Phone verified!', false);
      stopTimer();

      // 로컬 상태 업데이트
      const updatedFormData = {
        ...formData,
        cellPhoneVerified: true,
      };
      setFormData(updatedFormData);

      // DB에 업데이트 요청
      await authService.updateCellPhone(
        updatedFormData.internationalNumber,
        updatedFormData.cellPhone
      );

      // 전역 상태(user) 업데이트
      if (user) {
        setUser({
          ...user,
          international_number: updatedFormData.internationalNumber,
          cellphone: updatedFormData.cellPhone,
        });
      }

      // 인증 완료 후 이동
      navigate('/setting/personalInfo');
    } catch (error) {
      console.error('Error during phone verification:', error);
      toast('error', 'An unexpected error occurred. Please try again.', false);
    }
  }

  const clear = () => {
    setFormData({
      ...formData,
      cellPhone: '', // cellPhone 필드만 초기화
    });
  };

  function selectNation(code: string) {
    setSelectedNation(code);
    setFormData({
      ...formData,
      internationalNumber: '+' + code,
    });
  }

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
      />
      <div className={styles['title']}>{'Enter the new phone number'}</div>
      <div className={styles['label']}>{'Phone number'}</div>
      <div className={styles['text-container']}>
        <SelectBox
          value={`+${selectedNation}` || '+82'}
          width='11.6rem'
          textColor='var(--color-white700)'
          onAction={() => setOpenNationBottomSheet(true)}
        />
        <TextField
          name='cellPhone'
          width=''
          placeholder='Enter without dashes(-)'
          value={formData.cellPhone}
          // onChange={(e) => setNumber(e.target.value)}

          onClear={clear}
          onChange={(event) => handleChange(event)}
        />
      </div>
      <div className={styles['verification-container']}>
        {receive === true && (
          <>
            <div className={styles['flex']}>
              <div className={styles['label']}>{'Verification code'}</div>
              {isActiveTimer && <div className={styles['label-error']}>{formatTime()}</div>}
            </div>

            <TextField
              name='cellPhoneVerifyCode'
              type='number'
              width='unset'
              placeholder='Enter the verification code'
              value={formData.cellPhoneVerifyCode}
              onChange={(event) => handleChange(event)}
            />
          </>
        )}
      </div>

      <div className={styles['button-container']}>
        {receive === false ? (
          <Button
            height={'5.6rem'}
            radius={12}
            activeFontSize='1.6rem'
            activeFontWeight={700}
            marginHorizontalRem={2}
            onClick={() => requestCellPhoneVerify()}
            active={formData.cellPhone !== '' && cellPhoneVerifyStatus === VerifyStatus.NONE}
          >
            Receive verification code
          </Button>
        ) : (
          <Button
            height={'5.6rem'}
            radius={12}
            activeFontSize='1.6rem'
            activeFontWeight={700}
            marginHorizontalRem={2}
            onClick={() => checkCellPhoneVerifyCode()}
            active={
              formData.cellPhoneVerifyCode !== '' && cellPhoneVerifyStatus !== VerifyStatus.COMPLETE
            }
          >
            Next
          </Button>
        )}
      </div>
      <div
        className={styles['info']}
        onClick={() => requestCellPhoneVerify()}
      >
        {'Resend verification code'}
      </div>
      <Sheet
        isOpen={isOpenNationBottomSheet}
        detent='content-height'
        onClose={() => setOpenNationBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.7rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Select Country Code</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenNationBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {nationData.filter((e) => e.use === true).map((e) => (
                <div
                  key={e.code} // key 값을 e.code로 변경
                  className={styles['sheet-terms-content']}
                  onClick={() => selectNation(e.code)} // e.code 사용
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={selectedNation === e.code ? selectedIcon : unselectedIcon}
                      alt=''
                      style={{ marginRight: '0.5rem' }}
                    />
                    <div>{e.text}</div> {/* 코드와 국가명 동시 표시 */}
                  </div>
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenNationBottomSheet(false)} />
      </Sheet>
    </div>
  );
}

export default PersonalInfoNumber;
