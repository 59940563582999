import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MyInfoAuth.module.css';
import Header from '../../components/layout/Header';
import TextField from '../../components/element/TextField';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import authService from '../../services/authService';

function MyInfoAuth() {
  const navigate = useNavigate();
  const toast = useToast();
  const [password, setPassword] = useState<string>('');

  async function done() {
    if (password.length < 8) {
      toast('error', 'Password must be at least 8 characters');
      return;
    }

    //비밀번호 확인.
    const result: boolean = await authService.checkPassword(password);
    if (result) {
      navigate('/my/myInfoModify');
    } else {
      toast('error', 'No matching account found');
    }
  }

  return (
    <div>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title-text']}>
        Enter your password
        <br />
        to update your profile
      </div>
      <div className={styles['label-container']}>
        <label className={styles['label']}>Password</label>
      </div>
      <div className={styles['input-field-container']}>
        <TextField
          type='password'
          value={password}
          maxLength={20}
          placeholder='Enter your password'
          onChange={(event) => setPassword(event.target.value)}
          onClear={() => setPassword('')}
        />
      </div>
      <Button
        marginHorizontalRem={2.0}
        active={password.length >= 8}
        onClick={() => done()}
      >
        Submit
      </Button>
    </div>
  );
}

export default MyInfoAuth;
