'use client';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import useGlobalStore from '../../stores/globalStore';
import CommunityCategory from './components/CommunityCategory';
import CommunityTabs from './components/CommunityTabs';
import CommunityNotice from './components/CommunityNotice';
import Footer from '../../components/layout/Footer';
import CommunityPosts from './components/CommunityPosts';
import styles from './page.module.css'; // Importing the CSS module
import bellIcon from '../../assets/images/ic_bell.png';
import FloatingButton from './components/FloatingButton';
import searchIcon from '../../assets/images/ic_search.png';

function done() {}

function Page() {
  const navigate = useNavigate();
  const { isAuthenticated } = useGlobalStore();
  const [alarmCount, setAlarmCount] = useState(0); // 알림 갯수 상태 추가
  const toAlarm = () => {
    navigate('/my/notification');
  };

  useEffect(() => {
    // 알림 갯수는 실제 데이터 소스에서 받아오는 것이 이상적이지만, 여기서는 예시로 고정 값을 사용
    setAlarmCount(3); // 실제로는 API 또는 다른 로직을 통해 알림 갯수를 설정
  }, []);

  return (
    <div className={styles.pageContainer}>
      {/* Fixed header */}
      <Header
        customTitle='Community'
        useBackButton={true}
        useLogoTitle={false}
        useLanguageButton={true}
        actionType='icon'
        actionTitle={
          <div
            onClick={toAlarm}
            className={styles['alarm']}
          >
            <img
              src={bellIcon}
              alt='알림'
              style={{ margin: 0 }}
            />
            {alarmCount > 0 && <div className={styles['alarm-count']}>{alarmCount}</div>}
          </div>
        }
        onAction={toAlarm}
        secondaryTitle={
          <div onClick={done}>
            <img
              src={searchIcon}
              alt=''
              style={{ margin: 0 }}
            />
          </div>
        }
        onSecondaryAction={() => alert('bb')}
        //Search
      />

      {/* Content section that scrolls */}
      <div className={styles.contentContainer}>
        <CommunityCategory />
        <CommunityTabs />
        {/* <CommunityNotice /> 
        <CommunityNotice /> */}

        <div className={styles.postsContainer}>
          <CommunityPosts />
        </div>
      </div>
      <FloatingButton />

      {/* Fixed footer */}
      <Footer />
    </div>
  );
}

export default Page;
