import Header from '../../components/layout/Header';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import Button from '../../components/element/Button';
import styles from './NotificationSettingTime.module.css';
import TextField from '../../components/element/TextField';

function NotificationSettingTime() {
  const navigate = useNavigate();
  const goTimeSearch = () => {
    navigate('/setting/notification/time/search');
  };

  return (
    <div>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
        title='Change Time Zone'
      />
      <div className={styles['text']}>Time Zone</div>
      <TextField
        actionButtonEnable={true}
        actionButtonTitle='Change'
        value='Memphis, USA'
        onAction={goTimeSearch}
        width='unset'
        marginLeft='1.6rem'
        marginRight='1.6rem'
      />
      <div className={styles['button-container']}>
        <Button
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem'
          activeFontWeight={700}
          marginHorizontalRem={2}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}

export default NotificationSettingTime;
