import React from 'react';
import styles from './FloatingButton.module.css';
import { MdAdd } from 'react-icons/md'; // IconType 인터페이스 가져오기
import { Link } from 'react-router-dom';

const FloatingButton: React.FC = () => {
  return (
    <Link to="/community/posting" style={{ textDecoration: 'none' }}>
      <button className={styles.fab}>
        <MdAdd className={styles.fabIcon} /> {/* Add 아이콘 */}
      </button>
    </Link>
  );
};

export default FloatingButton;
