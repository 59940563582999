import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SearchResultCard.module.css';
import defaultProfileImg from '../../../assets/images/ic_profile_default.png'; // 기본 프로필 이미지
import bookmarkOn from '../../../assets/images/ic_bookmark_on.png';
import bookmarkOff from '../../../assets/images/ic_bookmark_off.png';
import starIcon from '../../../assets/images/ic_star.png'; // Ratings 아이콘 경로 추가

interface SearchResultCardProps {
  nickname: string; // 닉네임
  company: string; // 회사명
  annual?: number; // 회사명
  job: string; // 직무 및 경력
  profileImage?: string; // 메이트용 프로필 이미지
  introduceMessage: string; // 본인을 한 줄로 소개하는 메시지
  count_total: number; // 누적 미팅 횟수
  count_coffeeChat: number; // 누적 미팅 횟수
  count_interview: number; // 누적 미팅 횟수
  count_resume: number; // 누적 미팅 횟수
  price: number; // 가격
  paddingBottom?: number;
  nicknameFontSize?: number;
  companyFontSize?: number;
  tag?: string;
  nation?: string;
  rating?: number; // Ratings 속성 추가
  useRating?: boolean;
  useFontColor?: boolean;
  useBorder?: boolean;
  useTypeBox?: boolean;
  mateId?: number;
  review?: number;
  favorite?: boolean;
  date?: Date;
  created_date?: Date; // created_date 추가
  onClick?: () => void; // 클릭 시 호출할 콜백 함수
  goDetail?: () => void;
  goReview?: () => void;
  isBookmarked?: boolean; // isBookmarked 속성 추가
}

const SearchResultCard: React.FC<SearchResultCardProps> = ({
  nickname,
  company,
  job,
  profileImage,
  introduceMessage,
  count_total, // 누적 미팅 횟수
  count_coffeeChat, // 누적 미팅 횟수
  count_interview, // 누적 미팅 횟수
  count_resume, // 누적 미팅 횟수
  price,
  paddingBottom,
  annual,
  review,
  nation,
  rating = 1,
  nicknameFontSize,
  companyFontSize,
  tag,
  favorite = false,
  mateId,
  useFontColor = false,
  useRating = false,
  useTypeBox = false,
  useBorder = true,
  onClick,
  goDetail,
  goReview,
  // isBookmarked: initialIsBookmarked = false, // 북마크 초기 상태 설정
}) => {
  const navigate = useNavigate();

  const fontColor = {
    color: useFontColor ? 'var(--color-white700)' : 'inherit',
  };
  const borderStyle = {
    borderBottom: useBorder ? '1px solid var(--color-white200)' : 'none',
  };
  const ratingStyle = {
    display: useRating ? 'flex' : 'none',
  };
  const boxStyle = {
    display: useTypeBox ? 'none' : 'flex',
  };
  const typeBoxStyle = {
    display: useTypeBox ? 'flex' : 'none',
  };

  // const toReview = () => {
  //   navigate('/searchResult/review');
  // };

  const renderRating = (rating: number) => {
    const roundedRating = Math.round(rating); // 별점 개수를 위한 반올림
    return (
      <div className={styles['rating-container']}>
        {[...Array(roundedRating)].map((_, index) => (
          <img
            key={index}
            src={starIcon}
            alt='Ratings'
            className={styles['star-icon']}
          />
        ))}
      </div>
    );
  };

  return (
    <div
      className={styles['result-container']}
      style={{
        ...borderStyle, // 기존 borderStyle 속성 복사
        paddingBottom: `${paddingBottom}rem`, // padding 스타일 추가
      }}
      onClick={goDetail}
    >
      <div className={styles['result-info-container']}>
        <div
          className={styles['result-bookmark']}
          // onClick={() => bookmark()}
          onClick={(e) => {
            e.stopPropagation(); // 북마크 클릭 시 toDetail이 실행되지 않도록 이벤트 전파 차단
            onClick?.(); // onClick 콜백 호출
          }}
        >
          <img
            src={favorite ? bookmarkOn : bookmarkOff}
            alt={favorite ? 'bookmarkOn' : 'bookmarkOff'}
          />
        </div>

        <img
          src={profileImage || defaultProfileImg} // 프로필 이미지가 없으면 기본 이미지 사용
          alt={`${nickname} 프로필 이미지`}
          className={styles['result-profile-img']}
        />
        <div className={styles['result-info-item']}>
          <span
            className={styles['result-info-title']}
            style={{ fontSize: `${nicknameFontSize}rem` }}
          >
            {nickname}
          </span>
          <span
            className={styles['result-info-sub-title']}
            style={{ fontSize: `${companyFontSize}` }}
          >
            {company}
          </span>
          <span className={styles['result-info-text']}>
            {job} {`${annual ? '| ' + annual + ' years' : ''}`}
          </span>
        </div>
      </div>

      <div
        className={styles['result-info']}
        style={fontColor}
      >
        {introduceMessage} <span>{nation}</span>
        <br />
        {tag}
      </div>
      <div
        className={styles['result-rating']}
        style={ratingStyle}
      >
        {renderRating(rating)}
        {rating === 0 ? '' : <div className={styles['result-rating-count']}>{rating}</div>}
        <div
          className={styles['result-review']}
          onClick={goReview}
        >
          {review} {'Reviews'}
        </div>
      </div>
      <div
        className={styles['result-info-number']}
        style={boxStyle}
      >
        <span className={styles['result-count']}>
          Total Meetings <strong>{count_total}</strong>
        </span>
        <span className={styles['result-price']}>${price}</span>
      </div>
      <div
        className={styles['result-info-number-type']}
        style={typeBoxStyle}
      >
        <span className={styles['result-count']}>
          Chat <strong>{count_coffeeChat}</strong>
        </span>
        <span className={styles['result-line']}></span>
        <span className={styles['result-count']}>
          Resume <strong>{count_interview}</strong>
        </span>
        <span className={styles['result-line']}></span>
        <span className={styles['result-count']}>
          Interview <strong>{count_resume}</strong>
        </span>
      </div>
    </div>
  );
};

export default SearchResultCard;
