import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import styles from './Home.module.css';
import './swiper.css';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';

import AuthService from '../services/authService';
import useToast from '../hook/useToast';
import { Mate } from '../models/mate';
import MateService from '../services/mateService';
import UserService from '../services/userService';

import footerDividerIcon from '../assets/images/ic_footer_divider.png';
import socialTwitterIcon from '../assets/images/ic_social_twitter.png';
import socialInstagramIcon from '../assets/images/ic_social_instagram.png';
import socialYoutubeIcon from '../assets/images/ic_social_youtube.png';
import socialTictokIcon from '../assets/images/ic_social_tictok.png';
import socialWhatsappIcon from '../assets/images/ic_social_whatsapp.png';
import socialWechatIcon from '../assets/images/ic_social_wechat.png';
import socialKakaotalkIcon from '../assets/images/ic_social_kakaotalk.png';
import starIcon from '../assets/images/ic_star.png';

import searchIcon from '../assets/images/ic_search.png';
import categoryAllIcon from '../assets/images/ic_category_all.png';
import bookmarkOnIcon from '../assets/images/ic_bookmark_on.png';
import bookmarkOffIcon from '../assets/images/ic_bookmark_off.png';
import arrowRightIcon from '../assets/images/ic_arrow_right.png';
import profileDefaultIcon from '../assets/images/ic_profile_default.png';
import informationIcon1 from '../assets/images/ic_information_section1.png';
import informationIcon2 from '../assets/images/ic_information_section2.png';
import informationIcon3 from '../assets/images/ic_information_section3.png';
import informationIcon4 from '../assets/images/ic_information_section4.png';
import usaIcon from '../assets/images/nation/ic_usa.png';
import japanIcon from '../assets/images/nation/ic_japan.png';
import singaporeIcon from '../assets/images/nation/ic_singapore.png';
import austrailiaIcon from '../assets/images/nation/ic_austraila.png';
import bellIcon from '../assets/images/ic_bell.png';

import useGlobalStore from '../stores/globalStore';

import displayJobKRData from '../assets/data/display_job_kr.json';
import displayJobENData from '../assets/data/display_job_en.json';
import Button from '../components/element/Button';
import { ProductReview } from '../models/productReview';
import productService from '../services/productService';
import userService from '../services/userService';
import { format } from 'date-fns';

function Home() {
  const navigate = useNavigate();
  const toast = useToast();
  const [reviewList, setReviewList] = useState<ProductReview[]>([]);
  const [selectedTitleJob, setSelectedTitleJob] = useState<string>('');
  const [mateProfileList, setMateProfileList] = useState<Mate[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isLastPage, setLastPage] = useState<boolean>(false);

  const profileListContainerRef = useRef<HTMLDivElement | null>(null);

  const [categoryList, setCategoryList] = useState<[]>([]);
  const { isAuthenticated, setAuthenticated, user, setUser, resetStore } = useGlobalStore();
  const [alarmCount, setAlarmCount] = useState(0); // 알림 갯수 상태 추가

  //별점
  const renderRating = (rating: number) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: `${1.2}rem`,
        fontWeight: 600,
      }}
    >
      {[...Array(rating)].map((_, index) => (
        <img
          key={index}
          src={starIcon}
          alt='Ratings'
          style={{
            width: `${1.2}rem`,
            height: `${1.2}rem`,
            marginRight: '0.2rem',
          }}
        />
      ))}
      {rating}
    </div>
  );

  // 임시 Country 배열
  const items = [
    { id: 1, text: 'USA', icon: usaIcon },
    { id: 2, text: 'Japan', icon: japanIcon },
    { id: 3, text: 'Austraila', icon: singaporeIcon },
    { id: 4, text: 'Singapore', icon: austrailiaIcon },
    { id: 5, text: 'USA', icon: usaIcon },
    { id: 6, text: 'Japan', icon: japanIcon },
    { id: 7, text: 'Austraila', icon: singaporeIcon },
    { id: 8, text: 'Singapore', icon: austrailiaIcon },
  ];

  useEffect(() => {
    loadNotificationCount();
    tokenCheck();
    getMainReviews();
    setSelectedTitleJob(displayJobENData.display_job[0]);
    //직무별 메이트 목록 요청.
    loadMateProfileList(page, displayJobENData.display_job[0]);
  }, []);

  async function loadNotificationCount() {
    const count: number = await userService.getNotificationCount();
    setAlarmCount(count);
  }

  async function getMainReviews() {
    const reviews = await productService.getMainReviews();
    try {
      if (reviews) {
        setReviewList(reviews);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function loadMateProfileList(page: number, job: string) {
    const list = await MateService.getListByJob(page, 20, '', job === 'All' ? '' : job);
    if (page == 1) {
      setMateProfileList(list);
      if (profileListContainerRef.current) {
        profileListContainerRef.current.scrollLeft = 0;
      }
      setPage(1);
      setLastPage(false);
    } else {
      if (list.length > 0) {
        setMateProfileList([...mateProfileList, ...list]);
        setPage(page);
      } else {
        setLastPage(true);
      }
    }
  }

  function getProfileImageFile(mate: Mate) {
    if (mate.profile_image !== '') {
      //메이트 프로필 이미지가 존재하는 경우.
      return `${process.env.REACT_APP_STORE_ADDRESS}/${mate.profile_image}`;
    } else if (mate.user?.profile_image !== '') {
      //유저 프로필 이미지가 존재하는 경우.
      return `${process.env.REACT_APP_STORE_ADDRESS}/${mate.user?.profile_image}`;
    }

    return undefined;
  }

  function selectJob(job: string) {
    setPage(1);
    loadMateProfileList(1, job);
    setSelectedTitleJob(job);
  }

  function handleProfileListScroll() {
    if (profileListContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = profileListContainerRef.current;
      const scrollMax = scrollWidth - clientWidth;

      if (scrollLeft >= scrollMax * 0.8) {
        if (isLastPage == false) {
          loadMateProfileList(page + 1, selectedTitleJob);
        } else {
        }
      }
    }
  }

  function goMateProfilePage(id: number) {
    navigate(`/mate/detail/${id}`);
  }

  function goMateListPage() {
    navigate('/mate/listByJob');
  }

  async function toggleBookmark(mate: Mate) {
    if (mate.favorite === true) {
      await UserService.deleteMateFavorite(mate.id!);
      mate.favorite = false;
    } else {
      await UserService.addMateFavorite(mate.id!);
      mate.favorite = true;
    }
    const newMateProfileList = [...mateProfileList];
    newMateProfileList.find((item) => item.id === mate.id)!.favorite = mate.favorite;
    setMateProfileList(newMateProfileList);
  }

  async function tokenCheck() {
    //토큰 유효성 체크.
    const token = localStorage.getItem('authToken');
    if (token != undefined) {
      try {
        const userData = await AuthService.myprofile();
        if (userData && userData.user) {
          setAuthenticated(true);
          setUser(userData.user); // user를 상태로 설정
        } else {
          localStorage.setItem('authToken', '');
          resetStore();
        }
      } catch (err) {
        localStorage.setItem('authToken', '');
        resetStore();
      }
    } else {
      resetStore();
    }
  }

  const goMateProfile = (id: number) => {
    if (user?.mate === null) {
      navigate('/mate/agreement');
    } else if (user === null) {
      toast('error', 'You are not logged in.', false);
      return;
    } else {
      navigate(`/mate/detail/${id}`);
    }
  };

  const goMeetingsByCountry = (type: string, search: string) => {
    navigate('/mate/listByCountry', {
      state: { type, search },
    });
  };
  const goMeetingsByField = (category: string) => {
    navigate('/mate/listByJob', {
      state: { category },
    });
  };

  const toAlarm = () => {
    navigate('/my/notification');
  };

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={false}
        useLogoTitle={true}
        actionType={isAuthenticated == true ? 'icon' : 'signin'}
        actionTitle={isAuthenticated == true ? '' : 'Sign In / Sign Up'}
        onAction={isAuthenticated == true ? undefined : () => navigate('/auth/signIn')}
        secondaryTitle={
          isAuthenticated == true ? (
            <div className={styles['alarm']}>
              <img
                src={bellIcon}
                alt='Notification'
                style={{ margin: 0 }}
              />
              {alarmCount > 0 && <div className={styles['alarm-count']}>{alarmCount}</div>}
            </div>
          ) : undefined
        }
        onSecondaryAction={isAuthenticated == true ? toAlarm : undefined}
      />
      <div className={styles['bannerBox']}>
        <Swiper
          pagination={{
            type: 'fraction',
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className={styles['bannerSwiper']}
        >
          <SwiperSlide className={styles['banner1']}>
            Coffee Chat / Interview Practice with Mate
          </SwiperSlide>
          <SwiperSlide className={styles['banner2']}>
            Earn money through Nailedit Mate activities
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        className={styles['search-bar']}
        onClick={() => navigate('/search')}
      >
        <img
          src={searchIcon}
          alt=''
        />
        Start your job search by field / country
      </div>
      <div className={styles['category-bar']}>
        <img
          src={categoryAllIcon}
          alt=''
          onClick={() => goMateListPage()}
        />
        {displayJobENData.display_job.map((e) => (
          <div
            className={styles['category-item']}
            onClick={() => selectJob(e)}
          >
            <div className={selectedTitleJob === e ? styles['category-item-active'] : ''}>{e}</div>
            <div className={selectedTitleJob === e ? styles['category-active'] : ''} />
          </div>
        ))}
      </div>
      <div
        className={styles['profile-list-container']}
        ref={profileListContainerRef}
        onScroll={handleProfileListScroll}
      >
        {mateProfileList.map((e) => (
          <div
            className={styles['profile-list-item']}
            onClick={(ev) => {
              ev.stopPropagation();
              goMateProfilePage(e.id!);
            }}
          >
            <div className={styles['profile-item-image-group']}>
              <div className={styles['profile-item-thumbnail']}>
                <img
                  src={getProfileImageFile(e) ?? profileDefaultIcon}
                  alt=''
                />
              </div>
              <div className={styles['profile-item-bookmark']}>
                <img
                  src={e.favorite === true ? bookmarkOnIcon : bookmarkOffIcon}
                  alt=''
                  onClick={(ev) => {
                    ev.stopPropagation();
                    toggleBookmark(e);
                  }}
                />
              </div>
            </div>
            <div className={styles['profile-item-tag']}>{e.user?.annual}years</div>
            <div className={styles['profile-item-tag']}>{e.user?.job}</div>
            <div className={styles['profile-item-nickname']}>{e.user?.nickname}</div>
            <div className={styles['profile-item-company']}>{e.user?.company}</div>
          </div>
        ))}
        {mateProfileList.length >= 20 && (
          <div
            className={styles['profile-list-see-more-container']}
            onClick={() => goMateListPage()}
          >
            <div className={styles['profile-list-see-more-button']}>
              <img
                src={arrowRightIcon}
                alt=''
              />
            </div>
            See more
          </div>
        )}
      </div>
      <div className={styles['meeting-category-title-container']}>
        <div className={styles['meeting-category-title-text']}>Meetings by Field</div>
        <div
          className={styles['meeting-category-all-view']}
          onClick={() => navigate('/mate/listByJob')}
        >
          See All
        </div>
      </div>
      <div className={styles['meeting-category-item-container']}>
        {displayJobENData.display_category.map((e) => (
          <div
            className={styles['meeting-category-item']}
            onClick={() => goMeetingsByField(e === 'All' ? '' : e)}
          >
            <div className={styles['meeting-category-item-shape']} />
            <div className={styles['meeting-category-item-text']}>{e}</div>
          </div>
        ))}
      </div>
      <div className={styles['meeting-country-title-container']}>
        <div className={styles['meeting-country-title-text']}>Meetings by Country</div>
        <div
          className={styles['meeting-country-all-view']}
          onClick={() => navigate('/mate/listByCountry')}
        >
          See All
        </div>
      </div>
      <div className={styles['meeting-country-item-container']}>
        {items.map((item, index) => {
          if (index === 4 && items.length > 5) {
            return (
              <div
                key='see-all'
                className={styles['meeting-country-item']}
                onClick={() => navigate('/mate/listByCountry')}
              >
                <div className={styles['meeting-country-item-icon']}>
                  <img
                    src={categoryAllIcon}
                    alt=''
                  />
                </div>
                <div className={styles['meeting-country-item-text']}>See All</div>
              </div>
            );
          }
          if (index < 5) {
            return (
              <div
                key={item.id}
                className={styles['meeting-country-item']}
                onClick={() =>
                  goMeetingsByCountry(
                    'nation',
                    item.text === 'USA'
                      ? 'United States'
                      : item.text === 'Japan'
                      ? 'Japan (日本)'
                      : item.text
                  )
                }
              >
                <div className={styles['meeting-country-item-icon']}>
                  <img
                    src={item.icon}
                    alt=''
                  />
                </div>
                <div className={styles['meeting-country-item-text']}>{item.text}</div>
              </div>
            );
          }

          return null;
        })}
      </div>
      <div className={styles['review-title']}>Real-time Reviews</div>
      <div className={styles['review-container']}>
        {reviewList.map((review: ProductReview) => (
          <div
            key={review.id}
            className={styles['review-item']}
          >
            <div className={styles['review-item-row']}>
              <img
                src={`${
                  review.user?.profile_image !== ''
                    ? `${process.env.REACT_APP_STORE_ADDRESS}/${review.user?.profile_image}`
                    : profileDefaultIcon
                }`}
                alt=''
              />
              <div className={styles['review-item-nickname']}>{review.user?.nickname}</div>
              <div className={styles['review-item-date']}>
                {format(new Date(review.created_date), 'MMM d, yyyy')}
              </div>
            </div>
            <div>
              <div className={styles['review-item-row']}>
                <div className={styles['review-item-score']}>
                  {renderRating(review.score ? review.score : 0)}
                </div>
              </div>
              <div className={styles['review-item-company']}>
                {review.useful_answer_selected_reason}
              </div>
            </div>
            <div className={styles['mate-item']}>
              <div className={styles['mate-profile']}>
                <img
                  src={`${
                    review.mateUser?.profile_image !== ''
                      ? `${process.env.REACT_APP_STORE_ADDRESS}/${review.mateUser?.profile_image}`
                      : profileDefaultIcon
                  }`}
                  alt=''
                />
              </div>
              <div className={styles['mate-info']}>
                {`${review.mateUser?.nickname}'s`}{' '}
                <span>
                  {(review.product?.type === 'COFFEECHAT' && 'Coffee Chat') ||
                    (review.product?.type === 'REVIEW' && 'Resume /Portfolio Review') ||
                    (review.product?.type === 'INTERVIEW' && 'Interview Practice')}
                </span>
              </div>
              <div
                className={styles['mate-link']}
                onClick={() => goMateProfile(review.mate_user_id)}
              >
                {'More >'}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <div className={styles['review-item']}>
          <div className={styles['review-item-row']}>
            <img
              src={'https://maxst.icons8.com/vue-static/icon/landing/plugins/windows.svg'}
              alt=''
            />
            <div className={styles['review-item-nickname']}>JobSeekerUSA</div>
            <div className={styles['review-item-date']}>2024.12.04</div>
          </div>
          <div className={styles['review-item-row']}>
            <div className={styles['review-item-score']}>
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <div className={styles['review-item-score-text']}>4.5</div>
            </div>
          </div>
          <div className={styles['profile-item-tag']}>3 years</div>
          <div className={styles['profile-item-tag']}>DevOps</div>
          <div className={styles['profile-item-nickname']}>TRIWIZARD</div>
          <div className={styles['profile-item-company']}>Naver Corp.</div>
        </div>

        <div className={`${styles['profile-list-item']} ${styles['profile-list-item-active']}`}>
          <div className={styles['profile-item-image-group']}>
            <div className={styles['profile-item-thumbnail']}>
              <img
                src={categoryAllIcon}
                alt=''
              />
            </div>
            <div className={styles['profile-item-bookmark']}>
              <img
                src={bookmarkOffIcon}
                alt=''
              />
            </div>
          </div>
          <div className={styles['profile-item-tag']}>4 years</div>
          <div className={styles['profile-item-tag']}>Data Analyst</div>
          <div className={styles['profile-item-nickname']}>Hayato Sumino</div>
          <div className={styles['profile-item-company']}>Samsung Electronics Co., Ltd.</div>
        </div>

        <div className={styles['profile-list-item']}>
          <div className={styles['profile-item-image-group']}>
            <div className={styles['profile-item-thumbnail']}>
              <img
                src={categoryAllIcon}
                alt=''
              />
            </div>
            <div className={styles['profile-item-bookmark']}>
              <img
                src={bookmarkOffIcon}
                alt=''
              />
            </div>
          </div>
          <div className={styles['profile-item-tag']}>8 years</div>
          <div className={styles['profile-item-tag']}>PM</div>
          <div className={styles['profile-item-nickname']}>Hedwig’sWing</div>
          <div className={styles['profile-item-company']}>Sleek</div>
        </div> */}
      {/* </div> */}
      {/* <div className={styles['meeting-category-title-container']}>
        <div className={styles['meeting-category-title-text']}>Meetings by Field</div>
        <div className={styles['meeting-category-all-view']}>See All</div>
      </div>
      <div className={styles['meeting-category-item-container']}>
        <div className={styles['meeting-category-item']}>
          <div className={styles['meeting-category-item-icon']}>
            <img
              src={categoryAllIcon}
              alt=''
            />
          </div>
          <div className={styles['meeting-category-item-text']}>Finance</div>
        </div>
        <div className={styles['meeting-category-item']}>
          <div className={styles['meeting-category-item-icon']}>
            <img
              src={categoryAllIcon}
              alt=''
            />
          </div>
          <div className={styles['meeting-category-item-text']}>Medical/Nursing</div>
        </div>
        <div className={styles['meeting-category-item']}>
          <div className={styles['meeting-category-item-icon']}>
            <img
              src={categoryAllIcon}
              alt=''
            />
          </div>
          <div className={styles['meeting-category-item-text']}>Design</div>
        </div>
      </div>
      <div className={styles['meeting-category-title-container']}>
        <div className={styles['meeting-category-title-text']}>Meetings by Country</div>
        <div className={styles['meeting-category-all-view']}>See All</div>
      </div>
      <div className={styles['meeting-category-item-container']}>
        <div className={styles['meeting-category-item']}>
          <div className={styles['meeting-category-item-icon']}>
            <img
              src={categoryAllIcon}
              alt=''
            />
          </div>
          <div className={styles['meeting-category-item-text']}>USA</div>
        </div>
        <div className={styles['meeting-category-item']}>
          <div className={styles['meeting-category-item-icon']}>
            <img
              src={categoryAllIcon}
              alt=''
            />
          </div>
          <div className={styles['meeting-category-item-text']}>Japan</div>
        </div>
      </div>
      <div className={styles['review-title']}>Real-time Reviews</div>
      <div className={styles['review-container']}>
        <div className={styles['review-item']}>
          <div className={styles['review-item-row']}>
            <img
              src={'https://maxst.icons8.com/vue-static/icon/landing/plugins/windows.svg'}
              alt=''
            />
            <div className={styles['review-item-nickname']}>JobSeekerUSA</div>
            <div className={styles['review-item-date']}>2024.12.04</div>
          </div>
          <div className={styles['review-item-row']}>
            <div className={styles['review-item-score']}>
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <div className={styles['review-item-score-text']}>4.5</div>
            </div>
          </div>
          <div className={styles['review-item-description']}>
            {
              'Through a coffee chat with a data\r\nprofessional working abroad, I was able\r\nto learn about global industry trends and\r\nthe current industry landscape.'
            }
          </div>
          <div className={styles['review-item-view-box']}>
            <img
              src={categoryAllIcon}
              alt=''
            />
            <div className={styles['review-item-view-nickname']}>Hayato Sumino's </div>
            <div className={styles['review-item-view-type']}>Coffee Chat</div>
            <div className={styles['review-item-view-detail-text']}>View details</div>
            <div className={styles['review-item-view-arrow-right']}>
              <img
                src={arrowRightIcon}
                alt=''
              />
            </div>
          </div>
        </div>

        <div className={styles['review-item']}>
          <div className={styles['review-item-row']}>
            <img
              src={'https://maxst.icons8.com/vue-static/icon/landing/plugins/windows.svg'}
              alt=''
            />
            <div className={styles['review-item-nickname']}>JobSeekerUSA</div>
            <div className={styles['review-item-date']}>2024.12.04</div>
          </div>
          <div className={styles['review-item-row']}>
            <div className={styles['review-item-score']}>
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <img
                src={categoryAllIcon}
                alt=''
              />
              <div className={styles['review-item-score-text']}>4.5</div>
            </div>
          </div>
          <div className={styles['review-item-description']}>
            {
              'Through a coffee chat with her, I gained\r\ninsights into tech trends and real-world\r\npractices, helping me prepare for the\r\nnext step.'
            }
          </div>
          <div className={styles['review-item-view-box']}>
            <img
              src={categoryAllIcon}
              alt=''
            />
            <div className={styles['review-item-view-nickname']}>Hayato Sumino's </div>
            <div className={styles['review-item-view-type']}>Coffee Chat</div>
            <div className={styles['review-item-view-detail-text']}>View details</div>
            <div className={styles['review-item-view-arrow-right']}>
              <img
                src={arrowRightIcon}
                alt=''
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className={styles['information-container']}>
        <div className={styles['information-image-container']}>
          <img
            src={informationIcon1}
            alt=''
          />
        </div>
        <div className={styles['information-section']}>
          <div className={styles['information-title']}>
            {`Find, experience, choose\r\nthe country that's right for you`}
          </div>
          <div className={styles['information-text']}>
            {
              'Nailedit connects you with professionals\r\nworking abroad to help with your international\r\njob search.'
            }
            <br />
            <br />
            {'Have a coffee chat with mentors in your dream\r\nindustry and country.'}
          </div>
        </div>
        <div className={styles['information-section']}>
          <div className={styles['information-image2']}>
            <img
              src={informationIcon2}
              alt=''
            />
          </div>
          <div className={styles['information-text']}>
            {'Get ready for your interview\r\nwith a mock or portfolio/resume review.'}
          </div>
        </div>
        <div className={styles['information-section']}>
          <div className={styles['information-image3']}>
            <img
              src={informationIcon3}
              alt=''
            />
          </div>

          <div className={styles['information-title']}>
            {`Be a Mate,\r\nShare your experience,\r\nand connect with others.`}
          </div>
          <div className={styles['information-text']}>
            {'Share your story through 1:1 video chats.'}
            <br />
            <br />
            {
              'Create a profile that highlights your experience\r\nand set up consultations, Interview Practices, or\r\nresume reviews.'
            }
            <br />
            <br />
            {'You can freely set your rates and available time\r\nslots.'}
          </div>
        </div>
        <div className={styles['information-section']}>
          <div className={styles['information-image4']}>
            <img
              src={informationIcon4}
              alt=''
            />
          </div>
          <div className={styles['information-title']}>
            {`Wherever you are,\r\nwhatever you do,\r\nif you want it,\r\nyou can eventually Nailed it.`}
          </div>
        </div>
        <div className={styles['button-container']}>
          <Button
            marginHorizontalRem={2}
            marginBottom={0.8}
            active={true}
            onClick={() => navigate('/mate/listByJob')}
          >
            Explore Meetings
          </Button>
          <Button
            marginHorizontalRem={2}
            active={true}
            onClick={() => goMateProfile(user?.mate?.id!)}
          >
            Apply to be a Mate
          </Button>
        </div>
      </div>
      <div className={styles['footer-information-container']}>
        <div className={styles['footer-information-title']}>NAILEDITAPP LIMITED</div>
        <div className={styles['footer-information-menu']}>
          General Terms of use&nbsp;&nbsp;
          <img
            src={footerDividerIcon}
            alt=''
          />
          &nbsp;&nbsp;Privacy Policy
        </div>
        <div className={styles['footer-information-menu']}>
          Operational Policy&nbsp;&nbsp;
          <img
            src={footerDividerIcon}
            alt=''
          />
          &nbsp;&nbsp;Customer Support&nbsp;&nbsp;
          <img
            src={footerDividerIcon}
            alt=''
          />
          &nbsp;&nbsp;Site
        </div>
        <div className={styles['footer-information-site-icons-row-1']}>
          <img
            src={socialTwitterIcon}
            alt=''
          />
          <img
            src={socialInstagramIcon}
            alt=''
          />
          <img
            src={socialYoutubeIcon}
            alt=''
          />
          <img
            src={socialTictokIcon}
            alt=''
          />
        </div>
        <div className={styles['footer-information-site-icons-row-2']}>
          <img
            src={socialWhatsappIcon}
            alt=''
          />
          <img
            src={socialWechatIcon}
            alt=''
          />
          <img
            src={socialKakaotalkIcon}
            alt=''
          />
        </div>
        <div className={styles['footer-information-address']}>
          UNIT 2A, 17/F, GLENEALY TOWER, NO.1
          <br />
          GLENEALY, CENTRAL, HONG KONG S.A.R
          <br />
          CONTACT : nailedit_official@naileditapp.com
        </div>
        <div className={styles['footer-information-copyright']}>
          Nailedit is an intermediary, not a party
          <br />
          to transactions. Sellers are responsible
          <br />
          for products, information, and obligations
          <br />
          <br />
          (c) 2024 Nailedit. All right reserved.
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
