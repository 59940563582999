import { UserWithdrawalMethod } from '../models/userWithdrawalMethod';
import { post, get, destroy } from '../utils/apiClient';

class BillingService {
  public async createPaymentIntent(amount: number, productName: string, productId: number) {
    try {
      const response = await post('/billing/createPaymentIntent',
        {
          productName,
          productId,
          amount,
        }
      );

      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
}

const billingService = new BillingService();
export default billingService;
