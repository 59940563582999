import React, { useEffect, useState } from "react";
import styles from './Toast.module.css';
import { ToastType } from '@/models/toastItem';

import successIcon from '../../assets/images/ic_success.png';
import errorIcon from '../../assets/images/ic_error.png';

interface ToastProps {
    type: ToastType;
    message: string;
    seconds?: number;
    bottom?: boolean;
    onClose(): void;
  }
  
  const Toast: React.FC<ToastProps> = ({ 
    type,
    message, 
    seconds = 2, 
    bottom = true,
    onClose
  }) => {
    const [visible, setVisible] = useState<boolean>(true);

    useEffect(() => {
        let timer = setTimeout(() => {
          setVisible(false);
        }, seconds * 1000);

        let removeTimer = setTimeout(() => {
          onClose();
        }, seconds * 1000 + 300); //300ms = animation time

        return () => { 
          clearTimeout(timer);
          clearTimeout(removeTimer);
        }
    }, []);

    return (
      <div className={`${styles.toast} ${visible == true ? styles.openAnimation : styles.closeAnimation}`} style={bottom == false ? {bottom: 0, top: '4.0rem'} : undefined}>
        {type != 'none' && <img src={type == 'success' ? successIcon : errorIcon} alt=""/>}
        {message}
      </div>
    );
  };
  
  export default Toast;