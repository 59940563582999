// src/components/CommunityNotice.tsx

import React from 'react';
import styles from './CommunityNotice.module.css'; // Importing the CSS module
import { ReactComponent as MegaphoneIcon } from '../../../assets/images/ic_notice.svg'; // Importing the SVG

interface CommunityNoticeProps {
  text: string; // text를 prop으로 받도록 인터페이스 정의
}

function CommunityNotice({ text }: CommunityNoticeProps) {
  return (
    <div className={styles.centeredContainer}>
      <div className={styles.noticeContainer}>
        <div className={styles.iconContainer}>
          {/* Using the imported SVG as a React component */}
          <MegaphoneIcon className={styles.icon} />
        </div>
        <span className={styles.text}>{text}</span> {/* 받은 text를 표시 */}
      </div>
    </div>
  );
}

export default CommunityNotice;
