import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import { Sheet } from 'react-modal-sheet';
import styles from './Calculation.module.css';
import closeIcon from '../../assets/images/ic_close.png';
import Button from '../../components/element/Button';
import PaymentDetailCard from './components/PaymentDetailCard';
import downArrow from '../../assets/images/ic_arrow_down.png';
import check from '../../assets/images/ic_radio_selected.png';
import unCheck from '../../assets/images/ic_radio_unselected.png';
import infoIcon from '../../assets/images/ic_info.png';
import useToast from '../../hook/useToast';
import PaymentWithdrawCard from './components/PaymentWithdrawCard';
import { UserIncome } from '../../models/userIncome';
import settlementService from '../../services/settlementService';
import { UserWithdrawal } from '../../models/userWithdrawal';
import { format, getTime, isToday, toDate } from 'date-fns';
import { UserWithdrawalMethod } from '../../models/userWithdrawalMethod';

function Calculation() {
  const toast = useToast();
  const [openDate, setOpenDate] = useState(false);
  const [openWithdrawDate, setOpenWithdrawDate] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuInfo, setOpenMenuInfo] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const loaderRef = useRef<HTMLDivElement | null>(null); // 로딩 요소 참조

  const navigate = useNavigate();
  const [amount, setAmount] = useState<number>(0); // 초기 값을 0으로 설정
  const [page, setPage] = useState<number>(1);
  const [count, setCoumt] = useState<number>(10);
  const [selectedTabName, setSelectedTabName] = useState<string>('Earnings');
  const [isLoading, setIsLoading] = useState(false);
  const [incomeData, setIncomeData] = useState<UserIncome[]>([]);
  const [withdrawData, setWithdrawData] = useState<UserWithdrawal[]>([]);
  const [incomeDateFilters, setIncomeDateFilters] = useState<string[]>([]);
  const [withdrawDateFilters, setWithdrawDateFilters] = useState<string[]>([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>('All');
  const [newDataLength, setNewDataLength] = useState(0); // 새로 추가된 데이터의 길이 상태
  const [newWithdrawLength, setNewWithdrawLength] = useState(0); // 새로 추가된 데이터의 길이 상태
  const [openStatusFilter, setOpenStatusFilter] = useState(false);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<string>('All');
  const [methodList, setMethodList] = useState<UserWithdrawalMethod[]>([]);

  // Filter를 위한 배열
  // const [filteredIncomeData, setFilteredIncomeData] = useState<UserIncome[]>([]);
  // const [filteredWithdrawData, setFilteredWithdrawData] = useState<UserWithdrawal[]>([]);
  useEffect(() => {
    getWithdrawalMethodData();
    getAvailableAmount();
  }, []);

  console.log(amount);

  console.log(methodList[0]);
  useEffect(() => {
    if (incomeData.length < 5) return; // 데이터가 적을 때는 관찰 설정을 생략
    if (!loaderRef.current) return; // 로딩 참조가 없을 경우 생략

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && page && newDataLength >= 10) {
            handlePage(page + 1); // 새로운 페이지 로드
          }
        });
      },
      { root: null, threshold: 0.8 }
    );
    const timer = setTimeout(() => {
      if (loaderRef.current) {
        observer.observe(loaderRef.current); // 로딩 요소를 관찰
      }
    }, 1000); // 1초 지연

    const target = loaderRef.current;
    observer.observe(target);

    return () => {
      clearTimeout(timer); // 타이머가 설정된 경우 클리어
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current); // observer 해제
      }
    };
  }, [page, newDataLength]);

  useEffect(() => {
    setSelectedDateFilter('All'); // 날짜 필터를 초기화
    getIncomeList(page);
    getWithdrawList(page);
    setIncomeDateFilters(generateDateFilters());
    setWithdrawDateFilters(generateDateFilters());
    setPage(1);
  }, [selectedTabName]);

  const getAvailableAmount = async () => {
    try {
      const amount = await settlementService.availableAmount();
      console.log('API에서 반환된 amount:', amount); // 반환된 값 확인
      if (amount) {
        setAmount(amount); // 상태 업데이트
      }
    } catch (err) {
      console.error('API Error:', err);
    }
  };

  const getWithdrawalMethodData = async () => {
    try {
      const withdrawData = await settlementService.withdrawalMethodList();
      if (withdrawData) {
        setMethodList(withdrawData);
      } else {
        console.log('error');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePage = (newPage: number) => {
    if (newPage > 0 && newPage !== page) {
      // newPage가 유효한지 확인
      getIncomeList(page + 1);
      getWithdrawList(page + 1);
      setPage(newPage);
    }
  };

  const handleDateFilterChange = (selected: string) => {
    setSelectedDateFilter(selected); // Filter 기준 상태만 업데이트
  };
  const handleStatusFilterChange = (status: string) => {
    setSelectedStatusFilter(status); // 상태 Filter를 업데이트
  };

  // Filter링 로직을 컴포넌트에서 즉시 적용
  const filteredIncomeData = (
    selectedDateFilter === 'All'
      ? incomeData
      : incomeData.filter((data) => {
          const serviceDate = new Date(data.service_date);
          const [year, month] = selectedDateFilter.split('/');
          return (
            serviceDate.getFullYear() === parseInt(year, 10) &&
            serviceDate.getMonth() + 1 === parseInt(month, 10)
          );
        })
  ).sort((a, b) => new Date(b.service_date).getTime() - new Date(a.service_date).getTime());

  const filteredWithdrawData = withdrawData
    .filter((data) => {
      // 날짜 Filter링
      const isDateMatching =
        selectedDateFilter === 'All' ||
        (() => {
          const requestDate = new Date(data.request_date);
          const [year, month] = selectedDateFilter.split('/');
          return (
            requestDate.getFullYear() === parseInt(year, 10) &&
            requestDate.getMonth() + 1 === parseInt(month, 10)
          );
        })();

      // 상태 Filter링
      const isStatusMatching =
        selectedStatusFilter === 'All' || data.status === selectedStatusFilter;

      // 두 조건을 모두 만족하는 경우
      return isDateMatching && isStatusMatching;
    })
    .sort((a, b) => new Date(b.request_date).getTime() - new Date(a.request_date).getTime());

  //데이터 날짜 기준 Filter 배열 생성
  const generateDateFilters = () => {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1; // 월은 0부터 시작하므로 1 더함
    const filters = [];

    for (let i = 0; i < 12; i++) {
      const filterMonth = month - i > 0 ? month - i : 12 + (month - i);
      const filterYear = month - i > 0 ? year : year - 1;
      filters.push(`${filterYear}/${String(filterMonth).padStart(2, '0')}`);
    }

    return ['All', ...filters]; // "전체" 추가
  };

  async function getIncomeList(page: number) {
    try {
      let startDate: string;
      let endDate: string;

      if (selectedDateFilter === 'All') {
        const currentYear = new Date().getFullYear(); // 현재 년도를 가져옵니다.
        startDate = new Date(`${currentYear}-01-01`).toISOString(); // 올해 1월 1일
        endDate = new Date(`${currentYear}-12-31`).toISOString(); // 올해 12월 31일
      } else {
        const [year, month] = selectedDateFilter.split('/');
        const start = new Date(parseInt(year), parseInt(month) - 1, 1);
        const end = new Date(parseInt(year), parseInt(month), 0);
        startDate = start.toISOString();
        endDate = end.toISOString();
      }

      const incomeListData = await settlementService.incomeList(page, count, startDate, endDate);

      if (incomeListData && incomeListData.length > 0) {
        if (page === 1) {
          // 페이지가 1일 경우 데이터를 덮어씀
          setIncomeData(incomeListData);
        } else {
          // 새 데이터를 병합
          setIncomeData((prevData) => [
            ...prevData,
            ...incomeListData.filter(
              (newItem: UserIncome) => !prevData.some((prevItem) => prevItem.id === newItem.id)
            ),
          ]);
        }
        setNewDataLength(incomeListData.length); // 새 데이터 길이 업데이트
      }
    } catch (err) {
      console.error(err);
      toast('error', 'Something went wrong');
    }
  }
  async function getWithdrawList(page: number) {
    try {
      let startDate: string;
      let endDate: string;

      if (selectedDateFilter === 'All') {
        const currentYear = new Date().getFullYear(); // 현재 년도를 가져옵니다.
        startDate = new Date(`${currentYear}-01-01`).toISOString(); // 올해 1월 1일
        endDate = new Date(`${currentYear}-12-31`).toISOString(); // 올해 12월 31일
      } else {
        const [year, month] = selectedDateFilter.split('/');
        const start = new Date(parseInt(year), parseInt(month) - 1, 1);
        const end = new Date(parseInt(year), parseInt(month), 0);
        startDate = start.toISOString();
        endDate = end.toISOString();
      }

      const withdrawList = await settlementService.withdrawalList(page, count, startDate, endDate);

      if (withdrawList && withdrawList.length > 0) {
        if (page === 1) {
          // 페이지가 1일 경우 데이터를 덮어씀
          setWithdrawData(withdrawList);
        } else {
          // 새 데이터를 병합
          setWithdrawData((prevData) => [
            ...prevData,
            ...withdrawList.filter(
              (newItem: UserWithdrawal) => !prevData.some((prevItem) => prevItem.id === newItem.id)
            ),
          ]);
        }
        setNewWithdrawLength(withdrawList.length); // 새 데이터 길이 업데이트
      }
    } catch (err) {
      console.error(err);
      toast('error', 'Something went wrong');
    }
  }

  const openDateSheet = () => {
    setOpenDate(!openDate);
  };
  const openWithdrawDateSheet = () => {
    setOpenWithdrawDate(!openWithdrawDate);
  };
  const openHistorySheet = () => {
    setOpenHistory(!openHistory);
  };
  const openMenuSheet = () => {
    setOpenMenu(!openMenu);
  };
  const openMenuInfoSheet = () => {
    setOpenMenuInfo(!openMenuInfo);
  };

  const toOption = () => {
    navigate('/calculation/option');
  };

  return (
    <div className={styles['container']}>
      <Header
        onBack={() => navigate(-1)}
        useMenuButton={true}
        useBgColor={true}
        onMenuAction={openMenuSheet}
      />
      <div className={styles['main-container']}>
        <div className={styles['main-item']}>
          <div className={styles['main-text']}>{'Withdrawable Earnings'}</div>
          <div className={styles['main-title']}>{`$${amount}`}</div>
          <div className={styles['main-date']}> As of {format(new Date(), 'EEEE MM, yyyy')}</div>
        </div>
        <Button
  width='unset'
  marginHorizontalRem={0}
  active={true}
  onClick={() => {
    if (methodList.length === 0) {
      toast('error', 'Method list is not available.', false);
      return
    }else if(amount === 0){
      toast('error', `There is insufficient balance.`, false);
      return

    } else {
      navigate('/calculation/request/withdraw', {
        state: { method: methodList[0], amount },
      });
    }
  }}
>
  Withdraw Earnings
</Button>

        <div className={styles['main-info']}>
          <img
            src={infoIcon}
            alt=''
          />{' '}
          {
            'Local Transfer/Paypal allow immediate withdrawal,\r\nwhile SWIFT requires a minimum of 30 USD. '
          }
        </div>
      </div>

      <div className={styles['tab-container']}>
        <div
          className={`${styles['tab-item']} ${
            selectedTabName === 'Earnings' ? styles['tab-item-selected'] : ''
          }`}
          onClick={() => setSelectedTabName('Earnings')}
        >
          Earnings
          <div
            className={`${styles['tab-line']} ${
              selectedTabName === 'Earnings' ? styles['tab-line-selected'] : ''
            }`}
          />
        </div>
        <div
          className={`${styles['tab-item']} ${
            selectedTabName === 'Withdrawal' ? styles['tab-item-selected'] : ''
          }`}
          onClick={() => setSelectedTabName('Withdrawal')}
        >
          Withdrawal
          <div
            className={`${styles['tab-line']} ${
              selectedTabName === 'Withdrawal' ? styles['tab-line-selected'] : ''
            }`}
          />
        </div>
      </div>
      <div className={styles['item-container']}>
        {selectedTabName === 'Earnings' && (
          <>
            <div
              className={styles['item-filter']}
              onClick={openDateSheet}
            >
              {selectedDateFilter === 'All' ? 'All Dates' : selectedDateFilter}{' '}
              <img
                src={downArrow}
                alt=''
              />
            </div>
            {isLoading ? (
              <p>Just a moment</p>
            ) : (
              <div>
                {filteredIncomeData.map((income, index) => (
                  <PaymentDetailCard
                    key={index}
                    nickname={income.buyer_user_nickname}
                    date={income.service_date}
                    type={income.product.type}
                    price={income.price}
                    status={income.status}
                    onClick={() =>
                      navigate(`/calculation/detail`, {
                        state: { incomeId: income.id },
                      })
                    }
                  />
                ))}
              </div>
            )}
          </>
        )}
        <div
          style={{ height: '0.1rem' }}
          ref={loaderRef}
        ></div>
      </div>
      <div className={styles['item-container']}>
        {selectedTabName === 'Withdrawal' && (
          <>
            <div className={styles['item-flex']}>
              <div
                className={styles['item-filter']}
                onClick={openWithdrawDateSheet}
              >
                {selectedDateFilter === 'All' ? 'All Dates' : selectedDateFilter}{' '}
                <img
                  src={downArrow}
                  alt=''
                />
              </div>
              <div
                className={styles['item-filter']}
                onClick={() => setOpenStatusFilter(true)} // 상태 Filter Sheet 열기
              >
                {selectedStatusFilter === 'All' ? 'All' : selectedStatusFilter}{' '}
                <img
                  src={downArrow}
                  alt=''
                />
              </div>
            </div>
            <div className={styles['issue-box']}>
              <div className={styles['issue-item']}>
                <img
                  src={infoIcon}
                  alt=''
                />
                <span>
                  {`Having issues ? >`}{' '}
                  <strong onClick={() => navigate('/cs/customerService')}>Contact Support</strong>
                </span>
              </div>
            </div>

            {isLoading ? (
              <p>Just a moment</p>
            ) : (
              <div>
                {filteredWithdrawData.map((withdraw, index) => (
                  <PaymentWithdrawCard
                    key={index}
                    nickname={withdraw.name || ''}
                    date={withdraw.request_date}
                    type={withdraw.status}
                    price={withdraw.amount || 0}
                    // fail={detail.fail}
                    onClick={() =>
                      navigate(`/calculation/withdraw/waiting`, {
                        state: { withdrawId: withdraw.id },
                      })
                    }
                  />
                ))}
              </div>
            )}
          </>
        )}
        <div
          style={{ height: '1rem' }}
          ref={loaderRef}
        ></div>
      </div>
      <Sheet
        isOpen={openStatusFilter}
        detent='content-height'
        onClose={() => setOpenStatusFilter(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '35.5rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Filter : Status</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenStatusFilter(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              {['All', 'APPROVING', 'COMPLETE', 'DENIED'].map((status) => (
                <div
                  key={status}
                  className={styles['date-select']}
                  onClick={() => handleStatusFilterChange(status)}
                >
                  <img
                    src={selectedStatusFilter === status ? check : unCheck}
                    alt=''
                  />
                  {status}
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenStatusFilter(false)} />
      </Sheet>
      <Sheet
        isOpen={openWithdrawDate}
        detent='content-height'
        onClose={() => setOpenWithdrawDate(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '35.5rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Filter : Date</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenWithdrawDate(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              {withdrawDateFilters.map((date) => (
                <div
                  key={date}
                  className={styles['date-select']}
                  onClick={() => handleDateFilterChange(date)}
                >
                  <img
                    src={selectedDateFilter === date ? check : unCheck}
                    alt=''
                  />
                  {date}
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenWithdrawDate(false)} />
      </Sheet>
      <Sheet
        isOpen={openDate}
        detent='content-height'
        onClose={() => setOpenDate(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '35.5rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Filter : Date</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenDate(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              {incomeDateFilters.map((date) => (
                <div
                  key={date}
                  className={styles['date-select']}
                  onClick={() => handleDateFilterChange(date)}
                >
                  <img
                    src={selectedDateFilter === date ? check : unCheck}
                    alt=''
                  />
                  {date}
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenDate(false)} />
      </Sheet>
      <Sheet
        isOpen={openMenu}
        detent='content-height'
        onClose={() => setOpenMenu(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '17.3rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Manage my payout</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenMenu(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <div
                className={styles['date-select']}
                onClick={toOption}
              >
                {'Currently Registered Methods'}
              </div>
              <div
                className={styles['date-select']}
                onClick={openMenuInfoSheet}
              >
                {'Payout Eligibility Status'}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenMenu(false)} />
      </Sheet>
      <Sheet
        isOpen={openMenuInfo}
        detent='content-height'
        onClose={() => setOpenMenuInfo(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '29.1rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Payout Eligibility Status</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenMenuInfo(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <div className={styles['info-container']}>
                <div className={styles['info-box']}>{'Confirm'}</div>
                <div className={styles['info-text']}>{'Purchase Confirmed by Buyer'}</div>
              </div>
              <div className={styles['info-container']}>
                <div className={styles['info-box']}>{'Pending'}</div>
                <div className={styles['info-text']}>{'Payment Purchase by Buyer'}</div>
              </div>
              <div className={styles['info-container']}>
                <div className={styles['info-box']}>{'Cancelled'}</div>
                <div className={styles['info-text']}>{'Payment Cancelled by Buyer'}</div>
              </div>
              <div className={styles['info-container']}>
                <div className={styles['info-box']}>{'Completed'}</div>
                <div className={styles['info-text']}>{'Payment Completed by Buyer'}</div>
              </div>
              <div className={styles['info-container']}>
                <div className={styles['info-box']}>{'Refunded'}</div>
                <div className={styles['info-text']}>{'Refund Due to Other Reasons'}</div>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenMenuInfo(false)} />
      </Sheet>
    </div>
  );
}

export default Calculation;
