import React, { useState } from 'react';
import Header from '../../components/layout/Header';
import styles from './MeetingSuggested.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import useToast from '../../hook/useToast';
import MeetingCoupon from './components/MeetingCoupon';
import ReviewSelect from './components/ReviewSelect';
import profileIcon from '../../assets/images/ic_profile_default.png';
import Box from '../../components/element/Box';
import Button from '../../components/element/Button';
import meetingService from '../../services/meetingService';

function MeetingSuggested() {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<string>('coffeeChat');
  const suggestData = location.state;

  const acceptMeeting = async () => {
    if (selectedTime === null) {
      toast('error', 'Please select a time.', false);
      return;
    }

    try {
      const meetingId: number = suggestData.id;
      const meetingDate: Date | string | null = selectedTime;
      const response = await meetingService.acceptMeeting(meetingId, meetingDate);
      if (response === 'success') {
        suggestData.meeting_date = meetingDate;
        navigate('/meeting/confirm/complete', { state: suggestData });
      } else {
        console.log('Failed');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectTime = (text: string) => {
    setSelectedTime(`${text}`);
  };
  console.log(selectedTime);
  // const handleSelectTime = (text: string, time: string) => {
  //   setSelectedTime(`${text}${time}`);
  // };

  const handleAccept = () => {
    if (selectedTime) {
      // 선택된 시간과 타입을 state로 전달하며 navigate
      navigate('/meeting/confirm/complete', {
        state: {
          time: selectedTime,
          type: selectedType,
        },
      });
    } else {
      alert('시간을 선택해주세요.');
    }
  };

  const denied = () => {
    navigate('/meeting/denied', { state: suggestData });
  };
  console.log(suggestData);

  return (
    <div className={styles['suggested-container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['suggested-title']}>{'Accept / Decline Meeting'}</div>
      <div className={styles['suggested-coupon-container']}>
        <div className={styles['suggested-sub-title']}>{"Awaiting mate's response"}</div>
        <MeetingCoupon
          nickname={suggestData.user.nickname}
          type={suggestData.product.type}
          minute={suggestData.product.time}
          price={suggestData.product.price}
        />
      </div>
      <div className={styles['suggested-select-container']}>
        <div className={styles['suggested-sub-title']}>{'Choose a time slot'}</div>
        <div>
          <ReviewSelect
            text=''
            isoTime={suggestData.meeting_request_info.schedule1}
            active={selectedTime === suggestData.meeting_request_info.schedule1}
            onSelect={() => handleSelectTime(suggestData.meeting_request_info.schedule1)}
          />
          <ReviewSelect
            text=''
            isoTime={suggestData.meeting_request_info.schedule2}
            active={selectedTime === suggestData.meeting_request_info.schedule2}
            onSelect={() => handleSelectTime(suggestData.meeting_request_info.schedule2)}
          />
          <ReviewSelect
            text=''
            isoTime={suggestData.meeting_request_info.schedule3}
            active={selectedTime === suggestData.meeting_request_info.schedule3}
            onSelect={() => handleSelectTime(suggestData.meeting_request_info.schedule3)}
          />
        </div>
      </div>
      <div className={styles['suggested-text-container']}>
        <div className={styles['suggested-sub-title']}>{"Requester's message:"}</div>
        <div className={styles['suggested-text-item']}>
          {suggestData.meeting_request_info.user_written_subject}

          {suggestData.meeting_request_info.selected_subjects
            ? suggestData.meeting_request_info.selected_subjects.map((idx: number) => (
                <div key={idx}>
                  <br />
                  {`-${idx}`}
                  <br />
                </div>
              ))
            : ''}
        </div>
      </div>
      <div className={styles['suggested-sub-title']}>{suggestData.user.nickname}'s Information</div>
      <div className={styles['suggested-info-container']}>
        <div className={styles['suggested-info-item']}>
          <img
            src={
              suggestData.user && suggestData.user.mate && suggestData.user.mate.profile_image
                ? `${process.env.REACT_APP_STORE_ADDRESS}/${suggestData.user.mate.profile_image}`
                : suggestData.user && suggestData.user.profile_image
                ? `${process.env.REACT_APP_STORE_ADDRESS}/${suggestData.user.profile_image}`
                : profileIcon
            }
            alt='Profile Icon'
          />
          <div className={styles['suggested-info-text']}>
            {suggestData.user.nickname} is based in <span>{suggestData.user.country_name}</span> and
            <br />
            works in <span>{suggestData.user.job}</span>.<br /> They speak{' '}
            {suggestData.user.language.join(', ')}
          </div>
          <div className={styles['suggested-info-line']}>{/* 구분선 */}</div>
          <div className={styles['suggested-info-text']}>
            {suggestData.user.nickname}'s Introduction
          </div>
          <span className={styles['suggested-info-sub-text']}>
            {suggestData.user.introduce_message}
          </span>
          <div
            className={styles['suggested-info-link']}
            onClick={() => {}}
          >
            {'View Profile'}
          </div>
        </div>
      </div>
      <div className={styles['suggested-sub-title']}>
        {"Requester's attachment"}
        <div className={styles['suggested-sub-text']}>{'Available after meeting confirmation'}</div>
      </div>
      {suggestData.product.type !== 'COFFEECHAT' &&
        (suggestData.meeting_request_info.file_url !== '' ||
          suggestData.meeting_request_info.link_url !== '') && (
          <div className={styles['suggested-file-container']}>
            {suggestData.meeting_request_info.file_url !== '' && (
              <Box text={suggestData.meeting_request_info.file_url} />
            )}
            {suggestData.meeting_request_info.link_url !== '' && (
              <Box text={suggestData.meeting_request_info.link_url} />
            )}
          </div>
        )}
      <div className={styles['button-container']}>
        <Button
          width='45%'
          height='4.8rem'
          active={true}
          bgColor='var(--color-white200)'
          activeTextColor='var(--color-black900)'
          activeFontSize='1.5rem'
          marginTop={-0.9}
          radius={10}
          onClick={denied}
        >
          Decline
        </Button>
        <Button
          width='45%'
          height='4.8rem'
          active={true}
          activeFontSize='1.5rem'
          marginTop={-0.9}
          radius={10}
          onClick={() => acceptMeeting()}
        >
          Accept
        </Button>
      </div>
    </div>
  );
}

export default MeetingSuggested;
