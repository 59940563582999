import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ProductSuccess.module.css';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TextField from '../../components/element/TextField';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';

function ProductSuccess() {
  const navigate = useNavigate();
  const toast = useToast();
  const [price, setPrice] = useState<number>(0);

  function done() {
    
    toast('success', 'Sign In!', false);
  }
  
  function getTime() {
    const date = new Date();
    const hour = date.getHours();
    const minute = date.getMinutes();

    let aa;
    let resultHour = 0;

    if(hour > 12) {
        resultHour = hour - 12;
    }

    if(hour >= 12) {
        aa = 'PM';
    }else{
        aa = 'AM';
    }

    return `${aa} ${resultHour}:${minute}`;
  }

  function handleChange(event: any) {
    setPrice(event.target.value);
  }

  return (
    <div className={styles['conatiner']}>
      <Header 
        useBackButton={true}
      />
      <div className={styles['title-text']}>
        {'The item is now live'}
      </div>
      <div className={styles['title-sub-text']}>
        {'Now you can start offering this meeting item'}
      </div>
      <div className={styles['button-container']}>
        <Button 
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem' 
          activeFontWeight={600}
          active={true}
          onClick={() => alert('sss')}>Go to Check Item</Button>
      </div>
    </div>
    );
  }
  
  export default ProductSuccess;
  