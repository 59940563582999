import useToastStore from '../stores/toastStore'
import { ToastItem, ToastType } from '../models/toastItem';

import { v4 as uuidv4 } from 'uuid'

export default function useToast() {
  const { addToastList } = useToastStore()

  const toast = (type: ToastType, message: string, bottom?: boolean) => {
    const id = uuidv4() // 각각의 고유한 id를 사용
    addToastList({ id, type, message, bottom }) // toastList에 추가
  }

  return toast;
}