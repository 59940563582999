import React, { useState } from 'react';
import CountryDialog from './components/CountryDialog';
import TopicDialog from './components/TopicDialog';
import styles from './page.module.css';
import Tabs from './components/Tabs';
import { MdChevronRight } from 'react-icons/md';
import TextEditor from './components/TextEditor';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/layout/Header';
import useGlobalStore from '../../../stores/globalStore';

const Page: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useGlobalStore();

  const [openCountry, setOpenCountry] = useState(false);
  const [openTopic, setOpenTopic] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('Select a country');
  const [selectedTopic, setSelectedTopic] = useState('Select a topic');

  const handleOpenCountry = () => setOpenCountry(true);
  const handleCloseCountry = () => setOpenCountry(false);
  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCountry(event.target.value);
    handleCloseCountry(); // Close the dialog after the selection
  };

  const handleOpenTopic = () => setOpenTopic(true);
  const handleCloseTopic = () => setOpenTopic(false);
  const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTopic(event.target.value);
    handleCloseTopic(); // Close the dialog after the selection
  };

  return (
  <>
    <div className={styles.pageContainer}>
      {/* Fixed header */}
      <Header 
        useBackButton={false} 
        useLogoTitle={true} 
        useLanguageButton={true} 
        actionTitle={'로그인/가입'} 
        actionType={isAuthenticated ? 'none' : 'signin'} 
        onAction={() => navigate('/auth/signIn')}
      />
      <div className={styles.contentContainer}>
        {/* Country Selection Tabs with dynamic country text */}
        <Tabs
          icon={<MdChevronRight />}
          title="Select a country"
          text={selectedCountry}
          onClick={handleOpenCountry}
        />
        
        {/* Topic Selection Tabs with dynamic topic text */}
        <Tabs
          icon={<MdChevronRight />}
          title="Select a topic"
          text={selectedTopic}
          onClick={handleOpenTopic}
        />
        
        {/* Title and Content Inputs */}
        <TextEditor/>
      </div>
    </div>

    {/* Country Dialog */}
    <CountryDialog
      open={openCountry}
      selectedCountry={selectedCountry}
      handleClose={handleCloseCountry}
      handleCountryChange={handleCountryChange}
    />

    {/* Topic Dialog */}
    <TopicDialog
      open={openTopic}
      selectedTopic={selectedTopic}
      handleClose={handleCloseTopic}
      handleTopicChange={handleTopicChange}
    />
  </>
  );
};

export default Page;
