import React from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/layout/Header';
import useGlobalStore from '../../../stores/globalStore';
import { useLocation } from 'react-router-dom';
import styles from './page.module.css';
import CommentsWrapper from './components/Comments';
import Comment from './components/Comment';
import CommentsFields from './components/CommentsFields';

function Page() {
  const navigate = useNavigate();
  const { isAuthenticated } = useGlobalStore();
  const location = useLocation();
  const { title, date, views, category, replies } = location.state || {};

  return (
    <>
      <div className={styles.pageContainer}>
        {/* Fixed header */}
        <Header 
          useBackButton={false} 
          useLogoTitle={true} 
          useLanguageButton={true} 
          actionTitle={'Sign In / Sign Up'} 
          actionType={isAuthenticated ? 'none' : 'signin'} 
          onAction={() => navigate('/auth/signIn')}
        />

        <section className={styles.title}>
          {title ? title : 'Article Title'}
        </section>
        <section className={styles.article}>
          <article>
          Hello, I have been working in the marketing field for 5 years. Recently, I’ve developed a strong interest in data analysis and am considering transitioning to a related role. However, I’m unsure whether it’s the right decision to give up the career and network I’ve built in marketing. Should I be taking on a new field for better opportunities?
          </article>
        </section>
        <section className={styles.group}>
          <div>
            <span className={styles.information}> Anonymous </span>
            <span className={styles.information}> {date ? date : 'Unknown Date'}</span>
            <span className={styles.information}> Views {views ? views : 0}</span>
            <span className={styles.information}> {category ? category : 'Uncategorized'}</span>
            {/* <p>Replies: {replies ? replies : 0}</p> */}
          </div>

        </section>
        <section className={styles.group}>
          <div className={styles.additional}>
            <span className={styles.information}> Translate to my language </span>
          </div> 
        </section>

        <div className={styles.divider}/>
        
        <div className={styles.frame}>
          <span className={styles.text_wrapper}>
            EDIT
          </span>
          <span className={styles.text_wrapper}>
            DELETE
          </span>
        </div>

        <div className={styles.divider}/>
        
        <div className={styles.frame}>
          <CommentsWrapper/>
        </div>

        <div className={styles.divider}/>
        
        {/* 존재하는게 있을시 내용 댓글 추가 */}
        <Comment/>

        <div className={`${styles.frame} ${styles.floatingCommentsFields}`}>
          <CommentsFields />
        </div>


      </div>
    </>
  )
}

export default Page
