import React from 'react'
import styles from './Comment.module.css';
import MdGlobeIcon from '../../../../assets/images/ic_globe_filled.svg';
import MdReplyIcon from '../../../../assets/images/ic_reply_outline.svg';
import MdReportIcon from '../../../../assets/images/ic_report.svg';

function Comment() {
  return (
    <>
      <div className={styles.commentContainer}>
        <div className={styles.group}>
          <span className={styles.information}>
            Anonymous May 14, 2024
          </span>
        </div>
        <div className={styles.article}>
          Instead of completely transitioning right away, how about starting with related education or gaining experience through projects?You can assess the synergy with your current role first, and it's not too late to seriously consider the transition afterward.
        </div>
        <div className={styles.group}>
          <span className={styles.iconwrapper}>
            <img src={MdGlobeIcon} alt="Send" className={styles.icon} />
            Add a comment
          </span>
          <span className={styles.iconwrapper}>
            <img src={MdReplyIcon} alt="Send" className={styles.icon} />
            Translate
          </span>
          <span className={styles.iconwrapper}>
            <img src={MdReportIcon} alt="Send" className={styles.icon} />
            Report
          </span>
        </div>
      </div>
    </>
  )
}

export default Comment
