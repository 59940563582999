import Header from '../../components/layout/Header';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import styles from './TermsOfService.module.css';
import Button from '../../components/element/Button';
import { Sheet } from 'react-modal-sheet';
import checkIcon from '../../assets/images/ic_checked.png';
import unCheck from '../../assets/images/ic_unchecked.png';
import closeIcon from '../../assets/images/ic_close.png';

function TermsOfService() {
  const navigate = useNavigate();
  const [openBottomSheetType, setOpenBottomSheetType] = useState('');

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
      />
      <div className={styles['title']}>Terms of Service</div>
      <div className={styles['select-container']}>
        
        <div className={styles['select-item']}>
          <span>General terms of use</span>
          <div className={styles['link']} onClick={() => setOpenBottomSheetType('service')}>{'More'}</div>
        </div>
        <div className={styles['select-item']}>
          <span>Privacy Policy</span>
          <div className={styles['link']} onClick={() => setOpenBottomSheetType('privacy')}>{'More'}</div>
        </div>
        <div className={styles['select-item']}>
          <span>Operational Policy</span>
          <div className={styles['link']} onClick={() => setOpenBottomSheetType('policy')}>{'More'}</div>
        </div>
        <div className={styles['select-item']}>
          <span>Consent for promotion</span>
          <div className={styles['link']} onClick={() => setOpenBottomSheetType('marketing')}>{'More'}</div>
        </div>
        <div className={styles['select-item']}>
          <span>Mate Terms of Service</span>
          <div className={styles['link']} onClick={() => setOpenBottomSheetType('mate_service')}>{'More'}</div>
        </div>
        <div className={styles['select-item']}>
          <span>Promotion Agreement</span>
          <div className={styles['link']} onClick={() => setOpenBottomSheetType('discount_policy')}>{'More'}</div>
        </div>
      </div>
      <Sheet
        isOpen={openBottomSheetType !== ''}
        detent='content-height'
        onClose={() => setOpenBottomSheetType('')}
      >
        <Sheet.Container
          style={{ borderRadius: '12px 12px 0px 0px' }}
        >
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-terms-title']}>{openBottomSheetType === 'service' ? 'General terms of use' : 
                                                            openBottomSheetType === 'privacy' ? 'Privacy Policy' :
                                                            openBottomSheetType === 'policy' ? 'Operational Policy' :
                                                            openBottomSheetType === 'marketing' ? 'Consent for promotion' :
                                                            openBottomSheetType === 'mate_service' ? 'Mate Terms of Service' :
                                                            'Promotion Agreement'}</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBottomSheetType('')}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content']} style={{height: '41.4rem'}}>
                <iframe src={
                  openBottomSheetType === 'service' ? `/terms/terms_service_en.html` : 
                  openBottomSheetType === 'privacy' ? `/terms/terms_privacy_en.html` : 
                  openBottomSheetType === 'policy' ? `/terms/terms_policy_en.html` : 
                  openBottomSheetType === 'marketing' ? `/terms/terms_marketing_en.html` : 
                  openBottomSheetType === 'mate_service' ? `/terms/terms_mate_service_en.html` : 
                  `/terms/terms_discount_policy_en.html` 
                } style={{width: '100%', border: 'none'}}></iframe>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheetType('')} />
      </Sheet>
    </div>
  );
}

export default TermsOfService;