import { useNavigate } from 'react-router-dom';
import Header from '../../../components/layout/Header';
import Button from '../../../components/element/Button';
import styles from './CountryVerifyConfirm.module.css';

interface CountryVerifyConfirmProps {
  countryName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const CountryVerifyConfirm: React.FC<CountryVerifyConfirmProps> = ({ countryName, onConfirm, onCancel }) => {
  return (
    <div className={styles['container']}>
      <Header onBack={() => onCancel()}/>
      <div className={styles['label-container']}>
        {'Verification complete!\r\nAre you in the '}{countryName}?
      </div>  
      <Button marginHorizontalRem={2.0} active={true} onClick={() => onConfirm()}>Yes</Button>
      <div className={styles['sub-button']} onClick={() => onCancel()}>No, I'll verify again</div>
    </div>
  );
}

export default CountryVerifyConfirm;
  