import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../../components/element/SearchBar';
import styles from './Index.module.css';
import { Mate } from '../../models/mate';
import userService from '../../services/userService';
import mateService from '../../services/mateService';
import useDebounce from '../../utils/debounce';
import recentSearchIcon from '../../assets/images/ic_recent_search.png';
import closeIcon from '../../assets/images/ic_close.png';

const highlightedText = (text: string, query: string) => {
  if (query !== '' && text.includes(query)) {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));

    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <span
              className={styles['search-highlight-text']}
              key={index}
            >
              {part}
            </span>
          ) : (
            <span className={styles['search-general-text']}>{part}</span>
          )
        )}
      </>
    );
  }

  return text;
};

function Search() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>('');
  const [resultList, setResultList] = useState<string[]>([]);
  const [searchHistoryList, setSearchHistoryList] = useState<string[]>([]);

  const debounceText = useDebounce(searchText, 300);

  useEffect(() => {
    loadSearchHistory();
  }, []);

  useEffect(() => {
    if (searchText === '') {
      setResultList([]);
    }
  }, [searchText]);

  /*
  useEffect(() => {
    if(debounceText !== '') {
      search(debounceText);
    }
  }, [debounceText]);
  */

  function keyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      search();
    }
  }

  async function search() {
    const mateList: Mate[] = await mateService.search(searchText);
    const jobList: string[] = [];

    for (var item of mateList) {
      if (item.user !== undefined) {
        const job = item.user.job;
        jobList.push(job);
      }
    }
    setResultList(jobList);
  }

  async function goMateList(text: string) {
    //save history.
    await userService.addHistory(text);

    navigate(`/search/result?text=${text}`);
  }

  async function deleteHistory(text: string) {
    await userService.deleteHistory(text);
    loadSearchHistory();
  }

  async function deleteHistoryAll() {
    await userService.deleteHistoryAll();
    loadSearchHistory();
  }

  async function loadSearchHistory() {
    setSearchHistoryList(await userService.getHistory());
  }

  return (
    <div>
      <SearchBar
        onBack={() => navigate(-1)}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onClear={() => setSearchText('')}
        onKeyDown={(e) => keyDownHandler(e)}
        placeholder='Search by job, country, or skills'
      />
      {/*최근 검색어가 없거나, 검색 결과가 있는 경우*/}
      {searchHistoryList.length <= 0 || searchText !== '' ? (
        resultList.map((e) => (
          <div
            className={styles['search-result-item']}
            onClick={() => goMateList(e)}
          >
            {highlightedText(e, searchText)}
          </div>
        ))
      ) : (
        <div className={styles['search-history']}>
          <div className={styles['search-history-title-container']}>
            <div>Recent searches</div>
            <div onClick={() => deleteHistoryAll()}>Clear</div>
          </div>
          {searchHistoryList.map((historyText) => (
            <div className={styles['search-history-item-container']}>
              <div
                className={styles['search-history-item']}
                onClick={() => goMateList(historyText)}
              >
                <img
                  src={recentSearchIcon}
                  alt=''
                />
                {historyText}
              </div>
              <div
                className={styles['search-history-clear']}
                onClick={() => deleteHistory(historyText)}
              >
                <img
                  src={closeIcon}
                  alt=''
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Search;
