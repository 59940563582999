import React from 'react';
import nationData from '../../../../assets/data/nation.json'; // Import the JSON data
import styles from './CountryDialog.module.css';

interface CountryDialogProps {
  open: boolean;
  selectedCountry: string;
  handleClose: () => void;
  handleCountryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CountryDialog: React.FC<CountryDialogProps> = ({
  open,
  selectedCountry,
  handleClose,
  handleCountryChange,
}) => {
  if (!open) return null;

  return (
    <div className={styles.dialogOverlay} onClick={handleClose}>
      <div
        className={styles.dialog}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dialog
      >
        <div className={styles.dialogTitle}>
          <span>Select a country</span>
          <button className={styles.closeButton} onClick={handleClose} aria-label="close">
            ×
          </button>
        </div>
        <div className={styles.dialogContent}>
          <input
            type="text"
            className={styles.textField}
            placeholder="Search your country"
          />
          <div className={styles.radioGroup}>
            {nationData.filter((e) => e.use === true && e.disableWhenDuplicate == false).map((nation, index) => (
              <label key={index} className={styles.radioLabel}>
                <input
                  type="radio"
                  value={nation.nation}
                  checked={selectedCountry === nation.nation}
                  onChange={handleCountryChange}
                  className={styles.radioInput}
                />
                {nation.nation}
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryDialog;
