import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import styles from './NotificationSettingLanguages.module.css';
import useGlobalStore from '../../stores/globalStore';
import useToast from '../../hook/useToast';
import UserService from '../../services/userService';

import translateLanguagesData from '../../assets/data/translate_languages.json';

function NotificationSettingLanguages() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user, setUser } = useGlobalStore(); 
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);

  useEffect(() => {
    setSelectedLanguage(user?.translate_language_code ?? 'en');
  }, []);
  
  const handleLanguagesSelect = (languageCode: string) => {
    setSelectedLanguage(languageCode);
  };

  async function save() {
    if(selectedLanguage === null) {
      toast('error', 'Please select a language.');
      return;
    }
    
    const result = await UserService.updateTranslateLanguageCode(selectedLanguage!);
    if(result === 'success') {
      setUser({...user!, translate_language_code: selectedLanguage!});
      navigate(-1);
    }
  }

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
        title='Change Language'
      />
      <div className={styles['title']}>Language</div>
      <div className={styles['info']}>Please select the language for Google Translate</div>
      <div className={styles['lang-container']}>
        {translateLanguagesData.map((e) => 
          <div
            className={`${styles['lang-item']} ${selectedLanguage === e.code ? styles['active'] : ''}`}
            onClick={() => handleLanguagesSelect(e.code)}
          >
            <div className={styles['circle']}></div>
            <span>{e.language}</span>
          </div>
        )}
      </div>
      <div className={styles['button-container']}>
        <Button
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem'
          activeFontWeight={700}
          marginHorizontalRem={2}
          active={selectedLanguage !== null}
          onClick={() => save()}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}

export default NotificationSettingLanguages;
