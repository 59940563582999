import React, { useEffect, useState } from 'react';
import Header from '../../components/layout/Header';
import styles from './MateRegister.module.css';
import { useNavigate } from 'react-router-dom';
import welcomeImg from '../../assets/images/ic_celebrate.png';
import checkImg from '../../assets/images/ic_check_default.png';
import Button from '../../components/element/Button';

function MateRegister() {
  const navigate = useNavigate();

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['welcome-text']}>
        {"Welcome, New Mate!"}
      </div>
      <div className={styles['welcome-image']}>
        <img src={welcomeImg} alt='' />
      </div>
      <div className={styles['welcome-list']}>
        <ul>
          <img src={checkImg} alt='' />
          <li>
            The Nailedit team has carefully reviewed
            <br /> your introduction.
          </li>
          <img src={checkImg} alt='' />
          <li>
            We believe your experience and insights
            <br /> will greatly benefit our community.
          </li>
          <img src={checkImg} alt='' />
          <li>
            To get started, <strong>create your Mate profile</strong> and{' '}
            <strong>add the meeting items</strong> you'd like to offer.
          </li>
        </ul>
        <div className={styles['info']}>
          {
            '*Your meeting items will be reviewed internally\r\nbefore final approval.'
          }
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button width='unset' marginHorizontalRem={0.4} active={true}>
          Create Mate Profile
        </Button>
      </div>
    </div>
  );
}

export default MateRegister;
