import React, { useState } from "react";
import styles from './OutlineTextField.module.css';

import clearIcon from '../../assets/images/ic_clear_style_2.png';

interface TextFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onAction?: () => void;
  maxLength?: number;
  placeholder?: string;
  type?: string;
  radius?: number;
  height?: string;
  marginLeft?: string;
  marginRight?: string;
  fontSize?: string;
  fontWeight?: number;
  prefix?: string;
  error?: boolean;
  [key: string]: any; //기타 props를 처리할 수 있도록 추가.
}

const TextField: React.FC<TextFieldProps> = ({ 
  value, 
  onChange, 
  onClear,
  onAction,
  maxLength,
  placeholder, 
  type = 'text',
  radius = 12,
  height = '4.8rem',
  marginLeft = '0rem',
  marginRight = '0rem',
  fontSize = '1.5rem',
  fontWeight = 600,
  prefix = '',
  error = false,
  ...rest 
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div className={`${styles['container']} ${error ? styles['container-error'] : (isFocused ? styles['container-focused'] : '')}`}
        style={{borderRadius: `${radius}px`, height: height, marginLeft: marginLeft, marginRight: marginRight}}>
      {prefix != '' && <span className={styles['prefix']}>$</span>}
      <input
        className={styles['input-field']}
        style={{fontSize: fontSize, fontWeight: fontWeight}}
        type={type}
        value={value}
        maxLength={maxLength}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        {...rest}
      />
      {onClear !== undefined && value !== '' && <img src={clearIcon} alt="" onClick={onClear}/>}
    </div>
  );
};

export default TextField;