import React, { useState } from 'react';
import styles from './TextField.module.css';

import clearIcon from '../../assets/images/ic_clear.png';

interface TextFieldProps {
  width?: string;
  value?: string;
  enable?: boolean;
  actionButtonTitle?: string;
  actionButtonWidth?: string;
  actionButtonEnable?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onAction?: () => void;
  maxLength?: number;
  placeholder?: string;
  type?: string;
  radius?: number;
  height?: string;
  marginLeft?: string;
  marginRight?: string;
  paddingLeft?: string;
  fontSize?: string;
  fontWeight?: number;
  bottomLine?: boolean;
  error?: boolean;
  [key: string]: any; //기타 props를 처리할 수 있도록 추가.
}

const TextField: React.FC<TextFieldProps> = ({
  width,
  value,
  enable = true,
  actionButtonTitle = '',
  actionButtonWidth = '7.2rem',
  actionButtonEnable = false,
  onChange,
  onClear,
  onAction,
  maxLength,
  placeholder,
  type = 'text',
  radius = 12,
  height = '4.8rem',
  marginLeft = '0rem',
  marginRight = '0rem',
  paddingLeft = '1.6rem',
  paddingRight = '0.6rem',
  fontSize = '1.5rem',
  fontWeight = 600,
  bottomLine = false,
  error = false,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div
      className={`${styles['container']} ${
        error
          ? styles['container-error']
          : isFocused
          ? styles['container-focused']
          : ''
      }`}
      style={{
        borderRadius: `${radius}px`,
        width: width,
        height: height,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        marginLeft: marginLeft,
        marginRight: marginRight,
        border: `${bottomLine == false ? undefined : '0px'}`,
        borderBottom: `${bottomLine == true ? '3px solid #EDF0F3' : undefined}`,
      }}
    >
      <input
        className={styles['input-field']}
        style={{ fontSize: fontSize, fontWeight: fontWeight }}
        type={type}
        value={value != undefined ? value : ''}
        readOnly={!enable}
        maxLength={maxLength}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        {...rest}
      />
      {onClear !== undefined && value !== '' && (
        <img
          src={clearIcon}
          alt=''
          onClick={onClear}
        />
      )}
      {onAction !== undefined && (
        <div
          className={`${styles['action-button']} ${
            actionButtonEnable && styles['action-button-enable']
          }`}
          style={{ width: actionButtonWidth }}
          onClick={onAction}
        >
          {actionButtonTitle}
        </div>
      )}
    </div>
  );
};

export default TextField;
