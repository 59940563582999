import React, { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import styles from './TextField.module.css';

interface TextFieldProps {
  width?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  placeholder?: string;
  format: string;
  mask?: string;
  type?: string;
  radius?: number;
  height?: string;
  marginLeft?: string;
  marginRight?: string;
  paddingLeft?: string;
  fontSize?: string;
  fontWeight?: number;
  bottomLine?: boolean;
  error?: boolean;
  [key: string]: any; //기타 props를 처리할 수 있도록 추가.
}

const PatternTextField: React.FC<TextFieldProps> = ({
  width,
  value,
  onChange,
  maxLength,
  placeholder,
  format,
  mask,
  type = 'text',
  radius = 12,
  height = '4.8rem',
  marginLeft = '0rem',
  marginRight = '0rem',
  paddingLeft = '1.6rem',
  paddingRight = '0.6rem',
  fontSize = '1.5rem',
  fontWeight = 600,
  bottomLine = false,
  error = false,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div
      className={`${styles['container']} ${
        error
          ? styles['container-error']
          : isFocused
          ? styles['container-focused']
          : ''
      }`}
      style={{
        borderRadius: `${radius}px`,
        width: width,
        height: height,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        marginLeft: marginLeft,
        marginRight: marginRight,
        border: `${bottomLine == false ? undefined : '0px'}`,
        borderBottom: `${bottomLine == true ? '3px solid #EDF0F3' : undefined}`,
      }}
    >
      <PatternFormat 
        className={styles['input-field']}
        style={{ fontSize: fontSize, fontWeight: fontWeight }}
        type='text'
        format={format}
        mask={mask}
        value={value != undefined ? value : ''}
        maxLength={maxLength}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default PatternTextField;
