import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from './RecipientInformation.module.css';
import unselectedIcon from '../../../assets/images/ic_radio_unselected.png';
import selectedIcon from '../../../assets/images/ic_radio_selected.png';
import { UserWithdrawalMethod } from '../../../models/userWithdrawalMethod';
import settlementsData from '../../../assets/data/settlement.json';
// Props 타입 정의
interface RecipientInformationProps {
  onRecipientDataChange: (data: UserWithdrawalMethod, errors: string[]) => void;
  selectedSettlement?: any; // 필요한 경우 정확한 타입으로 대체
}

// 상태 데이터 타입 정의\

const RecipientInformation: React.FC<RecipientInformationProps> = ({ onRecipientDataChange }) => {
  const [value, setValue] = useState('');
  const [financialCode, setFinancialCode] = useState('');
  const [achCode, setAchCode] = useState('');
  const [sortCode, setSortCode] = useState('');
  const [swiftBicCode, setSwiftBicCode] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [ibanCode, setIbanCode] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [bsbCode, setBsbCode] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [transitNumber, setTransitNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [selectAccountType, setSelectAccountType] = useState('');
  const [selectIdType, setSelectIdType] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [country, setCountry] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postCode, setPostCode] = useState('');
  const [mobile, setMobile] = useState('');
  const [currency, setCurrency] = useState('');
  const [tax, setTax] = useState(0);
  const [email, setEmail] = useState('');
  const [selectRecipentType, setSelectRecipentType] = useState('');
  const [selectSwiftRecipentType, setSelectSwiftRecipentType] = useState('');
  const location = useLocation();
  const { methodData, selectedSettlement } = location.state || {}; // 전달된 selectedType 가져오기
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (selectedSettlement) {
      const matchedSettlement = settlementsData.find(
        (settlement) =>
          settlement.subTemplate === selectedSettlement.subTemplate &&
          settlement.abb === selectedSettlement.abb // abb와 subTemplate 모두 매칭
      );

      if (matchedSettlement) {
        setCurrency(matchedSettlement.currency || '');
        setTax(Number(matchedSettlement.taxRate) || 0); // taxRate 명시적 변환
        setSelectSwiftRecipentType(matchedSettlement.swiftFee || '');
      }
    }
  }, [selectedSettlement, settlementsData]);

  useEffect(() => {
    const validationErrors = validateFields();
    setErrors(validationErrors); // 오류 배열을 상태에 저장

    // 부모 컴포넌트에 상태 전달
    onRecipientDataChange(
      {
        email: email,
        bank_code: bankCode,
        account_number: accountNumber,
        account_name: accountName,
        currency: currency,
        recipient_type: selectRecipentType,
        iban: ibanCode,
        country_region: country,
        street_address: street,
        city: city,
        state_province: state,
        post_code: postCode,
        swift_code_bic: swiftBicCode,
        branch_code: branchCode,
        id_type: selectIdType,
        id_number: idNumber,
        account_type: selectAccountType,
        bsb_routing_number: bsbCode,
        ifsc_code: ifscCode,
        ach_routing_number: achCode,
        sort_code: sortCode,
        financial_institution_number: financialCode,
        transit_number: transitNumber,
        tax_rate: tax,
        swift_code: swiftCode,
        swift_transfer_fee_option: selectSwiftRecipentType,
      },
      validationErrors
    );
  }, [
    email,
    bankCode,
    accountNumber,
    accountName,
    currency,
    selectRecipentType,
    ibanCode,
    country,
    street,
    city,
    state,
    postCode,
    swiftBicCode,
    branchCode,
    selectIdType,
    idNumber,
    selectAccountType,
    bsbCode,
    ifscCode,
    achCode,
    sortCode,
    financialCode,
    transitNumber,
    tax,
    swiftCode,
    selectSwiftRecipentType,
  ]);
  // selectedType을 사용하여 필요한 작업 수행
  // console.log(selectedType);
  // console.log(selectedSettlement);
  const selectedAccountType = (type: string) => {
    setSelectAccountType(type);
  };
  const selectedIdType = (type: string) => {
    setSelectIdType(type);
  };
  const selectedRecipentType = (type: string) => {
    setSelectRecipentType(type);
  };
  const selectedSwiftRecipentType = (type: string) => {
    setSelectSwiftRecipentType(type);
  };

  const navigate = useNavigate();

  // 입력값 유효성 검사
  const validateFields = (): string[] => {
    let newErrors: string[] = [];

    // Bank Code
    if (
      ['Domestic', 'L-9', 'L-10', 'L-11', 'L-13', 'LT-2', 'LT-3', 'L-16'].includes(
        selectedSettlement.subTemplate
      ) ||
      selectedSettlement.abb === 'SE'
    ) {
      if (!bankCode) newErrors.push('Bank Code is required');
    }

    // ACH Routing Number
    if (selectedSettlement.abb === 'US' && !achCode) {
      newErrors.push('ACH Routing Number is required');
    }

    // Sort Code
    if (selectedSettlement.subTemplate === 'L-4' && !sortCode) {
      newErrors.push('Sort Code is required');
    }

    // IBAN
    if (
      ['L-1', 'S-4', 'S-3'].includes(selectedSettlement.subTemplate) ||
      selectedSettlement.abb === 'SE'
    ) {
      if (!ibanCode) newErrors.push('IBAN is required');
    }

    // BSB / Routing Number
    if (selectedSettlement.subTemplate === 'L-15' && !bsbCode) {
      newErrors.push('BSB / Routing Number is required');
    }

    // IFSC Code
    if (selectedSettlement.subTemplate === 'L-18' && !ifscCode) {
      newErrors.push('IFSC Code is required');
    }

    // Financial Institution Number
    if (selectedSettlement.subTemplate === 'L-19' && !financialCode) {
      newErrors.push('Financial Institution Number is required');
    }

    // SWIFT CODE / BIC
    if (['L-5', 'L-6', 'L-7', 'L-8'].includes(selectedSettlement.subTemplate) && !swiftBicCode) {
      newErrors.push('SWIFT CODE / BIC is required');
    }

    // SWIFT CODE
    if (['S-1', 'S-2'].includes(selectedSettlement.subTemplate) && !swiftCode) {
      newErrors.push('SWIFT CODE is required');
    }

    // Account Number
    if (
      [
        'Domestic',
        'L-2',
        'L-3',
        'L-4',
        'L-5',
        'L-6',
        'L-7',
        'L-8',
        'L-9',
        'L-10',
        'L-11',
        'L-12',
        'L-13',
        'L-14',
        'L-15',
        'L-16',
        'L-17',
        'L-18',
        'L-19',
        'LT-1',
        'S-1',
        'S-2',
        'LT-2',
        'LT-3',
      ].includes(selectedSettlement.subTemplate) &&
      !accountNumber
    ) {
      newErrors.push('Account Number is required');
    }

    // Account Name
    if (!accountName) {
      newErrors.push('Account Name is required');
    }

    // Account Type
    if (
      ['L-9', 'L-10', 'L-11', 'L-12', 'LT-3', 'L-13'].includes(selectedSettlement.subTemplate) &&
      !selectAccountType
    ) {
      newErrors.push('Account Type is required');
    }

    // ID Type & ID Number
    if (
      ['L-7', 'L-9', 'L-10', 'L-12', 'L-13', 'LT-1', 'LT-3', 'LT-2'].includes(
        selectedSettlement.subTemplate
      )
    ) {
      if (!selectIdType) newErrors.push('ID Type is required');
      if (!idNumber) newErrors.push('ID Number is required');
    }

    // Country
    if (selectedSettlement.subTemplate !== 'Domestic' && !country) {
      newErrors.push('Country/Region is required');
    }

    // Street
    if (selectedSettlement.subTemplate !== 'Domestic' && !street) {
      newErrors.push('Street/Address is required');
    }
    // Currency
    if (!currency) {
      newErrors.push('Currency is required');
    }
    // Email
    if (
      !email &&
      (selectedSettlement.subTemplate === 'L-10' || selectedSettlement.subTemplate === 'L-13')
    ) {
      newErrors.push('Email is required');
    }
    // RecipientType
    if (!selectRecipentType) {
      newErrors.push('RecipentType is required');
    }
    // RecipientType
    if (
      !selectSwiftRecipentType &&
      (selectedSettlement.subTemplate === 'S-1' ||
        selectedSettlement.subTemplate === 'S-2' ||
        selectedSettlement.subTemplate === 'S-3' ||
        selectedSettlement.subTemplate === 'S-4')
    ) {
      newErrors.push('Transfer Fee Option is required');
    }

    // City
    if (selectedSettlement.subTemplate !== 'Domestic' && !city) {
      newErrors.push('City is required');
    }

    // State/Province
    if (
      [
        'L-1',
        'L-2',
        'L-8',
        'L-9',
        'L-10',
        'L-14',
        'L-18',
        'L-19',
        'L-15',
        'LT-2',
        'S-1',
        'S-3',
        'S-4',
      ].includes(selectedSettlement.subTemplate) &&
      !state
    ) {
      newErrors.push('State/Province is required');
    }

    // Post Code
    if (
      [
        'L-1',
        'L-2',
        'L-3',
        'L-4',
        'L-6',
        'L-7',
        'L-8',
        'L-9',
        'L-10',
        'L-11',
        'L-12',
        'L-13',
        'L-14',
        'L-15',
        'L-16',
        'L-17',
        'L-19',
        'LT-1',
        'S-1',
        'S-3',
        'L-18',
      ].includes(selectedSettlement.subTemplate) &&
      !postCode
    ) {
      newErrors.push('Post Code is required');
    }

    // Mobile Number
    if (['L-6', 'L-10'].includes(selectedSettlement.subTemplate) && !mobile) {
      newErrors.push('Mobile Number is required');
    }

    return newErrors; // 항상 string[] 반환
  };
  return (
    <div>
      {/* {errors.length > 0 && (
        <ul className={styles.errorMessages}>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )} */}
      {(selectedSettlement.subTemplate === 'Domestic' ||
        selectedSettlement.subTemplate === 'L-9' ||
        selectedSettlement.subTemplate === 'L-10' ||
        selectedSettlement.subTemplate === 'L-11' ||
        selectedSettlement.subTemplate === 'L-13' ||
        selectedSettlement.subTemplate === 'LT-2' ||
        selectedSettlement.subTemplate === 'LT-3' ||
        selectedSettlement.subTemplate === 'L-16') && (
        <>
          <div className={styles['input-label']}>{'Bank Code'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              // 템플릿에 따라서 Bank Code , IBAN, SWIFR CODE, BIC /ACH, Sort Code 등 1번째 코드
              value={bankCode}
              onChange={(e) => setBankCode(e.target.value)}
              placeholder={`3 digit number to identify a bank in ${selectedSettlement.nation}`}
            />
          </div>
        </>
      )}

      {selectedSettlement.abb === 'US' && (
        <>
          <div className={styles['input-label']}>{'ACH routing number'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={achCode}
              onChange={(e) => setAchCode(e.target.value)}
              placeholder={'Please Write.'}
            />
          </div>
        </>
      )}

      {selectedSettlement.subTemplate === 'L-4' && (
        <>
          <div className={styles['input-label']}>{'Sort Code'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={sortCode}
              onChange={(e) => setSortCode(e.target.value)}
              placeholder={'Please Write.'}
            />
          </div>
        </>
      )}

      {(selectedSettlement.subTemplate === 'L-1' ||
        selectedSettlement.subTemplate === 'S-4' ||
        selectedSettlement.subTemplate === 'L-18' ||
        selectedSettlement.abb === 'SE' ||
        selectedSettlement.subTemplate === 'S-3') && (
        <>
          <div className={styles['input-label']}>{'IBAN'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={ibanCode}
              onChange={(e) => setIbanCode(e.target.value)}
              placeholder={'Please Write.'}
            />
          </div>
        </>
      )}

      {selectedSettlement.subTemplate === 'L-15' && (
        <>
          <div className={styles['input-label']}>{'BSB /  Routing Number (Code) '}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={bsbCode}
              onChange={(e) => setBsbCode(e.target.value)}
              placeholder={'Please Write.'}
            />
          </div>
        </>
      )}

      {selectedSettlement.subTemplate === 'L-18' ||
        (selectedSettlement.subTemplate === 'L-17' && (
          <>
            <div className={styles['input-label']}>{'IFSC Code'}</div>
            <div className={styles['container']}>
              <input
                className={styles['input-field']}
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                placeholder={'Please Write.'}
              />
            </div>
          </>
        ))}

      {selectedSettlement.subTemplate === 'L-19' && (
        <>
          <div className={styles['input-label']}>{'Financial Institution Number'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={financialCode}
              onChange={(e) => setFinancialCode(e.target.value)}
              placeholder={'Please Write.'}
            />
          </div>
        </>
      )}

      {(selectedSettlement.subTemplate === 'L-5' ||
        selectedSettlement.subTemplate === 'L-6' ||
        selectedSettlement.subTemplate === 'L-7' ||
        selectedSettlement.subTemplate === 'L-8') && (
        <>
          <div className={styles['input-label']}>{'SWIFT CODE / BIC'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={swiftBicCode}
              onChange={(e) => setSwiftBicCode(e.target.value)}
              placeholder={'Please Write.'}
            />
          </div>
        </>
      )}

      {(selectedSettlement.subTemplate === 'S-1' || selectedSettlement.subTemplate === 'S-2') && (
        <>
          <div className={styles['input-label']}>{'SWIFT CODE'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={swiftCode}
              onChange={(e) => setSwiftCode(e.target.value)}
              placeholder={'Please Write.'}
            />
          </div>
        </>
      )}
      {selectedSettlement.abb === 'SE' ||
        (selectedSettlement.subTemplate === 'L-18' && (
          <>
            {/* SE만 2번째 칸에 Bank Code칸이 필요해서 따로 생성 */}
            <div className={styles['input-label']}>{'Bank Code'}</div>
            <div className={styles['container']}>
              <input
                className={styles['input-field']}
                value={bankCode}
                onChange={(e) => setBankCode(e.target.value)}
                placeholder={`3 digit number to identify a bank in ${selectedSettlement.nation}`}
              />
            </div>
          </>
        ))}
      {selectedSettlement.subTemplate === 'L-19' && (
        <>
          <div className={styles['input-label']}>{'Transit Number'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={transitNumber}
              onChange={(e) => setTransitNumber(e.target.value)}
              placeholder='You may omit this field if your country does not have state.'
            />
          </div>
        </>
      )}
      {(selectedSettlement.subTemplate === 'L-5' ||
        selectedSettlement.subTemplate === 'L-11' ||
        selectedSettlement.subTemplate === 'LT-3') && (
        <>
          <div className={styles['input-label']}>{'Branch Code'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={branchCode}
              onChange={(e) => setBranchCode(e.target.value)}
              placeholder='You may omit this field if your country does not have state.'
            />
          </div>
        </>
      )}

      {(selectedSettlement.subTemplate === 'Domestic' ||
        selectedSettlement.subTemplate === 'L-2' ||
        selectedSettlement.subTemplate === 'L-3' ||
        selectedSettlement.subTemplate === 'L-4' ||
        selectedSettlement.subTemplate === 'L-5' ||
        selectedSettlement.subTemplate === 'L-6' ||
        selectedSettlement.subTemplate === 'L-7' ||
        selectedSettlement.subTemplate === 'L-8' ||
        selectedSettlement.subTemplate === 'L-9' ||
        selectedSettlement.subTemplate === 'L-10' ||
        selectedSettlement.subTemplate === 'L-11' ||
        selectedSettlement.subTemplate === 'L-12' ||
        selectedSettlement.subTemplate === 'L-13' ||
        selectedSettlement.subTemplate === 'L-14' ||
        selectedSettlement.subTemplate === 'L-15' ||
        selectedSettlement.subTemplate === 'L-16' ||
        selectedSettlement.subTemplate === 'L-17' ||
        selectedSettlement.subTemplate === 'L-18' ||
        selectedSettlement.subTemplate === 'L-19' ||
        selectedSettlement.subTemplate === 'LT-1' ||
        selectedSettlement.subTemplate === 'S-1' ||
        selectedSettlement.subTemplate === 'S-2' ||
        selectedSettlement.subTemplate === 'LT-2' ||
        selectedSettlement.subTemplate === 'LT-3') && (
        <>
          <div className={styles['input-label']}>{'Account Number'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              placeholder='Starting with 3-digit branch code, up to 17 digits'
            />
          </div>
        </>
      )}

      {/* Account Name */}
      <div className={styles['input-label']}>{'Account Name'}</div>
      <div className={styles['container']}>
        <input
          className={styles['input-field']}
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          placeholder='Account Holder’s full name as registered with the bank'
        />
      </div>
      {(selectedSettlement.subTemplate === 'L-9' ||
        selectedSettlement.subTemplate === 'L-10' ||
        selectedSettlement.subTemplate === 'L-11' ||
        selectedSettlement.subTemplate === 'L-12' ||
        selectedSettlement.subTemplate === 'LT-3' ||
        selectedSettlement.subTemplate === 'L-13') && (
        <>
          <div className={styles['input-label']}>{'Account Type'}</div>
          <div className={styles['select-container']}>
            <div
              className={styles['select-item']}
              onClick={() => selectedAccountType('Checking')}
            >
              <img
                src={selectAccountType === 'Checking' ? selectedIcon : unselectedIcon}
                alt=''
              />
              <span>Checking</span>
            </div>
            <div
              className={styles['select-item']}
              onClick={() => selectedAccountType('Savings')}
            >
              <img
                src={selectAccountType === 'Savings' ? selectedIcon : unselectedIcon}
                alt=''
              />
              <span>Savings</span>
            </div>
            {selectedSettlement.subTemplate === 'L-9' && (
              <div
                className={styles['select-item']}
                onClick={() => selectedAccountType('Vista')}
              >
                <img
                  src={selectAccountType === 'Vista' ? selectedIcon : unselectedIcon}
                  alt=''
                />
                <span>Vista</span>
              </div>
            )}
            {selectedSettlement.subTemplate === 'L-12' && (
              <div
                className={styles['select-item']}
                onClick={() => selectedAccountType('Maestra')}
              >
                <img
                  src={selectAccountType === 'Maestra' ? selectedIcon : unselectedIcon}
                  alt=''
                />
                <span>Maestra</span>
              </div>
            )}
          </div>
        </>
      )}
      {(selectedSettlement.subTemplate === 'L-7' ||
        selectedSettlement.subTemplate === 'L-9' ||
        selectedSettlement.subTemplate === 'L-10' ||
        selectedSettlement.subTemplate === 'L-12' ||
        selectedSettlement.subTemplate === 'L-13' ||
        selectedSettlement.subTemplate === 'LT-1' ||
        selectedSettlement.subTemplate === 'LT-3' ||
        selectedSettlement.subTemplate === 'LT-2') && (
        <>
          <div className={styles['input-label']}>{'ID Type'}</div>
          <div className={styles['select-container']}>
            <div
              className={styles['select-item']}
              onClick={() => selectedIdType('Foreigner ID (CE)')}
            >
              <img
                src={selectIdType === 'Foreigner ID (CE)' ? selectedIcon : unselectedIcon}
                alt=''
              />
              <span>Foreigner ID (CE) </span>
            </div>
            <div
              className={styles['select-item']}
              onClick={() => selectedIdType('National ID (CC)')}
            >
              <img
                src={selectIdType === 'National ID (CC)' ? selectedIcon : unselectedIcon}
                alt=''
              />
              <span>National ID (CC)</span>
            </div>
            <div
              className={styles['select-item']}
              onClick={() => selectedIdType('Passport')}
            >
              <img
                src={selectIdType === 'Passport' ? selectedIcon : unselectedIcon}
                alt=''
              />
              <span>Passport</span>
            </div>
            <div
              className={styles['select-item']}
              onClick={() => selectedIdType('Tax ID(INT)')}
            >
              <img
                src={selectIdType === 'Tax ID(INT)' ? selectedIcon : unselectedIcon}
                alt=''
              />
              <span>Tax ID(INT)</span>
            </div>
          </div>
          <div className={styles['input-label']}>{'ID Number'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={idNumber}
              onChange={(e) => setIdNumber(e.target.value)}
              placeholder=''
            />
          </div>
        </>
      )}
      {/* country */}
      {selectedSettlement.subTemplate === 'Domestic' ? (
        ''
      ) : (
        <>
          <div className={styles['input-label']}>{'Country/ Region '}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder='Account Holder’s full name as registered with the bank'
            />
          </div>
          {/* street */}
          <div className={styles['input-label']}>{'Street / Address '}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              placeholder='Account Holder’s full name as registered with the bank'
            />
          </div>
          {/* city */}
          <div className={styles['input-label']}>{'City'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder='Account Holder’s full name as registered with the bank'
            />
          </div>
          {(selectedSettlement.subTemplate === 'L-1' ||
            selectedSettlement.subTemplate === 'L-2' ||
            selectedSettlement.subTemplate === 'L-8' ||
            selectedSettlement.subTemplate === 'L-9' ||
            selectedSettlement.subTemplate === 'L-10' ||
            selectedSettlement.subTemplate === 'L-14' ||
            selectedSettlement.subTemplate === 'L-19' ||
            selectedSettlement.subTemplate === 'L-15' ||
            selectedSettlement.subTemplate === 'LT-2' ||
            selectedSettlement.subTemplate === 'S-1' ||
            selectedSettlement.subTemplate === 'S-3' ||
            selectedSettlement.subTemplate === 'S-4') && (
            <>
              <div className={styles['input-label']}>{'State / Province '}</div>
              <div className={styles['container']}>
                <input
                  className={styles['input-field']}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  placeholder='You may omit this field if your country does not have state.'
                />
              </div>
            </>
          )}
        </>
      )}

      {(selectedSettlement.subTemplate === 'L-1' ||
        selectedSettlement.subTemplate === 'L-2' ||
        selectedSettlement.subTemplate === 'L-3' ||
        selectedSettlement.subTemplate === 'L-4' ||
        selectedSettlement.subTemplate === 'L-6' ||
        selectedSettlement.subTemplate === 'L-7' ||
        selectedSettlement.subTemplate === 'L-8' ||
        selectedSettlement.subTemplate === 'L-9' ||
        selectedSettlement.subTemplate === 'L-10' ||
        selectedSettlement.subTemplate === 'L-11' ||
        selectedSettlement.subTemplate === 'L-12' ||
        selectedSettlement.subTemplate === 'L-13' ||
        selectedSettlement.subTemplate === 'L-14' ||
        selectedSettlement.subTemplate === 'L-15' ||
        selectedSettlement.subTemplate === 'L-16' ||
        selectedSettlement.subTemplate === 'L-17' ||
        selectedSettlement.subTemplate === 'L-19' ||
        selectedSettlement.subTemplate === 'LT-1' ||
        selectedSettlement.subTemplate === 'S-1' ||
        selectedSettlement.subTemplate === 'S-3' ||
        selectedSettlement.subTemplate === 'L-18') && (
        <>
          <div className={styles['input-label']}>{'Post Code'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
              placeholder='You may omit this field if your country does not have state.'
            />
          </div>
        </>
      )}
      {(selectedSettlement.subTemplate === 'L-6' || selectedSettlement.subTemplate === 'L-10') && (
        <>
          <div className={styles['input-label']}>{'Mobile Number'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder='You may omit this field if your country does not have state.'
            />
          </div>
        </>
      )}

      {/* currency */}
      <div className={styles['input-label']}>{'Currency'}</div>
      <div className={styles['container']}>
        <input
          className={styles['input-field']}
          value={currency}
          placeholder='Account Holder’s full name as registered with the bank'
        />
      </div>
      {(selectedSettlement.subTemplate === 'LT-1' ||
        selectedSettlement.subTemplate === 'LT-2' ||
        selectedSettlement.subTemplate === 'LT-3') && (
        <>
          <div className={styles['input-label']}>{'Tax Rate (%)'}</div>
          <div className={styles['container']}>
            <input
              className={styles['input-field']}
              value={tax}
            />
          </div>
        </>
      )}
      {/* email */}
      <div className={styles['input-label']}>
        {selectedSettlement.subTemplate === 'L-10' || selectedSettlement.subTemplate === 'L-13'
          ? 'Email'
          : 'Email(Optional)'}
      </div>
      <div className={styles['container']}>
        <input
          className={styles['input-field']}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Account Holder’s full name as registered with the bank'
        />
      </div>
      <div className={styles['input-label']}>{'Recipent Type'}</div>
      <div
        className={styles['select-container']}
        onClick={() => selectedRecipentType('Individual')}
      >
        <img
          src={selectRecipentType === 'Individual' ? selectedIcon : unselectedIcon}
          alt=''
        />
        <span>Individual</span>
      </div>
      {(selectedSettlement.subTemplate === 'S-2' ||
        selectedSettlement.subTemplate === 'S-1' ||
        selectedSettlement.subTemplate === 'S-3' ||
        selectedSettlement.subTemplate === 'S-4') && (
        <>
          <div className={styles['input-label']}>{'SWIFT Transfer Fee Option'}</div>
          <div
            className={styles['select-container']}
            onClick={() => setSelectSwiftRecipentType('OUR')}
          >
            <img
              src={selectSwiftRecipentType === 'OUR' ? selectedIcon : unselectedIcon} // 선택 상태 확인
              alt=''
            />
            <span>OUR</span>
          </div>
        </>
      )}
    </div>
  );
};

export default RecipientInformation;
