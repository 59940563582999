import React from 'react';
import styles from './TopicDialog.module.css';

interface TopicDialogProps {
  open: boolean;
  selectedTopic: string;
  handleClose: () => void;
  handleTopicChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TopicDialog: React.FC<TopicDialogProps> = ({
  open,
  selectedTopic,
  handleClose,
  handleTopicChange,
}) => {
  if (!open) return null;

  return (
    <div className={styles.dialogOverlay} onClick={handleClose}>
      <div
        className={styles.dialog}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dialog
      >
        <div className={styles.dialogTitle}>
          <span>Select a topic</span>
          <button className={styles.closeButton} onClick={handleClose} aria-label="close">
            ×
          </button>
        </div>
        <div className={styles.dialogContent}>
          <div>
            {['Job Search', 'Lifestyle', 'Field/Role', 'Studying Abroad', 'Events'].map((topic) => (
              <label key={topic} className={styles.radioLabel}>
                <input
                  type="radio"
                  value={topic}
                  checked={selectedTopic === topic}
                  onChange={handleTopicChange}
                  className={styles.radioInput}
                />
                {topic}
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicDialog;
