import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BirthInput.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import TextField from '../../components/element/TextField';
import { VerifyStatus } from '../../constants/constants';
import authService from '../../services/authService';
import useToast from '../../hook/useToast';
import useGlobalStore from '../../stores/globalStore';

import closeIcon from '../../../assets/images/ic_close.png';

function MateBirthInput() {
  const toast = useToast();
  const navigator = useNavigate();
  const { mate, setMate } = useGlobalStore();
  const [birth, setBirth] = useState<string>('');

  useEffect(() => {
    setBirth(mate!.birth);
  }, []);

  async function next() {
    if(birth.length < 10) {
      toast('error', 'Incorrect information. Try again.');
      return;
    }
    
    setMate({...mate!, birth: birth});
    navigator('/mate/emailInput?type=mate');
  }

  function updateBirth(text: string) {
    let input = text;

    // 숫자만 필터링
    input = input.replace(/[^0-9.]/g, '');

    // .이 입력 중간에 있으면 그 자리에 맞게 처리
    if (input.length >= 5 && input[4] !== '.') {
      input = `${input.slice(0, 4)}.${input.slice(4)}`;
    }
    if (input.length >= 8 && input[7] !== '.') {
      input = `${input.slice(0, 7)}.${input.slice(7)}`;
    }

    // 삭제할 때 .도 지워질 수 있게 설정
    if (input.length === 7) {
      input = input.slice(0, 7); // 마지막 마침표 포함 7자까지만 유지
    }
    if (input.length === 4) {
      input = input.slice(0, 4); // 첫 마침표 포함 4자까지만 유지
    }
    setBirth(input);
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigator(-1)}/>
      <div className={styles['title-text']}>Age Verification for Mates</div>
      <div className={styles['description-text']}>
        {'Mates must be 19+. Please enter your birth date.\r\nAccurate information is required.\r\nIncorrect details may affect your mate status.'}
      </div>
      <div style={{marginTop: '5.6rem'}}/>
      <div className={styles['input-field-container']}>
        <TextField 
          name='birth'
          type='text' 
          maxLength={10}
          value={birth} 
          enable={true}
          placeholder='Enter your date of birth.(YYYY.MM.DD)'
          onChange={(event) => updateBirth(event.target.value)}
        />
      </div>
      <div style={{marginTop: '8.8rem'}}/>
      <Button marginHorizontalRem={2.0} active={true} onClick={() => next()}>Next</Button>
    </div>
    );
  }
  
  export default MateBirthInput;
    