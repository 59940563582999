import { UserWithdrawal } from '../models/userWithdrawal';
import { UserWithdrawalMethod } from '../models/userWithdrawalMethod';
import { post, get, destroy } from '../utils/apiClient';

class SettlementService {
  public async incomeList(page: number, count: number, startDate: any, endDate: any) {
    try {
      const response = await get(
        `/settlement/incomeList?page=${page}&count=${count}&startDate=${startDate}&endDate=${endDate}`
      );

      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getIncomeData(id: number) {
    try {
      const response = await get(`/settlement/getIncomeData?id=${id}`);

      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async withdrawalList(page: number, count: number, startDate: any, endDate: any) {
    try {
      const response = await get(
        `/settlement/withdrawalList?page=${page}&count=${count}&startDate=${startDate}&endDate=${endDate}`
      );

      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getWithdrawalData(id: number) {
    try {
      const response = await get(`/settlement/getWithdrawalData?id=${id}`);

      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async withdrawalMethodList() {
    try {
      const response = await get(`/settlement/withdrawalMethodList`);

      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async availableAmount() {
    try {
      const response = await get(`/settlement/availableAmount`);

      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async deleteWithdrawalMethod(id: number) {
    try {
      const response = await destroy(`/settlement/deleteWithdrawalMethod`, {
        data: { id },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async addWithdrawalMethod(userWithdrawalMethod: UserWithdrawalMethod) {
    try {
      const response = await post('/settlement/addWithdrawalMethod', {
        userWithdrawalMethod,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async requestWithdrawal(userWithdrawal: UserWithdrawal) {
    try {
      const response = await post('/settlement/requestWithdrawal', {
        userWithdrawal,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
}

const settlementService = new SettlementService();
export default settlementService;
