import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import styles from './FindId.module.css';
import TextField from '../../components/element/TextField';
import SelectBox from '../../components/element/SelectBox';
import { Sheet } from 'react-modal-sheet';
import nationData from '../../assets/data/nation.json';
import closeIcon from '../../assets/images/ic_close.png';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast'; // 토스트 훅 추가
import AuthService from '../../services/authService';

// 인증 상태 정의
enum VerifyStatus {
  NONE = 'NONE',
  REQUESTED = 'REQUESTED',
  COMPLETE = 'COMPLETE',
}

function FindId() {
  const navigate = useNavigate();
  const toast = useToast(); // 토스트 훅 초기화
  const [
    isOpenInternationalNumberBottomSheet,
    setOpenInternationalNumberBottomSheet,
  ] = useState(false); // 국제번호 바텀시트.
  const [cellPhoneVerifyStatus, setCellPhoneVerifyStatus] =
    useState<VerifyStatus>(VerifyStatus.NONE); // 인증 상태 추가
  const [formData, setFormData] = useState<any>({
    email: 'user@example.com', // 예시 이메일 (실제 로직에선 API를 통해 얻어와야 함)
    emailVerifyCode: '',
    password: '',
    nickName: '',
    internationalNumber: '', // 국가번호.
    cellPhone: '',
    cellPhoneVerifyCode: '',
    agreeAge14: false,
    agreeService: false,
    agreePrivacy: false,
    agreeMarketing: false,
  });

  const inputRules: any = {
    email: {
      maxLength: 30,
      pattern:
        /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
    },
    emailVerifyCode: {
      minLength: 6,
      maxLength: 6,
    },
    password: {
      minLength: 8,
      maxLength: 20,
      pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/,
    },
    nickName: {
      maxLength: 11,
    },
    cellPhone: {
      minLength: 11,
      maxLength: 11,
    },
    cellPhoneVerifyCode: {
      minLength: 6,
      maxLength: 6,
    },
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const rule = inputRules[name];
    let formattedValue = value;

    if (rule.maxLength && value.length > rule.maxLength) {
      formattedValue = value.slice(0, rule.maxLength); // 글자 수 초과하지 않게
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  function selectInternationalNumber(code: string) {
    setFormData({
      ...formData,
      internationalNumber: '+' + code,
    });
    setOpenInternationalNumberBottomSheet(false);
  }

  const handleFindId = async () => {
    // 에러케이스 추가부분
    if (formData.cellPhone.length < inputRules.cellPhone.minLength) {
      toast('error', 'Incorrect information entered.', false);
      return;
    }

    const email: string | undefined = await AuthService.requestFindId(formData.internationalNumber, formData.cellPhone);

    if (email === undefined) {
      toast('error', 'Verification failed. Please try again later.', false);
      return;
    }else if (email === '') {
      toast('error', 'No matching account found.', false);
      return;
    }

    // 인증 상태를 COMPLETE로 설정하고 토스트 메시지 출력
    setCellPhoneVerifyStatus(VerifyStatus.COMPLETE);
    toast('success', 'Verification complete.', false);

    // 다음 페이지로 이메일 정보를 전달하면서 이동
    navigate('/auth/findIdLogin', { state: { email: email } });
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />

      <div className={styles['label-container']}>
        {'Enter your phone number\r\nto find your account'}
      </div>
      <div className={styles['label-container']}>
        <label className={styles['label']}>Phone number</label>
      </div>
      <div className={styles['input-field-container']}>
        <SelectBox
          width={'11.6rem'}
          name='internationalNumber'
          type='text'
          value={formData.internationalNumber}
          placeholder='+82'
          onAction={() => setOpenInternationalNumberBottomSheet(true)}
        />
        <div className={styles['cellphone-padding']} />
        <TextField
          name='cellPhone'
          type='text'
          value={formData.cellPhone}
          placeholder='Phone number'
          onChange={(event) => handleChange(event)}
          onClear={() => {
            setFormData({
              ...formData,
              cellPhone: '', // 상태 업데이트
            });
          }}
        />
      </div>
      <Sheet
        isOpen={isOpenInternationalNumberBottomSheet}
        detent='content-height'
        onClose={() => setOpenInternationalNumberBottomSheet(false)}
      >
        <Sheet.Container
          style={{ borderRadius: '12px 12px 0px 0px', height: '17.3rem' }}
        >
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenInternationalNumberBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {nationData.filter((e) => e.use === true).map((e) => (
                <div
                  className={styles['sheet-terms-content']}
                  onClick={() => selectInternationalNumber(e.code)}
                  key={e.code}
                >
                  <div>{e.text}</div>
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenInternationalNumberBottomSheet(false)}
        />
      </Sheet>
      <Button
        marginHorizontalRem={2.0}
        active={formData.cellPhone.length >= inputRules.cellPhone.minLength}
        onClick={handleFindId} // 아이디 찾기 요청 함수 호출
      >
        Find My Account
      </Button>
    </div>
  );
}

export default FindId;
