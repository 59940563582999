import { Mate } from '../models/mate';
import { get, post, put } from '../utils/apiClient';

class MeetingService {
  public async cancelMeeting(meetingId: number) {
    try {
      const response = await post('/meeting/cancelMeeting', {
        meetingId,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async acceptMeeting(meetingId: number, meetingDate: Date | string | null) {
    try {
      const response = await put('/meeting/acceptMeeting', {
        meetingId,
        meetingDate,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async deniedMeeting(
    meetingId: number,
    deniedType: string,
    schedule1: string,
    schedule2: string,
    schedule3: string
  ) {
    try {
      const response = await put('/meeting/deniedMeeting', {
        meetingId,
        deniedType,
        schedule1,
        schedule2,
        schedule3,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async completeMeeting(meetingId: number) {
    try {
      const response = await post('/meeting/completeMeeting', {
        meetingId,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async confirmPurchase(meetingId: number) {
    try {
      const response = await post('/meeting/confirmPurchase', {
        meetingId,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getMeetingCount() {
    try {
      const response = await get(`/meeting/getMeetingCount`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getMeetingById(meetingId: number) {
    try {
      const response = await get(`/meeting/getMeetingById?meetingId=${meetingId}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getMeeting() {
    try {
      const response = await get(`/meeting/getMeeting`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getSuggest() {
    try {
      const response = await get(`/meeting/getSuggest`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getMeetingByMate(mateUserId: number) {
    try {
      const response = await get(`/meeting/getMeetingByMate?mateUserId=${mateUserId}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
}

const meetingService = new MeetingService();
export default meetingService;
