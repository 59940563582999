import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AddComplete.module.css';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TextField from '../../components/element/TextField';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';

import productAddCompleteIcon from '../../assets/images/ic_product_add_complete.png';

function ProductAddComplete() {
  const navigate = useNavigate();
  
  function goNotificationPage() {
    navigate('/setting/notification');
  }

  function goManagePage() {
    navigate('/product/manage');
  }
  
  return (
    <div className={styles['conatiner']}>
      <Header 
        useBackButton={true}
        onBack={() => navigate(-1)}
      />
      <div className={styles['image']}><img src={productAddCompleteIcon} alt=""/></div>
      <div className={styles['title-text']}>
        {'Your item is listed'}
      </div>
      <div className={styles['title-sub-text']}>
        {"Once the item is approved, it will be officially live.\r\nYou'll receive a notification as soon as\r\nthe review is complete."}
      </div>
      <div className={styles['bottom-text']}>Add more products</div>
      <div className={styles['bottom-text-button']} onClick={() => goManagePage()}>Add</div>
      <div className={styles['bottom-text']}>Stay updated on your review result!</div>
      <div className={styles['bottom-text-button']} onClick={() => goNotificationPage()}>Turn on notifications</div>
      <div className={styles['button-container']}>
        <Button 
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem' 
          activeFontWeight={600}
          active={true}
          onClick={() => goManagePage()}>OK</Button>
      </div>
    </div>
    );
  }
  
  export default ProductAddComplete;
  