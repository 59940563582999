import { useEffect, useRef, useState } from 'react';
import Header from '../../components/layout/Header';
import styles from './ListByJob.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import categoryAllIcon from '../../assets/images/ic_category_all.png';
import jobENData from '../../assets/data/job_en.json';
import MateService from '../../services/mateService';
import { Mate } from '../../models/mate';
import UserService from '../../services/userService';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import bookmarkOnIcon from '../../assets/images/ic_bookmark_on.png';
import bookmarkOffIcon from '../../assets/images/ic_bookmark_off.png';
import { Sheet } from 'react-modal-sheet';
import closeIcon from '../../assets/images/ic_close.png';

const ListByJob = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category } = location.state || {}; // state에서 type과 search 값을 받아
  const [selectedTitleJob, setSelectedTitleJob] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const profileListContainerRef = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState<number>(1);
  const [isLastPage, setLastPage] = useState<boolean>(false);
  const [mateProfileList, setMateProfileList] = useState<Mate[]>([]);
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);

  console.log(mateProfileList);
  useEffect(() => {
    if (category) {
      setSelectedCategory(category);
      setSelectedTitleJob(jobENData[0].items[0]);
      loadMateProfileList(page, category, jobENData[0].items[0]); // job 추가
    } else {
      setSelectedTitleJob(jobENData[0].items[0]);
      loadMateProfileList(page, selectedCategory, jobENData[0].items[0]); // job 추가
    }
  }, [category]);

  // function selectJob(job: string) {
  //   setPage(1);
  //   loadMateProfileList(1, selectedCategory, job); // category에 기본값 추가
  //   setSelectedTitleJob(job);
  //   // setIsOpenBottomSheet(false);
  // }

  function handleCategoryClick(category: string, job: string) {
    setSelectedTitleJob(job);
    setSelectedCategory(category);
    loadMateProfileList(1, category, selectedTitleJob);
    // setIsOpenBottomSheet(true);
  }
  function openAllCategory() {
    // setSelectedTitleJob(job);
    // setSelectedCategory(category);
    // loadMateProfileList(1, category, selectedTitleJob);
    setIsOpenBottomSheet(true);
  }

  function handleProfileListScroll() {
    if (profileListContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = profileListContainerRef.current;
      const scrollMax = scrollWidth - clientWidth;

      if (scrollLeft >= scrollMax * 0.8) {
        if (isLastPage == false) {
          loadMateProfileList(page + 1, selectedCategory, selectedTitleJob);
        } else {
        }
      }
    }
  }
  console.log();

  async function loadMateProfileList(page: number, category: string, job: string) {
    const list = await MateService.getListByJob(
      page,
      20,
      category === '' ? '' : category,
      job === '' ? '' : job
    );
    if (page == 1) {
      setMateProfileList(list);
      if (profileListContainerRef.current) {
        profileListContainerRef.current.scrollLeft = 0;
      }
      setPage(1);
      setLastPage(false);
    } else {
      if (list.length > 0) {
        setMateProfileList([...mateProfileList, ...list]);
        setPage(page);
      } else {
        setLastPage(true);
      }
    }
  }

  function getProfileImageFile(mate: Mate) {
    if (mate.profile_image !== '') {
      //메이트 프로필 이미지가 존재하는 경우.
      return mate.profile_image
        ? `${process.env.REACT_APP_STORE_ADDRESS}/${mate.profile_image}`
        : profileDefaultIcon;
    } else if (mate.user?.profile_image !== '') {
      //유저 프로필 이미지가 존재하는 경우.
      return mate.user?.profile_image
        ? `${process.env.REACT_APP_STORE_ADDRESS}/${mate.user?.profile_image}`
        : profileDefaultIcon;
    }

    return undefined;
  }

  function goMateProfilePage(id: number) {
    navigate(`/mate/detail/${id}`);
  }

  async function toggleBookmark(mate: Mate) {
    if (mate.favorite === true) {
      await UserService.deleteMateFavorite(mate.id!);
      mate.favorite = false;
    } else {
      await UserService.addMateFavorite(mate.id!);
      mate.favorite = true;
    }
    const newMateProfileList = [...mateProfileList];
    newMateProfileList.find((item) => item.id === mate.id)!.favorite = mate.favorite;
    setMateProfileList(newMateProfileList);
  }

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={true}
        title='Meetings by Field'
        onBack={() => navigate(-1)}
      />
      <div className={styles['category-bar']}>
        <img
          src={categoryAllIcon}
          alt=''
          onClick={() => openAllCategory()}
        />
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('', '')}
        >
          <div className={selectedCategory === '' ? styles['category-item-active'] : ''}>
            {'' === '' && 'All'}
            <div className={selectedCategory === '' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Development', '')}
        >
          <div className={selectedCategory === 'Development' ? styles['category-item-active'] : ''}>
            {'Development'}
            <div className={selectedCategory === 'Development' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Medical/Bio', '')}
        >
          <div className={selectedCategory === 'Medical/Bio' ? styles['category-item-active'] : ''}>
            {'Medical/Bio'}
            <div className={selectedCategory === 'Medical/Bio' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Design', '')}
        >
          <div className={selectedCategory === 'Design' ? styles['category-item-active'] : ''}>
            {'Design'}
            <div className={selectedCategory === 'Design' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Finance/VC', '')}
        >
          <div className={selectedCategory === 'Finance/VC' ? styles['category-item-active'] : ''}>
            {'Finance/VC'}
            <div className={selectedCategory === 'Finance/VC' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Data/AI', '')}
        >
          <div className={selectedCategory === 'Data/AI' ? styles['category-item-active'] : ''}>
            {'Data/AI'}
            <div className={selectedCategory === 'Data/AI' ? styles['category-active'] : ''} />
          </div>
        </div>
        {/* {jobENData.map((category) => (
          <div
            key={category.category}
            className={`  ${styles['category-item']}`}
            // onClick={() => selectJob(category.category)}
            onClick={() => handleCategoryClick(category.category, category.items)}
          >
            <div
              className={
                selectedCategory === category.category ? styles['category-item-active'] : ''
              }
            >
              {(category.category === 'None' && 'All') || category.category}
              <div
                className={selectedCategory === category.category ? styles['category-active'] : ''}
              />
            </div>
          </div>
        ))} */}
      </div>
      <div
        className={styles['profile-list-container']}
        ref={profileListContainerRef}
        onScroll={handleProfileListScroll}
      >
        {mateProfileList.map((e) => (
          <div
            className={styles['profile-list-item']}
            onClick={(ev) => {
              ev.stopPropagation();
              goMateProfilePage(e.id!);
            }}
          >
            <div className={styles['profile-item-thumbnail']}>
              <img
                src={getProfileImageFile(e) ?? profileDefaultIcon}
                alt=''
              />
            </div>
            <div className={styles['profile-item-container']}>
              <div className={styles['flex']}>
                <div className={styles['profile-item-tag']}>{e.user?.annual}years</div>
                <div className={styles['profile-item-tag']}>{e.user?.job}</div>

                <div className={styles['profile-item-tag']}>{e.user?.country_name}</div>
              </div>

              <div className={styles['profile-item-nickname']}>{e.user?.nickname}</div>
              <div className={styles['profile-item-company']}>{e.user?.company}</div>
              <div className={styles['profile-item-message']}>{e.user?.introduce_message}</div>
            </div>
            <div className={styles['profile-item-bookmark']}>
              <img
                src={e.favorite === true ? bookmarkOnIcon : bookmarkOffIcon}
                alt=''
                onClick={(ev) => {
                  ev.stopPropagation();
                  toggleBookmark(e);
                }}
              />
            </div>
            {/* <div className={styles['profile-item-image-group']}>
              <div className={styles['profile-item-thumbnail']}>
                <img
                  src={getProfileImageFile(e) ?? profileDefaultIcon}
                  alt=''
                />
              </div>
              <div className={styles['profile-item-bookmark']}>
                <img
                  src={e.favorite === true ? bookmarkOnIcon : bookmarkOffIcon}
                  alt=''
                  onClick={(ev) => {
                    ev.stopPropagation();
                    toggleBookmark(e);
                  }}
                />
              </div>
            </div>
            <div className={styles['profile-item-tag']}>
              {e.user?.annual}years
            </div>
            <div className={styles['profile-item-tag']}>{e.user?.job}</div>
            <div className={styles['profile-item-nickname']}>
              {e.user?.nickname}
            </div>
            <div className={styles['profile-item-company']}>
              {e.user?.company}
            </div> */}
          </div>
        ))}
      </div>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setIsOpenBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.6rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              {/* <button onClick={() => setIsOpenBottomSheet(false)}>Close</button>
               */}
              <div className={styles['sheet-terms-title']}>Select Job</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setIsOpenBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content-container']}>
                {jobENData.map((category) => (
                  <div
                    key={category.category}
                    className={`${styles['sheet-terms-content']} ${
                      selectedCategory === category.category ? styles['active'] : ''
                    }`}
                    onClick={() =>
                      handleCategoryClick(category.category === 'None' ? '' : category.category, '')
                    }
                  >
                    {category.category === 'None' ? 'All' : category.category}
                  </div>
                ))}
                {/* {selectedItems.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles['sheet-terms-content']} ${
                      selectedTitleJob === item ? styles['active'] : ''
                    }`}
                    onClick={() => selectJob(item)}
                  >
                    <div className={styles['circle']}></div>

                    <span>{item || 'All'}</span>
                  </div>
                ))} */}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setIsOpenBottomSheet(false)} />
      </Sheet>
    </div>
  );
};

export default ListByJob;
