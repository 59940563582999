import React from 'react';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import styles from './MeetingConfirmComplete.module.css';
import ic_confirm from '../../assets/images/ic_confirm.png';
import Button from '../../components/element/Button';

function MeetingConfirmComplete() {
  const navigate = useNavigate();
  const location = useLocation();
  const suggestData = location.state; // 전달된 값이 없으면 기본값으로 빈 문자열 설정
  const toMeetingList = () => {
    navigate('/meeting/myMeeting');
  };
  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title-text']}>{'Meeting Confirmed'}</div>
      <div className={styles['sub-text']}>
        {`${
          suggestData.product.type === 'COFFEECHAT'
            ? 'Coffee Chat'
            : suggestData.product.type === 'INTERVIEW'
            ? 'Interview Practice'
            : suggestData.product.type === 'REVIEW'
            ? 'Review Meeting'
            : '찾을 수 없음'
        } You and the requester will receive email shortly\r\nSee you at the scheduled time!`}
      </div>
      <div className={styles['image']}>
        <img
          src={ic_confirm}
          alt=''
        />
      </div>
      <div className={styles['information-container']}>
        <div className={styles['information-text']}>
          {`Your Meeting Time`}
          <div className={styles['information-sub-text']}>
            {dayjs(suggestData.meeting_date).format('MMM D, YYYY (ddd) hh:mm A - ')}
            {dayjs(suggestData.meeting_date).add(suggestData.product.time, 'minute').format('hh:mm A')}
          </div>
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          marginHorizontalRem={2}
          active={true}
          onClick={toMeetingList}
        >
          View My Meeting
        </Button>
      </div>
    </div>
  );
}

export default MeetingConfirmComplete;
