import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import style from './CommentsFields.module.css';
import MdSendIcon from '../../../../assets/images/ic_send_message.png';

function CommentsFields() {
  return (
    <div className={style.commentContainer}>
      <div className={style.commentFieldContainer}>
        <TextareaAutosize
          placeholder="Enter your comment"
          className={style.commentInput}
          minRows={1}
          maxRows={5} // 필요 시 최대 높이 설정
        />
      </div>
      <button className={style.sendButton}>
        <img src={MdSendIcon} alt="Send" className={style.sendIcon} />
      </button>
    </div>
  );
}

export default CommentsFields;
