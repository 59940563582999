import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useGlobalStore from '../../stores/globalStore';
import styles from './Setting.module.css';
import { MateVerifyStatus } from '../../constants/constants';
import Footer from '../../components/layout/Footer';
import Popup from '../../components/popup/Popup';
import AuthService from '../../services/authService';
import useToast from '../../hook/useToast';
import celebrateIcon from '../../assets/images/ic_celebrate.png';
import personalInfoIcon from '../../assets/images/setting/personal_info.png';
import alarmSettingIcon from '../../assets/images/setting/alarm_setting.png';
import faqIcon from '../../assets/images/setting/FAQ.png';
import noticeIcon from '../../assets/images/setting/notice.png';
import leaveIcon from '../../assets/images/setting/leave.png';
import logoutIcon from '../../assets/images/setting/logout.png';
import appIcon from '../../assets/images/setting/app_version.png';

function Setting() {
  const navigate = useNavigate();
  const toast = useToast();
  const { resetStore } = useGlobalStore();

  const toFAQ = () => {
    navigate('/cs/customerService');
  };
  const goTermsOfServier = () => {
    navigate('/setting/termsOfService');
  };
  const goPersonalInfo = () => {
    navigate('/setting/personalInfo');
  };
  const goNotification = () => {
    navigate('/setting/notification');
  };
  const [showPopupType, setShowPopupType] = useState<string>('');

  useEffect(() => {}, []);

  const showLeaveConfirm = () => {
    setShowPopupType('leave');
  };

  const showLogoutConfirm = () => {
    setShowPopupType('logout');
  };

  const leave = async () => {
    const result = await AuthService.leave();
    if(result) {
      localStorage.removeItem('authToken');
      resetStore();
      setShowPopupType('');
  
      navigate('/');
    }else{
      toast('error', 'An error occurred during leave.\r\nPlease try again.', false);
    }
  }

  const logout = () => {
    localStorage.removeItem('authToken');
    resetStore();
    setShowPopupType('');

    navigate('/');
    //navigate('/auth/signIn');
  }

  return (
    <div>
      <div className={styles['profile-container']}>
        <div className={styles['welcome-text']}>Settings</div>
      </div>

      <div
        className={styles['card-menu-container']}
        style={{ marginTop: '1.6rem' }}
        onClick={goPersonalInfo}
      >
        <img src={personalInfoIcon} alt='' />
        <div>Personal Information</div>
      </div>

      <div className={styles['card-menu-container']} onClick={goNotification}>
        <img src={alarmSettingIcon} alt='' />
        <div>Notification Settings</div>
      </div>

      <div className={styles['card-menu-container']} onClick={toFAQ}>
        <img src={faqIcon} alt='' />
        <div>Customer Support</div>
      </div>

      <div className={styles['card-menu-container']} onClick={goTermsOfServier}>
        <img src={noticeIcon} alt='' />
        <div>Terms of Service</div>
      </div>

      <div className={styles['divider']} />

      <div className={styles['card-menu-container']} onClick={showLogoutConfirm}>
        <img src={logoutIcon} alt='' />
        <div>Sign Out</div>
      </div>

      <div className={styles['card-menu-container']} onClick={showLeaveConfirm}>
        <img src={leaveIcon} alt='' />
        <div>Delete My Account</div>
      </div>

      <div className={styles['card-menu-container']}>
        <img src={appIcon} alt='' />
        <div>App Version 1.0.0</div>
      </div>
      <Footer />
      {showPopupType == 'logout' && <Popup
        show={true}
        width={'27.4rem'}
        height={'14.8rem'}
      >
        <div className={styles['popup-container']}>
          <div className={styles['popup-content']}>
            {'Are you sure\r\nyou want to sign out?'}
          </div>
          <div className={styles['popup-button']}>
            <div className={styles['btn']} onClick={() => logout()}>Yes</div>
            <div className={styles['btn']} onClick={() => setShowPopupType('')}>No</div>
          </div>
        </div>
      </Popup>}
      {showPopupType == 'leave' && <Popup
        show={true}
        width={'27.4rem'}
        height={'14.8rem'}
      >
        <div className={styles['popup-container']}>
          <div className={styles['popup-content']}>
            {'Are you sure\r\nyou want to leave?'}
          </div>
          <div className={styles['popup-button']}>
            <div className={styles['btn']} onClick={() => leave()}>Yes</div>
            <div className={styles['btn']} onClick={() => setShowPopupType('')}>No</div>
          </div>
        </div>
      </Popup>}
    </div>
  );
}

export default Setting;
