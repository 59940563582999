import React, { useState } from "react";
import styles from './ComboBoxButton.module.css';

import comboArrowDownIcon from '../../assets/images/ic_combo_arrow_down.png';

type ComboBoxButtonType = 'combobox' | 'button';

interface ComboBoxButtonProps {
  marginLeft?: string;
  marginRight?: string;
  paddingLeft?: string;
  paddingRight?: string;
  type: ComboBoxButtonType;
  text?: string;
  actionButtonTitle?: string;
  onAction?: () => void;
}

const ComboBoxButton: React.FC<ComboBoxButtonProps> = ({ 
  marginLeft,
  marginRight,
  paddingLeft,
  paddingRight,
  type = 'combobox',
  text,
  actionButtonTitle,
  onAction
}) => {
  return (
    <div className={`${styles['container']}`} style={{marginLeft: marginLeft, marginRight: marginRight, paddingLeft: paddingLeft, paddingRight: paddingRight}}>
      {text != undefined ? text : ''}
      {type == 'combobox' ? 
        <div className={styles['combobox-icon']} onClick={onAction}><img src={comboArrowDownIcon} alt=""/></div> : 
        <div className={styles['button-container']} onClick={onAction}>{actionButtonTitle}</div>
      }
    </div>
  );
};

export default ComboBoxButton;