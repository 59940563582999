import React from 'react';
import styles from './Tabs.module.css';

interface TabsProps {
  title: string;
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const Tabs: React.FC<TabsProps> = ({ title, text, icon, onClick }) => {
  return (
    <div className={styles.tabsItem} onClick={onClick}>
      <span className={styles.tabsTitle}>{title}</span>
      <span className={styles.tabsText}>{text}</span>
      <span>
      <button className={styles.iconButton}>
        {icon || <span className={styles.chevronIcon}>›</span>}
      </button>
      </span>
    </div>
  );
};

export default Tabs;
