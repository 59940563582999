export const BASE_WIDTH = 375;
export const BASE_HEIGHT = 667;

export enum ProductType {
  COFFEECHAT = 'COFFEECHAT',
  INTERVIEW = 'INTERVIEW',
  REVIEW = 'REVIEW',
}

export enum ProductStatus {
  NONE = 'NONE', //상품 미등록.
  IN_REVIEW = 'IN_REVIEW', //심사중.
  ACTIVE = 'ACTIVE', //판매중.
  INACTIVE = 'INACTIVE', //판매중단.
  SUSPENSION = 'SUSPENSION', //판매정지.
  REJECT = 'REJECT', //등록실패.
}

export enum ProductRequestStatus {}

export enum MateVerifyStatus {
  TEMP = 'TEMP',
  WAIT = 'WAIT',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

export enum VerifyStatus {
  NONE, //미인증 상태
  WAIT, //인증 대기 (인증번호 받기 기다리는중)
  COMPLETE, //인증 완료
}

export enum NotificationTypes { //알림 방식.
  EMAIL = 'email',
  SMS = 'sms',
}

export enum MeetingStatus { //
  WAITING = 'WAITING', //대기
  DETERMINE = 'DETERMINE', //확정
  COMPLETE = 'COMPLETE', //완료
  CANCELLED = 'CANCELLED', //취소
}

export enum MateStatus {
  WAITING = 'WAITING', //대기
  ACCEPTED = 'ACCEPTED', //수락
  DENIED = 'DENIED', //거부
}

export enum UserIncomeStatus {
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export enum WithDrawalMethod {
  PAYPAL = 'PAYPAL',
  LOCAL_TRANSFER = 'LOCAL_TRANSFER',
  SWIFT = 'SWIFT',
}

export enum UserWithdrawalStatus {
  NONE = 'NONE',
  APPROVING = 'APPROVING',
  COMPLETE = 'COMPLETE',
  DENIED = 'DENIED',
}
export enum UserWithdrawalMethodStatus {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  AVAILABLE = 'AVAILABLE',
}
export enum ReportType {
  USER = 'USER',
  MATE = 'MATE',
  COMMUNITY = 'COMMUNITY',
  COMMUNITY_REPLY = 'COMMUNITY_REPLY',
}

export enum CommunityType {
  NOTICE = 'NOTICE',
  GENERAL = 'GENERAL',
}

export enum CouponProductType {
  NONE = 'NONE',
  COFFEECHAT = 'COFFEECHAT',
  INTERVIEW = 'INTERVIEW',
  REVIEW = 'REVIEW',
}
