import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import selectedIcon from '../../assets/images/ic_check_default.png';
import styles from './CalculationRequestAdd.module.css';
import infoIcon from '../../assets/images/ic_info.png';
import useToast from '../../hook/useToast';
import { UserWithdrawalMethod } from '../../models/userWithdrawalMethod';
import settlementData from '../../assets/data/settlement.json';
import useGlobalStore from '../../stores/globalStore';
import { WithDrawalMethod } from '../../constants/constants';
import { off } from 'process';
import settlementService from '../../services/settlementService';

function CalculationRequestAdd() {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedType, setSelectedType] = useState<WithDrawalMethod | null>(null);
  const { methodData } = location.state || {}; // 전달된 selectedNation 가져오기
  const { tempUserWithdrawalMethod, setTempUserWithdrawalMethod } = useGlobalStore();
  const [methodList, setMethodList] = useState<UserWithdrawalMethod[]>([]);

  useEffect(() => {
    // 이미 등록된 메서드 로드
    loadMethodList();
  }, []);

  const loadMethodList = async () => {
    try {
      const withdrawData = await settlementService.withdrawalMethodList();
      if (withdrawData) {
        setMethodList(withdrawData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    console.log('Selected ID:', id);
    // ID를 기반으로 데이터 로드
  }, [id]);

  const toAddEmail = () => {
    if (!id && selectedType === WithDrawalMethod.PAYPAL) {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        user_id: methodData.user_id,
        country_code: methodData.country_code,
        nationality: methodData.nationality,
        withdrawal_method: selectedType,
        created_date: new Date(),
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });

      navigate('/calculation/request/paypal/add', {
        state: { methodData: tempUserWithdrawalMethod, selectedSettlement },
      });
    }
    // 수정 아이디 네비게이트
    else if (id && selectedType === WithDrawalMethod.PAYPAL) {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        user_id: methodData.user_id,
        country_code: methodData.country_code,
        nationality: methodData.nationality,
        withdrawal_method: selectedType,
        created_date: new Date(),
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });
      navigate(`/calculation/request/paypal/${id}`, {
        state: { methodData: tempUserWithdrawalMethod, selectedSettlement },
      });
    } else if (selectedType === null) {
      toast('error', 'Please select a Transfer method', false);
    }
    // 수정 아이디 네비게이트
    else if (id) {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        user_id: methodData.user_id,
        country_code: methodData.country_code,
        nationality: methodData.nationality,
        withdrawal_method: selectedType,
        created_date: new Date(),
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });
      navigate(`/calculation/request/information/${id}`, {
        state: { methodData: tempUserWithdrawalMethod, selectedSettlement },
      });
    } else {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        user_id: methodData.user_id,
        country_code: methodData.country_code,
        nationality: methodData.nationality,
        withdrawal_method: selectedType,
        created_date: new Date(),
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });
      navigate('/calculation/request/information/add', {
        state: { methodData: tempUserWithdrawalMethod, selectedSettlement },
      });
    }
  };

  const selectedSettlement = settlementData.find((data) => data.abb === methodData.country_code);

  const handleSelect = (type: WithDrawalMethod) => {
    // ID가 있을 경우 중복 검사 건너뛰기
    if (id) {
      setSelectedType(type);
      return;
    }

    // 중복된 메서드 확인 (id가 없을 때만 실행)
    if (methodList.some((method) => method.withdrawal_method === type)) {
      toast('error', 'This method is already registered.', false);
      return;
    }

    setSelectedType(type);
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'Transfer Method'}</div>
      <div className={styles['text']}>{'The fees vary depending on the method'}</div>
      {/* <div className={styles['selected-nation']}>
        {'선택된 국가,코드: '}
        <h1>
          {selectedNation ? `${selectedNation.nation},${selectedNation.abb}` : 'Republic of Korea'}
        </h1>
      </div> */}
      <div className={styles['type-container']}>
        {selectedSettlement?.template === 'A' ? (
          <>
            <div
              className={styles['type-item']}
              onClick={() => handleSelect(WithDrawalMethod.PAYPAL)}
            >
              <span>PayPal</span>
              {selectedType === WithDrawalMethod.PAYPAL && (
                <img
                  src={selectedIcon}
                  alt='Selected Icon'
                  className={styles['selected-icon']}
                />
              )}
            </div>
            {methodData.country_code === 'US' ? (
              <div
                className={styles['type-item']}
                onClick={() => handleSelect(WithDrawalMethod.LOCAL_TRANSFER)}
              >
                <span>ACH</span>
                {selectedType === WithDrawalMethod.LOCAL_TRANSFER && (
                  <img
                    src={selectedIcon}
                    alt='Selected Icon'
                    className={styles['selected-icon']}
                  />
                )}
              </div>
            ) : methodData.country_code === 'GB' ? (
              <div>
                {' '}
                <div
                  className={styles['type-item']}
                  onClick={() => handleSelect(WithDrawalMethod.LOCAL_TRANSFER)}
                >
                  <span>Faster Payments</span>

                  {selectedType === WithDrawalMethod.LOCAL_TRANSFER && (
                    <img
                      src={selectedIcon}
                      alt='Selected Icon'
                      className={styles['selected-icon']}
                    />
                  )}
                </div>
              </div>
            ) : methodData.country_code === 'CA' ? (
              <div>
                {' '}
                <div
                  className={styles['type-item']}
                  onClick={() => handleSelect(WithDrawalMethod.LOCAL_TRANSFER)}
                >
                  <span>EFT</span>

                  {selectedType === WithDrawalMethod.LOCAL_TRANSFER && (
                    <img
                      src={selectedIcon}
                      alt='Selected Icon'
                      className={styles['selected-icon']}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div>
                {' '}
                <div
                  className={styles['type-item']}
                  onClick={() => handleSelect(WithDrawalMethod.LOCAL_TRANSFER)}
                >
                  <span>Local Bank Transfer</span>

                  {selectedType === WithDrawalMethod.LOCAL_TRANSFER && (
                    <img
                      src={selectedIcon}
                      alt='Selected Icon'
                      className={styles['selected-icon']}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        ) : selectedSettlement?.template === 'B' ? (
          <>
            <div
              className={styles['type-item']}
              onClick={() => handleSelect(WithDrawalMethod.PAYPAL)}
            >
              <span>PayPal</span>
              {selectedType === WithDrawalMethod.PAYPAL && (
                <img
                  src={selectedIcon}
                  alt='Selected Icon'
                  className={styles['selected-icon']}
                />
              )}
            </div>
            <div
              className={styles['type-item']}
              onClick={() => handleSelect(WithDrawalMethod.SWIFT)}
            >
              <span>SWIFT Transfer</span>
              {selectedType === WithDrawalMethod.SWIFT && (
                <img
                  src={selectedIcon}
                  alt='Selected Icon'
                  className={styles['selected-icon']}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className={styles['type-item']}
              onClick={() => handleSelect(WithDrawalMethod.PAYPAL)}
            >
              <span>PayPal</span>
              {selectedType === WithDrawalMethod.PAYPAL && (
                <img
                  src={selectedIcon}
                  alt='Selected Icon'
                  className={styles['selected-icon']}
                />
              )}
            </div>
          </>
        )}
      </div>

      <div className={styles['button-container']}>
        <div className={styles['info-container']}>
          <div className={styles['info-item']}>
            <img
              src={infoIcon}
              alt=''
            />
          </div>
          {selectedSettlement?.template === 'B' ? (
            <>
              {`PayPal transfer fees vary based on currency and amount.\r\nSWIFT Transfer fees vary based on the amount.\r\nNailedit will deduct the total fee from the requested\r\nwithdrawalamount before transferring the funds`}
            </>
          ) : (
            <>
              {`Transfer Fee: Free (Local Bank Transfer)\r\nSome country impose tax rate.\r\nPayPal transfer fees vary based on currency and amount.\r\nSWIFT Transfer fees vary based on the bank and amount.`}
            </>
          )}
        </div>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={selectedType !== null}
          onClick={toAddEmail}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default CalculationRequestAdd;
