const colors = {
  white0: '#FFFFFF',
  white50: '#F5F6F7',
  white100: '#F1F2F5',
  white200: '#EBEDF0',
  white300: '#D8DCE0',
  white400: '#CFD3D7',
  white500: '#BEC1C6',
  white600: '#AAACB6',
  white700: '#9B9DA9',
  white800: '#8E8F9B',
  black200: '#888B93',
  black300: '#80838E',
  black400: '#757781',
  black500: '#57595E',
  black600: '#46474A',
  black700: '#3D3F43',
  black800: '#343437',
  black900: '#1D1D1E',
  black1000: '#131314',
  blue100: '#A6B9FB',
  blue400: '#4E76FF',
  blue500: '#416BFF',
  blue600: '#1B69FF',
  grey100: '#CED3E3',
  grey200: '#C0C5D6',
  green50: '#F0FFF2',
  green70: '#DEFFDC',
  green100: '#88FFB0',
  green150: '#48FF91',
  green200: '#00E786',
  green300: '#00DB7F',
  green400: '#00D079',
  green500: '#00C471',
  green600: '#00B76A',
  green700: '#00A560',
  green800: '#009B5A',
  green900: '#009255',
  red50: '#FFF4F1',
  red70: '#FFB4AA',
  red100: '#FF6C55',
  red200: '#FF6048',
  red300: '#FF4A2E',
  red400: '#FF3232',
  red500: '#FF0707',
  red600: '#F00715',
  red700: '#E00000',
  white_transparent_12: '#FFFFFF1F',
  black_transparent_6: '#0000000F',
  black_transparent_12: '#0000001F',
  black_transparent_24: '#0000003E',
  black_transparent_32: '#00000052',
  black_transparent_40: '#00000067',
  nailet_green600: '#00B76A'
}

export const setCSSVariables = () => {
    const root = document.documentElement;
    
    Object.keys(colors).forEach(key => {
      root.style.setProperty(`--color-${key}`, colors[key as keyof typeof colors]);
    });
  };
  
  export default colors;