import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../../components/layout/Header';
import styles from './PaymnetCompleted.module.css';
import completed from '../../../assets/images/ic_paymentCompleted.png';
import MateService from '../../../services/mateService';
import useGlobalStore from '../../../stores/globalStore';
import Button from '../../../components/element/Button';

function PaymentCompleted() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { tempMeetingRequestData, setTempMeetingRequestData } = useGlobalStore();

  useEffect(() => {
    const intentId: string | null = searchParams.get('id');
    requestMeeting(intentId ?? '');
  }, []);

  async function requestMeeting(intentId: string) {
    if (tempMeetingRequestData !== null) {
      await MateService.requestMeeting(
        tempMeetingRequestData.mate_user_id,
        tempMeetingRequestData.product_id,
        tempMeetingRequestData.schedule1,
        tempMeetingRequestData.schedule2,
        tempMeetingRequestData.schedule3,
        tempMeetingRequestData.selected_subjects,
        tempMeetingRequestData.user_written_subject,
        tempMeetingRequestData.file_url,
        tempMeetingRequestData.link_url,
        tempMeetingRequestData.use_coupon_id,
        intentId
      );
      setTempMeetingRequestData(null);
    }
  }

  const toMyMeeting = () => {
    navigate('/meeting/myMeeting');
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'Meeting successfully scheduled'}</div>
      <div className={styles['sub-title']}>
        {'We will notify you once the\r\nmate confirms the meeting date.'}
      </div>
      <div className={styles['completed-container']}>
        <img
          src={completed}
          alt=''
        />
        <div className={styles['sub-title']}>{'It may take up to 48 hours for a response'}</div>
        <div className={styles['button']}>
          <Button
            marginHorizontalRem={2}
            active={true}
            onClick={toMyMeeting}
          >
            View My Meeting
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PaymentCompleted;
