import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MeetingConfirmDetail.module.css';
import Header from '../../components/layout/Header';
import Box from '../../components/element/Box';
import profileIcon from '../../assets/images/ic_profile_default.png';
import line from '../../assets/images/ic_line.png';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import { useLocation } from 'react-router-dom';
import { addMinutes, format } from 'date-fns';
function MeetingConfirmDetail() {
  const navigate = useNavigate();
  const toast = useToast();
  const downloadBtn = () => {
    // 파일 URL 확인
    const fileUrl = meetingData.meeting_request_info?.file_url;

    if (!fileUrl) {
      toast('error', 'No Attached Link', false);
      return;
    }

    // 가상의 a 태그 생성 후 클릭 이벤트로 다운로드
    const link = document.createElement('a');
    link.href = fileUrl;

    link.click();

    // 다운로드 성공 알림
    toast('success', 'File Downloaded', false);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => toast('success', 'The meeting link has been copied', false),
      () => toast('error', 'Failed to copy meeting link', false)
    );
  };

  const location = useLocation();

  const meetingData = location.state; // 전달된 state 데이터를 받음
  console.log(meetingData);
  const newDate = addMinutes(new Date(meetingData.meeting_date), meetingData.product.time);
  const formattedDate = format(newDate, 'MM/dd(EEE) HH:mm'); // 'HH:mm'으로 분을 표시

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />

      <div className={styles['confirm-detail-title']}>
        {'Your '}
        <span>{meetingData.product.type}</span>
        {' with'}
        <br />
        {`${meetingData.mate?.user?.nickname ?? 'undefinded'} planner is about to start.`}
      </div>

      <div className={styles['confirm-detail-sub-title']}>{'Scheduled Time'}</div>

      <Box
        text={` ${
          meetingData.meeting_date
            ? format(new Date(meetingData.meeting_date), 'MM/dd(EEE) HH:mm')
            : 'undefinded'
        } ~ ${meetingData.meeting_date ? formattedDate : 'undefinded'}`}
      />

      <div className={styles['confirm-detail-text']}>
        {'(*) You can join 10 minutes before the meeting starts.'}
      </div>

      <div className={styles['confirm-detail-member-container']}>
        <div className={styles['confirm-detail-sub-title']}>{'Participants'}</div>

        <div className={styles['confirm-detail-member-items']}>
          <div className={styles['confirm-detail-profile']}>
            <img
              src={
                meetingData.user.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${meetingData.user.profile_image}`
                  : profileIcon
              }
              alt='User Profile'
            />
            <p>{meetingData.user.nickname}</p>
          </div>

          <img
            className={styles['confirm-detail-line']}
            src={line}
            alt='Line'
          />

          <div className={styles['confirm-detail-profile']}>
            <img
              src={
                meetingData.mate.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${meetingData.mate.profile_image}`
                  : profileIcon
              }
              alt='Mate Profile'
            />
            <p>
              <span>{meetingData.mate?.user?.nickname ?? 'Unknown'}</span>
            </p>
          </div>
        </div>
      </div>
      <div className={styles['confirm-detail-meet-container']}>
        <div className={styles['confirm-detail-sub-title']}>{'Suggested Topics'}</div>
        <div className={styles['confirm-detail-meet-box']}>
          {"Hi, I'm looking to work as a data analyst in Japan."}
          <br />
          <br />
          {
            '- Essential steps to become a data analyst\r\n- Key technical skills and popular tools\r\n- Day-to-day challenges and workplace culture'
          }
          <br />
          <br />
          {
            '- Essential steps to become a data analyst\r\n- Key technical skills and popular tools\r\n- Day-to-day challenges and workplace culture'
          }
          {/*
          <br />
          <br />
          <Button active={true}>Copy Meeting Link</Button>
          */}
        </div>
      </div>
      <div className={styles['confirm-detail-file-container']}>
        <div className={styles['confirm-detail-sub-title']}>{'Resume / Portfolio / Link'}</div>
        <Box
          text={
            meetingData.meeting_request_info?.file_url
              ? `${meetingData.meeting_request_info.file_url.slice(0, 30)}...`
              : 'No Attached File'
          }
          buttonTitle='Download'
          onButtonClick={downloadBtn}
          active={!!meetingData.meeting_request_info?.file_url} // URL이 존재하면 활성화, 없으면 비활성화
        />

        <Box
          text={
            meetingData.meeting_request_info?.link_url
              ? meetingData.meeting_request_info.link_url
              : 'No Attached Link'
          }
          buttonTitle='Copy Link'
          onButtonClick={() => copyToClipboard(meetingData.meeting_request_info?.link_url ?? '')}
          active={!!meetingData.meeting_request_info?.link_url} // URL이 존재하면 활성화, 없으면 비활성화
        />
      </div>
      <div className={styles['confirm-detail-warning-container']}>
        <div className={styles['confirm-detail-sub-title']}>
          {'Guidelines for a positive meeting:'}
        </div>
        <Box
          text={'Keep personal information private'}
          readOnly={true}
        />
        <Box
          text={'Please join within 10 minutes. No refunds for late arrivals'}
          readOnly={true}
        />
        <Box
          text={'Be respectful and professional'}
          readOnly={true}
        />
      </div>
    </div>
  );
}

export default MeetingConfirmDetail;
