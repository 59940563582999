import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../../components/layout/Header';
import Button from '../../../components/element/Button';
import styles from './JobSelect.module.css';

import jobENList from '../../../assets/data/job_en.json';

interface JobSelectProps {
  onSelect: (category: string, selectedJob: string) => void;
  onCancel: () => void;
}

const JobSelect: React.FC<JobSelectProps> = ({ onSelect, onCancel }) => {
  return (
    <div className={styles['container']}>
      <Header onBack={() => onCancel()} actionType='signup' actionTitle={'Enter your own'} onAction={() => onSelect('-', '-')}/>
      <div className={styles['form-container']}>
        <div className={styles['label-title']}>
          Select your job field
        </div>
        <div className={styles['label-description']}>
          This helps us find meetups and matches for you
        </div>
        <div className={styles['divider']}/>
        <div className={styles['job-container']}>
        {jobENList.length > 0 && jobENList.map((e, idx) => 
          e.category !== 'None' &&
            <div key={idx}>
              <div className={styles['job-category']}>{e.category}</div>
              <div className={styles['job-itemlist']}>
              {e.items.map((item) => 
                <div className={styles['job-item']} onClick={() => onSelect(e.category, item)}>{item}</div>
              )} 
              </div>
            </div>
        )}
        </div>
      </div>
    </div>
    );
  }
  
  export default JobSelect;
    