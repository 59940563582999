import React from 'react';
import styles from './PaymentDetailCard.module.css';
import { ProductType } from '../../../constants/constants';
import { format } from 'date-fns';

interface PaymentDetailCardProps {
  date: string | Date;
  nickname: string;
  type: ProductType;
  price: number;
  status?: string;
  onClick?(): void;
}

const PaymentDetailCard: React.FC<PaymentDetailCardProps> = ({
  date,
  nickname,
  price,
  type,
  status,
  onClick,
}) => {
  return (
    <div
      className={styles['container']}
      onClick={onClick}
    >
      <div className={styles['card-container']}>
        <div className={styles['card-item']}>
          {`${format(new Date(date), 'MM-dd-yyyy')} ${
            (type === 'COFFEECHAT' && 'Coffee Chat') ||
            (type === 'INTERVIEW' && 'Interview Practice') ||
            (type === 'REVIEW' && 'Resume/Portfolio Review')
          } ${status === 'CANCELLED' ? 'cancelled' : ''}`}
          <div className={styles['nickname']}>
            {`${nickname} ${status === 'CANCELLED' ? 'cancelled' : 'completed'} the payment`}
          </div>
        </div>
        <div
          className={styles['price']}
          style={{
            textDecoration: status === 'CANCELLED' ? 'line-through' : 'none',
            color: status === 'CANCELLED' ? 'var(--color-red500)' : '',
          }}
        >
          {`$${price}`}
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailCard;
