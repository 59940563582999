import { ToNailedit } from '../models/toNailedit';
import { post } from '../utils/apiClient';

class EtcService {
  public async toNailedit(toNailedit: ToNailedit): Promise<ToNailedit | undefined> {
    try {
      // const response = await post('/etc/toNailedit', { toNailedit });
      const response = await post('/etc/toNailedit', {
        to_nailedit_message: toNailedit.to_nailedit_message,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async sendContact(subject: string, content: string, receive_email: string) {
    try {
      const response = await post('/etc/contact', {
        subject,
        content,
        receive_email
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
}

const etcService = new EtcService();
export default etcService;
