import React from 'react';
import styles from './MeetingCoupon.module.css';
import coffeeIcon from '../../../assets/images/ic_coffeechat.png';
import { Product } from '../../../models/product';
import { ProductType } from '../../../constants/constants';

interface MeetingCouponProps {
  nickname: string; // 닉네임
  type: ProductType;
  minute: number; // 시간(분 단위)
  price: number; // 시간(분 단위)
  onClick?: () => void; // 클릭 시 호출할 콜백 함수
}

const MeetingCoupon: React.FC<MeetingCouponProps> = ({
  nickname,
  type,
  minute,
  price,
  onClick,
}) => {
  return (
    <div
      className={styles['coupon-container']}
      onClick={onClick}
    >
      <div className={styles['coupon-top']}>
        <img
          src={coffeeIcon}
          alt='커피챗 아이콘'
        />
        <span className={styles['coupon-text']}>
          {minute}min{' '}
          {type === 'COFFEECHAT'
            ? 'Coffee Chat'
            : type === 'INTERVIEW'
            ? 'Interview Practice'
            : type === 'REVIEW'
            ? 'Resume'
            : 'Undefinded'}{' '}
          with {nickname}
        </span>
      </div>
      <div className={styles['coupon-bottom']}>
        {/* <span className={styles['coupon-label']}>쿠폰 적용가 </span> */}
        <span className={styles['coupon-price']}>${price}</span>
      </div>
    </div>
  );
};

export default MeetingCoupon;
