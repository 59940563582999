import { useEffect, useRef, useState } from 'react';
import Header from '../../components/layout/Header';
import styles from './ListByCountry.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import categoryAllIcon from '../../assets/images/ic_category_all.png';
import nationData from '../../assets/data/nation.json';
import MateService from '../../services/mateService';
import { Mate } from '../../models/mate';
import UserService from '../../services/userService';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import bookmarkOnIcon from '../../assets/images/ic_bookmark_on.png';
import bookmarkOffIcon from '../../assets/images/ic_bookmark_off.png';
import { Sheet } from 'react-modal-sheet';
import closeIcon from '../../assets/images/ic_close.png';

const ListByCountry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, search } = location.state || {}; // state에서 type과 search 값을 받아
  const [selectedTitleCountry, setSelectedTitleCountry] = useState<string>('All');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const profileListContainerRef = useRef<HTMLDivElement | null>(null);
  const [selectedNation, setSelectedNation] = useState<string>('');
  const [isLastPage, setLastPage] = useState<boolean>(false);
  const [mateProfileList, setMateProfileList] = useState<Mate[]>([]);
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false); // 국제번호 바텀시트.
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    if (type && search) {
      loadMateProfileList(type, search); // type과 search 값으로 데이터를 로드
      setSelectedTitleCountry(search);
    } else {
      loadMateProfileList('nation', 'All'); // type과 search 값으로 데이터를 로드
    }
  }, [type, search]);

  function handleCategoryClick(nation: string) {
    // nation 클릭 시 전체 nation 데이터를 전달
    setSelectedItems(nationData.filter((item) => item.use === true &&  item.disableWhenDuplicate === false && item.showMeetingByLocation === true).map((item) => item.nation));
    setSelectedCategory(nation);
    setIsOpenBottomSheet(true);
  }

  function selectCountry(nation: string) {
    setSelectedNation(nation);
    loadMateProfileList('nation', nation);
    setSelectedTitleCountry(nation);
  }
  function handleProfileListScroll() {
    if (profileListContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = profileListContainerRef.current;
      const scrollMax = scrollWidth - clientWidth;

      if (scrollLeft >= scrollMax * 0.8) {
        if (isLastPage == false) {
          loadMateProfileList('nation', selectedTitleCountry);
        } else {
        }
      }
    }
  }

  async function loadMateProfileList(type: string, search: string) {
    try {
      const list = await MateService.searchBy(type, search);

      if (search !== 'All') {
        const filteredList = list.filter((item: Mate) => item.user?.country_name === search);
        setMateProfileList(filteredList); // 상태 업데이트
        console.log('Filtered List:', filteredList);
      } else if (search === 'All') {
        setMateProfileList(list);
      }
    } catch (err) {
      console.error(err);
    }
  }

  function getProfileImageFile(mate: Mate) {
    if (mate.profile_image !== '') {
      //메이트 프로필 이미지가 존재하는 경우.
      return mate.profile_image
        ? `${process.env.REACT_APP_STORE_ADDRESS}/${mate.profile_image}`
        : profileDefaultIcon;
    } else if (mate.user?.profile_image !== '') {
      //유저 프로필 이미지가 존재하는 경우.
      return mate.user?.profile_image
        ? `${process.env.REACT_APP_STORE_ADDRESS}/${mate.user?.profile_image}`
        : profileDefaultIcon;
    }

    return undefined;
  }

  function goMateProfilePage(id: number) {
    navigate(`/mate/detail/${id}`);
  }

  async function toggleBookmark(mate: Mate) {
    if (mate.favorite === true) {
      await UserService.deleteMateFavorite(mate.id!);
      mate.favorite = false;
    } else {
      await UserService.addMateFavorite(mate.id!);
      mate.favorite = true;
    }
    const newMateProfileList = [...mateProfileList];
    newMateProfileList.find((item) => item.id === mate.id)!.favorite = mate.favorite;
    setMateProfileList(newMateProfileList);
  }

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={true}
        title='Meetings by Location'
        onBack={() => navigate(-1)}
      />
      <div className={styles['category-bar']}>
        <img
          src={categoryAllIcon}
          alt=''
          onClick={() => handleCategoryClick('All')}
        />
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('All')}
        >
          <div className={selectedTitleCountry === 'All' ? styles['category-item-active'] : ''}>
            {'All'}
          </div>
          <div className={selectedTitleCountry === 'All' ? styles['category-active'] : ''} />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('United States')}
        >
          <div
            className={
              selectedTitleCountry === 'United States' ? styles['category-item-active'] : ''
            }
          >
            {'USA'}
          </div>
          <div
            className={selectedTitleCountry === 'United States' ? styles['category-active'] : ''}
          />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('Japan (日本)')}
        >
          <div
            className={
              selectedTitleCountry === 'Japan (日本)' ? styles['category-item-active'] : ''
            }
          >
            {'Japan'}
          </div>
          <div
            className={selectedTitleCountry === 'Japan (日本)' ? styles['category-active'] : ''}
          />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('Austraila')}
        >
          <div
            className={selectedTitleCountry === 'Austraila' ? styles['category-item-active'] : ''}
          >
            {'Austraila'}
          </div>
          <div className={selectedTitleCountry === 'Austraila' ? styles['category-active'] : ''} />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('Singapore')}
        >
          <div
            className={selectedTitleCountry === 'Singapore' ? styles['category-item-active'] : ''}
          >
            {'Singapore'}
          </div>
          <div className={selectedTitleCountry === 'Singapore' ? styles['category-active'] : ''} />
        </div>
      </div>
      <div
        className={styles['profile-list-container']}
        ref={profileListContainerRef}
        onScroll={handleProfileListScroll}
      >
        {mateProfileList.map((e) => (
          <div
            className={styles['profile-list-item']}
            onClick={(ev) => {
              ev.stopPropagation();
              goMateProfilePage(e.id!);
            }}
          >
            <div className={styles['profile-item-thumbnail']}>
              <img
                src={getProfileImageFile(e) ?? profileDefaultIcon}
                alt=''
              />
            </div>
            <div className={styles['profile-item-container']}>
              <div className={styles['flex']}>
                <div className={styles['profile-item-tag']}>{e.user?.annual}years</div>
                <div className={styles['profile-item-tag']}>{e.user?.job}</div>

                <div className={styles['profile-item-tag']}>{e.user?.country_name}</div>
              </div>

              <div className={styles['profile-item-nickname']}>{e.user?.nickname}</div>
              <div className={styles['profile-item-company']}>{e.user?.company}</div>
              <div className={styles['profile-item-message']}>{e.user?.introduce_message}</div>
            </div>
            <div className={styles['profile-item-bookmark']}>
              <img
                src={e.favorite === true ? bookmarkOnIcon : bookmarkOffIcon}
                alt=''
                onClick={(ev) => {
                  ev.stopPropagation();
                  toggleBookmark(e);
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setIsOpenBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.6rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-terms-title']}>Select Country</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setIsOpenBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content-container']}>
                <div
                  className={`${styles['sheet-terms-content']} ${
                    selectedTitleCountry === 'All' ? styles['active'] : ''
                  }`}
                  onClick={() => selectCountry('All')}
                >
                  <div className={styles['circle']}></div>

                  <span>{'All'}</span>
                </div>
                {selectedItems.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles['sheet-terms-content']} ${
                      selectedTitleCountry === item ? styles['active'] : ''
                    }`}
                    onClick={() => selectCountry(item)}
                  >
                    <div className={styles['circle']}></div>

                    <span>{item || 'All'}</span>
                  </div>
                ))}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setIsOpenBottomSheet(false)} />
      </Sheet>
    </div>
  );
};

export default ListByCountry;
