import React from 'react';
import styles from './Box.module.css';
import dateIcon from '../../assets/images/ic_date.png';
import fileIcon from '../../assets/images/ic_file.png';
import warningIcon from '../../assets/images/ic_warning.png';
import checkIcon from '../../assets/images/ic_check_default.png'; // 체크 이미지 import

interface BoxProps {
  text: string; // Text를 소문자로 변경
  img?: string; // 이미지 URL
  buttonTitle?: string; // 버튼 텍스트
  fontSize?: string; // 폰트 크기 설정
  fontWeight?: string; // 폰트 굵기 설정
  onButtonClick?: () => void; // 버튼 클릭 핸들러
  readOnly?: boolean; // 읽기 전용 여부
  file?: boolean; // 파일 첨부 여부
  link?: boolean; // 링크 첨부 여부
  pay?: boolean; // 결제 여부
  active?: boolean;
}

const Box: React.FC<BoxProps> = ({
  text = '',
  img = dateIcon, // 기본 이미지를 dateIcon으로 설정
  buttonTitle,
  onButtonClick,
  fontSize = '1.4rem', // 기본 폰트 크기를 설정
  fontWeight = 'bold', // 기본 폰트 굵기를 설정
  readOnly = false, // 기본값은 읽기 전용이 아님
  file = true, // 기본값은 파일이 있음
  link = true, // 기본값은 링크가 있음
  pay = false, // 기본값은 false
  active = true,
}) => {
  // readOnly일 때는 warningIcon, buttonTitle이 있을 때는 fileIcon, 나머지는 img를 사용
  const Icon = readOnly ? warningIcon : buttonTitle ? fileIcon : img;

  // file 또는 link가 false일 때는 active 효과를 주지 않음 (색상이 var(--color-white600)로 변경됨)
  const isActive = active; // active 값이 true이면 스타일을 변경합니다.
  const fontColor = isActive ? 'inherit' : 'var(--color-white600)';
  const fontButtonColor = isActive ? 'var(--color-green600)' : 'var(--color-white600)';

  // file과 link가 false일 때 적절한 텍스트로 변경
  const displayText = !file ? '첨부된 파일 없음' : !link ? '첨부된 링크 없음' : text;

  // pay가 true일 때 적용할 스타일
  const border = pay ? '1px solid var(--color-green600)' : ''; // 기본 border 색상

  return (
    <div
      className={styles['box-container']}
      style={{ border }}
    >
      <div
        className={styles['box-text']}
        style={{
          fontSize,
          fontWeight,
          color: fontColor,
        }} // 폰트 스타일과 색상 적용
      >
        <div className={styles['box-flex']}>
          {pay ? (
            // useCheckImg가 true일 경우 변경할 이미지 요소
            <img
              src={checkIcon}
              alt='체크 아이콘'
            /> // 체크 이미지를 사용
          ) : (
            <img
              src={Icon}
              alt='아이콘'
            />
          )}
          {displayText}
        </div>
      </div>
      {!readOnly && buttonTitle && (
        <button
          className={styles['box-btn']}
          onClick={onButtonClick} // 버튼 클릭 핸들러
          style={{ color: fontButtonColor }}
        >
          {buttonTitle}
        </button>
      )}
    </div>
  );
};

export default Box;
