import React, { useEffect, useState } from 'react';
import styles from './MeetingReview.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/layout/Header';
import MeetingCompleteCard from './components/MeetingCompleteCard';
import ReviewSelect from './components/ReviewSelect';
import ReviewRating from './components/ReviewRating';
import Button from '../../components/element/Button';
import { ProductReview } from '../../models/productReview';
import useToast from '../../hook/useToast'; // 토스트 훅 추가
import meetingService from '../../services/meetingService';
import defaultProfileImg from '../../assets/images/ic_profile_default.png'; // 기본 프로필 이미지

function MeetingReview() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedReview, setSelectedReview] = useState<string[]>([]);
  const toast = useToast(); // 토스트 훅 초기화
  const [score, setScore] = useState<number | null>(null); // Ratings 상태 추가
  const [meetingData, setMeetingData] = useState(location.state || {});

  useEffect(() => {
    if (id) {
      loadTargetMeeting(Number(id));
    }
  }, []);

  async function loadTargetMeeting(id: number) {
    const data = await meetingService.getMeetingById(id);
    if (data) {
      setMeetingData(data);
    } else {
      console.log('에러');
    }
  }

  // 리뷰 작성 API 호출
  const submitReview = async () => {
    if (!selectedReview.length || score === null) {
      toast('error', 'Please rate and select highlights', false);
      return;
    } // score가 null일 경우 처리 추가
    try {
      const { user_id, product_id, mate_user_id, created_date } = meetingData;
      const productReview: ProductReview = {
        user_id: user_id,
        product_id: product_id, //연동 상품.
        meeting_id: meetingData.id, //연동되는 미팅 id
        mate_user_id: mate_user_id, //메이트 유저.
        score: score, //리뷰 점수
        good_reason: selectedReview, //어떤 점이 좋았는지.
        useful_answer: '', //어떤 답변이 가장 유용했는지.
        useful_answer_selected_reason: '',
        to_mate_message: '', //메이트한테 감사 인사.
        created_date: created_date,
      };
      toNext(productReview);
    } catch (err) {
      toast('error', 'Something went wrong while submitting', false); // 실패 메시지 출력
    }
  };

  const toNext = (productReview: ProductReview) => {
    if (meetingData.product.type === 'COFFEECHAT') {
      navigate('/meeting/review/coffeechat', { state: { productReview, meetingData } });
    } else if (meetingData.product.type === 'INTERVIEW') {
      navigate('/meeting/review/interview', { state: productReview });
    } else if (meetingData.product.type === 'REVIEW') {
      navigate('/meeting/review/review', { state: productReview });
    }
  };

  const handleSelect = (text: string) => {
    // 현재 선택된 리뷰가 클릭된 경우 선택 해제
    if (selectedReview.includes(text)) {
      setSelectedReview((prev) => prev.filter((item) => item !== text)); // 선택된 항목 제거
    } else {
      setSelectedReview((prev) => [...prev, text]); // 새로운 항목 추가
    }
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />

      <div className={styles['label-container']}>{'How was the meeting?'}</div>
      <div className={styles['text']}>
        {'Feedback will be shared with mate and may appear on profile'}
      </div>

      <MeetingCompleteCard
        profileImage={
          `${process.env.REACT_APP_STORE_ADDRESS}/${meetingData?.mate?.profile_image}` ||
          defaultProfileImg
        }
        productName={meetingData?.product?.type}
        nickName={meetingData?.mate?.user?.nickname ?? 'Unknown'}
        companyName={meetingData?.mate?.user?.company ?? 'Unknown'}
        meetingTime={meetingData?.meeting_date}
        useButtonCount={0}
        productTime={meetingData?.product?.time ?? 0} // 기본값 설정
        useUnderLine={false}
        useBgColor={true}
      />
      <div className={styles['label-sub-container']}>{'Rate out of 5 stars'}</div>
      <ReviewRating score={setScore} />
      <div className={styles['label-sub-container']}>
        {'Let us know what was particularly good'}
      </div>

      <div className={styles['text']}>{'Select multiple positive aspects'}</div>
      <ReviewSelect
        text={'Motivating and encouraging'}
        onSelect={(active, text) => handleSelect(text)}
      />
      <ReviewSelect
        text='Practical advice'
        onSelect={(active, text) => handleSelect(text)}
      />
      <ReviewSelect
        text='Specific expertise and insights'
        onSelect={(active, text) => handleSelect(text)}
      />
      <ReviewSelect
        text='Clear and detailed feedback'
        onSelect={(active, text) => handleSelect(text)}
      />
      <ReviewSelect
        text='Highly professional'
        onSelect={(active, text) => handleSelect(text)}
      />
      <ReviewSelect
        text='In-depth insights'
        onSelect={(active, text) => handleSelect(text)}
      />
      <ReviewSelect
        text='Well-prepared for meeting'
        onSelect={(active, text) => handleSelect(text)}
      />
      <ReviewSelect
        text='Helpful for job preparation'
        onSelect={(active, text) => handleSelect(text)}
      />
      <ReviewSelect
        text='Kind and clear explanations'
        onSelect={(active, text) => handleSelect(text)}
      />
      <div className={styles['button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={1.6}
          active={selectedReview.length > 0} // length가 0일 경우 false로 설정
          onClick={submitReview} // 수정: submitReview 호출
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default MeetingReview;
