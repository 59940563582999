import dayjs from 'dayjs';
import styles from './ProductStatusCard.module.css';
import Button from '../../../components/element/Button';
import StatusTag from './StatusTag';
import { ProductStatus } from '../../../constants/constants';

import starIcon from '../../../assets/images/ic_star.png';
import reviewIcon from '../../../assets/images/ic_review.png';

export enum ActionStyle {
  OUTLINE,
  FILL
}

interface ProductStatusCardProps {
  productName: string;
  icon: any;
  status: ProductStatus;
  reviewCount?: number;
  reviewScore?: number;
  startDate?: Date;
  endDate?: Date;
  createdDate?: Date;
  buyerCount?: number;
  price?: number;
  onAction1?: () => void;
  onAction2?: () => void;
  onChangePriceAction?: () => void;
}

const ProductStatusCard: React.FC<ProductStatusCardProps> = ({ 
  productName = '',
  icon,
  status = ProductStatus.IN_REVIEW,
  reviewCount = 0,
  reviewScore = 0,
  startDate,
  endDate,
  createdDate,
  buyerCount = 0,
  price,
  onAction1,
  onAction2,
  onChangePriceAction,
}) => {
  return (
    <div>
      <div className={styles['card-container']}>
        <div className={styles['card-row']}>
          <img className={styles['card-thumbnail']} src={icon} alt=""/>
          <div className={styles['card-product-name']}>{productName}</div>
          {status !== ProductStatus.NONE && 
            <>
            <div className={styles['card-review']}>
              <img className={styles['card-star-icon']} src={starIcon} alt=""/>
              <div className={styles['card-review-text']}>{reviewScore.toFixed(1)}</div>
              <img className={styles['card-star-icon']} src={reviewIcon} alt=""/>
              <div className={styles['card-review-text']}>{reviewCount}</div>
            </div>
            <div style={{marginLeft: 'auto', marginRight: '2.0rem'}}><StatusTag status={status}/></div>
            </>
          }
        </div>
        {status !== ProductStatus.NONE &&
          <>
            <div style={{marginBottom: '0.3rem'}}/>
            <div className={styles['card-description-text']} style={{marginBottom: '0.6rem'}}>
              {status === ProductStatus.IN_REVIEW || status === ProductStatus.REJECT ? 'Review Request Date' : 'Date of Sale'} : {dayjs(startDate).format('MMM D, YYYY')}{status !== ProductStatus.IN_REVIEW && ' - '}
              {(status === ProductStatus.INACTIVE || status === ProductStatus.SUSPENSION) && dayjs(endDate).format('MMM D, YYYY')}
            </div>
            {status !== ProductStatus.IN_REVIEW && status !== ProductStatus.REJECT && <div className={styles['card-description-text']} style={{marginBottom: '0.6rem'}}>Total Buyers : {buyerCount}</div>}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div className={styles['card-description-text']}>{status === ProductStatus.IN_REVIEW || status === ProductStatus.REJECT ? 'Desired Price' : 'Current Price'} : {price}$</div>
              {status === ProductStatus.ACTIVE && <div className={styles['card-price-change-text']} onClick={onChangePriceAction}>Change Price</div>}
            </div>
          </>
        }
        {status !== ProductStatus.IN_REVIEW && status !== ProductStatus.SUSPENSION && 
          <>
            <div style={{marginBottom: '2.2rem'}}/>
            <div className={styles['card-row']} style={{marginTop: '0.2rem', paddingLeft: '1.6rem', paddingRight: '1.6rem'}}>
              <Button 
                width={'100vw'} 
                height={'4rem'}
                activeBorderColor={status === ProductStatus.ACTIVE ? '#CED3E3' : undefined}
                activeColor={status === ProductStatus.ACTIVE ? '#FFFFFF' : 
                              status === ProductStatus.NONE || status === ProductStatus.INACTIVE ? '#00B76A' : '#EBEDF0'}
                activeTextColor={status === ProductStatus.NONE || 
                                  status === ProductStatus.INACTIVE ? '#FFFFFF' : '#131314'}
                activeFontSize='1.4rem' 
                radius={8}
                activeFontWeight={600}
                active={true}
                onClick={onAction1}>
                  {status === ProductStatus.REJECT ? 'View Reason' :
                    status === ProductStatus.INACTIVE ? 'Relist' :
                    status === ProductStatus.ACTIVE ? 'Remove from Listing' : 'Add items for sale'}
              </Button>
              {status === ProductStatus.REJECT && //등록실패일 경우(버튼 2개)
              <>
                <div style={{marginLeft: '0.5rem'}}/>
                <Button 
                  width={'100vw'} 
                  height={'4rem'}
                  activeBorderColor={undefined}
                  activeColor={'#00B76A'}
                  activeTextColor={'#FFFFFF'}
                  activeFontSize='1.4rem' 
                  radius={8}
                  activeFontWeight={600}
                  active={true}
                  onClick={onAction2}>Revise and Resubmit
                </Button>
              </>
              }
            </div>
          </>
        }
      </div>
    </div>
  );
}
  
export default ProductStatusCard;
  