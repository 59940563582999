import React from 'react';
import styles from './ProductCard.module.css';
import timeIcon from '../../../assets/images/ic_time_green.png';
import starIcon from '../../../assets/images/ic_star.png';
import { ProductType } from '../../../constants/constants';

interface ProductCardProps {
  nickname: string; // 닉네임
  type: string; // 커피챗, 멘토링 등 타입 선택
  minute: number; // 시간(분 단위)
  price: number;
  couponPrice?: number;
  review: number;
  rating: number;
  active?: boolean; // 활성화 상태
  coupon?: boolean; // 활성화 상태
  onClick?: () => void; // 클릭 시 호출할 콜백 함수
}

const ProductCard: React.FC<ProductCardProps> = ({
  nickname,
  type,
  minute,
  price,
  couponPrice,
  review,
  rating,
  active = false, // 기본값 false
  coupon = false, // 기본값 false
  onClick,
}) => {
  return (
    <div
      className={`${styles['coupon-container']} ${
        active ? styles['active'] : ''
      }`}
      onClick={onClick} // 클릭 시 콜백 호출
      style={active ? { borderColor: 'var(--color-green300)' } : {}}
    >
      <div className={styles['coupon-top']}>
        <span className={styles['coupon-text']}>
          {type === 'COFFEECHAT'
            ? 'Coffee Chat'
            : type === 'INTERVIEW'
            ? 'Interview Practice'
            : 'Resume/Protfolio Review'}
        </span>
        <div className={styles['coupon-top-item']}>
          <img src={timeIcon} alt='시계아이콘' />
          <span>{minute}m</span>
          <img src={starIcon} alt='별 아이콘' />
          <span>
            {rating} review / {review}
          </span>
        </div>
      </div>
      <div className={styles['coupon-bottom']}>
        {coupon === true ? (
          <>
            <span className={styles['coupon-sub-price']}>1 ${price}</span>
            <span className={styles['coupon-couponPrice']}>${couponPrice}</span>
            <span className={styles['coupon-label']}>with coupon </span>
          </>
        ) : (
          <>
            <span className={styles['coupon-price']}>${price}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
