import React from 'react';
import Header from '../../components/layout/Header';
import styles from './MeetingMyReviewDetail.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import starIcon from '../../assets/images/ic_star.png'; // Ratings 아이콘 경로 추가
import ReviewSelect from './components/ReviewSelect';
import default_profile from '../../assets/images/ic_profile_default.png';
import { Mate } from '../../models/mate';

function MeetingMyReviewDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const review = location.state.review; // location.state에서 리뷰 객체 가져오기

  console.log(review);
  // Ratings 아이콘을 렌더링하는 함수
  const renderRating = (rating: number) => {
    return (
      <div className={styles['rating-container']}>
        {[...Array(rating)].map((_, index) => (
          <img
            key={index}
            src={starIcon}
            alt='Ratings'
            className={styles['star-icon']}
          />
        ))}
      </div>
    );
  };

  if (!review) {
    return <div>No reviews found</div>;
  }
  const goMateProfile = (mate: Mate) => {
    navigate(`/mate/profile`, { state: mate });
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div
        className={styles['detail-top-btn']}
        onClick={() => goMateProfile(review.mateUser!)}
      >
        {'View Mate Profile'}
      </div>
      <div className={styles['detail-container']}>
        <div className={styles['detail-flex']}>
          <img
            src={
              review.user?.profile_image
                ? `${process.env.REACT_APP_STORE_ADDRESS}/${review.user.profile_image}`
                : default_profile
            }
            alt='프로필 아이콘'
            className={styles['profile-image']}
          />
          <h1>{review.user.nickname}'s Review</h1>
        </div>
        <div className={styles['detail-top']}>
          <div className={styles['detail-title']}>
            <span>Meeting Review</span>
          </div>
          <div className={styles['detail-text']}>
            {review.to_mate_message}
            <div className={styles['detail-line']}>{/* 구분선 */}</div>
          </div>
        </div>
        <div className={styles['detail-title']}>{review.user.nickname}’s Rating</div>
        <div className={styles['detail-flex']}>
          {renderRating(review.score)}
          <div className={styles['detail-rating-text']}>{review.score}</div>
        </div>
        <div className={styles['detail-line']}>{/* 구분선 */}</div>
        <div className={styles['review-select-container']}>
          <div className={styles['detail-title']}>{' What Was Useful'}</div>
          <div className={styles['button-container']}>
            {Array.isArray(review.useful_answer) ? (
              review.useful_answer.map((item: string, index: number) => (
                <ReviewSelect
                  key={index}
                  text={item}
                  readOnly={true}
                />
              ))
            ) : (
              <ReviewSelect
                text={review.useful_answer}
                readOnly={true}
              />
            )}
          </div>

          <div className={styles['detail-line']}>{/* 구분선 */}</div>
          {/* {review.useful_answer_selected_reason >= 1 ? (
            <>
              <div className={styles['detail-title']}>{'Highlights'}</div>
              <div className={styles['detail-text-container']}>
                {review.useful_answer_selected_reason}
              </div>
              <div className={styles['detail-line']}></div>
            </>
          {/* ) : ( */}
          <></>
          {/* )} */}
          <>
            <div className={styles['detail-title']}>{'Highlights'}</div>
            {Array.isArray(review.useful_answer_selected_reason) ? (
              review.useful_answer_selected_reason.length === 1 ? (
                <div className={styles['detail-text-container']}>
                  {review.useful_answer_selected_reason[0]}
                </div>
              ) : (
                review.useful_answer_selected_reason.map((item: string, index: number) =>
                  item ? (
                    <div
                      key={index}
                      className={styles['detail-text-container']}
                    >
                      {item}
                    </div>
                  ) : (
                    ''
                  )
                )
              )
            ) : (
              <div className={styles['detail-text-container']}>
                {review.useful_answer_selected_reason}
              </div>
            )}

            <div className={styles['detail-line']}></div>
          </>
          <div className={styles['review-select-container']}>
            <div className={styles['detail-title']}>{'Mate’s Key Strengths'}</div>
            <div className={styles['button-container']}>
              {review.good_reason.map((item: string, index: number) => (
                <ReviewSelect
                  key={index}
                  text={item}
                  active={true}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetingMyReviewDetail;
