import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../../components/layout/Header';
import Button from '../../../components/element/Button';
import styles from './LanguageSelect.module.css';

import uncheckedIcon from '../../../assets/images/ic_unchecked.png';
import checkedIcon from '../../../assets/images/ic_checked.png';

import languageList from '../../../assets/data/languages.json';

interface LanguageSelectProps {
  languages: string[];
  onSelect: (selectedLanguages: string[]) => void;
  onCancel: () => void;
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({ languages, onSelect, onCancel }) => {
  const navigate = useNavigate();
  const [selectedLanguageList, setSelectedLanguageList] = useState<string[]>([]);

  useEffect(() => {
    setSelectedLanguageList(languages);
  }, []);

  function selectLanguage(language: string) {
    if (selectedLanguageList.includes(language)) {
      setSelectedLanguageList(selectedLanguageList.filter((value: string) => value !== language));
    } else {
      setSelectedLanguageList([...selectedLanguageList, language]);
    }
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => onCancel()} />
      <div className={styles['form-container']}>
        <div className={styles['label-container']}>
          {'Please select all language\r\nyou can communicate in.'}
        </div>
        <div className={styles['result-container']}>
          {languageList.length > 0 &&
            languageList.map((e, idx) => (
              <div
                key={idx}
                className={`${styles['result-item']} ${
                  selectedLanguageList.includes(e) && styles['result-item-selected']
                }`}
                onClick={() => selectLanguage(e)}
              >
                <div>{e}</div>
                <img
                  src={selectedLanguageList.includes(e) ? checkedIcon : uncheckedIcon}
                  alt=''
                />
              </div>
            ))}
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={2.0}
          active={true}
          onClick={() => onSelect(selectedLanguageList)}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default LanguageSelect;
