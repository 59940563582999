import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DiscountCoupon from '../search/components/DiscountCoupon';
import styles from './MyCoupon.module.css';
import Header from '../../components/layout/Header';
import { Coupon } from '../../models/coupon';
import UserService from '../../services/userService';

function MyCoupon() {
  const navigate = useNavigate();
  const [couponData, setCouponData] = useState<Coupon[]>([]);
  const [isLoaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    loadCouponList('date');
  }, []);

  async function loadCouponList(type: string) {
    try {
      const couponListData = await UserService.couponList(type);
      if (couponListData && couponListData.length > 0) {
        setCouponData(couponListData);
        setLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>My Coupons</div>
      {isLoaded === true ? (couponData.length > 0 ? (
        <div>
          {couponData.map((coupon: Coupon) => (
            <DiscountCoupon
              key={coupon.id}
              text={coupon.name}
              discount={coupon.discount_rate}
              start={coupon.start_date}
              end={coupon.end_date}
              deadline={true}
            />
          ))}
        </div>
      ) : (
        <div className={styles['title']}>There is no coupon.</div>
      )) : ''}
    </div>
  );
}

export default MyCoupon;
