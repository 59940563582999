import axios, { AxiosRequestConfig } from "axios";

export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER_ADDRESS}`,
  headers: { 'Content-Type': 'application/json' }
});

//요청 인터셉터
apiClient.interceptors.request.use((config) => {
    //로컬 스토리지에서 토큰을 가져옵니다.
    const token = localStorage.getItem('authToken');
    
    //토큰이 있는 경우 Authorization 헤더에 추가합니다.
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // 요청이 실패할 경우의 처리
    return Promise.reject(error);
  }
);

//응답 인터셉터
apiClient.interceptors.response.use((response) => {
    // 응답이 성공한 경우 그대로 반환
    return response;
  },
  (error) => {
    console.error('API Error:', error); //공통 에러.

    if (error.response && error.response.status === 401) { //Unauthorized
      //로그아웃 처리 후 로그인 페이지로 리다이렉트
      localStorage.removeItem('authToken');
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy };