import styles from './StatusTag.module.css';
import { ProductStatus } from '../../../constants/constants';

interface StatusTagProps {
  status: ProductStatus;
  fixedSize?: boolean;
}

const StatusTag: React.FC<StatusTagProps> = ({ 
  status,
  fixedSize = false,
}) => {
  function getStatusText() {
    if(status == ProductStatus.IN_REVIEW) {
      return "Review";
    }else if(status == ProductStatus.ACTIVE) {
      return "Available";
    }else if(status == ProductStatus.INACTIVE) {
      return "Unavailable";
    }else if(status == ProductStatus.SUSPENSION) {
      return "Removed";
    }else{
      return "Failed";
    }
  }

  function getStatusStyle() {
    if(status == ProductStatus.IN_REVIEW) {
      return 'card-status-in-review';
    }else if(status == ProductStatus.ACTIVE) {
      return 'card-status-active';
    }else if(status == ProductStatus.INACTIVE) {
      return 'card-status-inactive';
    }else if(status == ProductStatus.SUSPENSION) {
      return 'card-status-request-suspension';
    }else{
      return 'card-status-reject';
    }
  }

  return (
    <div className={styles[getStatusStyle()]} style={fixedSize == true ? {width: '8.5rem', height: '3.0rem', padding: 0, lineHeight: '3.0rem'} : undefined}>{getStatusText()}</div>
  );
}

export default StatusTag;