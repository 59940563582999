import React, { useState } from 'react';
import styles from './CommunityCategory.module.css'; // Ensure your CSS file is correctly imported
import categoryAllIcon from '../../../assets/images/ic_category_all.png'; // Replace with the actual path to the icon

const CategoryBar = () => {
  const [activeCategory, setActiveCategory] = useState('All'); // State for active category

  const categories = ['All', 'USA', 'Japan', 'Australia', 'Canada', 'Singapore']; // Your categories

  return (
    <div className={styles['category-bar']}>
      <img src={categoryAllIcon} alt="All Categories" />
      {categories.map((category) => (
        <div
          key={category}
          className={styles['category-item']}
          onClick={() => setActiveCategory(category)}
        >
          <div
            className={
              activeCategory === category
                ? styles['category-item-active']
                : ''
            }
          >
            {category}
          </div>
          {/* Render underline only for the active category */}
          {activeCategory === category && (
            <div className={styles['category-active']}></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CategoryBar;
