import { useEffect, useState, useRef } from 'react';
import Header from '../../../components/layout/Header';
import SearchBar from '../../../components/element/SearchBar';
import styles from './EditTimezone.module.css';
import timezoneData from '../../../assets/data/timezone.json';

interface EditTimezoneProps {
  onSelect: (index: number) => void;
  onCancel: () => void;
}

const EditTimezone: React.FC<EditTimezoneProps> = ({ onSelect, onCancel }) => {
  const [searchText, setSearchText] = useState<string>('');

  function selectTimezone(index: number) {
    onSelect(index);
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => onCancel()} title='Time Zone'/>
      <SearchBar 
        useBackButton={false} 
        value={searchText} 
        onChange={(e) => setSearchText(e.target.value)} 
        onClear={() => setSearchText('')}
        placeholder='Seoul'
      />
      <div className={styles['search-result-container']}>
        {timezoneData.map((e, idx) => 
          searchText === '' || e['Timezone_en(wo_utc)'].toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ? 
            <div className={styles['search-result-item']} onClick={() => selectTimezone(idx)}>
              <div>{'+' + e['Timezone_en(with_utc)']}</div>
            </div> : <div/>
        )}
      </div>
    </div>
    );
  }
  
  export default EditTimezone;
    