import React from 'react';
import styles from './PaymentWithdrawCard.module.css';
import { format } from 'date-fns';

interface PaymentDetailCardProps {
  date: string | Date;
  nickname: string;
  type?: string;
  price: number;
  fail?: boolean;
  onClick?(): void;
}

const PaymentDetailCard: React.FC<PaymentDetailCardProps> = ({
  date,
  nickname,
  price,
  type,
  fail,
  onClick,
}) => {
  return (
    <div
      className={styles['container']}
      onClick={onClick}
    >
      <div className={styles['card-container']}>
        <div className={styles['card-item']}>
          {`$${price}`}
          <div className={styles['date']}>{format(new Date(date), 'MMM dd, yyyy hh:mm a')}</div>
        </div>
        <div className={`${styles['waiting']} ${type === 'DENIED' ? styles['fail'] : ''}`}>
          {type === 'DENIED'
            ? '인출 거절'
            : type === 'APPROVING'
            ? '인출 승인 중'
            : type === 'COMPLETE' && <span>인출 완료</span>}
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailCard;
