import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './CalculationRequestAddMethod.module.css';
import completedIcon from '../../assets/images/ic_requestCompleted.png';
import { UserWithdrawalMethod } from '../../models/userWithdrawalMethod';
import useGlobalStore from '../../stores/globalStore';
import settlementService from '../../services/settlementService';
import { UserWithdrawalMethodStatus } from '../../constants/constants';

function CalculationRequestAddMethod() {
  const navigate = useNavigate();
  const location = useLocation();
  const { methodData, selectedSettlement } = location.state || {};
  const { tempUserWithdrawalMethod, setTempUserWithdrawalMethod } = useGlobalStore();
  console.log(tempUserWithdrawalMethod);
  const { id } = useParams<{ id?: string }>();
  useEffect(() => {
    console.log('Selected ID:', id);
    // ID를 기반으로 데이터 로드
  }, [id]);

  const deleteWithdrawalMethod = async (id: number) => {
    await settlementService.deleteWithdrawalMethod(id);
    console.log('Deleting withdrawal method ID:', id);
    const response = await settlementService.deleteWithdrawalMethod(id);
    console.log('Response from delete API:', response);
  };

  const toCompleted = () => {
    const tempUserWithdrawalMethod: UserWithdrawalMethod = {
      country_code: methodData.country_code,
      nationality: methodData.nationality,
      withdrawal_method: methodData.withdrawal_method,
      created_date: new Date(),
      email: methodData.email,
      recipient_name: methodData.firstName,
    };
    setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });
    navigate('/calculation/request/add/completed');
  };

  const addWithdrawalMethod = async () => {
    try {
      // UserWithdrawalMethod 타입으로 변환 (필요 시 추가적인 타입 정의)
      const userWithdrawMethod: UserWithdrawalMethod = {
        user_id: methodData.id,
        country_code: methodData.country_code,
        nationality: methodData.nationality || '',
        withdrawal_method: methodData.withdrawal_method,
        email: methodData.email || '',
        mobile_number: methodData.mobile_number || '',
        bank_code: methodData.bank_code || '',
        account_number: methodData.account_number || '',
        account_name: methodData.account_name || '',
        currency: methodData.currency || '',
        recipient_type: methodData.recipient_type || '',
        iban: methodData.iban || '',
        country_region: methodData.country_region || '',
        street_address: methodData.street_address || '',
        city: methodData.city || '',
        state_province: methodData.state_province || '',
        post_code: methodData.post_code || '',
        swift_code_bic: methodData.swift_code_bic || '',
        branch_code: methodData.branch_code || '',
        id_type: methodData.id_type || '',
        id_number: methodData.id_number || '',
        account_type: methodData.account_type || '',
        bsb_routing_number: methodData.bsb_routing_number || '',
        ifsc_code: methodData.ifsc_code || '',
        ach_routing_number: methodData.ach_routing_number || '',
        sort_code: methodData.sort_code || '',
        financial_institution_number: methodData.financial_institution_number || '',
        transit_number: methodData.transit_number || '',
        tax_rate: methodData.tax_rate || 0,
        swift_code: methodData.swift_code || '',
        swift_transfer_fee_option: methodData.swift_transfer_fee_option || '',
        recipient_name: methodData.recipient_name || '',
        status: UserWithdrawalMethodStatus.AVAILABLE,
      };
      navigate('/calculation/request/add/completed', {
        state: userWithdrawMethod,
      });
      if (id) {
        deleteWithdrawalMethod(Number(id)); // id를 number로 변환하여 호출
      }
      console.log('Request Data:', userWithdrawMethod);
      const response = await settlementService.addWithdrawalMethod(userWithdrawMethod);
      console.log('Response:', response);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(selectedSettlement);

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'Add a payout method'}</div>
      <div className={styles['text']}>{`Checking the information you provided for
${
  methodData.withdrawal_method === 'PAYPAL' ? 'Paypal' : selectedSettlement.transferMethod
} registration`}</div>

      <div className={styles['img-container']}>
        <img
          src={completedIcon}
          alt=''
        />
      </div>

      <div className={styles['button-container']}>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={true}
          onClick={addWithdrawalMethod}
        >
          Add payment
        </Button>
      </div>
    </div>
  );
}

export default CalculationRequestAddMethod;
