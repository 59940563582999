import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import selectedIcon from '../../assets/images/ic_check_default.png';
import styles from './CalculationOptionAdd.module.css';

function CalculationOptionAdd() {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);

  const toRequest = () => {
    navigate('/calculation/request');
  };

  // 대금 수령 방식 목록
  const paymentOptions = ['Paypal', 'Local Transfer', 'SWIFT Transfer'];

  // 선택 아이템 업데이트 함수
  const handleSelect = (type: any) => {
    setSelectedType(type);
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'Please add a payout method'}</div>
      <div className={styles['text']}>{'Select the method for receiving payments.'}</div>

      <div className={styles['type-container']}>
        {paymentOptions.map((type) => (
          <div
            key={type}
            className={styles['type-item']}
            onClick={() => handleSelect(type)}
          >
            {type}
            {selectedType === type && (
              <img
                src={selectedIcon}
                alt='Selected Icon'
                className={styles['selected-icon']}
              />
            )}
          </div>
        ))}
      </div>

      <div className={styles['button-container']}>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={true}
          onClick={toRequest}
        >
          Choose payout method
        </Button>
      </div>
    </div>
  );
}

export default CalculationOptionAdd;
