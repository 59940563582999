import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import styles from './Introduce.module.css';
import Header from '../../components/layout/Header';
import TextField from '../../components/element/TextField';
import TextArea from '../../components/element/TextArea';
import Button from '../../components/element/Button';
import { Mate } from '../../models/mate';
import useToast from '../../hook/useToast';
import uploadService from '../../services/uploadService';
import mateService from '../../services/mateService';
import useGlobalStore from '../../stores/globalStore';

import cameraIcon from '../../assets/images/ic_camera.png';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import closeIcon from '../../assets/images/ic_close.png';
import addIcon from '../../assets/images/ic_add.png';
import { MateVerifyStatus } from '../../constants/constants';

function MateIntroduce() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user, mate, setMate } = useGlobalStore();
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);
  const [isOpenQuitBottomSheet, setOpenQuitBottomSheet] = useState(false);
  const [saved, setSaved] = useState(true);
  const [mode, setMode] = useState<string>('');
  const thumbnailRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<any>(null);
  console.log(mate);

  useEffect(() => {
    if (user) {
      setMate({
        ...mate!,
        ...user!.mate!, // mate가 있으면 덮어쓰기
        chat_subject: user.mate?.chat_subject ?? ['', '', ''], // chat_subject 초기화
        user_id: user.id || 0, // user.id가 있을 때만 사용
      });
    }
  }, [user, setMate]); // user와 setMate가 변경될 때마다 실행

  function changeChatSubject(idx: number, value: string) {
    mate!.chat_subject[idx] = value;
    setMate(mate!);
    setSaved(false);
  }

  function addChatSubject() {
    mate!.chat_subject.push('');
    setMate(mate!);
  }

  async function uploadFile() {
    let formData = new FormData();
    formData.append('file', file);
    const uploadFileName: string = await uploadService.addImage('user', formData);
    return uploadFileName;
  }

  const onChangeImageUpload = (e: any) => {
    setFile(e.target.files[0]);

    if (thumbnailRef.current?.files != null) {
      setMate({
        ...mate!,
        profile_image: URL.createObjectURL(thumbnailRef.current?.files[0]),
      });
      setSaved(false);
    }
  };

  function selectThumbnail() {
    thumbnailRef.current?.click();
    setOpenBottomSheet(false);
  }

  function deleteProfileThumbnail() {
    setMate({
      ...mate!,
      profile_image: '',
    });
    setOpenBottomSheet(false);
  }

  function checkValidData({ toastMessage = false }) {
    //데이터가 정상적으로 입력되었는지 체크.
    if (mate!.introduce_message === '') {
      if (toastMessage) {
        toast('error', 'Some fields are empty');
      }
      return false;
    }

    if (mate!.my_career_message === '') {
      if (toastMessage) {
        toast('error', 'Some fields are empty');
      }
      return false;
    }

    let cnt = 0;
    for (var chat_subject of mate!.chat_subject) {
      if (chat_subject !== '') {
        cnt++;
      }
    }

    if (cnt < 3) {
      if (toastMessage) {
        toast('error', 'You need at least 3 chat topics');
      }
      return false;
    }

    if (mate!.recommend_message === '') {
      toast('error', 'Some fields are empty');
      return false;
    }

    if (mate!.not_recommend_reason_message === '') {
      toast('error', 'Some fields are empty');
      return false;
    }

    if (mate!.hash_tag === '') {
      toast('error', 'Some fields are empty');
      return false;
    }

    return true;
  }

  function quit() {
    setOpenQuitBottomSheet(false);

    //프로필 페이지로 이동.
    navigate('/menu');
  }

  function saveTemporary() {
    setOpenQuitBottomSheet(false);

    //현재까지 입력된 내용만 임시 저장.
    saveMate({ validCheck: false });
    setSaved(false);
    //프로필 페이지로 이동.
    toast('success', 'Your mate profile has been saved');
  }

  async function saveMate({ validCheck = true }) {
    if (validCheck == true) {
      const valid = checkValidData({ toastMessage: true });
      if (!valid) return;
    }

    //프로필 이미지가 있으면 업로드 처리.
    let uploadFileName = '';
    if (file != null) {
      uploadFileName = await uploadFile();
      mate!.profile_image = uploadFileName;
    }

    setMate({ ...mate!, profile_image: uploadFileName });

    //프로필 저장 처리.
    let data: any;
    if (validCheck == true) {
      //모두 입력 후 가입시에만 Wait 처리.
      mate!.verify_status = MateVerifyStatus.WAIT;
    }
    data = await mateService.saveMateProfile(mate!);

    if (!data || data['type'] !== 'success') {
      toast('error', 'Profile creation failed. Please try again', false);
      return;
    }

    setMate(data['mate']); //업데이트된 데이터로 변경.

    if (validCheck === true) {
      navigate('/mate/profileComplete');
    }
  }

  function togglePreview() {
    if (mode == 'preview') {
      setMode('');
    } else {
      setMode('preview');
    }
  }

  function toggleExample() {
    if (mode == 'example') {
      setMode('');
    } else {
      setMode('example');
    }
  }

  function goBack() {
    if (!saved) {
      //저장하지 않은 항목이 있는 경우.
      setOpenQuitBottomSheet(true);
      return;
    }

    navigate(-1);
  }

  function updateMateData(type: string, value: string) {
    setMate({ ...mate!, [type]: value });
    setSaved(false);
  }

  return (
    <div className={styles['container']}>
      <Header
        onBack={() => goBack()}
        actionType={'signup'}
        actionTitle={mode === 'preview' ? 'Preview' : 'Profile Preview'}
        onAction={() => togglePreview()}
      />
      <div className={styles['form-container']}>
        <div className={styles['profile-image-container']}>
          <div className={styles['profile-image-thumbnail']}>
            <img
              src={
                user!.mate === null
                  ? user?.profile_image !== ''
                    ? `${process.env.REACT_APP_STORE_ADDRESS}/${user!.profile_image}`
                    : profileDefaultIcon
                  : `${process.env.REACT_APP_STORE_ADDRESS}/${user!.mate!.profile_image}`
              }
              alt=''
            />
          </div>
          <div className={styles['profile-information']}>
            <div className={styles['profile-nickname']}>{user!.nickname}</div>
            <div className={styles['profile-company']}>{user!.company}</div>
            <div className={styles['profile-job']}>
              {user!.job !== '' ? `${user!.job}` : ''}
              {user!.annual !== 0 ? ` | ${user!.annual} years` : ''}
            </div>
          </div>
        </div>
        {mode === '' && (
          <div className={styles['example-container']}>
            <div className={styles['example-icon']} />
            <div className={styles['example-description-text']}>Not sure how to write it?</div>
            <div
              className={styles['example-view']}
              onClick={() => toggleExample()}
            >
              View Examples
            </div>
          </div>
        )}
        <div className={styles['introduce-container']}>
          <div className={styles['introduce-title']}>Introduce Yourself in One Sentence</div>
          <div className={styles['introduce-content']}>
            <TextArea
              enable={mode === ''}
              width='100%'
              height='6.9rem'
              paddingTop='1.4rem'
              paddingBottom='0'
              name='introduce_message'
              value={
                mode === 'example'
                  ? 'Data Analyst Specializing in Predictive Modeling'
                  : mate?.introduce_message
              }
              onChange={(event: any) => updateMateData('introduce_message', event.target.value)}
              placeholder='Introduce yourself briefly in one sentence, highlighting your unique strengths..'
            />
          </div>
        </div>
        <div className={styles['introduce-container']}>
          <div className={styles['introduce-title']}>Share Your Career and Experience</div>
          <div className={styles['introduce-content']}>
            <TextArea
              enable={mode === ''}
              width='100%'
              height='9.0rem'
              paddingTop='1.4rem'
              paddingBottom='0'
              name='my_career_message'
              value={
                mode === 'example'
                  ? 'Financial data analyst with 3 years of experience in customer analytics, marketing optimization, and targeted product development. Also served as an in-house LLM instructor.'
                  : mate?.my_career_message
              }
              onChange={(event: any) => updateMateData('my_career_message', event.target.value)}
              placeholder='Share your background and career details. More specifics help people connect with you better!'
            />
          </div>
        </div>
        <div className={styles['introduce-container']}>
          <div
            className={styles['introduce-title']}
            style={{ marginBottom: '4px' }}
          >
            Share Your Expertise (at least 3)
          </div>
          <div className={styles['introduce-description']}>
            Add topics you'd like to share with.
          </div>
          {mate?.chat_subject?.map((e, idx) => (
            <div
              className={styles['introduce-content']}
              style={{ marginBottom: '1.6rem' }}
            >
              <TextArea
                enable={mode === ''}
                paddingTop='1.4rem'
                paddingBottom='1.4rem'
                width='100%'
                height='auto'
                autoResize={true}
                name={`chat_subject_${idx}`}
                value={
                  mode === 'example'
                    ? idx == 0
                      ? 'Example : Preparing for a data career in Japan'
                      : idx == 1
                      ? 'Example: Tips and relevant experiences for data analysts in global companies'
                      : idx == 2
                      ? 'Example: Experience building a data pipeline for a service with 66 million MAU'
                      : ''
                    : e
                }
                onChange={(event: any) => changeChatSubject(idx, event.target.value)}
                placeholder={
                  idx == 0
                    ? 'Add your chat topics'
                    : idx == 1
                    ? 'Add your chat topics'
                    : idx == 2
                    ? 'Add your chat topics'
                    : ''
                }
              />
            </div>
          ))}
          <div
            className={styles['introduce-add-button']}
            onClick={() => addChatSubject()}
          >
            <div>Add</div>
            <img
              src={addIcon}
              alt=''
            />
          </div>
        </div>
        <div className={styles['divider']} />
        <div className={styles['introduce-container']}>
          <div className={styles['introduce-title']}>Who would you recommend this to?</div>
          <div className={styles['introduce-content']}>
            <TextArea
              enable={mode === ''}
              width='100%'
              height='auto'
              paddingTop='1.4rem'
              paddingBottom='0'
              name='recommend_message'
              value={
                mode === 'example'
                  ? 'Entry-level candidates pursuing data analyst positions'
                  : mate?.recommend_message
              }
              onChange={(event: any) => updateMateData('recommend_message', event.target.value)}
              placeholder='Early-career professionals (0-2 years) seeking data analyst or business strategist roles in global markets'
              autoResize={true}
            />
          </div>
        </div>
        <div className={styles['introduce-container']}>
          <div className={styles['introduce-title']}>Who might this not be suitable for?</div>
          <div className={styles['introduce-content']}>
            <TextArea
              enable={mode === ''}
              width='100%'
              height='auto'
              autoResize={true}
              paddingTop='1.4rem'
              paddingBottom='0'
              name='not_recommend_reason_message'
              value={
                mode === 'example'
                  ? 'Experienced professionals (5+ years) in data analysis or business strategy'
                  : mate?.not_recommend_reason_message
              }
              onChange={(event: any) =>
                updateMateData('not_recommend_reason_message', event.target.value)
              }
              placeholder='Experienced professionals (5+ years) in data analysis or business strategy'
            />
          </div>
        </div>
        <div className={styles['divider']} />
        <div className={styles['introduce-container']}>
          <div className={styles['introduce-title']}>Add keywords about yourself</div>
          <div className={styles['introduce-content']}>
            <TextArea
              enable={mode === ''}
              width='100%'
              height='auto'
              autoResize={true}
              paddingTop='1.4rem'
              paddingBottom='0'
              name='hash_tag'
              value={
                mode === 'example'
                  ? '#Data_Analyst, #Seoul, #Data #LLM #ML #MachineLearning #Python #Data_Driven'
                  : mate?.hash_tag
              }
              onChange={(event: any) => updateMateData('hash_tag', event.target.value)}
              placeholder={'Describe yourself with English tags.\r\nThey help others find you!'}
            />
          </div>
        </div>
        <div className={styles['divider']} />
        <div className={styles['introduce-container']}>
          <div
            className={styles['introduce-title']}
            style={{ marginBottom: '4px' }}
          >
            Update Mate Profile Photo
          </div>
          <div className={styles['introduce-description']}>
            Choose from your main profile pictures or upload a new one.
          </div>
          <div className={styles['profile-image-container']}>
            <div
              className={styles['profile-image-thumbnail-mate']}
              onClick={mode === '' ? () => setOpenBottomSheet(true) : undefined}
            >
              <img
                src={
                  mate?.profile_image !== undefined && mate?.profile_image !== ''
                    ? `${
                        !mate?.profile_image.includes('http')
                          ? process.env.REACT_APP_STORE_ADDRESS + '/'
                          : ''
                      }${mate?.profile_image}`
                    : profileDefaultIcon
                }
                alt=''
              />
              <form
                encType='multipart/form-data'
                onSubmit={uploadFile}
              >
                <input
                  type='file'
                  style={{ display: 'none' }}
                  ref={thumbnailRef}
                  accept='image/*'
                  onChange={onChangeImageUpload}
                />
              </form>
              {(mate?.profile_image === undefined || mate?.profile_image === '') && mode === '' && (
                <div className={styles['profile-image-camera-icon']}>
                  <img
                    src={cameraIcon}
                    alt=''
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles['divider']} />
        <div className={styles['introduce-container']}>
          <div className={styles['introduce-title']}>Running your own channel? (Optional)</div>
          <div className={styles['introduce-content']}>
            <TextArea
              enable={mode === ''}
              width='100%'
              height='auto'
              autoResize={true}
              paddingTop='1.4rem'
              paddingBottom='0'
              name='youtube_url'
              value={mode === 'example' ? 'www.youtube.com/@Nailedit_user' : mate?.youtube_url}
              onChange={(event: any) => updateMateData('youtube_url', event.target.value)}
              placeholder={'If you have a relevant SNS channel,add the link here.'}
            />
          </div>
        </div>
        <div className={styles['divider']} />
        <div className={styles['introduce-container']}>
          <div className={styles['introduce-title']}>
            {'Add links to your other professional\r\nbchannels or portfolios'}
          </div>
          <div className={styles['introduce-description-link']}>Link</div>
          <div className={styles['introduce-content']}>
            <TextField
              enable={mode === ''}
              width='100%'
              height='4.8rem'
              name='etc_link_url'
              value={mode === 'example' ? 'x.com/@nailedit_user' : mate?.etc_link_url}
              onChange={(event: any) => updateMateData('etc_link_url', event.target.value)}
              placeholder={'Add url'}
            />
          </div>
        </div>
      </div>
      {mode != 'example' && (
        <div className={styles['button-container']}>
          <Button
            width={'100%'}
            active={true}
            onClick={() => saveTemporary()}
          >
            Save Progress
          </Button>
          <div style={{ marginRight: '5px' }} />
          <Button
            width={'100%'}
            active={true}
            onClick={() => saveMate({ validCheck: true })}
          >
            Submit
          </Button>
        </div>
      )}
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setOpenBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '17.3rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-container']}>
              <div className={styles['sheet-title']}>Edit Profile Photo</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBottomSheet(false)}
              />
            </div>
            <div
              className={styles['sheet-content']}
              onClick={() => selectThumbnail()}
            >
              Choose from Library
            </div>
            <div
              className={styles['sheet-content']}
              onClick={() => deleteProfileThumbnail()}
            >
              Delete photo
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheet(false)} />
      </Sheet>
      <Sheet
        isOpen={isOpenQuitBottomSheet}
        detent='content-height'
        onClose={() => setOpenQuitBottomSheet(false)}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px' }}>
          <Sheet.Content style={{ marginBottom: '2.0rem' }}>
            <div className={styles['sheet-container']}>
              <div className={styles['sheet-title']}>Are you sure you want to leave?</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenQuitBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-content-container']}>
                <div className={styles['sheet-content']}>
                  {'Save as draft to keep your progress and edit later.'}
                </div>
              </div>
              <div className={styles['sheet-button-container']}>
                <Button
                  width={'16.9rem'}
                  height={'4.8rem'}
                  radius={10}
                  activeFontSize='1.5rem'
                  activeFontWeight={600}
                  activeTextColor='#343437'
                  active={true}
                  activeColor={'#EBEDF0'}
                  onClick={() => quit()}
                >
                  Leave for Now
                </Button>
                <Button
                  width={'16.9rem'}
                  height={'4.8rem'}
                  radius={10}
                  activeFontSize='1.5rem'
                  activeFontWeight={600}
                  activeTextColor='#FFFFFF'
                  active={true}
                  onClick={() => saveTemporary()}
                >
                  Save Progress
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenQuitBottomSheet(false)} />
      </Sheet>
    </div>
  );
}

export default MateIntroduce;
