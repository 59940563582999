import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MeetingConfirm.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';

import searchIcon from '../../assets/images/ic_search.png';

function MeetingConfirm() {
  const navigate = useNavigate();
  const toast = useToast();
  const requestContent = "Hi, I'm looking to work as a data analyst in Japan.\r\nI'd like to learn:\r\nEssential steps to become a data analyst in Japan as an international candidate\r\nKey technical skills and popular tools\r\nDay-to-day challenges and workplace culture in Japanese data teams";

  return (
    <div>
      <Header/>
      <div className={styles['title-text']}>Accept / Decline Meeting</div>
      <div>
        <div className={styles['sub-text']} style={{marginBottom: '0.8rem'}}>Meeting Awaiting Acceptance</div>
        <div className={styles['meeting-container']}>
          <div className={styles['row']}>
            <div className={styles['profile-thumbnail']}><img src={'https://maxst.icons8.com/vue-static/icon/landing/plugins/windows.svg'} alt=""/></div>
            <div className={styles['description-text']}>30min Coffee Chat with JobSeekerUSA</div>
          </div>
          <div className={styles['price-text']}>$40</div>
        </div>
      </div>
      <div style={{marginTop: '4.0rem'}}/>
      <div className={styles['sub-text']} style={{marginBottom: '1.4rem'}}>Please select an available time</div>
      <div className={`${styles['time-container']} ${styles['time-container-selected']}`} style={{marginBottom: '1.2rem'}}>
        <div className={styles['time-information']}>
          <div className={styles['time-text']} style={{marginBottom: '0.4rem'}}>7/23(TUE) Time Information</div>
          <div className={styles['time-text']}>06:30 PM ~ 07:00 PM</div>
        </div>
        <img className={styles['check-icon']} src={searchIcon} alt=""/>
      </div>
      <div className={`${styles['time-container']} ${styles['time-container-selected']}`}>
        <div className={styles['time-information']}>
          <div className={styles['time-text']} style={{marginBottom: '0.4rem'}}>7/23(TUE) Time Information</div>
          <div className={styles['time-text']}>07:30 PM ~ 08:00 PM</div>
        </div>
        <img className={styles['check-icon']} src={searchIcon} alt=""/>
      </div>
      <div style={{marginTop: '4.0rem'}}/>
      <div className={styles['sub-text']} style={{marginBottom: '1.4rem'}}>Requester's message:</div>
      <div className={styles['content-container']} style={{marginBottom: '1.0rem'}}>{requestContent}</div>
      <div className={styles['content-container']}>Hi, I'm looking to work as a data analyst in Japan.</div>
      <div style={{marginTop: '4.0rem'}}/>
      <div className={styles['sub-text']} style={{marginBottom: '1.4rem'}}>JobSeekerUSA's Information</div>
      <div className={styles['content-container']}>
        <div className={styles['profile-big-thumbnail']} style={{marginBottom: '1.6rem'}}>
          <img src={'https://maxst.icons8.com/vue-static/icon/landing/plugins/windows.svg'} alt=""/>
        </div>
        {requestContent}
        <div className={styles['content-divider']}/>
        <div className={styles['content-title']}>JobSeekerUSA's Introduction</div>
        <div className={styles['content-description']}>
          Made my living as a data analyst for years. My literature degree hangs on a wall somewhere
        </div>
        <div className={styles['profile-check-text']}>View Profile</div>
      </div>
      <div style={{marginTop: '4.0rem'}}/>
      <div className={styles['sub-text']} style={{marginBottom: '0.4rem'}}>Requester's attachment</div>
      <div className={styles['file-container']} style={{marginBottom: '0.8rem'}}>yourfile.pdf</div>
      <div className={styles['file-container']}>yourfile.pdf</div>
      <div style={{marginTop: '4.0rem'}}/>
      <div className={styles['button-container']}>
        <Button 
            width='100vw'
            height={'4.8rem'}
            radius={10}
            activeColor='#E8EAF0'
            activeFontSize='1.5rem' 
            activeFontWeight={600}
            activeTextColor='#1D1D1E'
            active={true}
            onClick={() => alert('sss')}>Decline</Button>
        <Button  
            width='100vw'
            height={'4.8rem'}
            radius={10}
            activeColor='#00B76A'
            activeFontSize='1.4rem' 
            activeFontWeight={600}
            activeTextColor='#FFFFFF'
            active={true}
            onClick={() => alert('sss')}>Accept</Button>
      </div>
      <div style={{marginTop: '12.0rem'}}/>
    </div>
  );
}
  
export default MeetingConfirm;
  