import styles from './MeetingCompleteCard.module.css';
import Button from '../../../components/element/Button';
import dateIcon from '../../../assets/images/ic_date.png';
import profileIcon from '../../../assets/images/ic_profile_default.png';

interface MeetingCompleteCardProps {
  productName: string;
  // statusText: string;
  nickName: string;
  companyName: string;
  meetingTime?: string | Date | null;
  profileImage: string;
  useButtonCount: number;
  productTime: number;
  // 닉네임 밑줄 on off
  useUnderLine?: boolean;
  useBgColor?: boolean;
  useBorder?: boolean;
  actionTitle1?: string;
  actionTitle2?: string;
  actionTitle3?: string;
  onAction1?: () => void;
  onAction2?: () => void;
  onAction3?: () => void;
}

const MeetingCompleteCard: React.FC<MeetingCompleteCardProps> = ({
  productName = '',
  // statusText = '',
  nickName = '',
  profileImage = '',
  companyName = '',
  meetingTime = '',
  productTime,
  useButtonCount = 1,
  useUnderLine = true,
  useBgColor = false,
  actionTitle1 = '',
  actionTitle2 = '',
  actionTitle3 = '',
  onAction1,
  onAction2,
  onAction3,
}) => {
  function formatDateToCustomFormat(meetingDate: Date): string {
    const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const month = meetingDate.getMonth() + 1; // 월 (0부터 시작)
    const day = meetingDate.getDate(); // 일
    const dayOfWeekString = dayOfWeek[meetingDate.getDay()]; // 요일
    const hours = meetingDate.getHours().toString().padStart(2, '0');
    const minutes = meetingDate.getMinutes().toString().padStart(2, '0');
    const getHours = meetingDate.getHours();
    const period = getHours >= 12 ? 'PM' : 'AM';
    return `${month}/${day}(${dayOfWeekString})  ${hours}:${minutes} ${period}`;
  }

  const meetingEndTime = (meetingDate: Date, duration: number): string => {
    if (!meetingDate) return '';

    const endDate = new Date(meetingDate);
    endDate.setMinutes(endDate.getMinutes() + duration);

    const hours = endDate.getHours().toString().padStart(2, '0');
    const minutes = endDate.getMinutes().toString().padStart(2, '0');
    const getHours = meetingDate.getHours();
    const period = getHours >= 12 ? 'PM' : 'AM';

    return `${hours}:${minutes} ${period}`;
  };

  return (
    <div>
      <div
        className={`${styles['card-container']} ${
          useBgColor ? styles['bgColor'] : styles['no-bgColor']
        }`}
      >
        <div className={styles['card-row']}>
          <img
            className={styles['card-thumbnail']}
            src={profileImage || profileIcon}
            alt=''
            width='5.6rem'
            height='5.6rem'
          />
          <div className={styles['card-meet-info-container']}>
            <div className={styles['card-row']}>
              <div className={styles['card-meet-product-name']}>
                {productTime}min {productName}
              </div>
              {/* <div className={styles['card-tag']}>{statusText}</div> */}
            </div>
            <div
              className={styles['card-row']}
              style={{ marginBottom: '0.6rem' }}
            >
              <div
                className={`${styles['card-profile-name']} ${
                  useUnderLine ? styles['underline'] : styles['no-underline']
                }`}
              >
                {nickName}
              </div>
              <div className={styles['card-company']}>{companyName}</div>
            </div>
            <div className={styles['card-row']}>
              <img
                src={dateIcon}
                alt=''
              />
              <div className={styles['card-description']}>
                {' '}
                {meetingTime ? formatDateToCustomFormat(new Date(meetingTime)) : 'dd'} ~{' '}
                {meetingTime ? meetingEndTime(new Date(meetingTime), productTime) : 'dd'}
              </div>
            </div>
          </div>
        </div>
        {useButtonCount >= 1 && (
          <div
            className={styles['card-row']}
            style={{ marginTop: '1.9rem', paddingLeft: '1.2rem', paddingRight: '1.2rem' }}
          >
            <Button
              width={'100vw'}
              height={'4rem'}
              activeBorderColor='#C9CDDA'
              activeColor='#FFFFFF'
              activeTextColor='#000000'
              activeFontSize='1.4rem'
              radius={10}
              activeFontWeight={600}
              active={true}
              onClick={onAction1}
            >
              {actionTitle1}
            </Button>
            {useButtonCount >= 2 && <div style={{ marginLeft: '0.5rem' }} />}
            {useButtonCount >= 2 && (
              <Button
                width={'100vw'}
                height={'4rem'}
                activeBorderColor='#C9CDDA'
                activeColor='#FFFFFF'
                activeTextColor='#000000'
                activeFontSize='1.4rem'
                radius={10}
                activeFontWeight={600}
                active={true}
                onClick={onAction2}
              >
                {actionTitle2}
              </Button>
            )}
          </div>
        )}
        {useButtonCount >= 3 && (
          <div
            className={styles['card-row']}
            style={{ marginTop: '0.5rem', paddingLeft: '1.2rem', paddingRight: '1.2rem' }}
          >
            <Button
              width={'100vw'}
              height={'4rem'}
              activeBorderColor='#C9CDDA'
              activeColor='#FFFFFF'
              activeTextColor='#000000'
              activeFontSize='1.4rem'
              radius={10}
              activeFontWeight={600}
              active={true}
              onClick={onAction3}
            >
              {actionTitle3}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingCompleteCard;
