import React from 'react';
import styles from './Button.module.css';

interface ButtonProps {
  children: string;
  width?: string;
  height?: string;
  active?: boolean;
  marginTop?: number;
  marginRight?: number;
  marginLeft?: number;
  paddingHorizontalRem?: number;
  marginBottom?: number;
  marginHorizontalRem?: number;
  radius?: number;
  activeBorderColor?: string;
  activeColor?: string;
  inactiveColor?: string;
  activeTextColor?: string;
  inactiveTextColor?: string;
  activeFontWeight?: number;
  activeFontSize?: string;
  inactiveFontWeight?: number;
  inactiveFontSize?: string;
  bgColor?: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  width,
  paddingHorizontalRem,
  height = '5.6rem',
  active = false,
  marginTop = null,
  marginRight = '',
  marginLeft = '',
  marginBottom = null,
  marginHorizontalRem = 0,
  radius = 12,
  activeBorderColor = null,
  activeColor = null,
  inactiveColor = null,
  activeTextColor = null,
  inactiveTextColor = null,
  activeFontWeight = null,
  activeFontSize = null,
  inactiveFontWeight = null,
  inactiveFontSize = null,
  bgColor = '#00B76A',
  onClick,
}) => {
  return (
    <div
      className={styles.container}
      style={{
        border: activeBorderColor != null ? `1px solid ${activeBorderColor}` : undefined,
        borderRadius: `${radius}px`,
        backgroundColor: `${
          active
            ? activeColor != null
              ? activeColor
              : bgColor
            : inactiveColor != null
            ? inactiveColor
            : '#D8D9DC'
        }`,
        marginLeft: `${marginHorizontalRem ? marginHorizontalRem : marginLeft}rem`,
        marginRight: `${marginHorizontalRem ? marginHorizontalRem : marginRight}rem`,
        marginTop: `${marginTop}rem`,
        marginBottom: `${marginBottom}rem`,
        width: width,
        height: height,
        paddingRight: `${paddingHorizontalRem}rem`,
        paddingLeft: `${paddingHorizontalRem}rem`,
        lineHeight: height,
        color: `${active ? activeTextColor : inactiveTextColor}`,
        fontWeight: `${
          active
            ? activeFontWeight != null
              ? activeFontWeight
              : 600
            : inactiveFontWeight != null
            ? inactiveFontWeight
            : 600
        }`,
        fontSize: `${
          active
            ? activeFontSize != null
              ? activeFontSize
              : '1.6rem'
            : inactiveFontSize != null
            ? inactiveFontSize
            : '1.6rem'
        }`,
        boxSizing: 'border-box',
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Button;
