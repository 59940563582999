import { destroy, get, post, put } from '../utils/apiClient';

class CommunityService {
  // 커뮤니티 게시물 불러오기
  public async getList() {
    try {
      const response = await get(`/community/list`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 커뮤니티 게시물 작성
  public async addPost(
    countryCode: string,
    countryName: string,
    category: string,
    subject: string,
    content: string
  ) {
    try {
      const response = await post(
        `/community/add?counrtyCode=${countryCode}&countryName=${countryName}&category=${category}&subject=${subject}&content=${content}`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 커뮤니티 게시물 수정
  public async modifyPost(
    countryCode: string,
    countryName: string,
    category: string,
    subject: string,
    content: string
  ) {
    try {
      const response = await put(
        `/community/add?counrtyCode=${countryCode}&countryName=${countryName}&category=${category}&subject=${subject}&content=${content}`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 커뮤니티 게시물 삭제
  public async deletePost(id: number) {
    try {
      const response = await destroy(`/community/delete`, { data: id });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
}

const communityService = new CommunityService();
export default communityService;
