import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/layout/Header';
import styles from './CustomerServiceFaq.module.css';
import emailIcon from '../../assets/images/ic_email.png';
import outlinkIcon from '../../assets/images/ic_outlink.png';
import faqData from '../../assets/data/faq.json';

function CustomerServiceFaq() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    
  }, []);

  const toMail = () => {
    navigate('/cs/customerService?tab=cs');
  };
  
  function goNotionFAQPage() {
    window.open('https://www.notion.so/Nailedit-FAQ-12b50a7d0c928043b4cbf90ecd7fb54b?pvs=4', "_blank", "noopener, noreferrer");
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['faq-container']}>
        <div className={styles['title']}>{faqData[parseInt(searchParams.get('id')!) - 1].subject}</div>
        <ul className={styles['content']}>
          <li>{faqData[parseInt(searchParams.get('id')!) - 1].content}
          </li>
        </ul>
        <div className={styles['faq-more-container']}>
          <div className={styles['faq-more-text']}>
            {'Do you have any other questions?\r\nCheck the Notion link below for more info'}
          </div>
          <div className={styles['faq-more-link']} onClick={() => goNotionFAQPage()}>
            {'[Nailedit Notion Link]'}
            <img
              src={outlinkIcon}
              alt=''
            />
          </div>
          <div className={styles['faq-more-text']}>
            {'Is there an issue that was not resolved by the Q&A?\r\nPlease send an email to the Help Center.'}
          </div>
          <div
            className={styles['faq-more-link']}
            onClick={toMail}
          >
            {'[Send email to Help Center]'}
            <img
              src={emailIcon}
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerServiceFaq;
