import styles from './MeetingSuggestCard.module.css';
import Button from '../../../components/element/Button';
import { ProductType, MateStatus } from '../../../constants/constants';
import defaultProfileImg from '../../../assets/images/ic_profile_default.png'; // 기본 프로필 이미지

interface MeetingSuggestCardProps {
  type: ProductType;
  price: number; //상품 가격.
  profileImage: string; //이미지.
  mateTag?: string; //메이트 태그
  suggestNickName: string; //제안한 사람 닉네임.
  mateStatus: MateStatus;
  onAction?: () => void;
  goProfile?: () => void;
}

const MeetingSuggestCard: React.FC<MeetingSuggestCardProps> = ({
  type,
  price,
  profileImage,
  mateTag,
  suggestNickName,
  mateStatus,
  onAction,
  goProfile,
}) => {
  return (
    <div>
      <div className={styles['card-container']}>
        <div className={styles['card-row']}>
          <div className={styles['card-suggest-type-text']}>
            {type === 'COFFEECHAT'
              ? 'Coffee Chat'
              : type === 'INTERVIEW'
              ? 'Interview Practice'
              : type === 'REVIEW'
              ? 'Resume/Portfolio Review'
              : 'Undefinded'}
          </div>
          {/* <div className={styles['card-price']}>${price}</div> */}
        </div>
        <div style={{ marginTop: '0.6rem' }} />
        <div className={styles['card-row']}>
          <img
            className={styles['card-thumbnail']}
            src={profileImage || defaultProfileImg}
            alt=''
            width='5.6rem'
            height='5.6rem'
          />
          <div className={styles['card-mate-nickname']}>{mateTag}</div>
          <div
            className={styles['card-suggest-nickname']}
            onClick={goProfile}
          >
            {suggestNickName}
          </div>
          <div className={styles['card-suggest-text']}>'s Proposal</div>
        </div>
        <div
          className={styles['card-row']}
          style={{ marginTop: '1.9rem', paddingLeft: '1.2rem', paddingRight: '1.2rem', justifyContent: 'center'}}
        >
          {mateStatus === MateStatus.WAITING ?
          <Button
            width={'100%'}
            height={'4rem'}
            activeBorderColor='#C9CDDA'
            activeColor='#FFFFFF'
            activeTextColor='#000000'
            activeFontSize='1.4rem'
            radius={10}
            activeFontWeight={600}
            active={true}
            onClick={onAction}
          >
            Accept / Decline Meeting
          </Button> : 
            <div className={styles['card-suggest-text']}>Waiting for the requester's reponse</div>
          }
        </div>
      </div>
    </div>
  );
};

export default MeetingSuggestCard;
