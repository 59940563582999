import { get, post, put } from '../utils/apiClient';
import { ProductType, ProductStatus } from '../constants/constants';
import { Product } from '../models/product';
import { ProductReview } from '../models/productReview';

//상품 관련 API
class ProductService {
  public async getMainReviews(): Promise<ProductReview[] | undefined> {
    try {
      const response = await get(`/product/mainReviews`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getList() {
    try {
      const response = await get(`/product/list`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getTargetProduct(productId: number) {
    try {
      const response = await get(`/product/get?productId=${productId}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async addProduct(product: Product): Promise<Product | undefined> {
    try {
      const response = await post('/product/addProduct', {
        product,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async updateProductPrice(productId: number, price: number) {
    try {
      const response = await put('/product/updateProductPrice', {
        productId,
        price,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async updateProductStatus(
    productId: number,
    productStatus: ProductStatus
  ): Promise<string | undefined> {
    try {
      const response = await put('/product/updateProductStatus', {
        productId,
        productStatus,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  //리뷰 대표 여부 처리
  public async updateMainReview(reviewId: number, on: boolean): Promise<ProductReview | undefined> {
    try {
      const response = await put('/product/updateMainReview', {
        reviewId,
        on,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async getAverage(type: ProductType) {
    try {
      const response = await get(`/product/getAverage?type=${type}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  // 리뷰

  public async addReview(productReview: ProductReview): Promise<ProductReview | undefined> {
    try {
      const response = await post('/product/addReview', { productReview });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 리뷰 불러오기

  public async getReview(): Promise<ProductReview[] | undefined> {
    try {
      const response = await get('/product/reviews');
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 메이트 리뷰 목록 불러오기

  public async getMateReviews(mateUserId: number): Promise<ProductReview[] | undefined> {
    try {
      const response = await get(`/product/getMateReviews?mateUserId=${mateUserId}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
}

const productService = new ProductService();
export default productService;
