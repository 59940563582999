import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './CustomerService.module.css';

import questionIcon from '../../assets/images/ic_question.png';
import emailIcon from '../../assets/images/ic_email.png';
import outlinkIcon from '../../assets/images/ic_outlink.png';
import unselectedIcon from '../../assets/images/ic_radio_unselected.png';
import selectedIcon from '../../assets/images/ic_radio_selected.png';
import TextArea from '../../components/element/TextArea';
import { SubjectCategory } from '../../models/subject';
import TextField from '../../components/element/TextField';
import useToast from '../../hook/useToast';
import faqData from '../../assets/data/faq.json';
import EtcService from '../../services/etcService';

function CustomerService() {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [ask, setAsk] = useState<string>('');
  const [selectedTabName, setSelectedTabName] = useState<string>('faq');
  // 선택된 주제 ID를 저장하는 상태
  const [selectedSubject, setSelectedSubject] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  //고객센터 주제 목록.
  const [subjectList, setSubjectList] = useState<SubjectCategory[]>([
    { id: 1, name: 'Applying for mate' },
    { id: 2, name: 'Meeting Issues' },
    { id: 3, name: 'Payment' },
    { id: 4, name: 'Process Payment' },
    { id: 5, name: 'Privacy Policy' },
    { id: 6, name: 'Report a Problem' },
    { id: 7, name: 'Service Quality Improvement' },
    { id: 8, name: 'Other / Suggestions' },
  ]);

  useEffect(() => {
    if(searchParams.get('tab') !== null) {
      setSelectedTabName(searchParams.get('tab')!);
      if(containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    }
  }, []);

  // 주제 선택 함수
  const selectSubject = (id: number) => {
    setSelectedSubject(id === selectedSubject ? null : id); // 클릭한 주제가 이미 선택된 상태면 해제
  };

  const mailSend = async () => {
    if(selectedSubject === null) {
      toast('error', 'Please select a subject', false);
      return;
    }

    if(ask.length < 10) {
      toast('error', 'Please enter the contents', false);
      return;
    }
    
    if(email.length < 10) {
      toast('error', 'Please check email address', false);
      return;
    }

    const subject = subjectList[selectedSubject - 1].name;
    const result = await EtcService.sendContact(subject, ask, email);
    if(result !== undefined) {
      navigate('/cs/customerService/mailCompleted');
    }else{
      toast('error', "Couldn't send. Try again.", false);
    }
  };

  const toMail = () => {
    setSelectedTabName('cs');
    if(containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };

  function goNotionFAQPage() {
    window.open('https://www.notion.so/Nailedit-FAQ-12b50a7d0c928043b4cbf90ecd7fb54b?pvs=4', "_blank", "noopener, noreferrer");
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['form-container']} ref={containerRef}>
        <div className={styles['label-container']}>Support</div>
        <div className={styles['tab-container']}>
          <div
            className={`${styles['tab-item']} ${
              selectedTabName === 'faq' ? styles['tab-item-selected'] : ''
            }`}
            onClick={() => setSelectedTabName('faq')}
          >
            FAQ
            <div
              className={`${styles['tab-line']} ${
                selectedTabName === 'faq' ? styles['tab-line-selected'] : ''
              }`}
            />
          </div>
          <div
            className={`${styles['tab-item']} ${
              selectedTabName === 'cs' ? styles['tab-item-selected'] : ''
            }`}
            onClick={() => setSelectedTabName('cs')}
          >
            Support
            <div
              className={`${styles['tab-line']} ${
                selectedTabName === 'cs' ? styles['tab-line-selected'] : ''
              }`}
            />
          </div>
        </div>
        <div className={styles['tab-all-line']} />
        {selectedTabName === 'faq' && (
          <div className={styles['faq-container']}>
            {faqData.length > 0 && faqData.map((e, idx) => 
              <div
                key={idx}
                className={styles['faq-item']}
                onClick={() => navigate(`/cs/customerService/faq?id=${idx + 1}`)}
              >
                <img
                  src={questionIcon}
                  alt=''
                />
                <div>{e.subject}</div>
              </div>
            )}
            <div className={styles['faq-more-container']}>
              <div className={styles['faq-more-text']}>
                {'Still have questions? Check our Notion guide'}
              </div>
              <div className={styles['faq-more-link']} onClick={() => goNotionFAQPage()}>
                {'[Nailedit Notion Link]'}
                <img
                  src={outlinkIcon}
                  alt=''
                />
              </div>
              <div className={styles['faq-more-text']}>
                {'Need more help? Email our support team'}
              </div>
              <div
                className={styles['faq-more-link']}
                onClick={toMail}
              >
                {'[Send email]'}
                <img
                  src={emailIcon}
                  alt=''
                />
              </div>
            </div>
          </div>
        )}
        {selectedTabName === 'cs' && (
          <div className={styles['cs-container']}>
            <div className={styles['cs-sub-title']}>
              {"We'll read your message and get back to you soon"}
            </div>
            <div className={styles['cs-select-container']}>
              <div className={styles['cs-title']}>{'Select your topic'}</div>

              {subjectList.map((subject) => (
                <div
                  className={styles['cs-subject']}
                  key={subject.id}
                  onClick={() => subject.id && selectSubject(subject.id)}
                >
                  <img
                    src={selectedSubject === subject.id ? selectedIcon : unselectedIcon}
                    alt=''
                  />
                  {subject.name}
                </div>
              ))}
            </div>
            <div className={styles['cs-mail-container']}>
              <div className={styles['cs-mail-title']}>{'Tell us how we can help'}</div>
              <TextArea
                placeholder='Tell us how we can help'
                useCount={true}
                maxLength={500} // 원하는 글자 수 제한
                width='unset'
                autoResize={true}
                useBgColor={true}
                value={ask}
                onChange={(e) => setAsk(e.target.value)}
                marginLeft='1.6rem'
                marginRight='1.6rem'
                border='none'
                useFocus={true}
              />
              <div className={styles['cs-mail-title']}>
                {'Where should we reply?'}
              </div>
              <TextField
                placeholder='your_email@nailedtiapp.com'
                width='unset'
                marginLeft='1.6rem'
                marginRight='1.6rem'
                actionButtonEnable={true}
                actionButtonTitle='Change'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className={styles['faq-more-container']}>
                <div className={styles['faq-more-text']}>
                  {'Still have questions? Check our Notion guide'}
                </div>
                <div className={styles['faq-more-link']} onClick={() => goNotionFAQPage()}>
                  {'[Nailedit Notion Link]'}
                  <img
                    src={outlinkIcon}
                    alt=''
                  />
                </div>
                <div className={styles['faq-more-text']}>
                  {'Need more help? Email our support team'}
                </div>
              </div>
            </div>
            <div className={styles['button-container']}>
              <Button
                width={'100%'}
                marginHorizontalRem={2.0}
                active={ask.length >= 10 && email.length >= 10} // ask의 길이가 10 이상일 때만 active
                onClick={mailSend}
              >
                Send
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomerService;
