import React from 'react';
import styles from './CommunityPost.module.css';
import replyIcon from '../../../assets/images/ic_reply_filled.svg';
import { useNavigate } from 'react-router-dom';
import { format, isToday } from 'date-fns';

interface CommunityPostProps {
  id: number;
  title: string;
  date: Date | string;
  views: number;
  category: string;
  replies: number;
}

const CommunityPost = ({
  id,
  title,
  date,
  views,
  category,
  replies,
}: CommunityPostProps) => {
  const navigate = useNavigate();

  const handlePostClick = () => {
    navigate(`/community/article/${id}`, {
      state: {
        id,
        title,
        date,
        views,
        category,
        replies,
      },
    });
  };

  // 날짜 포맷 결정
  const formattedDate = isToday(new Date(date))
    ? format(new Date(date), 'HH:mm') // 오늘이라면 HH:mm 형식
    : format(new Date(date), 'MMM dd, yyyy'); // 그렇지 않다면 기존 형식

  return (
    <div onClick={handlePostClick} className={styles.postContainer}>
      <div className={styles.postContent}>
        <div className={styles.postHeader}>
          <span className={styles.postTitle}>{title}</span>
          <div className={styles.postDetails}>
            <div className={styles.postInfo}>
              <span className={styles.postDate}>{formattedDate}</span>
              <span className={styles.postViews}>Views {views}</span>
              <span className={styles.postCategory}>{category}</span>
            </div>
            <div className={styles.postReplyChip}>
              <img src={replyIcon} alt='Reply Icon' />
              <span className={styles.replyCount}>{replies}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityPost;
