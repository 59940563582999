'use client'
import ReactDOM from 'react-dom'
import styles from './Popup.module.css';
import Modal from 'react-modal';

interface PopupProps {
  children: any;
  show: boolean;
  width: string;
  height: string;
  onClose?(): void;
}

const Popup: React.FC<PopupProps> = ({ 
  children,
  show,
  width, 
  height, 
  onClose
}) => { 
  const customModalStyles: ReactModal.Styles = {
    overlay: {
      backgroundColor: " rgba(0, 0, 0, 0.32)",
      width: "100%",
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
    },
    content: {
      width: width,
      height: height,
      position: "absolute",
      margin: 0,
      padding: 0,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      backgroundColor: "white",
      justifyContent: "center",
      overflow: "auto",
    },
  };

  return <Modal isOpen={show} style={customModalStyles} ariaHideApp={false} shouldCloseOnOverlayClick={true} onRequestClose={onClose}>{children}</Modal>
}

export default Popup;