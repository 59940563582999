import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './SetPassword.module.css';
import TextField from '../../components/element/TextField';
import { useState } from 'react';
import useToast from '../../hook/useToast'; // 토스트 훅 추가
import { useLocation } from 'react-router-dom';
import { User } from '@/models/user';
import authService from '../../services/authService';

function SetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast(); // 토스트 훅 초기화
  const { email } = location.state || { email: '' };

  const [formData, setFormData] = useState<any>({
    email: '',
    emailVerifyCode: '',
    password: '',
    confirmPassword: '', // confirmPassword 추가
  });

  // console.log(email);

  const [errorMessage, setErrorMessage] = useState<string>(''); // 오류 메시지 상태

  const inputRules: any = {
    password: {
      minLength: 8,
      maxLength: 20,
      pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/,
    },
    confirmPassword: {
      minLength: 8,
      maxLength: 20,
      pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/,
    },
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const rule = inputRules[name];
    let formattedValue = value;

    if (rule.maxLength && value.length > rule.maxLength) {
      formattedValue = value.slice(0, rule.maxLength); // 글자 수 초과하지 않게.
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  // 비밀번호가 유효한지 확인하는 함수
  const isPasswordValid = () => {
    return (
      formData.password.length >= 8 &&
      formData.password.length <= 16 &&
      /[A-Z]/.test(formData.password) &&
      /\d/.test(formData.password) &&
      /[!@#$%^&*]/.test(formData.password) // 특수문자 포함 여부 체크
    );
  };

  const handleChangePassword = async () => {
    if (!isPasswordValid()) {
      const message = 'Invalid Password';
      setErrorMessage(message); // 오류 메시지 상태 업데이트
      toast('error', message, false); // 토스트 호출
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      const message = 'Passwords do not match';
      setErrorMessage(message); // 오류 메시지 상태 업데이트
      toast('error', message, false); // 토스트 호출
      return;
    }

    // 비밀번호 변경 api 호출
    try {
      const result = await authService.changePassword(email, formData.password);

      if (result['type'] === 'success') {
        toast('success', 'Password changed and logged in.', false);
        navigate('/'); // 비밀번호 변경 후 이동할 페이지로 변경
      } else if (result['message'] === 'same password') {
        toast('error', 'New password is the same as the old one.', false); // 실패 메시지 출력
      }
    } catch (err) {
      toast('error', 'A network error occurred', false); // 실패 메시지 출력
    }
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-container']}>{'Please enter the new password'}</div>
      <div className={styles['text']}>
        {`Create a password with 8-16 characters\r\nincluding letters, numbers, and symbols.`}
      </div>
      <div className={styles['label-flex']}>
        <label className={styles['label']}>Password</label>
        <label className={styles['check']}>
          {errorMessage && formData.password && formData.password !== formData.confirmPassword
            ? ''
            : errorMessage}
        </label>{' '}
      </div>
      {/* 비밀번호가 일치하지 않을 때만 메시지 표시 */}
      <div className={styles['input-field-container']}>
        <TextField
          name='password'
          type='password'
          value={formData.password}
          placeholder='Enter your password (min 8 characters)'
          onChange={(event) => handleChange(event)}
          onClear={() => setFormData({ ...formData, password: '' })}
        />
      </div>
      <div className={styles['label-flex']}>
        <label className={styles['label']}>Confirm password</label>
        <label className={styles['check']}>
          {formData.password !== formData.confirmPassword && formData.confirmPassword
            ? 'Passwords do not match'
            : ''}
        </label>{' '}
      </div>

      {/* 비밀번호 확인이 있을 때만 메시지 표시 */}
      <div className={styles['input-field-container']}>
        <TextField
          name='confirmPassword'
          type='password' // 비밀번호 확인 입력란
          value={formData.confirmPassword}
          placeholder='Re-enter your password'
          onChange={(event) => handleChange(event)}
          onClear={() => setFormData({ ...formData, confirmPassword: '' })}
        />
      </div>
      <Button
        marginHorizontalRem={2.0}
        active={isPasswordValid() && formData.password === formData.confirmPassword} // 비밀번호 유효성 검사 및 일치 여부로 버튼 활성화
        onClick={handleChangePassword} // 버튼 클릭 시 처리 함수 호출
      >
        Change Password
      </Button>
    </div>
  );
}

export default SetPassword;
