import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Header from '../../components/layout/Header';
import styles from './MyManageReview.module.css';
import starIcon from '../../assets/images/ic_star.png'; // Ratings 아이콘 경로 추가
import { useNavigate } from 'react-router-dom';
import { ProductType } from '../../constants/constants';
import { ProductReview } from '../../models/productReview';
import Button from '../../components/element/Button';
import productService from '../../services/productService';
import default_profile from '../../assets/images/ic_profile_default.png';
import useGlobalStore from '../../stores/globalStore';
import { error } from 'console';

function MyManageReview() {
  const [reviews, setReviews] = useState<ProductReview[]>([]);
  const navigate = useNavigate();
  const { user, setUser } = useGlobalStore();

  useEffect(() => {
    reviewsData(); // 컴포넌트 마운트 시 데이터 가져오기
  }, []);

  const reviewsData = async () => {
    try {
      const reviewData = await productService.getMateReviews(user?.mate!.id!);
      if (reviewData && reviewData.length > 0) {
        setReviews(reviewData);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  const mainReviewValues = reviews
    .filter((review) => review.main_review !== undefined) // undefined 방지
    .map((review) => review.main_review);

  const updateMainReview = async (reviewId: number, on: boolean) => {
    try {
      if (on) {
        // 기존에 main_review가 true인 리뷰를 찾아서 false로 업데이트
        const mainReviews = reviews.find((review) => review.main_review === true);

        if (mainReviews && mainReviews.id !== undefined) {
          await productService.updateMainReview(mainReviews.id, false);
        }
      }

      // 선택된 리뷰의 main_review 값을 on으로 업데이트
      const updatedReview = await productService.updateMainReview(reviewId, on);
      if (updatedReview) {
        reviewsData(); // 최신 리뷰 데이터 다시 가져오기
      }
    } catch (err) {
      console.log('리뷰 업데이트 오류:', err);
    }
  };

  function getProductTypeName(type?: ProductType) {
    if (type === ProductType.COFFEECHAT) {
      return 'Coffee Chat';
    } else if (type === ProductType.INTERVIEW) {
      return 'Interview Practive';
    } else if (type === ProductType.REVIEW) {
      return 'Resume';
    }

    return '';
  }

  const renderRating = (rating: number) => {
    return (
      <div className={styles['rating-container']}>
        {[...Array(rating)].map((_, index) => (
          <img
            key={index}
            src={starIcon}
            alt='Ratings'
            className={styles['star-icon']}
          />
        ))}
      </div>
    );
  };

  // 각 리뷰의 ID에 따라 리뷰 상세 페이지로 이동, state로 review객체 전달
  const toReview = (review: ProductReview) => {
    navigate(`/meeting/myReview/detail`, { state: { review } });
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-container']}>{'Manage My Review'}</div>
      <div className={styles['review-container']}>
        {reviews.find((review) => review.main_review === true) && (
          <div>
            {/* main_review가 true인 첫 번째 리뷰 */}
            {reviews
              .filter((review) => review.main_review === true) // main_review가 true인 리뷰들만 필터링
              .map((review) => (
                <div
                  key={review.id}
                  className={styles['review-item']}
                >
                  <div className={styles['container-flex']}>
                    <img
                      src={
                        review.user?.profile_image
                          ? `${process.env.REACT_APP_STORE_ADDRESS}/${review.user.profile_image}`
                          : default_profile
                      }
                      alt=''
                      className={styles['profile-image']}
                    />
                    <div className={styles['nickname']}>
                      {getProductTypeName(review.product?.type)} with {review.user?.nickname}
                    </div>
                  </div>
                  <div className={styles['review-rating']}>
                    <div className={styles['container-flex']}>
                      {renderRating(review.score)}
                      {review.score}
                    </div>
                  </div>
                  <div className={styles['review-date']}>
                    <>Meeting Date : {dayjs(review.created_date).format('MMM D, YYYY, hh:mm A')}</>
                  </div>
                  <div className={styles['review-flex']}>
                    <Button
                      width='100%'
                      marginTop={2}
                      marginBottom={1}
                      height='4rem'
                      radius={8}
                      activeBorderColor={'var(--color-grey100)'}
                      bgColor={'#fff'}
                      activeTextColor={'var(--color-black1000)'}
                      active
                      onClick={() => updateMainReview(review.id!, false)} // 개별 리뷰의 ID 전달
                      activeFontSize='1.4rem'
                    >
                      Hide from Profile
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        )}

        {reviews.length >= 1 ? (
          <div>
            {reviews
              .filter((review) => review.main_review === false) // main_review가 false 리뷰들만 필터링
              .map((review) => (
                <div
                  key={review.id}
                  className={styles['review-item']}
                >
                  <div className={styles['container-flex']}>
                    <img
                      src={
                        review.user?.profile_image
                          ? `${process.env.REACT_APP_STORE_ADDRESS}/${review.user.profile_image}`
                          : default_profile
                      }
                      alt=''
                      className={styles['profile-image']}
                    />
                    <div className={styles['nickname']}>
                      {getProductTypeName(review.product?.type)} with {review.user?.nickname}
                    </div>
                  </div>
                  <div className={styles['review-rating']}>
                    <div className={styles['container-flex']}>
                      {renderRating(review.score)}
                      {review.score}
                    </div>
                  </div>
                  <div className={styles['review-date']}>
                    <>Meeting Date : {dayjs(review.created_date).format('MMM D, YYYY, hh:mm A')}</>
                  </div>
                  <div className={styles['review-flex']}>
                    <Button
                      width='100%'
                      marginTop={2}
                      marginBottom={1}
                      height='4rem'
                      radius={8}
                      activeBorderColor={'var(--color-grey100)'}
                      bgColor={'#fff'}
                      activeTextColor={'var(--color-black1000)'}
                      active
                      onClick={() => updateMainReview(review.id!, true)} // 개별 리뷰의 ID 전달
                      activeFontSize='1.4rem'
                    >
                      Pin This Review
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div>
            <h1>No reviews yet</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyManageReview;
