import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './WithdrawRequestCompleted.module.css';
import cashoutIcon from '../../assets/images/ic_cashout.png';

function WithdrawRequestCompleted() {
  const navigate = useNavigate();

  const toCalculation = () => {
    navigate('/calculation');
  };



  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'Request successful!'}</div>
      <div className={styles['text']}>{'Payment schedule:\r\n• Request by 20th → Paid by 31st\r\n• Request after 20th → Paid by end of next month'}</div>

      <div className={styles['img-container']}>
        <img
          src={cashoutIcon}
          alt=''
        />
      </div>

      <div className={styles['button-container']}>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={true}
          activeColor='white'
          activeTextColor='black'
          activeBorderColor='var(--color-black_transparent_12)'
          onClick={toCalculation}
        >
          Back to Payout
        </Button>
      </div>
    </div>
  );
}

export default WithdrawRequestCompleted;
