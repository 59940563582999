import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import styles from './EmailVerification.module.css';
import TextField from '../../components/element/TextField';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import authService from '../../services/authService';
import { VerifyStatus } from '../../constants/constants';

function EmailVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [emailVerifyStatus, setEmailVerifyStatus] = useState<VerifyStatus>(VerifyStatus.NONE);
  const { email } = location.state || { email: '' };
  const [formData, setFormData] = useState<any>({
    emailVerifyCode: '',
  });

  const inputRules: any = {
    emailVerifyCode: {
      minLength: 6,
      maxLength: 6,
    },
  };
  console.log(email);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const rule = inputRules[name];
    let formattedValue = value;

    if (rule.maxLength && value.length > rule.maxLength) {
      formattedValue = value.slice(0, rule.maxLength); //글자 수 초과하지 않게.
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  async function checkEmailVerifyCode() {
    if (formData.email === '' || emailVerifyStatus === VerifyStatus.COMPLETE) return;

    const result: boolean = await authService.checkVerifyCode(email, formData.emailVerifyCode);
    if (!result) {
      toast('error', 'Invalid verification code', false);
      return;
    }

    //이메일 인증 완료.
    setEmailVerifyStatus(VerifyStatus.COMPLETE);
    toast('success', 'Email verification successful!', false);
  }

  const toNext = () => {
    if (emailVerifyStatus === VerifyStatus.COMPLETE) {
      navigate('/auth/setPassword', { state: { email } });
      toast('success', 'Email verification successful!', false);
    } else {
      toast('error', 'Email already in use!', false);
    }
  };

  return (
    <div>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-container']}>{'We’ve sent the code to your email.'}</div>
      <div className={styles['label-flex']}>
        <label className={styles['label']}>Email verification code</label>
        <div className={styles['label-description']}>The verification code is valid for 3 minutes.</div>
      </div>

      <div className={styles['input-field-container']}>
        <TextField
          name='emailVerifyCode'
          type='number'
          value={formData.emailVerifyCode}
          enable={emailVerifyStatus !== VerifyStatus.COMPLETE}
          actionButtonTitle={formData.emailVerifyCode === '' ? 'Pending' : 'Verified'}
          onAction={() => checkEmailVerifyCode()}
          actionButtonEnable={
            formData.emailVerifyCode !== '' && emailVerifyStatus !== VerifyStatus.COMPLETE
          } //이메일이 입력되었으면서 인증 완료를 아직 안한 경우.
          placeholder='Enter the verification code'
          onChange={(event) => handleChange(event)}
        />
      </div>

      <Button
        marginHorizontalRem={2.0}
        active={emailVerifyStatus === VerifyStatus.COMPLETE} // 인증번호가 입력된 경우 활성화
        onClick={toNext}
      >
        Check
      </Button>
    </div>
  );
}

export default EmailVerification;
