import React from 'react';
import Header from '../../components/layout/Header';
import { useNavigate } from 'react-router-dom';
import styles from './CustomerServiceMailCompleted.module.css';
import Button from '../../components/element/Button';

function CustomerServiceMailCompleted() {
  const navigate = useNavigate();

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'Message received!'}</div>
      <div className={styles['text']}>
        {
          "We'll review your message and get back to you soon"
        }
      </div>
      <div className={styles['button-container']}>
        <Button
          width={'90%'}
          marginHorizontalRem={2.0}
          active={true}
          onClick={() => navigate('/')}
        >
          Close
        </Button>
      </div>
    </div>
  );
}

export default CustomerServiceMailCompleted;
