import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import styles from './ReportCompleted.module.css';
import Button from '../../components/element/Button';

function ReportCompleted() {
  const navigate = useNavigate();
  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'The profile has been blocked'}</div>
      <div className={styles['text']}>{'Thank you for your valuable feedback'}</div>
      <div className={styles['button-container']}>
        <Button
          width='unset'
          marginHorizontalRem={2}
          active={true}
          onClick={() => navigate('/')}
        >
          OK
        </Button>
      </div>
    </div>
  );
}

export default ReportCompleted;
