import React from 'react';
import styles from './Comments.module.css';
import MdShare from '../../../../assets/images/ic_share.svg';
import MdReport from '../../../../assets/images/ic_report.svg';
import MdBookmark_off from '../../../../assets/images/ic_bookmark_off.svg';

function Comments() {
  return (
    <div className={styles.commentsContainer}>
      <div className={styles.commentText}>
        0 Comments
      </div>
      <div className={styles.iconContainer}>
        <img src={MdShare} alt="Share Icon" className={styles.icon} />
        <img src={MdReport} alt="Report Icon" className={styles.icon} />
        <img src={MdBookmark_off} alt="Bookmark Icon" className={styles.icon} />
      </div>
    </div>
  );
}

export default Comments;
