import { create } from 'zustand';
import { ToastItem, ToastType } from '@/models/toastItem';

interface ToastStore {
  toastList: ToastItem[];
  addToastList: (element: ToastItem) => void;
  removeToastList: (id: string) => void;
}

const useToastStore = create<ToastStore>(
    (set) => ({
        toastList: [], //토스트 목록.
        addToastList: (element: ToastItem) => set((state) => ({ toastList: [...state.toastList, element] })),
        removeToastList: (id: string) => set((state) => ({ toastList: state.toastList.filter((toast) => toast.id !== id) })),
    }),
);

export default useToastStore;