import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Resuggested.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';

import confirm from '../../assets/images/ic_confirm.png';

function MeetingResuggested() {
  const navigate = useNavigate();
  const toast = useToast();
  const toMeetingList = () => {
    navigate('/meeting/myMeeting')
  }
  return (
    <div>
      <Header onBack={() => navigate(-1)}/>
      <div className={styles['title-text']}>You've suggested 
        new meeting times</div>
      <div className={styles['title-hint-text']}>{"As the requester selects the preferred time,\r\nwe'll notify you once the schedule is confirmed."}</div>
      <div className={styles['image']}><img src={confirm} alt=""/></div>
      <div className={styles['button-container']}>
        <Button 
            height={'5.6rem'}
            radius={12}
            activeFontSize='1.6rem' 
            activeFontWeight={700}
            active={true}
            onClick={toMeetingList}>View My Meetings</Button>
      </div>
    </div>
  );
}
  
export default MeetingResuggested;
  