'use client'
import ReactDOM from 'react-dom'
import useToastStore from '../../stores/toastStore'
import Toast from './Toast'

function ToastList() {
  const { toastList, removeToastList } = useToastStore() // 전역 상태에서 toastList를 가져옴

  if (typeof document === 'undefined' || toastList.length === 0) return null // 클라이언트에서만 실행되도록
  const element = document.querySelector('body')
  if (!element) return null
  
  return ReactDOM.createPortal(
    <div>
      {toastList.map((toast) => (
        <Toast key={toast.id} type={toast.type} message={toast.message} bottom={toast.bottom} onClose={() => removeToastList(toast.id!)}/>
      ))}
    </div>,
    element
  )
}

export default ToastList;