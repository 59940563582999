import React from 'react';
import styles from './PurchaseConfirm.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';

import { useNavigate } from 'react-router-dom';
import confirmImgage from '../../assets/images/ic_confirm_image.png';
const PurchaseConfirm = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['container']}>
        <div className={styles['confirm-title']}>
          {'Leave a review\r\nand confirm your purchase'}
        </div>
        <div className={styles['confirm-text']}>
          {
            'Leave a warm review to thank the mate. Confirm\r\nyour purchase to speed up the mate’s payment.\r\nIf not confirmed in 7 days, it will auto-confirm.'
          }
        </div>
        <div className={styles['confirm-image-container']}>
          <img
            src={confirmImgage}
            alt=''
          />
        </div>

        <div className={styles['confirm-info']}>
          {'For any inquiries, contact us within 3 days.\r\nMeeting records are kept for 7 days.'}
        </div>
        <Button
          active={true}
          marginBottom={2}
          onClick={() => navigate('/meeting/myMeeting')}
        >
          Write a review and confirm purchase
        </Button>
        <div
          className={styles['confirm-support']}
          onClick={() => navigate('/cs/customerService ')}
        >
          {'Contact Customer Support'}
        </div>
      </div>
    </>
  );
};

export default PurchaseConfirm;
