import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import './index.css';
import App from './App';
import ToastList from './components/toast/ToastList';
import reportWebVitals from './reportWebVitals';
import EN from './assets/languages/en.json';
import KR from './assets/languages/kr.json';
import { setCSSVariables } from './styles/colors/colors';

setCSSVariables();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // default language
  resources: {
    en: {
      translation: EN,
    },
    kr: {
      translation: KR,
    },
  },
});

root.render(
  /*<React.StrictMode>*/
  <I18nextProvider i18n={i18n}>
    <App />
    <ToastList />
  </I18nextProvider>
  /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
