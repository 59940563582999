import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './WithdrawRequest.module.css';

function WithdrawRequest() {
  const navigate = useNavigate();
  const location = useLocation();
  const method = location.state.method;
  const amountData = location.state.amount;
  const [amount, setAmount] = useState<number>(0); // 입력 값을 숫자형으로 저장할 상태
  const [available, setAvailable] = useState<number>(amountData); // 입력 값을 숫자형으로 저장할 상태
  // 입력 값을 설정할 때 available 값으로 제한
  console.log(method);
  console.log(amountData);
  const updateAmount = (newAmount: number) => {
    setAmount(newAmount > amountData ? amountData : newAmount);
  };
  const handleButtonClick = (value: number) => {
    if (amount.toString().length < 5) {
      const newAmount = amount * 10 + value;
      updateAmount(newAmount);
    }
  };

  const handleDoubleZeroClick = (value: number) => {
    if (amount.toString().length < 5) {
      setAmount((prevAmount) => prevAmount * 100); // '00' 추가
    }
  };

  const handleClear = () => {
    setAmount(0); // 입력 값 초기화
  };

  const toNext = () => {
    if (amount > 0) {
      // 다음 화면으로 숫자 값만 전달
      navigate('/calculation/request/withdraw/confirm', { state: { amount, method } });
    }
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title']}>{'Withdrawal amount'}</div>
      <div className={styles['withdraw-container']}>
        <input
          type='text'
          placeholder='$0'
          value={`${amount === 0 ? '' : `$${amount}`}`} // $와 숫자 표시
          readOnly={true} // 사용자가 직접 입력하지 않도록 설정
        />
      </div>
      <div className={styles['money-container']}>{`Available: $${available}`}</div>
      <div className={styles['text']}>
        {
          'SWIFT: Min. 30 USD\r\nPayment: 25th-31st (request by 20th)\r\nOnly confirmed amounts can be withdrawn.'
        }
      </div>
      <div className={styles['number-container']}>
        <span onClick={() => handleButtonClick(1)}>1</span>
        <span onClick={() => handleButtonClick(2)}>2</span>
        <span onClick={() => handleButtonClick(3)}>3</span>
        <span onClick={() => handleButtonClick(4)}>4</span>
        <span onClick={() => handleButtonClick(5)}>5</span>
        <span onClick={() => handleButtonClick(6)}>6</span>
        <span onClick={() => handleButtonClick(7)}>7</span>
        <span onClick={() => handleButtonClick(8)}>8</span>
        <span onClick={() => handleButtonClick(9)}>9</span>
        <span onClick={() => handleDoubleZeroClick(0)}>00</span>
        <span onClick={() => handleButtonClick(0)}>0</span>
        <span onClick={handleClear}>{'<'}</span> {/* Clear 버튼 */}
      </div>
      <div className={styles['button-container']}>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={amount > 0} // amount가 비어있지 않으면 true
          onClick={toNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default WithdrawRequest;
