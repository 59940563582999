import { post } from '../utils/apiClient';

class UploadService {
  async addImage(folder: string, formData: any) {
    try {
      const response = await post(
        `${process.env.REACT_APP_API_SERVER_ADDRESS}/upload/image/` + folder,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  // 일반 파일 업로드
  async addFile(folder: string, formData: FormData) {
    try {
      const response = await post(
        `${process.env.REACT_APP_API_SERVER_ADDRESS}/upload/data/` + folder,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
}

const uploadService = new UploadService();
export default uploadService;
