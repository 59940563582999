import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './ProfileComplete.module.css';

import profileCompleteIcon from '../../assets/images/ic_mate_profile_complete.png';

function MateProfileComplete() {
  const navigate = useNavigate();

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-title']}>Your profile has been submitted!</div>
      <div className={styles['label-description']}>
        {"Nailedit team will review your profile\r\nWe'll update you on your Mate status soon!"}
      </div>
      <div className={styles['complete-image']}>
        <img
          src={profileCompleteIcon}
          width={'16.0rem'}
          height={'16.0rem'}
          alt=''
        />
      </div>
      <div className={styles['title']}>{'Keep your notifications on for updates!'}</div>
      <div
        className={styles['link-text']}
        onClick={() => navigate('/setting/notification')}
      >
        Enable Notifications
      </div>
      <div className={styles['button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={2.0}
          active={true}
          onClick={() => navigate('/menu')}
        >
          Got it
        </Button>
      </div>
    </div>
  );
}

export default MateProfileComplete;
